import { Button } from "@mui/material";
import InputField from "components/forms/InputField";
import TextAreaField from "components/forms/TextAreaField";
import Modal from "components/Modal";
import MessageModal from "components/Modal/MessageModal";
import ServicesIcon from "components/Vectors/ServicesIcon";
import { useState } from "react";

function EnableModal({
  openModal,
  setopenModal,
  otherState,
  disabledTitle,
  title,
}) {
  const [openSuccessModal, setopenSuccessModal] = useState(false);

  const handleClick = () => {
    if (otherState) {
      otherState();
    }

    setopenModal(false);
    setopenSuccessModal(true);
  };

  return (
    <>
      <Modal
        title={title ? title : `Enable Service`}
        openModal={openModal}
        closeModal={() => setopenModal(false)}
      >
        <form className="flex flex-col h-full flex-grow justify-between">
          <div>
            <InputField label="Email Address " placeholder="user@gmail.com" />
            <TextAreaField label="Send a message" placeholder="Enter text..." />
          </div>
          <Button
            onClick={() => handleClick()}
            sx={{ mt: 5 }}
            variant="contained"
            fullWidth
            // color="success"
          >
            Enable
          </Button>
        </form>
      </Modal>

      <MessageModal
        openModal={openSuccessModal}
        icon={<ServicesIcon color="#8585A3" />}
        closeModal={() => setopenSuccessModal(false)}
        iconBg={"bg-[#EAEAF0]"}
        title={`${disabledTitle ? disabledTitle : "Service Disabled"}`}
        description="A message has been sent to Nneka Chukwu"
      />
    </>
  );
}

export default EnableModal;
