import countries from "./countries";

export const callingCodes = countries
  .map(country => {
    return country.callingCodes.map(code => ({
      name: country.name,
      alpha3code: country.alpha3Code,
      flag: country.flag,
      callingCode: code,
      alpha2Code: country.alpha2Code,
    }));
  })
  .flat()
  .filter(country => country.callingCode);
