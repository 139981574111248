import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {CustomTabStyle2} from "App";
import {useState} from "react";
import TransactionsTab from "./Tabs/TransactionsTab";
import PayoutsTab from "./Tabs/PayoutsTab";
// import RefundTab from "./Tabs/RefundTab";
import ReferralsTab from "./Tabs/ReferralTab";
import {
  // useGetPayoutsStatsQuery, useGetRefundStatsQuery,
  useGetTransactionsStatsQuery
} from "services/transactions.api";

function Transactions() {
  const [activeMainTab, setactiveMainTab] = useState("txns");
  // const {data: refundStats} = useGetRefundStatsQuery();
  // const {data: influencerPayoutStats} = useGetPayoutsStatsQuery('influencer')
  // const {data: creatorPayoutStats} = useGetPayoutsStatsQuery('creator')
  const {data: transactionStats , isLoading: isTransactionStatsLoading} = useGetTransactionsStatsQuery();

  return (
    <div className="h-full p-6 lg:px-10 px-4 bg-grey100 ">
      <TabContext value={activeMainTab}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          className="font-semibold"
          value={activeMainTab}
          sx={{
            fontWeight: 600,
            ...CustomTabStyle2,
            textTransform: "capitalize",
            ".MuiTab-root:first-child": {
              paddingLeft: "0",
            },
          }}
          onChange={(e, newValue) => setactiveMainTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Transactions" value="txns" />
          <Tab
            label={
              <div className="flex gap-3 items-center">
               Earning Payouts
                <div className="flex justify-center items-center text-white text-sm bg-[#D61E34] font-semibold rounded-xl w-[34px] h-25px]">
                 {!isTransactionStatsLoading?.pending_withdrawals_count ? transactionStats?.data?.withdrawal?.pending_withdrawals_count: "..."}
                </div>
              </div>
            }
            value="payouts"
          />
          {/* <Tab
            label={
              <div className="flex gap-3 items-center">
                Refunds
                <div className="flex justify-center items-center text-white text-sm bg-[#D61E34] font-semibold rounded-xl w-[34px] h-25px]">
                  { !isTransactionStatsLoading ?  transactionStats?.data?.refund?.pending_refunds_count : "..."}
                </div>
              </div>
            }
            value="refunds"
          /> */}
            <Tab
            label={
              <div className="flex gap-3 items-center">
                Referrals
                <div className="flex justify-center items-center text-white text-sm bg-[#D61E34] font-semibold rounded-xl w-[34px] h-25px]">
                  { !isTransactionStatsLoading?  transactionStats?.data?.referral?.total_referred_users : "..."}
                </div>
              </div>
            }
            value="referrals"
          />

        </TabList>
        <TabPanel value="txns">
          <TransactionsTab />
        </TabPanel>
        <TabPanel value="payouts">
          <PayoutsTab />
        </TabPanel>
        {/* <TabPanel value="refunds">
          <RefundTab />
        </TabPanel> */}
        <TabPanel value="referrals">
          <ReferralsTab />
        </TabPanel>
      </TabContext>
    </div>
  );
}

export default Transactions;
