const EmptyUsers = () => {
 return (
  <div className="flex items-center justify-center flex-col gap-4">
   <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
     d="M43.1735 5.33325H20.8268C11.1202 5.33325 5.3335 11.1199 5.3335 20.8266V43.1733C5.3335 50.6666 8.7735 55.8133 14.8268 57.7599C16.5868 58.3733 18.6135 58.6666 20.8268 58.6666H43.1735C45.3868 58.6666 47.4135 58.3733 49.1735 57.7599C55.2268 55.8133 58.6668 50.6666 58.6668 43.1733V20.8266C58.6668 11.1199 52.8802 5.33325 43.1735 5.33325ZM54.6668 43.1733C54.6668 48.8799 52.4268 52.4799 47.9202 53.9733C45.3335 48.8799 39.2002 45.2533 32.0002 45.2533C24.8002 45.2533 18.6935 48.8533 16.0802 53.9733H16.0535C11.6002 52.5333 9.3335 48.9066 9.3335 43.1999V20.8266C9.3335 13.3066 13.3068 9.33325 20.8268 9.33325H43.1735C50.6935 9.33325 54.6668 13.3066 54.6668 20.8266V43.1733Z"
     fill="#DCDCE5"
    />
    <path
     d="M32.0003 21.3333C26.7203 21.3333 22.4536 25.5999 22.4536 30.8799C22.4536 36.1599 26.7203 40.4533 32.0003 40.4533C37.2803 40.4533 41.5469 36.1599 41.5469 30.8799C41.5469 25.5999 37.2803 21.3333 32.0003 21.3333Z"
     fill="#DCDCE5"
    />
   </svg>

   <h4 className="text-[#53536F] font-semibold text-[18px]">No new users</h4>
  </div>
 );
};

export default EmptyUsers;
