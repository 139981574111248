import WestRoundedIcon from '@mui/icons-material/WestRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import Modal from 'components/Modal';
import { IconButton } from '@mui/material';

const CarouselModal = ({
    otherChildren,
    closeModal,
    openModal = true,
    setopenModal,
    otherClasses,
    handleNext,
    handlePrev,
    img,
}) => {
    return (
        <Modal title="" openModal={openModal} closeModal={() => setopenModal(false)} hideScroll={true}>
            <div className="flex py-8 relative justify-center items-center gap-4 ">
                <div className="absolute left-[-10px] ">
                    <IconButton
                        onClick={() => handlePrev()}
                        className="border-[2px] bg-[red] "
                        sx={{
                            background: '#F9F9FB !important',
                            height: '40px',
                            width: '40px',
                            border: '1px solid #EAEAF0',
                            borderRadius: 999,
                        }}
                    >
                        <WestRoundedIcon />
                    </IconButton>
                </div>
                <img src={img} alt="carousel-img" className="w-fit h-[300px] " />

                <div className="absolute right-[-10px] ">
                    <IconButton
                        className="w-8 h-8"
                        sx={{
                            background: '#F9F9FB !important',
                            height: '40px',
                            width: '40px',
                            border: '1px solid #EAEAF0',
                            borderRadius: 999,
                        }}
                        border="bg-grey10"
                        onClick={() => handleNext()}
                    >
                        <EastRoundedIcon color="grey20" />
                    </IconButton>
                </div>
            </div>
        </Modal>
    );
};

export default CarouselModal;
