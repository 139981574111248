import { Button } from '@mui/material';
import LogoIcon from 'components/LogoIcon';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BasicAuthLayout({ children, showBackBtn = false }) {
    return (
        <div className="flex scrollbar-style text-sm text-grey justify-center min-h-[100vh] lg:pt-16 pt-10 auth-layout">
            <div className="inner relative flex flex-col items-center">
                {showBackBtn && (
                    <Button
                        component={Link}
                        to={`/login`}
                        className="absolute top-0 -left-4 md:left-0"
                        sx={{ position: 'absolute', p: 0 }}
                        startIcon={<ArrowBackIcon />}
                    >
                        <span className="hidden md:block">back to Login</span>
                    </Button>
                )}
                <LogoIcon className="mb-14 mx-auto" />
                {children}
            </div>
        </div>
    );
}

export default BasicAuthLayout;
