import {Rating} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Star from "@mui/icons-material/Star";

function CustomRating({
  value,
  textBlack = false,
  className = "",
  mobile = false,
  responsive = false,
}) {
  return (
    <div className={`flex space-x-2 items-center ${className}`}>
      {!mobile ? (
        <>
          <Rating
            emptyIcon={<StarIcon color="greyBorder" fontSize="inherit" />}
            name="rating"
            defaultValue={value}
            precision={0.5}
            readOnly
            sx={{
              display: {
                xs: !responsive ? "flex !important" : "none !important",
                md: !responsive ? "none !important" : "flex !important",
              },
            }}
          />
          <Star
            className={`${responsive ? "hidden lg:block" : "hidden"}`}
            sx={{
              display: {
                xs: responsive ? "flex !important" : "none !important",
                md: responsive ? "none !important" : "flex !important",
              },
              color: "#FFB33E",
            }}
          />
        </>
      ) : (
        <Star sx={{color: "#FFB33E"}} />
      )}
      <p
        className={`font-semibold ${
          !textBlack ? "text-[#FFB33E]" : "text-grey10"
        }`}
      >
        {value}
      </p>
    </div>
  );
}

export default CustomRating;
