function BagIcon(props) {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M47.0734 20.9066C45.5101 19.1799 43.1534 18.1766 39.8867 17.8266V16.0533C39.8867 12.8566 38.5334 9.7766 36.1534 7.62993C33.7501 5.4366 30.6234 4.40993 27.3801 4.71326C21.8034 5.24993 17.1134 10.6399 17.1134 16.4733V17.8266C13.8467 18.1766 11.4901 19.1799 9.92672 20.9066C7.66339 23.4266 7.73339 26.7866 7.99006 29.1199L9.62339 42.1166C10.1134 46.6666 11.9567 51.3333 21.9901 51.3333H35.0101C45.0434 51.3333 46.8867 46.6666 47.3767 42.1399L49.0101 29.0966C49.2667 26.7866 49.3134 23.4266 47.0734 20.9066ZM27.7067 7.9566C30.0401 7.7466 32.2567 8.46993 33.9834 10.0333C35.6867 11.5733 36.6434 13.7666 36.6434 16.0533V17.6866H20.3567V16.4733C20.3567 12.3199 23.7867 8.32993 27.7067 7.9566ZM20.1467 30.6833H20.1234C18.8401 30.6833 17.7901 29.6333 17.7901 28.3499C17.7901 27.0666 18.8401 26.0166 20.1234 26.0166C21.4301 26.0166 22.4801 27.0666 22.4801 28.3499C22.4801 29.6333 21.4301 30.6833 20.1467 30.6833ZM36.4801 30.6833H36.4567C35.1734 30.6833 34.1234 29.6333 34.1234 28.3499C34.1234 27.0666 35.1734 26.0166 36.4567 26.0166C37.7634 26.0166 38.8134 27.0666 38.8134 28.3499C38.8134 29.6333 37.7634 30.6833 36.4801 30.6833Z"
        fill={props.color || "#EAEAF0"}
      />
    </svg>
  );
}

export default BagIcon;
