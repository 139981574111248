import { formatTimeElapsed, getUserDetails, toCurrency } from "utils";
import PayoutDetailsModal from "../../Transactions/Widgets/PayoutDetailsModal"
import { useState } from "react";

const PayoutRequestContent = ({
  // senderImg,
  // name,
  // amount,
  // handle,
  // title,
  // description,
  // date,
  data
}) => {
  const [openModal, setopenModal] = useState(false)
  // name={getUserDetails(data?.user).name}
  //                             handle={data?.user?.username}
  //                             senderImg={data?.user?.profile_image}
  //                             amount={toCurrency(data?.amount)}
  //                             description={data?.description}
  //                             date={data?.created_at}

 
  return (
    <div className="flex flex-col border-b[#BFBFCF] border-b[1px] pb[24px] ">
      <div className="flex justify-between ">
        <div className="flex items-center gap-2  ">
          <div className="h-[40px] w-[40px] rounded-full ">
            <img
              src={getUserDetails(data?.user).img}
              alt="buyer"
              className="h-[40px] w-[40px] rounded-full "
            />
          </div>
          <div className=" ">
            <h4 className="font-medium text-[1rem] text-prop text-grey10 ">
              {getUserDetails(data?.user).name}
            </h4>
            <span className="text-prop font-medium text-grey text-[14px]  ">
              {getUserDetails(data?.user).username}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-end  ">
          <p className="text-prop text-[1rem] text-grey font-medium ">
           {formatTimeElapsed(data?.created_at)} 
          </p>
          <h5 className="text-prop font-semibold text-[20px] text-[#000] ">
            {toCurrency(data?.amount)}
          </h5>
        </div>
      </div>
      <div className="mt-[14px] ">
        {/* <h5 className="font-semibold text-[18px] text-prop text-grey10 ">
          {title}
        </h5> */}
        <p className="mt-[8px] font-medium text-[14px] tracking-[-2%] leading-[24px] text-grey30  ">
          {data?.description}
        </p>
      </div>
      <div className="mt-[24px] flex justify-between items-center ">
        {/* <Link to="/request/12?status=pending"> */}
          <button onClick={()=> setopenModal(true)} className="text-primary font-semibold text-[1rem] tracking-[-2%] leading-[24px] ">
            Review Request
          </button>
        {/* </Link> */}
      </div>
      <PayoutDetailsModal status='payout' data={data} openModal={openModal} setopenModal={setopenModal} />

    </div>
  );
};
export default PayoutRequestContent;
