const ProfileDeleteIcon = ({width, height}) => {
  return (
    <svg
      width={width || "64"}
      height={height || "64"}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.5732 57.76C41.0665 57.76 40.5598 57.5733 40.1598 57.1733C39.3865 56.4 39.3865 55.12 40.1598 54.3466L47.6798 46.8266C48.4532 46.0533 49.7332 46.0533 50.5065 46.8266C51.2798 47.6 51.2798 48.88 50.5065 49.6533L42.9865 57.1733C42.5865 57.5733 42.0798 57.76 41.5732 57.76Z"
        fill="#8937CE"
      />
      <path
        d="M49.0932 57.76C48.5865 57.76 48.0798 57.5733 47.6798 57.1733L40.1598 49.6533C39.3865 48.88 39.3865 47.6 40.1598 46.8266C40.9332 46.0533 42.2132 46.0533 42.9865 46.8266L50.5065 54.3466C51.2798 55.12 51.2798 56.4 50.5065 57.1733C50.1065 57.5733 49.5998 57.76 49.0932 57.76Z"
        fill="#8937CE"
      />
      <path
        d="M32.4265 30.9866C32.3465 30.9866 32.2931 30.9866 32.2131 30.9866C32.0798 30.96 31.8931 30.96 31.7331 30.9866C23.9998 30.7466 18.1598 24.6666 18.1598 17.1733C18.1598 9.54665 24.3731 3.33331 31.9998 3.33331C39.6265 3.33331 45.8398 9.54665 45.8398 17.1733C45.8131 24.6666 39.9465 30.7466 32.5065 30.9866C32.4798 30.9866 32.4531 30.9866 32.4265 30.9866ZM31.9998 7.33331C26.5865 7.33331 22.1598 11.76 22.1598 17.1733C22.1598 22.5066 26.3198 26.8 31.6265 26.9866C31.7865 26.96 32.1331 26.96 32.4798 26.9866C37.7065 26.7466 41.8131 22.4533 41.8398 17.1733C41.8398 11.76 37.4131 7.33331 31.9998 7.33331Z"
        fill="#8937CE"
      />
      <path
        d="M31.9998 60.16C26.5598 60.16 21.4132 58.7467 17.5198 56.1333C13.8132 53.6533 11.7865 50.2667 11.7865 46.6133C11.7865 42.96 13.8398 39.6 17.5198 37.1467C25.4932 31.8133 38.4532 31.8133 46.4265 37.1467C47.3332 37.76 47.5998 39.0133 46.9865 39.92C46.3732 40.8533 45.1198 41.0933 44.2132 40.48C37.5732 36.0533 26.3732 36.0533 19.7332 40.48C17.1732 42.1867 15.7865 44.3467 15.7865 46.6133C15.7865 48.88 17.1998 51.0933 19.7332 52.8C22.9598 54.96 27.3065 56.1333 31.9732 56.1333C33.0665 56.1333 33.9732 57.04 33.9732 58.1333C33.9732 59.2267 33.0932 60.16 31.9998 60.16Z"
        fill="#8937CE"
      />
    </svg>
  );
};

export default ProfileDeleteIcon;
