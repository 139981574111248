import { Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StatCard from "components/StatCard";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import CampaignsTab from "./Widgets/CampaignsTab";
import VideoCameraIcon from "components/Vectors/VideoCameraIcon";
import { useGetAllCampaignsStatisticsQuery } from "services/campaigns.api";

function AdminCampaigns() {
 const [activeTab, setactiveTab] = useState("ongoing");
 const navigate = useNavigate();
 let { search } = useLocation();
 const params = new URLSearchParams(search);
 const tab = params.get("tab");
 useEffect(() => {
  if (tab) setactiveTab(tab);
 }, [tab]);

 const { data: stats } = useGetAllCampaignsStatisticsQuery();
 // console.log(stats)
 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Campaigns</h1>
   </div>
   <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
    <StatCard
     iconDown
     name="All campaigns"
     value={stats?.data?.total}
     iconBg="bg-[#FAF5FF]"
     icon={<VideoCameraIcon width={32} height={32} color="#8937CE" />}
    />
    <StatCard
     iconDown
     name="Campaigns Pending Approval"
     value={stats?.data?.pending}
     iconBg="bg-[#F6A00114]"
     icon={<VideoCameraIcon width={32} height={32} color="#F6A001" />}
    />
    <StatCard
     iconDown
     name="Ongoing campaigns"
     value={stats?.data?.ongoing}
     iconBg="bg-[#FAF5FF]"
     icon={<VideoCameraIcon width={32} height={32} color="#8937CE" />}
    />
    <StatCard
     iconDown
     name="Completed campaigns"
     value={stats?.data?.completed}
     iconBg="bg-[#E5F7ED]"
     icon={<VideoCameraIcon width={32} height={32} color="#00A04A" />}
    />
    {/* <StatCard
                    iconDown
                    name="Suspended campaigns"
                    value={stats?.data?.suspended}
                    iconBg="bg-[#EAEAF094]"
                    icon={<VideoCameraIcon width={32} height={32} color="#8585A3" />}
                /> */}
   </div>

   <div className="mt-5">
    <TabContext value={activeTab}>
     <TabList
      variant="scrollable"
      scrollButtons="auto"
      className="mt-6 font-semibold"
      value={activeTab}
      sx={{
       textTransform: "capitalize",
      }}
      onChange={(e, newValue) => {
       navigate(`?tab=${newValue}`);
       setactiveTab(newValue);
      }}
      aria-label="basic tabs example"
     >
      <Tab label={"Ongoing Campaigns"} value="ongoing" />
      <Tab label={"Completed Campaigns"} value="completed" />
      <Tab label="Closed Campaigns" value="closed" />
      {/* <Tab label={"Suspended campaigns"} value="suspended" /> */}
     </TabList>
     <TabPanel value="ongoing">
      <CampaignsTab status="ongoing" />
     </TabPanel>
     <TabPanel value="completed">
      <CampaignsTab status="completed" />
     </TabPanel>
     <TabPanel value="closed">
      <CampaignsTab status="closed" />
     </TabPanel>
     {/* <TabPanel value="suspended">
      <CampaignsTab status="suspended" />
     </TabPanel> */}
    </TabContext>
   </div>
  </div>
 );
}

export default AdminCampaigns;
