function DownloadIconCircle(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={props.color || "white"}
      />
      <path
        d="M12 15.25C11.59 15.25 11.25 14.91 11.25 14.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V14.5C12.75 14.91 12.41 15.25 12 15.25Z"
        fill={props.color || "white"}
      />
      <path
        d="M12 16.2495C11.81 16.2495 11.62 16.1795 11.47 16.0295L8.47 13.0295C8.18 12.7395 8.18 12.2595 8.47 11.9695C8.76 11.6795 9.24 11.6795 9.53 11.9695L12 14.4395L14.47 11.9695C14.76 11.6795 15.24 11.6795 15.53 11.9695C15.82 12.2595 15.82 12.7395 15.53 13.0295L12.53 16.0295C12.38 16.1795 12.19 16.2495 12 16.2495Z"
        fill={props.color || "white"}
      />
    </svg>
  );
}

export default DownloadIconCircle;
