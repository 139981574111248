import { Button } from "@mui/material";
import moment from "moment";
import { getUserDetails, handleError } from "utils";
// import { createElement } from "react";
import ActionImg from "../../../assets/images/action-img.jpg";
import { useMarkNotificationAsReadMutation } from "services";
import RoundBag from "components/Vectors/RoundBag"
import MessageIcon from "components/Vectors/MessageIcon"
import TwoUserIcon from "components/Vectors/TwoUserIcon"
import QuestionMarkIcon from "components/Vectors/QuestionMarkIcon"


const getConversationModule = (type, data) => {
  return type?.split("\\")?.pop() === "Conversation"
    ? data?.get_conversation_module?.client_job_id ||
    data?.get_conversation_module?.engagement_id
    : data?.module_id;
};
const NotificationCard = ({
  actionNotif,
  isPreview,
  notification
}) => {
  const isRead = Boolean(notification?.seen);
  const sender = notification?.notifiable
  const [markAsRead] = useMarkNotificationAsReadMutation();

  const isRejected =
    notification?.title.includes("rejected") ||
    notification?.title.includes("declined") ||
    notification?.title.includes("cancelled");

  const handleOpening = () => {
    if (isRead) return;
    const body = {
      notification_id: notification?.id,
    }
    try {
      markAsRead(body).unwrap()
    } catch (err) {
      handleError(err)
    }

  }; const modulesWithId = [
    "Ticket",
    "TicketResponse",
    "Engagement",
    "Offer",
    "Conversation",
    'ClientJob'
  ];

  const Links = {
    Engagement: "/engagements",
    Conversation: "/engagements",
    Offer: "/marketplace",
    Ticket: "/tickets",
    Payout: "/transactions",
    WithdrawalHistory: "/transactions",
    EarningHistory: "/transactions",
    RefundHistory: "/transactions",
    TokenWallet: "/subscriptions",
    Kyc: "/settings?tab=kyc",
    CacCertificate: "/settings?tab=kyc",
    TicketResponse: "/tickets",
    ClientJob: "/marketplace",
    User: ""
  };

  const getNotificationLink = (type, id, userType = "client") => {
    let link =
      userType === "client" && type?.includes("Offer")
        ? "/jobs"
        : type === 'App\\Models\\User'
          ? `${userType}s/${id}`
          : Links[type?.split("\\")?.pop()];

    if (id && link && modulesWithId.includes(type?.split("\\")?.pop())) {
      link = link + `/${id}`;
    }
    return link;
  };
  // if (notification?.title === 'New Ticket Created') {
  //   console.log(notification?.message?.split(" ")?.slice(0, -4)?.join(' '))
  // }
  return (
    <div
      onClick={handleOpening}
      className={`p-4 mb-4 text-sm md:text-base gap-3 rounded-lg flex flex-wrap justify-between items-center ${!isRead ? "bg-grey100 border" : "bg-white"
        }`}
    >
      <div className="flex-grow flex gap-x-4">
        <div
          className={`h-[50px] md:h-[65px] w-[90px] md:w-[65px] rounded-md flex justify-center items-center ${isRejected ? "bg-error0" : "bg-secondary-100"
            }`}
        >
          {sender?.profile_image ?
            <img
              src={getUserDetails(sender)?.img || ActionImg}
              alt="sender-img"
              className="h-full w-full roundedfull"
            />
            :
            isRejected
              ? iconsArray.cancelled
              : iconsArray[notification?.notifiable_type
                ?.split("\\")
                ?.pop()] || iconsArray.default}
        </div>
        <div>
          <div className="flex gap-x-4 my-1 items-center ">
            <p className="font-semibold">{notification?.title}</p>
            <p className="text-sm text-grey font-medium my-1"> {moment(notification?.created_at).fromNow()}</p>
          </div>
          <p className="mt-auto text-grey font-medium">
            {
              notification?.title === 'New Ticket Created'
                ? notification?.message?.split(" ")?.slice(0, -4)?.join(' ')
                : notification?.message
            }
          </p>

        </div>
      </div>

      <Button
        href={notification?.notifiable_type.includes("Ticket") ?
          `/tickets/${notification?.notifiable?.ticket_id || notification?.notifiable?.id} `
          :
          `${getNotificationLink(
            notification?.notifiable?.module_type ||
            notification?.notifiable_type,
            notification?.notifiable_type.includes("Offer")
              ? notification?.notifiable?.client_job_id
              : getConversationModule(
                notification?.notifiable_type,
                notification?.notifiable,
              ) || notification?.notifiable_id,
            notification?.notifiable?.user_type,
          )}`}
        variant="contained"
        color="primaryInverse"
        size="small"
        onClick={handleOpening}
        sx={{
          width: 'fit-content !important',
          mt: { xs: 1, md: 0 },
          mx: { xs: "auto", md: 0 },
        }}
      >
        View {' '}
        {camelToFlat(notification?.notifiable_type
          ?.split("\\")
          ?.pop() === 'ClientJob' ? 'Job' : notification?.notifiable_type
            ?.split("\\")
            ?.pop())}
      </Button>
      {/* )
      } */}
    </div >
  );
};
export default NotificationCard;

const camelToFlat = (camel) => {
  const camelCase = camel?.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ")

  let flat = ""

  camelCase?.forEach(word => {
    flat = flat + word?.charAt(0).toUpperCase() + word?.slice(1) + " "
  })
  return flat
}

const iconsArray = {
  Offer: <RoundBag transparent={true} width={42} height={42} />,
  ClientJob: <RoundBag transparent={true} width={42} height={42} />,
  WithdrawalHistory: <RoundBag transparent={true} width={42} height={42} />,
  Conversation: <MessageIcon color="#000042" />,
  TicketResponse: <MessageIcon color="#000042" />, Engagement: <TwoUserIcon color="#000042" />,
  Cancelled: <TwoUserIcon color="#D61E34" />,
  User: <TwoUserIcon color="#000042" />,
};
iconsArray.default = (
  <QuestionMarkIcon color="#000042" width={32} height={32} />
);

