import CategoryFilled from "assets/icons/category-filled.svg";

const InfoCard = ({title, description, icon}) => {
  return (
    <div className="border-[1px] border-borderColor rounded-[8px] p-4 w-full ">
      <div className="flex gap-4">
        {icon ? (
          <div className="h-[20px] w-[20px] ">{icon}</div>
        ) : (
          <img src={CategoryFilled} alt="icon" className="h-[20px] w-[20px] " />
        )}
        <div className="">
          <p className="text-prop font-medium capitalize text-grey text-[14px] ">
            {title}
          </p>
          <p className="text-prop font-semibold capitalize text-grey10 text-[1rem] mt-[8px] ">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default InfoCard;
