function SendIconPlane(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2199 21.6303C13.0399 21.6303 11.3699 20.8003 10.0499 16.8303L9.32988 14.6703L7.16988 13.9503C3.20988 12.6303 2.37988 10.9603 2.37988 9.78031C2.37988 8.61031 3.20988 6.93032 7.16988 5.60032L15.6599 2.77032C17.7799 2.06032 19.5499 2.27032 20.6399 3.35032C21.7299 4.43032 21.9399 6.21032 21.2299 8.33032L18.3999 16.8203C17.0699 20.8003 15.3999 21.6303 14.2199 21.6303ZM7.63988 7.03032C4.85988 7.96032 3.86988 9.06032 3.86988 9.78031C3.86988 10.5003 4.85988 11.6003 7.63988 12.5203L10.1599 13.3603C10.3799 13.4303 10.5599 13.6103 10.6299 13.8303L11.4699 16.3503C12.3899 19.1303 13.4999 20.1203 14.2199 20.1203C14.9399 20.1203 16.0399 19.1303 16.9699 16.3503L19.7999 7.86032C20.3099 6.32032 20.2199 5.06032 19.5699 4.41032C18.9199 3.76032 17.6599 3.68032 16.1299 4.19032L7.63988 7.03032Z"
        fill={props.color || "#EAEAF0"}
      />
      <path
        d="M10.1098 14.4C9.9198 14.4 9.7298 14.33 9.5798 14.18C9.2898 13.89 9.2898 13.41 9.5798 13.12L13.1598 9.53C13.4498 9.24 13.9298 9.24 14.2198 9.53C14.5098 9.82 14.5098 10.3 14.2198 10.59L10.6398 14.18C10.4998 14.33 10.2998 14.4 10.1098 14.4Z"
        fill={props.color || "#EAEAF0"}
      />
    </svg>
  );
}

export default SendIconPlane;



