import { useUnSuspendUserMutation } from "services";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

import Modal from ".";

const UnsuspendUsermodal = ({ user, openModal, setOpenModal }) => {
 const [unSuspendUser, { isLoading }] = useUnSuspendUserMutation();

 const handleUnsuspendAdmin = async () => {
  try {
   const res = await unSuspendUser(user?.id).unwrap();
   showToast(res?.message);
   setOpenModal(false);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <Modal openModal={openModal} closeModal={() => setOpenModal(!openModal)} showCancel={false}>
   <div className="flex flex-col justify-center items-center gap-6">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
     <rect width="128" height="128" rx="64" fill="#FAF5FF" />
     <path
      d="M64.0001 99.8327C44.2334 99.8327 28.1667 83.766 28.1667 63.9994C28.1667 44.2327 44.2334 28.166 64.0001 28.166C83.7668 28.166 99.8334 44.2327 99.8334 63.9994C99.8334 83.766 83.7668 99.8327 64.0001 99.8327ZM64.0001 33.166C47.0001 33.166 33.1667 46.9993 33.1667 63.9994C33.1667 80.9994 47.0001 94.8327 64.0001 94.8327C81.0001 94.8327 94.8334 80.9994 94.8334 63.9994C94.8334 46.9993 81.0001 33.166 64.0001 33.166Z"
      fill="#8937CE"
     />
     <path
      d="M59.2666 75.9327C58.5999 75.9327 57.9666 75.666 57.4999 75.1993L48.0666 65.766C47.0999 64.7993 47.0999 63.1993 48.0666 62.2327C49.0332 61.266 50.6332 61.266 51.5999 62.2327L59.2666 69.8994L76.3999 52.766C77.3666 51.7993 78.9666 51.7993 79.9332 52.766C80.8999 53.7327 80.8999 55.3327 79.9332 56.2993L61.0332 75.1993C60.5666 75.666 59.9332 75.9327 59.2666 75.9327Z"
      fill="#8937CE"
     />
    </svg>

    <h4 className="text-[#424257] text-[20px] font-semibold text-center max-w-[18.438rem]">
     Are you sure you want to Unsuspend the user?
    </h4>

    <div className="flex items-center w-full gap-4 mt-8">
     <Button
      color="inherit"
      sx={{
       background: "#F8F0FF",
       color: "#8937CE",
       width: "100%",
       height: "56px",
       marginTop: 0,
      }}
      onClick={() => {
       setOpenModal(false);
      }}
     >
      Cancel
     </Button>
     <LoadingButton
      onClick={handleUnsuspendAdmin}
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#fff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading}
      type="submit"
      variant="contained"
      fullWidth
      sx={{ mt: 2, background: "#8937CE", color: "#fff", height: "56px" }}
     >
      Unsuspend
     </LoadingButton>
    </div>
   </div>
  </Modal>
 );
};

export default UnsuspendUsermodal;
