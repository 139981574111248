const EmptyTickets = () => {
 return (
  <div className="flex items-center justify-center flex-col gap-4">
   <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
     d="M51.8337 25.0833C52.7903 25.0833 53.5837 24.2899 53.5837 23.3333V20.9999C53.5837 10.7099 50.457 7.58325 40.167 7.58325H25.5837V12.8333C25.5837 13.7899 24.7903 14.5833 23.8337 14.5833C22.877 14.5833 22.0837 13.7899 22.0837 12.8333V7.58325H16.8337C6.54366 7.58325 3.41699 10.7099 3.41699 20.9999V22.1666C3.41699 23.1233 4.21033 23.9166 5.16699 23.9166C7.40699 23.9166 9.25033 25.7599 9.25033 27.9999C9.25033 30.2399 7.40699 32.0833 5.16699 32.0833C4.21033 32.0833 3.41699 32.8766 3.41699 33.8333V34.9999C3.41699 45.2899 6.54366 48.4166 16.8337 48.4166H22.0837V43.1666C22.0837 42.2099 22.877 41.4166 23.8337 41.4166C24.7903 41.4166 25.5837 42.2099 25.5837 43.1666V48.4166H40.167C50.457 48.4166 53.5837 45.2899 53.5837 34.9999C53.5837 34.0433 52.7903 33.2499 51.8337 33.2499C49.5937 33.2499 47.7503 31.4066 47.7503 29.1666C47.7503 26.9266 49.5937 25.0833 51.8337 25.0833ZM25.5837 33.0633C25.5837 34.0199 24.7903 34.8133 23.8337 34.8133C22.877 34.8133 22.0837 34.0199 22.0837 33.0633V22.9366C22.0837 21.9799 22.877 21.1866 23.8337 21.1866C24.7903 21.1866 25.5837 21.9799 25.5837 22.9366V33.0633Z"
     fill="#DCDCE5"
    />
   </svg>

   <h4 className="text-[#53536F] font-semibold text-[18px]">No tickets to display</h4>
  </div>
 );
};

export default EmptyTickets;
