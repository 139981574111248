import { closeToast } from "../../store/store.hooks";
import { selectToasts } from "../../store/slice/ToasterSlice";
import CloseIcon from "components/Vectors/CloseIcon";
import SuccessCheck from "components/Vectors/SuccessCheck";
import ErrorAlert from "components/Vectors/ErrorAlert";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const ToastWidget = () => {
  const toasts = useSelector(selectToasts);
  
  // there are situations where the toast get stuck on the screen, this will close it
  useEffect(() => {
    setTimeout(() => {
      closeToast();
    }, 5000);
  }, [toasts]);

  return (
    <div className="fixed right-4 top-0 grid gap-y-4 pr-5 pt-5 z-[2000]">
      {toasts.map((toast) => (
        <div
          style={{ boxShadow: "0px 20px 20px rgb(0 0 0 / 10%)" }}
          className={`md:min-w-[27rem] max-w-[20rem] rounded-lg md:p-4 p-[0.8125rem]  bg-white flex space-x-4 ${
            toast.isOpen ? "animate-slide" : "translate-x-[150vw]"
          } transition-transform duration-700`}
          key={`toast-${toast.id}`}
        >
          <div className="min-w-[42px] min-h-[42px]">
            {toast.messageType === "success" ? (
              <SuccessCheck />
            ) : (
              <ErrorAlert />
            )}
          </div>
          <div className="flex-grow flex flex-col">
            <span className="text-sm md:text-lg font-semibold">
              {toast.messageType === "success" ? "Success!" : "Error"}
            </span>
            <p className="text-gray-500 text-xs md:text-base mt-1">
              {toast.text}
            </p>
          </div>
          <div onClick={() => closeToast(toast.id)} className="cursor-pointer">
            <CloseIcon />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastWidget;
