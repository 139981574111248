import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import {useState, useEffect} from "react";
import WalletIcon from "components/Vectors/WalletIcon";
import StatCard from "components/StatCard";
import PayoutDetailsModal from "../Widgets/PayoutDetailsModal";
import {RemoveRedEyeOutlined} from "@mui/icons-material";
import {Chip} from "@mui/material";
import MyDataTable, {
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import { useGetCreatorPayoutStatsQuery, useGetCreatorPayoutsQuery, useGetInfluencerPayoutStatsQuery, useGetInfluencerPayoutsQuery } from "services/transactions.api";
import { formatDate, getUserDetails, toCurrency } from "utils";
import { STATUS2 } from "utils/constants";
import ReferralPayoutModal from "../Widgets/ReferralPayoutModal";




const UserReferralTab = ({type}) => {

    
  const JOBS_PER_PAGE = 16;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(JOBS_PER_PAGE);
  
  const [transactionData, setTransactionData] = useState('');

  const [openModal, setopenModal] = useState(false);
  const [currentStats ,setCurrentStats] = useState('');
  const [currentData , setCurrentData] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false)
const {data: creatorPayoutStats, isLoading: isCreatorPayoutStatsLoading} = useGetCreatorPayoutStatsQuery();
const {data: influencerPayoutStats, isLoading: isInfluencerPayoutStatsLoading} = useGetInfluencerPayoutStatsQuery();

if(influencerPayoutStats) console.log(influencerPayoutStats)
const {data: creatorPayoutsData, isLoading: isCreatorPayoutsLoading} = useGetCreatorPayoutsQuery({
  params: { is_paginated: "yes", page, per_page: perPage},
});
const {data: influencerPayoutsData, isLoading: isInfluencerPayoutsLoading} = useGetInfluencerPayoutsQuery({
  params: { is_paginated: "yes", page, per_page: perPage},
});

if(creatorPayoutsData) console.log(creatorPayoutsData);



useEffect(()=>{
  if(isCreatorPayoutsLoading || isInfluencerPayoutsLoading ){
    setIsDataLoading(true)
  }else{
    setIsDataLoading(false)
  }
},[isCreatorPayoutsLoading , isInfluencerPayoutsLoading , creatorPayoutsData ,  influencerPayoutsData])






useEffect(()=>{
if(type === 'influencer'){
  setCurrentStats(influencerPayoutStats)
  setCurrentData(influencerPayoutsData)
}else{
  setCurrentStats(creatorPayoutStats)
  setCurrentData(creatorPayoutsData)
}
},[influencerPayoutStats,creatorPayoutStats,type, creatorPayoutsData, influencerPayoutsData])

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <StatCard
          name={"Total payouts"}
          icon={<WalletIcon color="#8937CE" />}
          iconBg="bg-primary-100"
          value={isCreatorPayoutStatsLoading || currentStats?.data?.total === undefined ? "..." : toCurrency( currentStats?.data?.total)}
        />
        <StatCard
          name={"Pending payouts"}
          icon={<WalletIcon color="#8585A3" />}
          iconBg="bg-grey100"
                   value={isCreatorPayoutStatsLoading || currentStats?.data?.pending === undefined  ? "..." : toCurrency(currentStats?.data?.pending)}

        />
        <StatCard
          name={"Completed payouts"}
          icon={<WalletIcon color="#00A04A" />}
          iconBg="bg-success-100"
          value={isCreatorPayoutStatsLoading || currentStats?.data?.completed === undefined  ? "..." : toCurrency(currentStats?.data?.completed)}

        />
      </div>
      <div className="p-4 bg-white py-6 border rounded-xl mt-5">
        <MyDataTable
          searchPlaceholder="Select Service, creator, buyer,..."
          options={{
            searchOpen: true,
          }}
          isLoading={isDataLoading}
          headCells={[
            {
              name: "sn",
              label: "S/N",
            },
            {
              name: "user",
              label: type,
              options: {
                customBodyRender: value => (
                  <UserDisplay
                    name={value?.name}
                    badge={value?.badge}
                    email={value?.type}
                    img={value?.img}
                  />
                ),
              },
            },

            {name: "price", label: "Amount"},
            {name: "commission", label: "commission"},
            {name: "date_start", label: "Date requested"},
            {name: "date_paid", label: "Date completed"},
            {
              name: "status",
              label: "Status",
              options: {
                filter: true,
                customBodyRender: value => (
                  <Chip
                    color={`${STATUS2[value.toLowerCase()] || 'greyBorder'}`}
                    // sx={{minWidth: 90}}
                    label={`${value}`}
                  />
                ),
              },
            },
            {
              name: "actions",
              label: null,
              options: {
                customBodyRender: children => (
                  <TableDropDown tableId={"table"}>{children}</TableDropDown>
                ),
              },
            },
          ]}
          data={currentData?.data?.map((el, i) => ({
              sn: i + 1,
              user: {
                name: getUserDetails(el?.user).name,
                type: el?.user?.user_type,
                badge: el?.user?.kyc_verified,
                img: el?.user?.profile_image,
              },
              price: toCurrency(el?.amount),
              commission: toCurrency(el?.commission),
              date_start: formatDate(el?.created_at),
              date_paid: formatDate(el?.paid_date_time),
              status: el?.status,
              actions: (
                <>
                  <button onClick={() => {
                    setTransactionData(el)
                    setopenModal(true)
                    }}>
                    <RemoveRedEyeOutlined /> View
                  </button>
                </>
              ),
            }))}
        />
      </div>
      <ReferralPayoutModal status='referral' data={transactionData} openModal={openModal} setopenModal={setopenModal} />
    </div>
  );
};

function ReferralsTab() {
  const [activeTab, setactiveTab] = useState("Influencers");

  return (
    <>
      <TabContext value={activeTab}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          className="font-semibold"
          value={activeTab}
          onChange={(e, newValue) => setactiveTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Influencers" value="Influencers" />
          {/* <Tab label="Creators" value="creators" /> */}
        </TabList>
        <TabPanel value="Influencers">
          <UserReferralTab type="influencer" />
        </TabPanel>
        {/* <TabPanel value="creators">
          <UserReferralTab type="creator" />
        </TabPanel> */}
      </TabContext>
    </>
  );
}

export default ReferralsTab;
