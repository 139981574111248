import InputField from "./InputField";
import SearchIcon from "@mui/icons-material/Search";

function SearchInput(props) {
  return (
    <InputField
      {...props}
      prefixIcon={<SearchIcon sx={{color: "greyBorder?.contrastText"}} />}
      className="max-w-[420px] w-full"
      spaceY={false}
      placeholder={props.placeholder || "Search, user, services, campaigns..."}
    />
  );
}

export default SearchInput;
