import {Button, Chip, IconButton} from "@mui/material";
import {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import EditIcon2 from "components/Vectors/EditIcon2";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import MyDataTable, {
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import moment from "moment";
import ManageCityModal from "../Modals/ManageCityModal";
import DeleteCityModal from "../Modals/DeleteCityModal";
import {useGetCityByStateQuery} from "services";

const StateDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const countryId = searchParams.get("id");
  const {data: cities, isLoading} = useGetCityByStateQuery(state);
  // eslint-disable-next-line no-unused-vars
  const [_, __, ...stateName] = cities?.message?.split(" ") || [];
  const thisState = cities?.data?.[0]?.state;
  const [showEditCityModal, setShowEditCityModal] = useState(false);
  const [showDeleteCityModal, setShowDeleteCityModal] = useState(false);
  const [showAddCityModal, setShowAddCityModal] = useState(false);
  const [selectedEl, setSelectedEl] = useState(null);
  const headCells = [
    {
      label: "S/N",
      name: "sn",
    },
    {
      label: "Cities",
      name: "cities",
    },
    {
      label: "Last Modified",
      name: "lastModified",
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => (
          <Chip
            color={
              value?.toLowerCase() === "active"
                ? "successInverse"
                : "greyBorder"
            }
            label={value}
          />
        ),
      },
    },
    {
      label: "",
      name: "action",
      options: {
        customBodyRender: (value) => (
          <TableDropDown tableId={"table"}>
            <Button
              onClick={() => {
                setSelectedEl(value);
                setShowEditCityModal(true);
              }}
              startIcon={<EditIcon2 />}
              color="inherit"
              sx={{py: "0.5rem !important"}}
            >
              Edit city
            </Button>
            <Button
              onClick={() => {
                setSelectedEl(value);
                setShowDeleteCityModal(true);
              }}
              startIcon={<DoDisturbAltRoundedIcon color="#D61E34" />}
              color="error"
              sx={{py: "0.5rem !important"}}
            >
              Delete city
            </Button>
          </TableDropDown>
        ),
      },
    },
  ];
  const data = cities?.data?.map((city, index) => ({
    sn: index + 1,
    cities: city.name,
    lastModified: moment(city.updated_at).format("DD MMMM YYYY"),
    status: city.status,
    action: city,
  }));

  return (
    <>
      <div className="flex mb-4 items-center justify-between">
        <div className="flex items-center">
          <IconButton
            onClick={() =>
              navigate(`?tab=location&id=${thisState?.country_id || countryId}`)
            }
          >
            <KeyboardBackspaceOutlinedIcon />
          </IconButton>
          <h1 className="font-semibold capitalize inline-flex items-center gap-x-2 ml-2 text-prop text-[20px] text-grey30 my-3">
            {thisState?.name || stateName?.join(" ")}
          </h1>
        </div>
        <Button onClick={() => setShowAddCityModal(true)} variant="contained">
          Add City
        </Button>
      </div>

      <MyDataTable
        options={{
          searchOpen: true,
          viewColumns: false,
          filter: false,
        }}
        headCells={headCells}
        scrollX={false}
        isLoading={isLoading}
        data={data}
        emptyData={{
          icon: LocationCityRoundedIcon,
          message: "No Cities yet",
        }}
      />

      <DeleteCityModal
        open={showDeleteCityModal}
        close={() => setShowDeleteCityModal(false)}
        city={selectedEl}
      />
      <ManageCityModal
        open={showAddCityModal}
        close={() => setShowAddCityModal(false)}
        state={thisState || {id: state}}
      />
      <ManageCityModal
        open={showEditCityModal}
        close={() => setShowEditCityModal(false)}
        isEditing
        state={thisState}
        city={selectedEl}
      />
    </>
  );
};
export default StateDetail;
