const StarOutlineIcon = (props) => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8.83006 11.3352C8.56506 11.3352 8.22506 11.2502 7.80006 11.0002L6.30506 10.1152C6.15006 10.0252 5.85006 10.0252 5.70006 10.1152L4.20006 11.0002C3.31506 11.5252 2.79506 11.3152 2.56006 11.1452C2.33006 10.9752 1.97006 10.5402 2.20506 9.54016L2.56006 8.00516C2.60006 7.84516 2.52006 7.57016 2.40006 7.45016L1.16006 6.21016C0.540059 5.59016 0.590059 5.06016 0.675059 4.80016C0.760059 4.54016 1.03006 4.08016 1.89006 3.93516L3.48506 3.67016C3.63506 3.64516 3.85006 3.48516 3.91506 3.35016L4.80006 1.58516C5.20006 0.780156 5.72506 0.660156 6.00006 0.660156C6.27506 0.660156 6.80006 0.780156 7.20006 1.58516L8.08006 3.34516C8.15006 3.48016 8.36506 3.64016 8.51506 3.66516L10.1101 3.93016C10.9751 4.07516 11.2451 4.53516 11.3251 4.79516C11.4051 5.05516 11.4551 5.58516 10.8401 6.20516L9.60006 7.45016C9.48006 7.57016 9.40506 7.84016 9.44006 8.00516L9.79506 9.54016C10.0251 10.5402 9.67006 10.9752 9.44006 11.1452C9.31506 11.2352 9.11506 11.3352 8.83006 11.3352ZM6.00006 9.29516C6.24506 9.29516 6.49006 9.35516 6.68506 9.47016L8.18006 10.3552C8.61506 10.6152 8.89006 10.6152 8.99506 10.5402C9.10006 10.4652 9.17506 10.2002 9.06506 9.71016L8.71006 8.17516C8.61506 7.76016 8.77006 7.22516 9.07006 6.92016L10.3101 5.68016C10.5551 5.43516 10.6651 5.19516 10.6151 5.03016C10.5601 4.86516 10.3301 4.73016 9.99006 4.67516L8.39506 4.41016C8.01006 4.34516 7.59006 4.03516 7.41506 3.68516L6.53506 1.92516C6.37506 1.60516 6.17506 1.41516 6.00006 1.41516C5.82506 1.41516 5.62506 1.60516 5.47006 1.92516L4.58506 3.68516C4.41006 4.03516 3.99006 4.34516 3.60506 4.41016L2.01506 4.67516C1.67506 4.73016 1.44506 4.86516 1.39006 5.03016C1.33506 5.19516 1.45006 5.44016 1.69506 5.68016L2.93506 6.92016C3.23506 7.22016 3.39006 7.76016 3.29506 8.17516L2.94006 9.71016C2.82506 10.2052 2.90506 10.4652 3.01006 10.5402C3.11506 10.6152 3.38506 10.6102 3.82506 10.3552L5.32006 9.47016C5.51006 9.35516 5.75506 9.29516 6.00006 9.29516Z"
          fill={props.color ? props.color : "#A2A2B9"}
        />
      </svg>
    );
  };
  export default StarOutlineIcon;
  