import {useMemo} from "react";
import {selectCurrentUser} from "store/slice/AuthSlice";
import {useSelector} from "react-redux";

export const initialUser = {
  id: 31,
  role_id: 4,
  country_id: 156,
  state_id: 2412,
  category_id: null,
  first_name: "Ajayi",
  last_name: "Emmanuel",
  username: "ajayiemmanuel630",
  email: "ajayiemmanuel630@gmail.com",
  phone_number: "+234453446775",
  gender: "Male",
  website: null,
  years_of_experience: null,
  profile_image:
    "https://lh3.googleusercontent.com/a/AGNmyxbLgkSSv-37z-1hlYqIj78LdfxecTk5nmIJ_wP8RQ=s96-c",
  bio: "Hello there",
  status: "active",
  email_verified_at: "2023-02-24T16:03:18.000000Z",
  last_login: null,
  created_at: "2023-02-24T16:03:18.000000Z",
  updated_at: "2023-03-01T13:27:05.000000Z",
  profile_completion: 30,
  full_name: "Ajayi Emmanuel",
  role: {id: 4, name: "Client", slug: "client", modules: []},
  bank_account: {
    account_number: "1233553367",
  },
  social_login: [
    {
      id: 5,
      user_id: 31,
      provider: "google",
      provider_id: "115429507474454245228",
      token: "256|FEah0VMET7dbryMH7prFoCl9RjshR9TbQB7bEWeG",
      created_at: "2023-02-24T16:03:18.000000Z",
      updated_at: "2023-02-24T16:03:18.000000Z",
      deleted_at: null,
    },
  ],
};
export const useGetUser = () => {
  const user = useSelector(selectCurrentUser);
  const isLoggedIn = !!user;
  return useMemo(() => ({user, isLoggedIn}), [isLoggedIn, user]);
};
