import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";

const DropDownWrapper = ({
  action,
  children,
  className,
  contentPadding = "px-4",
  position = "right",
  orientation = "bottom",
  mobilePosition,
  closeOnBtnClick = false,
  extraClick = () => { },
}) => {
  const location = useLocation()


  const [showDropDown, setshowDropDown] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (showDropDown && ref.current && !ref.current.contains(e.target)) {
        setshowDropDown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showDropDown]);

  // close dropdown when route changes
  useEffect(() => {
    setshowDropDown(false)
  }, [location?.pathname])

  return (
    <div ref={ref} className={`drop-down-wrapper ${className || ""}`}>
      <div
        onClick={() => {
          extraClick();
          setshowDropDown(!showDropDown);
        }}
        className="drop-down-action flex"
      >
        {action}
      </div>
      <div
        className={`drop-down-content ${mobilePosition ? mobilePosition : `${position}-0`
          } md:${position}-0 ${position === "right" ? "origin-top-right" : "origin-top-left"
          } ${showDropDown ? "show-drop-down" : ""} ${orientation === "top"
            ? `origin-bottom-right bottom-[120%]`
            : `origin-top-right top-[120%]`
          }`}
      >
        <div
          className={`py-2 w-full ${contentPadding}`}
          onClick={() =>
            closeOnBtnClick ? setshowDropDown(prev => !prev) : null
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DropDownWrapper;
