import {IconButton} from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import DownloadIconCircle from "./Vectors/DownloadIconCircle";
import CloseIcon from "./Vectors/CloseIcon";
function FileDisplayUi({
  name,
  link,
  size,
  isFileLarge,
  downloadType = true,
  onCancel,
}) {
  return (
    <div className="flex justify-between rounded-20 border-[2px] border-dashed border-[#DCDCE5] py-[16px] px-4">
      <div className="flex items-center gap-4">
        <FilePresentIcon />
        <div>
          <p className="text-prop text-base font-bold text-grey_20">{name}</p>
          <div>
            {isFileLarge && (
              <span className="text-xs text-error">File size too large - </span>
            )}{" "}
            {!downloadType && <span className="text-xs">{size}</span>}
          </div>
        </div>
      </div>
      {downloadType ? (
        <a
          href={link}
          download
          className="flex items-center justify-center rounded-[12px] bg-[#FDF2E6] p-[12px] "
        >
          <DownloadIconCircle />
        </a>
      ) : (
        <IconButton onClick={onCancel}>
          <CloseIcon color="#8937CE" />
        </IconButton>
      )}
    </div>
  );
}

export default FileDisplayUi;
