import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "./slice/AuthSlice";
import ToastReducer from "./slice/ToasterSlice";
import { authApi } from "services/auth.api";
import { generalApi } from "services";
import { ticketsApi } from "services/tickets.api";
import { jobsApi } from "services/jobs.api";
import { engagementsApi } from "services/engagements.api";
import { campaignsApi } from "services/campaigns.api";
import { payoutsApi } from "services/payouts.api";
import { searchApi } from "services/search.api";
import { transactionsApi } from "services/transactions.api";

const rootReducer = combineReducers({
 //Shared Reducers
 auth: AuthReducer,
 toasts: ToastReducer,
 [authApi.reducerPath]: authApi.reducer,
 [generalApi.reducerPath]: generalApi.reducer,
 [ticketsApi.reducerPath]: ticketsApi.reducer,
 [jobsApi.reducerPath]: jobsApi.reducer,
 [engagementsApi.reducerPath]: engagementsApi.reducer,
 [payoutsApi.reducerPath]: payoutsApi.reducer,
 [campaignsApi.reducerPath]: campaignsApi.reducer,
 [searchApi.reducerPath]: searchApi.reducer,
 [transactionsApi.reducerPath]: transactionsApi.reducer,
});
export default rootReducer;
