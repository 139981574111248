import MailIcon from "components/Vectors/MailIcon";
import { ReactComponent as LocationIcon } from "assets/icons/location.svg";
import moment from "moment";
import GenderIcon from "components/Vectors/GenderIcon";
import CallIcon from "components/Vectors/CallIcon";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LanguageSquareIcon from "components/Vectors/LanguageSquareIcon";
import CalendarIcon2 from "components/Vectors/CalendarIcon2";
import CalendarOutline from "components/Vectors/CalendarOutline";
// import StarRoundedIcon from "@mui/icons-material/StarRounded";
// import { TabContext, TabList, TabPanel } from "@mui/lab";
// import { useState } from "react";
// import { Tab } from "@mui/material";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import { useEffect } from "react";
// import EachClientReview from "components/EachClientReview";
// import { BadgeText } from "templates/DashboardLayout/DashboardLayout";
// import StarOutlineIcon from "components/Vectors/StarOutlineIcon";
// import EmptyResponse from "components/EmptyResponse";

// import { Button } from "@mui/material";
// import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
// import ReviewSummary from "./ReviewSummary";

const UserBio = ({ user, isClient, receivedReviews, givenReviews }) => {
 //  const [activeTab, setActiveTab] = useState("given");
 //  const navigate = useNavigate();
 //  const [searchParams] = useSearchParams();

 //  const reviewsReceived = receivedReviews?.data;
 //  const reviewsGiven = givenReviews?.data;
 //  const allStars = reviewsReceived?.reduce((accumulated, current) => accumulated + current?.star_rating_count, 0);

 //  const cumulativeReviews = Math.floor(allStars / reviewsReceived?.length);

 //  useEffect(() => {
 //   const tab = searchParams.get("tab");
 //   if (tab) setActiveTab(tab);
 //  }, [searchParams]);

 return (
  <div className="flex-1 flex flex-col justify-between">
   <div className="w-full">
    <p className="text-grey10 mb-2 text-[1rem] font-medium ">Bio</p>
    {/* <p className="text-prop font-medium text-[14px] text-grey ">{user?.about_me || "No Bio"}</p> */}
   </div>
   <div className="flex flex-col mt-[24px] gap-4 ">
    <div className="flex flex-col gap-4">
     {user?.gender && (
      <p className="flex items-start gap-2 justify-between">
       <span className="flex gap-x-2 items-center">
        <GenderIcon color="#8937CE" />
        <span className="text-grey30 text-[1rem] font-medium">Sex</span>
       </span>
       <span className="font-medium capitalize text-prop text-[1rem] text-grey max-w-[240px] text-right">
        {user?.gender}
       </span>
      </p>
     )}

     <p className="flex items-start gap-2 justify-between">
      <span className="flex gap-x-2 items-center">
       <MailIcon color="#8937CE" />
       <span className="text-grey30 text-[1rem] font-medium">Email</span>
      </span>
      <p className="font-medium flex-1 text-prop text-[1rem] text-grey maxw-[240px] text-right">
       {user?.email || "N/A"}
      </p>
     </p>
     <p className="flex items-start gap-2 justify-between">
      <span className="flex gap-x-2 items-center">
       <CallIcon fill="#8937CE" />
       <span className="text-grey30 text-[1rem] font-medium">Phone</span>
      </span>
      <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
       {user?.phone_number || "N/A"}
      </span>
     </p>
     <p className="flex items-start gap-2 justify-between">
      <span className="flex gap-x-2 items-center">
       <LanguageOutlinedIcon sx={{ color: "#8937CE" }} color="#8937CE" />
       <span className="text-grey30 text-[1rem] font-medium">Country</span>
      </span>
      <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
       {user?.country?.name || "N/A"}
      </span>
     </p>
     <div className="flex items-start gap-2 justify-between">
      <div className="flex gap-x-2">
       <LocationIcon className="h-full min-w-[24px]" />
       <span className="text-grey30 min-w-max text-[1rem] font-medium">City, State</span>
      </div>
      <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right ">
       {user?.city ? user?.city?.name + ", " + user?.state?.name : "N/A"}
      </span>
     </div>
     {user?.country === "United States" && (
      <div className="flex items-start gap-2 justify-between">
       <div className="flex gap-x-2">
        <LocationIcon className="h-full min-w-[24px]" />
        <span className="text-grey30 flex-grow text-[1rem] font-medium">ZIP code</span>
       </div>
       <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right ">
        {user?.zip_code || "2H124"}
       </span>
      </div>
     )}

     {user?.languages.length > 0 && (
      <p className="flex items-start gap-2 justify-between">
       <span className="flex gap-x-2 items-center">
        <LanguageSquareIcon color="#8937CE" />
        <span className="text-grey30 text-[1rem] font-medium">Language Spoken</span>
       </span>
       <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
        {user?.languages?.map((lang) => lang.name).join(", ") || "N/A"}
       </span>
      </p>
     )}
     {user?.dob && (
      <p className="flex items-start gap-2 justify-between">
       <span className="flex gap-x-2 items-center">
        <CalendarOutline />
        <span className="text-grey30 text-[1rem] font-medium">Date of Birth</span>
       </span>
       <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
        {moment(user?.dob).format("DD MMM YYYY") || "N/A"}
       </span>
      </p>
     )}
     <p className="flex items-start gap-2 justify-between">
      <span className="flex gap-x-2 items-center">
       <CalendarIcon2 stroke="#8937CE" width={24} height={24} />
       <span className="text-grey30 text-[1rem] font-medium">Date Registered</span>
      </span>
      <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
       {moment(user?.created_at).format("DD MMM YYYY") || "N/A"}
      </span>
     </p>
     {user?.residential_address && (
      <div className="flex items-start gap-2 justify-between">
       <div className="flex gap-x-2">
        <LocationIcon className="h-full min-w-[24px]" />
        <span className="text-grey30 text-[1rem] font-medium">Address</span>
       </div>
       <span className="font-medium text-prop text-[1rem] text-grey max-w-[240px] text-right">
        {user?.residential_address || "N/A"}
       </span>
      </div>
     )}
    </div>
    {/* ********** RATINGS ********* */}
    {isClient && (
     <section>
      {/* <h2 className="text-2xl mb-3 mt-10 font-semibold">Ratings and Reviews</h2>
      <div className="flex items-center justify-between">
       <ReviewSummary showStar={false} receivedReviews={reviewsReceived} />
       <div className="border rounded-lg flex flex-col items-center min-w-[100px] p-2">
        <div className="bg-[#FFF5E5] h-16 w-16 flex items-center rounded-lg justify-center">
         <StarRoundedIcon
          sx={{
           color: "#FFB33E",
           fontSize: "46px",
          }}
         />
        </div>
        <p className="text-3xl mt-2.5 text-center font-semibold">{cumulativeReviews || 0}</p>
       </div>
      </div> */}
      {/* <TabContext value={activeTab}>
       <TabList
        variant="scrollable"
        scrollButtons="auto"
        className="mt-10 font-semibold"
        value={activeTab}
        sx={{
         fontWeight: 600,
         textTransform: "uppercase",
        }}
        onChange={(_, newValue) => {
         navigate(`?tab=${newValue}`);
         setActiveTab(newValue);
        }}
        aria-label="review tab"
       >
        <Tab
         label={
          <span className="flex gap-x-2 items-center">
           Review Given <BadgeText number={user?.reviews_given_count} />
          
          </span>
         }
         value="given"
        />
        <Tab
         label={
          <span className="flex gap-x-2 items-center">
           Review Received <BadgeText number={user?.reviews_received_count} />
  
          </span>
         }
         value="received"
        />
       </TabList>
       <TabPanel value="given">
        <div className="flex p-4 flex-col gap-y-4">
         {reviewsGiven?.length > 0 ? (
          reviewsGiven?.map((review) => <EachClientReview review={review} />)
         ) : (
          <EmptyResponse
           message={"No Reviews yet"}
           icon={<StarOutlineIcon />}
           className="bg-[#F9F9FB] w-[108%] -left-[4%] relative min-h-[20rem] my-0 -mb-4 rounded-b-2xl"
          />
         )}
        </div>
        {reviewsGiven?.length > 0 && (
         <Button startIcon={<ControlPointRoundedIcon />} fullWidth>
          See More
         </Button>
        )}
       </TabPanel>
       <TabPanel value="received">
        <div className="flex p-4 flex-col gap-y-4">
         {reviewsReceived?.length > 0 ? (
          reviewsReceived?.map((review) => <EachClientReview review={review} />)
         ) : (
          <EmptyResponse
           message={"No Reviews yet"}
           icon={<StarOutlineIcon />}
           className="bg-[#F9F9FB] w-[108%] -left-[4%] relative min-h-[20rem] my-0 -mb-4 rounded-b-2xl"
          />
         )}
        </div>
        {reviewsReceived?.length > 0 && (
         <Button startIcon={<ControlPointRoundedIcon />} fullWidth>
          See More
         </Button>
        )}
       </TabPanel>
      </TabContext> */}
     </section>
    )}
    {/* <div className="flex flex-col mt-[32px] gap-4 w-full md:w-[70%] ">
          <div className="flex items-center justify-between ">
            <p className="text-grey70 text-[16px] mb-1 flex gap-2 items-center">
              <DateRangeRoundedIcon fontSize="small" />
              <span>Date Joined</span>
            </p>
            <p className="text-[16px] font-medium text-prop text-grey">
              {moment(user?.created_at).format("MMMM DD YYYY")}
            </p>
          </div>
          <div className="flex items-center justify-between  ">
            <p className="text-grey70 text-[16px] flex gap-2 items-center">
              <DateRangeRoundedIcon fontSize="small" />
              <span>Last Login</span>
            </p>
            <p className="text-[16px] font-medium text-prop text-grey">
              {moment(user?.last_login).format("MMMM DD YYYY, h:mm a")}
            </p>
          </div>
        </div> */}
   </div>
   {/* <div className="">
        <ChipsWrap
          chipsArr={user?.skills?.map((el) => el.name) || []}
          title="Skills"
          className="text-grey10 mb-2 text-[1rem] font-medium mt-[40px] "
        />
      </div> */}
  </div>
 );
};
export default UserBio;
