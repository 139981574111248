import { Button, CircularProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useGetInfluencerSubmissionsQuery, useRemoveInfluencerFromCampaignMutation } from "services/campaigns.api";
import { LoadingButton } from "@mui/lab";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

import SubmissionCard from "./SubmissionCard";
import moment from "moment";

const SubmissionModal = ({ item, setModal, clientId }) => {
 const { id } = useParams();

 const { data: influencerSubmissions } = useGetInfluencerSubmissionsQuery(item?.influencer?.id);
 const [remove, { isLoading }] = useRemoveInfluencerFromCampaignMutation();

 const handleInfluencerRemoval = async () => {
  const body = {
   campaign_id: id,
   influencer_id: item?.influencer?.id,
  };

  try {
   const response = await remove(body).unwrap();
   showToast(response?.message);
   setModal(false);
  } catch (error) {
   handleError(error);
  }
 };
 console.log(item);

 //  console.log(influencerSubmissions);

 return (
  <div className="bg-white rounded-xl  h-[100vh] min-w-[31.25rem] w-full overflow-auto">
   <header className="flex items-center justify-between py-4 px-6  border-b border-b-[#DCDCE5]">
    <h4 className="text-[#424257] text-[20px] font-semibold">Influencer submission</h4>
    <svg
     width="32"
     height="32"
     viewBox="0 0 32 32"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
     role="button"
     onClick={() => setModal(false)}
    >
     <path
      d="M22.364 11.0503L11.0502 22.364C10.6637 22.7506 10.0226 22.7506 9.63603 22.364C9.24948 21.9775 9.24948 21.3364 9.63603 20.9498L20.9497 9.6361C21.3363 9.24954 21.9774 9.24955 22.364 9.6361C22.7505 10.0226 22.7505 10.6638 22.364 11.0503Z"
      fill="#2C2C3A"
     />
     <path
      d="M22.3631 22.364C21.9765 22.7506 21.3354 22.7506 20.9489 22.364L9.63516 11.0503C9.24861 10.6638 9.24861 10.0226 9.63516 9.63609C10.0217 9.24954 10.6628 9.24954 11.0494 9.63609L22.3631 20.9498C22.7496 21.3364 22.7496 21.9775 22.3631 22.364Z"
      fill="#2C2C3A"
     />
    </svg>
   </header>

   <div className="p-6 ">
    <div className="flex items-center justify-between">
     <div className="flex items-center gap-2">
      <img
       src={!item?.influencer?.profile_image ? "/default_avatar.png" : item?.influencer?.profile_image}
       alt="influencer display pic"
       className="h-[2.5rem] w-[2.5rem] rounded-full"
      />
      <div>
       <h4 className="text-[#2C2C3A] text-sm font-semibold">
        {" "}
        {item?.influencer?.first_name} {item?.influencer?.last_name}
       </h4>
       <p className="text-[#2C2C3A] text-xs font-medium">
        Joined Campaign: {moment(item?.created_at).format("DD/MM/YY")}
       </p>
      </div>
     </div>

     <Link to={`/influencers/${item?.influencer?.id}`}>
      <Button
       className="flex items-center justify-center text-sm !rounded-md !text-[#181820] whitespace-nowrap"
       sx={{
        height: "36px",
        width: "108px",
        border: "1px solid #BFBFCF",
       }}
      >
       View Profile
      </Button>
     </Link>
    </div>

    <LoadingButton
     className="flex items-center justify-center text-sm !rounded-md !text-[#D61E34] whitespace-nowrap !mt-6"
     sx={{
      height: "36px",
      width: "157px",
      border: "1px solid #BFBFCF",
     }}
     loadingIndicator={
      <CircularProgress
       sx={{
        color: "#D61E34",
       }}
       size="1.2rem"
      />
     }
     loading={isLoading}
     onClick={handleInfluencerRemoval}
    >
     {!isLoading && "Remove Influencer"}
    </LoadingButton>

    {influencerSubmissions?.data?.map((submission, i) => (
     <SubmissionCard key={submission.id} id={i} submission={submission} clientId={clientId} />
    ))}

    {/* <SubmissionCard type="screenshot_and_summary" id={5} status="rejected" />
    <SubmissionCard type="url_and_summary_screenshot" id={4} status="pending" />
    <SubmissionCard type="url_and_summary" id={3} status="pending" />
    <SubmissionCard type="url_and_screenshot" id={2} status="rejected" />
    <SubmissionCard type="screenshot_only" id={1} status="rejected" /> */}
   </div>
  </div>
 );
};

export default SubmissionModal;
