function EmptyImgIconFilled(props) {
  return (
    <svg
      width="24"
      height="24"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0201 16.8203L18.8901 9.50027C18.3201 8.16027 17.4701 7.40027 16.5001 7.35027C15.5401 7.30027 14.6101 7.97027 13.9001 9.25027L12.0001 12.6603C11.6001 13.3803 11.0301 13.8103 10.4101 13.8603C9.78014 13.9203 9.15014 13.5903 8.64014 12.9403L8.42014 12.6603C7.71014 11.7703 6.83014 11.3403 5.93014 11.4303C5.03014 11.5203 4.26014 12.1403 3.75014 13.1503L2.02014 16.6003C1.40014 17.8503 1.46014 19.3003 2.19014 20.4803C2.92014 21.6603 4.19014 22.3703 5.58014 22.3703H18.3401C19.6801 22.3703 20.9301 21.7003 21.6701 20.5803C22.4301 19.4603 22.5501 18.0503 22.0201 16.8203Z"
        fill={props.color || "#A2A2B9"}
      />
      <path
        d="M6.96984 8.37914C8.83657 8.37914 10.3498 6.86586 10.3498 4.99914C10.3498 3.13242 8.83657 1.61914 6.96984 1.61914C5.10312 1.61914 3.58984 3.13242 3.58984 4.99914C3.58984 6.86586 5.10312 8.37914 6.96984 8.37914Z"
        fill={props.color || "#A2A2B9"}
      />
    </svg>
  );
}

export default EmptyImgIconFilled;
