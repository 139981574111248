import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import {FormProvider, useForm} from "react-hook-form";
import {useSuspendAdminMutation} from "services";
import {showToast} from "store/store.hooks";
import {handleError} from "utils";

function SuspendAdminModal({openModal, setopenModal, id}) {
  const methods = useForm();
  const [suspendAdmin, {isLoading}] = useSuspendAdminMutation();

  const onSubmit = async (body) => {
    try {
      let res = await suspendAdmin({id, body}).unwrap();
      showToast(res.message);
      setopenModal(false);
      methods.reset();
      setopenModal(false);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      openModal={openModal}
      closeModal={() => setopenModal(false)}
      title="Suspend Account"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ValidatedTextArea
            maxLength={160}
            name="reason_for_suspension"
            label="Message"
          />
          <LoadingButton
            variant="contained"
            color="error"
            fullWidth
            type="submit"
            loading={isLoading}
            loadingIndicator={<CircularProgress color="white" size={20} />}
            sx={{marginTop: 4}}
          >
            Send Message & Suspend
          </LoadingButton>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default SuspendAdminModal;
