import { useEffect } from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "assets/icons/cancel.svg";
import { FormProvider, useForm } from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import { useUpdateSubCategoryMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import ToggleSwitch from "components/ToggleSwitch";

const EditSubCatgryModal = ({ openSubCategoryModal, handleCloseSubCategory, id, userType, data }) => {
 const methods = useForm();

 useEffect(() => {
  if (data) {
   methods.reset({
    name: data?.name,
    category_id: data?.category?.id,
    status: data?.status,
   });
  }
 }, [methods, data]);

 const [update, { isLoading }] = useUpdateSubCategoryMutation();
 const status = methods.watch("status");

 const handleSubmit = async (body) => {
  const formData = new FormData();
  formData.append("name", body.name);
  formData.append("category_id", data.category_id);
  formData.append("id", data.id);
  formData.append("status", body.status);

  try {
   let res = await update(formData).unwrap();
   showToast(res?.message);
   handleCloseSubCategory();
   methods.reset();
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <Modal open={openSubCategoryModal} onClose={handleCloseSubCategory} aria-labelledby="check-password-reset-modal">
   <div className="flex items-center justify-center h-full px-[2rem]">
    <div className="modal-card p-[24px]">
     <div className="flex justify-between">
      <p className="text-prop font-semibold text-[20px] text-grey10">Edit Sub Category</p>
      <button onClick={() => handleCloseSubCategory()}>
       <img src={CancelIcon} alt="icon" />
      </button>
     </div>

     <FormProvider {...methods}>
      <form className="mt-[auto]" onSubmit={methods.handleSubmit(handleSubmit)}>
       {/* <AutoCompleteField
                loading={isCategoryLoading}
                name="category_id"
                placeholder="Select Category"
                required={false}
                selectOption={categoryData?.data.map((el) => ({
                  label: el.name,
                  value: el.id,
                }))}
                sx={{
                  "& .MuiChip-root": {
                    borderRadius: 5,
                  },
                }}
                label="Category"
              /> */}
       <ValidatedInput label="Name" required={true} type="text" name="name" placeholder="Enter Name" />
       <div className="mt-6">
        <p className="text-grey30 text-sm mb-2">Status</p>
        <ToggleSwitch
         name="status"
         toggleState={status === "Active"}
         setToggleState={() => {
          methods.setValue("status", status === "Active" ? "Inactive" : "Active");
         }}
        />
       </div>
       <LoadingButton
        loadingIndicator={
         <CircularProgress
          sx={{
           color: "#ffffff",
          }}
          size="1.2rem"
         />
        }
        type="submit"
        loading={isLoading}
        fullWidth
        sx={{
         marginTop: 2,
        }}
        variant="contained"
       >
        Submit
       </LoadingButton>
      </form>
     </FormProvider>
    </div>
   </div>
  </Modal>
 );
};
export default EditSubCatgryModal;
