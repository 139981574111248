import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
const LoadingBtn = ({
  loading,
  children,
  variant = "contained",
  iconColor = "#fff",
  ...props
}) => {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition="center"
      variant={variant}
      loadingIndicator={<CircularProgress size={24} sx={{color: iconColor}} />}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default LoadingBtn;
