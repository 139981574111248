import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import DropDownWrapper from "components/DropDownWrapper";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
// import DropDownWrapper from 'components/DropDownWrapper';
// import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import SuspendUserModal from "components/Modal/SuspendUserModal";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { useActivateCreatorAccountMutation } from "services";

const CreatorCta = ({ user }) => {
 const [openSuspendModal, setOpenSuspendModal] = useState(false);
 const isSuspended = user?.status?.toLowerCase() === "suspended";

 const [activateCreator, { isLoading }] = useActivateCreatorAccountMutation();

 const handleActivateCreatorAccount = async () => {
  try {
   const res = await activateCreator(user?.id).unwrap();
   showToast(res?.message);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <>
   <div className="flex gap-2 items-center">
    {user?.status?.toLowerCase() === "inactive" && (
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      onClick={handleActivateCreatorAccount}
      loading={isLoading}
      variant="contained"
     >
      Activate Account
     </LoadingButton>
    )}

    <DropDownWrapper
     className="more-actions"
     position="right"
     border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
     closeOnBtnClick={false}
     action={
      <button type="button" className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]">
       <MoreHorizOutlinedIcon color="primary" fontSize="large" />
      </button>
     }
    >
     <Button
      onClick={() => (isSuspended ? handleActivateCreatorAccount() : setOpenSuspendModal(true))}
      color="inherit"
      startIcon={<DoDisturbAltRoundedIcon />}
     >
      {isSuspended ? "Unsuspend" : "Suspend"} Account
     </Button>
    </DropDownWrapper>

    {/* <DropDownWrapper
                    className="more-actions"
                    position="right"
                    border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                    closeOnBtnClick={false}
                    action={
                        <button
                            type="button"
                            className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]"
                        >
                            <MoreHorizOutlinedIcon color="primary" fontSize="large" />
                        </button>
                    }
                > */}
    {/* <Button
                        onClick={() => (isSuspended ? handleActivateCreatorAccount() : setOpenSuspendModal(true))}
                        color="errorInverse"
                        variant='contained'
                        startIcon={<DoDisturbAltRoundedIcon />}
                    >
                        {isSuspended ? 'Unsuspend' : 'Suspend'} Account
                    </Button> */}
    {/* </DropDownWrapper> */}
   </div>

   <SuspendUserModal user={user} setOpenModal={setOpenSuspendModal} openModal={openSuspendModal} />
  </>
 );
};

export default CreatorCta;
