// import {handleError} from "utils";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
// import {useSendNotificationToAllUsersMutation} from "services/admin/adminGeneralApi";
// import {showToast} from "redux/store.hooks";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import ValidatedRadioBtn from "components/forms/ValidatedRadioBtn";
import { CircularProgress } from "@mui/material";

function AdminPushNotification() {
 // const [sendNotification, {isLoading}] =
 //   useSendNotificationToAllUsersMutation();
 const methods = useForm();
 const handleSend = (data) => {
  // sendNotification(data)
  //   .unwrap()
  //   .then((res) => {
  //     showToast(res?.message);
  //     methods.reset();
  //   })
  //   .catch((err) => handleError(err));
 };
 return (
  <main className="h-full bg-grey100 p-6 md:px-10">
   <h1 className="mb-4 font-Bai text-[32px] font-semibold text-grey_20">Mobile Push Notification</h1>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(handleSend)} className="w-full md:w-1/2">
     <p className="text-lg font-semibold">Send Notification to</p>
     <ValidatedRadioBtn value="all" name="user_type" label="All" />
     <ValidatedRadioBtn value="clients" name="user_type" label="Clients" />
     <ValidatedRadioBtn value="influencers" name="user_type" label="Influencers" />
     <ValidatedRadioBtn value="creators" name="user_type" label="creators" />
     <ValidatedTextArea name="message" label="Message" placeholder="Type message here" bg="transparent" />
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      type="submit"
      // loading={isLoading}
      variant="contained"
      sx={{ mt: 3 }}
     >
      Send Message
     </LoadingButton>
    </form>
   </FormProvider>
  </main>
 );
}

export default AdminPushNotification;
