const SecurityUserIcon = (props) => {
    return (
        <>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M61.6662 13.6997L45.0329 7.46641C42.2662 6.43307 37.7329 6.43307 34.9662 7.46641L18.3329 13.6997C14.4995 15.1331 11.3662 19.6664 11.3662 23.7331V48.4997C11.3662 52.4331 13.9662 57.5997 17.1329 59.9664L31.4662 70.6664C36.1662 74.1997 43.8995 74.1997 48.5995 70.6664L62.9329 59.9664C66.0995 57.5997 68.6995 52.4331 68.6995 48.4997V23.7331C68.6329 19.6664 65.4995 15.1331 61.6662 13.6997ZM39.7662 23.4331C43.6995 23.4331 46.8995 26.6331 46.8995 30.5664C46.8995 34.4331 43.8662 37.5331 40.0329 37.6664H39.9662H39.8995C39.8329 37.6664 39.7662 37.6664 39.6995 37.6664C35.6995 37.5331 32.6995 34.4331 32.6995 30.5664C32.6662 26.6331 35.8662 23.4331 39.7662 23.4331ZM47.2995 54.5331C45.2662 55.8664 42.6329 56.5664 39.9995 56.5664C37.3662 56.5664 34.6995 55.8997 32.6995 54.5331C30.7995 53.2664 29.7662 51.5331 29.7329 49.6331C29.7329 47.7664 30.7995 45.9664 32.6995 44.6997C36.7329 42.0331 43.2995 42.0331 47.3329 44.6997C49.2329 45.9664 50.2995 47.6997 50.2995 49.5997C50.2662 51.4664 49.1995 53.2664 47.2995 54.5331Z" fill="#8937CE" />
            </svg>
        </>
    );
}

export default SecurityUserIcon;