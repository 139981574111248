import { MenuItem, Pagination, Select } from "@mui/material";
import "./style.scss";
function TablePagination({
 meta: { totalPages, currentPage, rowCount, totalCount },
 page,
 setPage,
 type,
 perPage,
 setPerPage = () => {},
 hideRowPerPage = false,
}) {
 return (
  <>
   <div className="pagination-wrap gap-4">
    <p>
     Showing 1 - {rowCount} out of {totalCount} {type}
    </p>
    <div className="flex flex-wrap items-center gap-4 rounded-xl sm:justify-end">
     {!hideRowPerPage && (
      <div className="flex items-center gap-3">
       <Select
        value={perPage}
        onChange={(e) => {
         setPerPage(e.target.value);
        }}
       >
        <MenuItem value={"4"} className="center">
         4
        </MenuItem>
        <MenuItem value={"9"} className="center">
         8
        </MenuItem>
        <MenuItem value={"12"} className="center">
         12
        </MenuItem>
        <MenuItem value={"16"} className="center">
         16
        </MenuItem>
       </Select>
       <p>Rows per page</p>
      </div>
     )}
     <Pagination
      color="primary"
      count={totalPages}
      defaultPage={currentPage}
      page={page}
      onChange={(e, val) => setPage(val)}
      variant="text"
      shape="rounded"
      siblingCount={0}
     />
    </div>
   </div>
  </>
 );
}

export default TablePagination;
