import MUIDataTable from "mui-datatables";
import { Button, Skeleton } from '@mui/material';
import { CSVLink } from 'react-csv';
import UserDisplay from "./UserDisplay";
import "./style.scss";
import SearchInput from "components/forms/SearchInput";
import LoadingTable from "components/loadingTable";
import ErrorMsg from "components/ErrorMsg";
import { Chip, IconButton } from "@mui/material";
import DropDownWrapper from "components/DropDownWrapper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmptyResponse from "components/EmptyResponse";
import QuestionMarkIcon from "components/Vectors/QuestionMarkIcon";
import { createElement } from "react";
export const filterOption = {
    options: {
        filter: true,
    },
};

export const TableDropDown = ({ children, tableId, closeDrpDwn }) => (
    <DropDownWrapper
        closeOnBtnClick={closeDrpDwn}
        extraClick={() => {
            setTimeout(() => {
                document.querySelector(`#${tableId} div table`).parentElement.scroll({
                    top: document.querySelector(`#${tableId} div table`).parentElement.scrollHeight + 500,
                    left: document.querySelector(`#${tableId} div table`).parentElement.scrollWidth + 500,
                    behavior: "smooth",
                });
            }, 300);
        }}
        className="more-actions pr-[1rem] "
        action={
            <IconButton className="more-action-btn" aria-label="actions">
                <MoreHorizIcon />
            </IconButton>
        }
    >
        {children}
    </DropDownWrapper>
);

const handleExportCSVSelected = (data) => {
    // Extract relevant details from data
    const exportData = data.map(item => ({
        first_name: item.user?.name?.split(' ')[0] || '', // Extract first name
        last_name: item.user?.name?.split(' ')[1] || '', // Extract last name (if available)
        email: item.user?.email || '', // Extract email
        number: item.user?.number || '', // Extract number
        location: item.location || '', // Extract location
        'date joined': item.joined?.name || '', // Extract date joined
    }));

    // CSV headers
    const headers = [
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Number', key: 'number' },
        { label: 'Location', key: 'location' },
        { label: 'Date Joined', key: 'date joined' }
    ];

    // Prepare CSV data
    const csvData = {
        data: exportData,
        headers: headers,
        filename: 'exported_data.csv',
    };

    // Trigger CSV download
    return <CSVLink {...csvData}><Button variant="contained" color="primary">Export Selected ({data.length})</Button></CSVLink>;
};

const handleExportCSVAll = (data) => {
    // Extract relevant details from data
    const exportData = data.map(item => ({
        first_name: item.user?.name?.split(' ')[0] || '', // Extract first name
        last_name: item.user?.name?.split(' ')[1] || '', // Extract last name (if available)
        email: item.user?.email || '', // Extract email
        number: item.user?.number || '', // Extract number
        location: item.location || '', // Extract location
        'date joined': item.joined?.name || '', // Extract date joined
    }));

    // CSV headers
    const headers = [
        { label: 'First Name', key: 'first_name' },
        { label: 'Last Name', key: 'last_name' },
        { label: 'Email', key: 'email' },
        { label: 'Number', key: 'number' },
        { label: 'Location', key: 'location' },
        { label: 'Date Joined', key: 'date joined' }
    ];

    // Prepare CSV data
    const csvData = {
        data: exportData,
        headers: headers,
        filename: 'exported_data.csv',
    };

    // Trigger CSV download
    return <CSVLink {...csvData}><Button variant="contained" color="primary">Export All ({data.length})</Button></CSVLink>;
};


const mockData = Array(700)
    .fill("")
    .map((_, i) => ({
        name: {
            name: `James Aluko ${i}`,
            email: "email@mail.com",
        },
        number: "(405) 555-0128",
        email: `deanna${i}.curtis@example.com`,
        date: "01/01/2020",
        status: i % 2 === 0 ? "Active" : "Disabled",
        actions: (
            <>
                <button>Disable</button>
                <button>Enable</button>
            </>
        ),
        id: `row_${i}`,
    }));

const mockHeadCells = [
    {
        name: "name",
        label: "Full Name",
        options: {
            customBodyRender: (value) => <UserDisplay name={value.name} email={value.email} img="" />,
        },
    },
    {
        name: "email",
        label: "Email Address",
    },
    {
        name: "number",
        label: "Phone Number",
    },
    {
        name: "date",
        label: "Date joined",
    },
    {
        name: "status",
        label: "Status",
        options: {
            customBodyRender: (value) => <Chip label={value} color={value === "Active" ? "success" : ""} />,
        },
    },
    {
        name: "actions",
        label: "Actions",
        options: {
            customBodyRender: (value) => <TableDropDown tableId={"table"}>{value}</TableDropDown>,
        },
    },
];

function MyDataTable({
    headCells = mockHeadCells,
    options,
    data = mockData,
    searchFunc,
    title = "",
    alignLastTdRight = false,
    isLoading,
    isError,
    error,
    isLoadingAll,
    dataAll,
    type,
    searchPlaceholder = "Search...",
    emptyData = {
        icon: QuestionMarkIcon,
        message: "No record found",
    },
    scrollX = true,
    hideScollbar = true,
}) {
    if (isLoading) return <LoadingTable />;
    if (isError) return <ErrorMsg error={error} />;
    if (data.length <= 0)
        return (
            <EmptyResponse
                {...emptyData}
                iconBg="bg-none"
                icon={createElement(emptyData.icon, {
                    width: 24,
                    height: 24,
                    color: "#acacb4",
                })}
            />
        );
    return (
        <div
            id={"table"}
            className={`my-table  ${hideScollbar ? "hideScrollbar" : "scrollbar-style"}  ${scrollX ? "scrollX" : ""} ${alignLastTdRight ? "alignLastTdRight" : ""
                } `}
        >
            {
                type === "users"? 
                (
                    <div className="text-end">
                        {handleExportCSVSelected(data)} {/* Render current selection export button */}
                        {isLoadingAll ? (
                            <Skeleton variant="text" width={80} height={52} />
                        ) : (dataAll?.length > 0) ? (
                            handleExportCSVAll(dataAll)
                        ) : (
                            <span className="font-bold text-lg text-grey50">...</span>
                        )}
                    </div>  
                )
                :
                ""
            }
            <MUIDataTable
                title={title}
                data={data}
                columns={headCells.map(({ name, label, showLabel = true, options }) => ({
                    name,
                    label,
                    options: {
                        filter: false,
                        customHeadLabelRender: (column) => (showLabel ? label : ""),
                        ...options,
                    },
                }))}
                options={{
                    download: false,
                    print: false,
                    caseSensitive: true,
                    jumpToPage: false,
                    searchAlwaysOpen: true,
                    customSearchRender: (_, handleSearch) => (
                        <div className="md:mb-4">
                            <SearchInput
                                placeholder={searchPlaceholder}
                                onChange={(e) => {
                                    if (typeof searchFunc === "function") {
                                        searchFunc(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    ),
                    customSort: (data, colIndex, order) => {
                        return data.sort((a, b) => {
                            return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === "desc" ? 1 : -1);
                        });
                    },
                    responsive: "standard",
                    elevation: 0,
                    search: false,
                    customSearch: (searchQuery, currentRow) => {
                        let isFound = false;
                        currentRow.forEach((col) => {
                            if (
                                col?.name?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0 ||
                                col?.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
                            ) {
                                isFound = true;
                            }
                        });
                        return isFound;
                    },
                    enableNestedDataAccess: ".",
                    rowsPerPageOptions: [5, 10, 15, 20, 100],
                    selectableRows: options?.selectableRows ? "multiple" : "none",
                    ...options,
                }}
            />
        </div>
    );
}

export default MyDataTable;
