import { Avatar } from "@mui/material";
import Download from "../../../../assets/icons/id-download.svg";

const UploadedId = ({ frontImg, backImg }) => {
  return (
    <div className="mt-[8px] flex flex-wrap flex-col md:flex-row gap-2   ">
      <div className="">
        <p className="font-semibold text-prop text-grey text-[14px] mb-2 ">
          Front
        </p>
        <div className="relative rounded-[8px] h-[188px] w-[208px] ">
          <Avatar
            src={frontImg}
            alt="kyc front image"
            className="!h-full !w-full !rounded-[8px]"
          >
            <img
              className="w-full"
              alt="broken"
              src="/images/broken-image.png"
            />
          </Avatar>
          <div className="absolute cursor-pointer top-0 z-10 h-full w-full bg-[#0000002d] rounded-[8px] flex items-center justify-center ">
            <a href={frontImg} download className="h-[40px] w-[40px] ">
              <img src={Download} alt="icon" />
            </a>
          </div>
        </div>
      </div>
      {backImg &&
        <div className="">
          <p className="font-semibold text-prop text-grey text-[14px] mb-2 ">
            Back
          </p>
          <div className="relative rounded-[8px] h-[188px] w-[208px] ">
            <Avatar
              src={backImg}
              alt="kyc back image"
              className="!h-full !w-full !rounded-[8px]"
            >
              <img
                className="w-full"
                alt="broken"
                src="/images/broken-image.png"
              />
            </Avatar>

            <div className="absolute cursor-pointer top-0 z-10 h-full w-full bg-[#0000002d] rounded-[8px] flex items-center justify-center ">
              <a href={backImg} download className="h-[40px] w-[40px] ">
                <img src={Download} alt="icon" />
              </a>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
export default UploadedId;
