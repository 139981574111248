import DropDownWrapper from "components/DropDownWrapper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {useState} from "react";

function DurationFilter({options = ["Custom"], defaultValue, onChange}) {
  const [value, setvalue] = useState(options[0]?.label || options[0]);
  return (
    <DropDownWrapper
      action={
        <button className="flex space-x-1 px-4 py-2 rounded-lg bg-gray-100">
          <span>{value || defaultValue}</span>
          <KeyboardArrowDownIcon />
        </button>
      }
    >
      {options.map((item, index) => (
        <button
          key={index}
          onClick={() => {
            onChange(item?.value || item);
            setvalue(item?.label || item);
          }}
        >
          {item?.label || item}
        </button>
      ))}
    </DropDownWrapper>
  );
}

export default DurationFilter;
