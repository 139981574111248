import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { CustomTabStyle } from "App";
import SearchInput from "components/forms/SearchInput";
import TablePagination from "components/TablePagination";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TicketCardSummary, { TicketCard } from "components/TicketCardSummary";
import { BadgeText } from "templates/DashboardLayout/DashboardLayout";
import EmptyResponse from "components/EmptyResponse";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import UserSquare from "components/UserSquare";
import { useGetTicketByStatusQuery } from "services";
import { useGetTicketStatsQuery } from "services/tickets.api";
import EmptyTickets from "./Widgets/EmptyTickets";

function CategorizedTickets() {
 const [activeTab, setactiveTab] = useState("new");
 const TICKETS_PER_PAGE = 16;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(TICKETS_PER_PAGE);

 const navigate = useNavigate();
 let { search } = useLocation();
 const params = new URLSearchParams(search);
 const tab = params.get("tab");
 useEffect(() => {
  if (tab) setactiveTab(tab);
 }, [tab]);
 const { data: ticketStats } = useGetTicketStatsQuery();
 const {
  data: newTickets,
  isLoading: newTicketsLoading,
  isError: newTicketsError,
  error: newTicketsErrorMsg,
 } = useGetTicketByStatusQuery({
  status: "New",
  params: {
   per_page: perPage,
   is_paginated: "yes",
   page,
  },
 });

 const {
  data: progressTickets,
  isLoading: progressTicketsLoading,
  isError: progressTicketsError,
  error: progressTicketsErrorMsg,
 } = useGetTicketByStatusQuery({ status: "In Progress", params: { per_page: perPage, is_paginated: "yes", page } });

 const {
  data: resolvedTickets,
  isLoading: resolvedTicketsLoading,
  isError: resolvedTicketsError,
  error: resolvedTicketsErrorMsg,
 } = useGetTicketByStatusQuery({ status: "Resolved", params: { per_page: perPage, is_paginated: "yes", page } });

 const [tickets, setTickets] = useState([]);
 const currentTickets = activeTab === "new" ? newTickets : activeTab === "resolved" ? resolvedTickets : progressTickets;

 useEffect(() => {
  if (currentTickets?.data?.data) {
   const initialTickets = currentTickets?.data?.data?.filter((ticket) => ticket);
   setTickets(initialTickets);
  }
 }, [currentTickets]);

 const handleSearch = (e) => {
  const val = e.target.value;
  const matchedTickets = currentTickets?.data?.data?.filter((ticket) =>
   ticket?.title?.toLowerCase().includes(val.toLowerCase()),
  );

  setTickets(matchedTickets);
 };

 const TicketsTab = ({ data, isLoading, isError, error }) => {
  if (isLoading) return <Loader />;
  if (isError) return <ErrorMsg error={error} />;
  const sortData = [...data];
  const updatedData = sortData?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  return (
   <>
    {updatedData?.length !== 0 ? (
     <div className="grid grid-cols-2 gap-2">
      {updatedData?.map((item, i) => (
       //  <TicketCardSummary key={`ticket_${i}`} ticket={item} tab={activeTab} />
       <TicketCard ticket={item} key={item?.id} />
      ))}
     </div>
    ) : (
     <div className="flex items-center justify-center">
      <EmptyTickets />
     </div>
    )}
    {activeTab === "new" ? (
     <TablePagination
      type={activeTab + " Tickets"}
      perPage={perPage}
      meta={{
       totalCount: newTickets?.data?.total,
       rowCount: updatedData?.length,
       totalPages: newTickets?.data?.last_page,
       currentPage: page,
      }}
      page={page}
      setPage={setPage}
      setPerPage={setPerPage}
     />
    ) : activeTab === "in_progress" ? (
     <TablePagination
      type={activeTab + " Tickets"}
      perPage={perPage}
      meta={{
       totalCount: progressTickets?.data?.total,
       rowCount: updatedData?.length,
       totalPages: progressTickets?.data?.last_page,
       currentPage: page,
      }}
      setPage={setPage}
      page={page}
      setPerPage={setPerPage}
     />
    ) : (
     <TablePagination
      type={activeTab + " Tickets"}
      perPage={perPage}
      meta={{
       totalCount: resolvedTickets?.data?.total,
       rowCount: updatedData?.length,
       totalPages: resolvedTickets?.data?.last_page,
       currentPage: page,
      }}
      setPage={setPage}
      page={page}
      setPerPage={setPerPage}
     />
    )}
   </>
  );
 };

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Tickets</h1>
   </div>
   <TabContext value={activeTab}>
    <TabList
     variant="scrollable"
     scrollButtons="auto"
     className="mt-10 font-semibold border-b"
     value={activeTab}
     sx={{
      fontWeight: 600,
      ...CustomTabStyle,
      textTransform: "capitalize",
     }}
     onChange={(e, newValue) => {
      navigate(`?tab=${newValue}`);
      setactiveTab(newValue);
     }}
     aria-label="basic tabs example"
    >
     <Tab
      label={
       <div className="flex gap-2 items-center">
        In Progress{" "}
        <BadgeText
         color={activeTab === "in_progress" ? "bg-error" : "bg-grey"}
         number={ticketStats?.data?.["In Progress"] || 0}
        />
       </div>
      }
      value="in_progress"
     />
     <Tab
      label={
       <div className="flex gap-2 items-center">
        New <BadgeText color={activeTab === "new" ? "bg-error" : "bg-grey"} number={ticketStats?.data?.New || 0} />
       </div>
      }
      value="new"
     />
     <Tab
      label={
       <div className="flex gap-2 items-center">
        Resolved{" "}
        <BadgeText
         color={activeTab === "resolved" ? "bg-error" : "bg-grey"}
         number={ticketStats?.data?.Resolved || 0}
        />
       </div>
      }
      value="resolved"
     />
    </TabList>

    <h2 className="text-[#181820] font-semibold text-2xl mt-5 mb-0">
     {`${activeTab === "new" ? "New" : activeTab === "resolved" ? "Resolved" : "In Progress"}`} Tickets
    </h2>
    <TabPanel value="new">
     <TicketsTab
      data={tickets || []}
      isLoading={newTicketsLoading}
      isError={newTicketsError}
      error={newTicketsErrorMsg}
     />
    </TabPanel>
    <TabPanel value="in_progress">
     <TicketsTab
      data={tickets || []}
      isLoading={progressTicketsLoading}
      isError={progressTicketsError}
      error={progressTicketsErrorMsg}
     />
    </TabPanel>

    <TabPanel value="resolved">
     <TicketsTab
      data={tickets || []}
      isLoading={resolvedTicketsLoading}
      isError={resolvedTicketsError}
      error={resolvedTicketsErrorMsg}
     />
    </TabPanel>
   </TabContext>
  </div>
 );
}

export default CategorizedTickets;
