import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import MessageModal from "components/Modal/MessageModal";
import { DoNotDisturbAltRounded } from "@mui/icons-material";
import { useUpdateChannelStatusMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const SuspendChannelModal = ({ open, close, channel }) => {
 const [suspendChannel, { isLoading }] = useUpdateChannelStatusMutation();
 const handleSuspend = async () => {
  try {
   const res = await suspendChannel({
    channel_id: String(channel?.id),
    status: "suspended",
   }).unwrap();
   showToast(res?.message);
   close();
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   title={`Are you sure you want to suspend ${channel?.name}?`}
   description="Suspending doesn’t mean lorem ipsum"
   icon={<DoNotDisturbAltRounded color="error" />}
   cta={
    <div className="grid grid-cols-2 gap-4 w-full">
     <Button variant="outlined" color="error" onClick={close}>
      Cancel
     </Button>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading}
      onClick={handleSuspend}
      variant="contained"
      color="error"
     >
      Suspend
     </LoadingButton>
    </div>
   }
  />
 );
};
export default SuspendChannelModal;
