const SubCategoryIcon = (props) => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.03329 1.66699H4.44996C2.62496 1.66699 1.66663 2.62533 1.66663 4.44199V6.02533C1.66663 7.84199 2.62496 8.80033 4.44163 8.80033H6.02496C7.84163 8.80033 8.79996 7.84199 8.79996 6.02533V4.44199C8.80829 2.62533 7.84996 1.66699 6.03329 1.66699Z" fill="#8937CE" />
                <path d="M15.5583 1.66699H13.975C12.1583 1.66699 11.2 2.62533 11.2 4.44199V6.02533C11.2 7.84199 12.1583 8.80033 13.975 8.80033H15.5583C17.375 8.80033 18.3333 7.84199 18.3333 6.02533V4.44199C18.3333 2.62533 17.375 1.66699 15.5583 1.66699Z" fill="#8937CE" />
                <path d="M15.5583 11.1914H13.975C12.1583 11.1914 11.2 12.1497 11.2 13.9664V15.5497C11.2 17.3664 12.1583 18.3247 13.975 18.3247H15.5583C17.375 18.3247 18.3333 17.3664 18.3333 15.5497V13.9664C18.3333 12.1497 17.375 11.1914 15.5583 11.1914Z" fill="#8937CE" />
                <path d="M6.03329 11.1914H4.44996C2.62496 11.1914 1.66663 12.1497 1.66663 13.9664V15.5497C1.66663 17.3747 2.62496 18.3331 4.44163 18.3331H6.02496C7.84163 18.3331 8.79996 17.3747 8.79996 15.5581V13.9747C8.80829 12.1497 7.84996 11.1914 6.03329 11.1914Z" fill="#8937CE" />
            </svg>
        </>
    );
}

export default SubCategoryIcon;