const EmptyPayout = () => {
 return (
  <div className="flex items-center justify-center flex-col gap-4">
   <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
     d="M39.667 5.66992H16.3337C9.33366 5.66992 4.66699 10.3366 4.66699 17.3366V31.3366C4.66699 38.3366 9.33366 43.0033 16.3337 43.0033V47.9733C16.3337 49.8399 18.4103 50.9599 19.9503 49.9099L30.3337 43.0033H39.667C46.667 43.0033 51.3337 38.3366 51.3337 31.3366V17.3366C51.3337 10.3366 46.667 5.66992 39.667 5.66992ZM28.0003 34.0666C27.0203 34.0666 26.2503 33.2733 26.2503 32.3166C26.2503 31.3599 27.0203 30.5666 28.0003 30.5666C28.9803 30.5666 29.7503 31.3599 29.7503 32.3166C29.7503 33.2733 28.9803 34.0666 28.0003 34.0666ZM30.9403 24.3833C30.0303 24.9899 29.7503 25.3866 29.7503 26.0399V26.5299C29.7503 27.4866 28.957 28.2799 28.0003 28.2799C27.0437 28.2799 26.2503 27.4866 26.2503 26.5299V26.0399C26.2503 23.3333 28.2337 22.0033 28.9803 21.4899C29.8437 20.9066 30.1237 20.5099 30.1237 19.9033C30.1237 18.7366 29.167 17.7799 28.0003 17.7799C26.8337 17.7799 25.877 18.7366 25.877 19.9033C25.877 20.8599 25.0837 21.6533 24.127 21.6533C23.1703 21.6533 22.377 20.8599 22.377 19.9033C22.377 16.7999 24.897 14.2799 28.0003 14.2799C31.1037 14.2799 33.6237 16.7999 33.6237 19.9033C33.6237 22.5633 31.6637 23.8933 30.9403 24.3833Z"
     fill="#DCDCE5"
    />
   </svg>

   <h4 className="text-[#53536F] font-semibold text-[18px]">There are no payout requests</h4>
  </div>
 );
};

export default EmptyPayout;
