import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Chip } from "@mui/material";
import Modal from "components/Modal";
import ValidatedInput from "components/forms/ValidatedInput";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAddCityMutation, useEditCityMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const ManageCityModal = ({ open, close, isEditing, state, city }) => {
 const [addCity, { isLoading }] = useAddCityMutation();
 const [editCity, { isLoading: isEditingCity }] = useEditCityMutation();
 const [status, setStatus] = useState("");
 const methods = useForm();
 useEffect(() => {
  if (isEditing) {
   methods.reset({
    ...city,
   });
  }
 }, [city, isEditing, methods]);

 const handleSubmit = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("status", isEditing ? status : "active");
  formData.append("state_id", parseInt(state.id));
  try {
   const res = isEditing ? await editCity({ id: city?.id, body: formData }).unwrap() : await addCity(formData).unwrap();
   showToast(res?.message);
   methods.reset();
   close();
  } catch (e) {
   handleError(e);
  }
 };
 return (
  <Modal
   openModal={open}
   closeModal={() => {
    methods.reset();
    close();
   }}
   imgClass="modal-content scrollbar-style !min-h-[280px]"
   title={isEditing ? "Edit City" : "Add City"}
  >
   <FormProvider {...methods}>
    <form className="align-bottom" onSubmit={methods.handleSubmit(handleSubmit)}>
     <ValidatedInput label="City Name" name="name" />
     {isEditing && (
      <div className="my-6 mt-8">
       <p className="text-grey30 text-sm mb-2">Status</p>
       <div className="flex items-center gap-x-3.5">
        <Chip label="Inactive" onClick={() => setStatus("inactive")} />
        <Chip label="Active" onClick={() => setStatus("active")} color="primaryInverse" />
       </div>
      </div>
     )}
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading || isEditingCity}
      sx={{ mt: isEditing ? 2 : 6 }}
      fullWidth
      variant="contained"
      type="submit"
     >
      {isEditing ? "Edit City" : "Add City"}
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
};

export default ManageCityModal;
