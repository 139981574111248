import Modal from "@mui/material/Modal";
import CancelIcon from "assets/icons/cancel.svg";
import { FormProvider, useForm } from "react-hook-form";
import ValidatedInput from "components/forms/ValidatedInput";
import { useAddSubCategoryMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

const AddSubCatgryModal = ({ openSubCategoryModal, handleCloseSubCategory, id }) => {
 const methods = useForm();
 const [add, { isLoading }] = useAddSubCategoryMutation();

 const handleSubmit = async (data) => {
  const formData = new FormData();
  formData.append("name", data?.name);
  formData.append("category_id", id);
  try {
   let res = await add(formData).unwrap();
   showToast(res?.message);
   handleCloseSubCategory();
   methods.reset();
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <Modal open={openSubCategoryModal} onClose={handleCloseSubCategory} aria-labelledby="check-password-reset-modal">
   <div className="flex items-center justify-center h-full px-[2rem]">
    <div className="modal-card p-[24px]">
     <div className="flex justify-between">
      <p className="text-prop font-semibold text-[20px] text-grey10">Add Sub Category</p>
      <button onClick={() => handleCloseSubCategory()}>
       <img src={CancelIcon} alt="icon" />
      </button>
     </div>

     <FormProvider {...methods}>
      <form className="mt-auto" onSubmit={methods.handleSubmit(handleSubmit)}>
       <ValidatedInput label="Name" required={true} type="text" name="name" placeholder="Enter Name" />
       <LoadingButton
        loadingIndicator={
         <CircularProgress
          sx={{
           color: "#ffffff",
          }}
          size="1.2rem"
         />
        }
        type="submit"
        loading={isLoading}
        fullWidth
        sx={{
         marginTop: "50px",
        }}
        variant="contained"
       >
        Submit
       </LoadingButton>
      </form>
     </FormProvider>
    </div>
   </div>
  </Modal>
 );
};
export default AddSubCatgryModal;
