import { useGetNewVerifiedUsersQuery, useGetPendingVerificationQuery } from "services";
import EachVerification from "./EachVerification";

const PendingVerification = () => {
 const { data } = useGetPendingVerificationQuery();
 //  if (data) console.log(data);

 const sortedData = [...(data?.data || [])].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

 return (
  <div className="border flex-1 p-5 rounded-lg">
   <h3 className="dashboard-h2 text-prop pb-2">Pending Verification Approval</h3>
   <div className="noScroll scrollbar-style flex h-full max-h-[620px] flex-col gap-4 overflow-y-auto rounded-2xl bg-white pt-2 pb-4">
    <>
     {data?.data?.length === 0 ? (
      <div className="h-[400px] flex items-center justify-center">
       <div className="flex items-center justify-center flex-col gap-4">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path
          d="M43.1735 5.3335H20.8268C11.1202 5.3335 5.3335 11.1202 5.3335 20.8268V43.1735C5.3335 50.6668 8.7735 55.8135 14.8268 57.7602C16.5868 58.3735 18.6135 58.6668 20.8268 58.6668H43.1735C45.3868 58.6668 47.4135 58.3735 49.1735 57.7602C55.2268 55.8135 58.6668 50.6668 58.6668 43.1735V20.8268C58.6668 11.1202 52.8802 5.3335 43.1735 5.3335ZM54.6668 43.1735C54.6668 48.8802 52.4268 52.4802 47.9202 53.9735C45.3335 48.8802 39.2002 45.2535 32.0002 45.2535C24.8002 45.2535 18.6935 48.8535 16.0802 53.9735H16.0535C11.6002 52.5335 9.3335 48.9068 9.3335 43.2002V20.8268C9.3335 13.3068 13.3068 9.3335 20.8268 9.3335H43.1735C50.6935 9.3335 54.6668 13.3068 54.6668 20.8268V43.1735Z"
          fill="#DCDCE5"
         />
         <path
          d="M32.0003 21.3335C26.7203 21.3335 22.4536 25.6002 22.4536 30.8802C22.4536 36.1602 26.7203 40.4535 32.0003 40.4535C37.2803 40.4535 41.5469 36.1602 41.5469 30.8802C41.5469 25.6002 37.2803 21.3335 32.0003 21.3335Z"
          fill="#DCDCE5"
         />
        </svg>

        <h4 className="text-[#53536F] font-semibold text-[18px]">No pending approvals</h4>
       </div>
      </div>
     ) : (
      sortedData?.slice(0, 10).map((user) => <EachVerification key={user?.id} user={user} />)
     )}
    </>
   </div>
  </div>
 );
};

export default PendingVerification;
