const LanguageSquareIcon = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9897 9.70996H7.00977C6.59977 9.70996 6.25977 9.36996 6.25977 8.95996C6.25977 8.54996 6.59977 8.20996 7.00977 8.20996H16.9897C17.3997 8.20996 17.7397 8.54996 17.7397 8.95996C17.7397 9.36996 17.3997 9.70996 16.9897 9.70996Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M12 9.71027C11.59 9.71027 11.25 9.37027 11.25 8.96027V7.28027C11.25 6.87027 11.59 6.53027 12 6.53027C12.41 6.53027 12.75 6.87027 12.75 7.28027V8.96027C12.75 9.37027 12.41 9.71027 12 9.71027Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M7 17.4699C6.59 17.4699 6.25 17.1299 6.25 16.7199C6.25 16.3099 6.59 15.9699 7 15.9699C10.72 15.9699 13.75 12.8199 13.75 8.93994C13.75 8.52994 14.09 8.18994 14.5 8.18994C14.91 8.18994 15.25 8.52994 15.25 8.93994C15.25 13.6499 11.55 17.4699 7 17.4699Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M17.0002 17.4702C15.0302 17.4702 13.2002 16.4903 11.8602 14.7003C11.6102 14.3703 11.6802 13.9003 12.0102 13.6503C12.3402 13.4003 12.8102 13.4702 13.0602 13.8002C14.1202 15.2002 15.5202 15.9702 17.0102 15.9702C17.4202 15.9702 17.7602 16.3102 17.7602 16.7202C17.7602 17.1302 17.4102 17.4702 17.0002 17.4702Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={color || "#8937CE"}
      />
    </svg>
  );
};

export default LanguageSquareIcon;
