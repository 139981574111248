import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import { FormProvider, useForm } from "react-hook-form";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import MessageModal from "components/Modal/MessageModal";
import BagIcon from "components/Vectors/BagIcon";
import { useTerminateEngagementMutation } from "services/engagements.api";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { CircularProgress } from "@mui/material";

const TerminateEngagementModal = ({ openModal, setOpenModal, engagement_id, client_id }) => {
 const method = useForm();
 const [showDoneModal, setShowDoneModal] = useState(false);
 const [terminateEngagement, { isLoading: isTerminating }] = useTerminateEngagementMutation();

 console.log(typeof engagement_id, typeof client_id);

 const onSubmit = async (bodyMessage) => {
  setOpenModal(false);
  // setShowDoneModal(true);
  // console.log(body);
  // method.reset();

  const body = new FormData();
  body.append("engagement_id", `${engagement_id}`);
  body.append("client_id", `${client_id}`);
  body.append("reason_for_termination", bodyMessage.message);

  for (var pair of body.entries()) {
   console.log(pair[0] + ", " + pair[1]);
  }
  const bodyData = {
   engagement_id: `${engagement_id}`,
   client_id: `${client_id}`,
   reason_for_termination: bodyMessage.message,
  };
  console.log(bodyData);

  try {
   //  const res = await terminate({ bodyData }).unwrap();
   //  const res = await terminate({ formData }).unwrap();
   const res = await terminateEngagement({ body }).unwrap();
   //  const res = await terminate(formData).unwrap();
   showToast(res?.message);
   console.log(res);

   //  setShowDoneModal(true);
   //  closeModal();
   method.reset();
  } catch (err) {
   console.log(err);
   handleError(err);
  }

  // try {
  //   await terminateAdminEng({
  //     reason_for_termination: body?.message,
  //     engagement_id,
  //     client_id,
  //   }).unwrap();
  //   setOpenModal(false);
  //   setShowDoneModal(true);
  //   method.reset();
  // } catch (error) {
  //   handleError(error);
  // }
 };

 return (
  <>
   <Modal
    openModal={openModal}
    closeModal={() => setOpenModal(false)}
    maxWidth={500}
    className="!min-h-[unset]"
    title={"Reason for termination"}
   >
    <FormProvider {...method}>
     <form className="flex-grow flex flex-col" onSubmit={method.handleSubmit(onSubmit)}>
      <div className="space-y-5">
       <ValidatedTextArea
        className="flex"
        name="message"
        label="Write a message"
        bg="grey_80"
        placeholder="Type something"
       />
      </div>
      <LoadingButton
       loadingIndicator={
        <CircularProgress
         sx={{
          color: "#ffffff",
         }}
         size="1.2rem"
        />
       }
       variant="contained"
       sx={{
        width: "100%",
        mt: "auto",
       }}
       color={"error"}
       type="submit"
       loading={isTerminating}
      >
       Terminate Engagement
      </LoadingButton>
     </form>
    </FormProvider>
   </Modal>

   <MessageModal
    icon={<BagIcon color="#8937CE" />}
    openModal={showDoneModal}
    iconBg={"bg-primary-100"}
    title={`This engagement has been terminated`}
    description={`A meesage has been sent to Nneka Chukwu and Elon `}
    actionBtnText="Thanks"
    closeModal={() => setShowDoneModal(false)}
   />
  </>
 );
};

export default TerminateEngagementModal;
