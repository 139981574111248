import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Avatar, Button, CircularProgress } from "@mui/material";
import BackBtn from "components/BackBtn";
import ValidatedRadioBtns from "components/ValidatedRadioBtns";
import ValidatedInput from "components/forms/ValidatedInput";
import { useGetUser } from "hooks/getUserHook";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useUpdatePhotoMutation, useUpdateProfileMutation, useRemovePhotoMutation } from "services";
import { setUserDetails, removeProfilePhoto } from "store/slice/AuthSlice";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const EditProfile = () => {
 const { user } = useGetUser();
 const [localImgUrl, setLocalImgUrl] = useState("");
 const [userPhoto, setUserPhoto] = useState("");
 const [isRemoving, setIsRemoving] = useState(false);
 const methods = useForm({
  defaultValues: {
   first_name: user?.first_name,
   last_name: user?.last_name,
   gender: user?.gender?.charAt(0).toUpperCase() + user?.gender?.slice(1),
  },
 });
 const { setValue, register } = methods;

 useEffect(() => {
  setUserPhoto(user?.profile_image);
 }, [setValue, user]);

 const [updateProfile, { isLoading }] = useUpdateProfileMutation();
 const [updateProfilePhoto] = useUpdatePhotoMutation();
 const [removeAdminProfilePhoto] = useRemovePhotoMutation();
 const dispatch = useDispatch();
 const isDirty = methods.formState.isDirty;
 const changedImage = localImgUrl.length > 0;
 const onSubmit = async (data) => {
  const body = new FormData();
  const photoBody = new FormData();

  body.append("first_name", data.first_name);
  body.append("last_name", data.last_name);
  body.append("gender", data.gender);
  if (changedImage) {
   photoBody.append("profile_image", data.profile_picture[0]);
  }
  try {
   let res = await updateProfile(body).unwrap();
   const profileRes = changedImage ? await updateProfilePhoto(photoBody).unwrap() : undefined;
   dispatch(setUserDetails({ ...res?.data, ...profileRes?.data }));
   showToast(res?.message);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <FormProvider {...methods}>
   <form onSubmit={methods.handleSubmit(onSubmit)} className="h-full p-6 lg:px-10 px-4 bg-grey100">
    <h1 className="title">Edit Profile</h1>
    <div className="bg-white p-4 rounded-xl mt-6">
     <BackBtn />
     <div className="border rounded-lg p-4 my-6 w-full max-w-[574px]">
      <div className="flex gap-4 items-center w-full">
       <Avatar sx={{ width: 100, height: 100 }} src={localImgUrl || userPhoto} />
       <div className="flex flex-col gap-3">
        <Button component="label" variant="contained">
         <input
          type="file"
          {...register("profile_picture", {
           required: false,
           onChange: async (e) => {
            let file = e.target.files && e.target?.files[0];
            if (file) {
             const reader = new FileReader();
             reader.readAsDataURL(file);
             reader.addEventListener(
              "load",
              function () {
               setLocalImgUrl(reader?.result || "");
              },
              false,
             );
            }
           },
          })}
          hidden
          accept="image/png, image/jpeg"
         />
         Change Photo
        </Button>

        <Button
         onClick={async () => {
          setIsRemoving(true);
          try {
           await removeAdminProfilePhoto();
           setLocalImgUrl("");
           setUserPhoto("");
           methods.setValue("profile_image", "", { shouldDirty: true });
           dispatch(removeProfilePhoto());
          } catch (error) {
           handleError(error);
          } finally {
           setIsRemoving(false);
          }
         }}
         variant="contained"
         color="errorInverse"
         disabled={isRemoving || !userPhoto}
        >
         Remove Photo
        </Button>
       </div>
      </div>

      <div className="w-full my-4 max-w-[407px]">
       <div className="flex gap-3">
        <ValidatedInput name="first_name" label="First Name" placeholder="Jibike" />
        <ValidatedInput name="last_name" label="Last Name" placeholder="Alarape" />
       </div>
       <ValidatedRadioBtns label="Sex" name="gender" options={["Male", "Female", "Others"]} />
      </div>
     </div>
     <LoadingButton
      loading={isLoading}
      disabled={!isDirty}
      loadingIndicator={<CircularProgress sx={{ color: "#ffffff" }} size="1.2rem" />}
      type="submit"
      variant="contained"
     >
      Save Changes
     </LoadingButton>
    </div>
   </form>
  </FormProvider>
 );
};

export default EditProfile;
