/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Button, Chip, Tooltip } from "@mui/material";
import UserDisplay from "components/TableComponent/UserDisplay";
import FlagIcon from "components/Vectors/FlagIcon";
import { PRIORITY_STATUS, TICKET_STATUS } from "utils/constants";
import { Link } from "react-router-dom";
import DoubleMessageIcon from "./Vectors/DoubleMessageIcon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarIcon2 from "./Vectors/CalendarIcon2";
//import SmallTickIcon from "components/Vectors/SmallTickIcon";

import moment from "moment";
import PaperClip2 from "./PaperClip2";
import { initialUser } from "hooks/getUserHook";

export const mock_ticket = {
 id: 1,
 title: "Ticket title",
 description:
  "Dictumst cursus penatibus amet sagittis, placerat in. Magna venenatis tristique dolor elementum nibh tempus scelerisque nisl. Tristique dignissim pellentesque egestas sed. Dolor sed vitae at vel pharetra nulla mauris, aliquam cras. Nibh scelerisque nec facilisis tortor. Mattis viverra eget dui, ultrices ullamcorper rhoncus.",
 status: "new",
 priority: "low",
 category: "category",
 createdAt: "2021-09-01T12:00:00.000Z",
 updatedAt: "2021-09-01T12:00:00.000Z",
 createdBy: "user",
 assignedTo: "user",
 user: {
  full_name: "Test user",
  profile_image: "https://picsum.photos/200/300",
  username: "testuser",
  user_type: "Creator",
  is_verified: true,
 },
 comments: [],
 attachment: "https://picsum.photos/200/300",
 engagement_id: 1,
 engagement: {
  img: "https://picsum.photos/200/300",
  name: "Dance & Music",
 },
 ticket_tag: "1krs2Ly",
 responses: [
  {
   user: initialUser,
   user_role: "client",
   created_at: new Date(),
   response:
    "Dictumst cursus penatibus amet sagittis, placerat in. Magna venenatis tristique dolor elementum nibh tempus scelerisque nisl. Tristique dignissim pellentesque egestas sed. Dolor sed vitae at vel pharetra nulla mauris, aliquam cras. Nibh scelerisque nec facilisis tortor. Mattis viverra eget dui, ultrices ullamcorper rhoncus.",
  },
  {
   admin: {
    ...initialUser,
    full_name: "Jide Kosoko",
    first_name: "Jide",
    last_name: "Kosoko",
    profile_image: "/images/avatar.png",
   },
   is_admin: true,
   created_at: new Date(),
   response:
    "Dictumst cursus penatibus amet sagittis, placerat in. Magna venenatis tristique dolor elementum nibh tempus scelerisque nisl. Tristique dignissim pellentesque egestas sed. Dolor sed vitae at vel pharetra nulla mauris, aliquam cras. Nibh scelerisque nec facilisis tortor. Mattis viverra eget dui, ultrices ullamcorper rhoncus.",
  },
 ],
};

function TicketCardSummary({ ticket, type = "admin", tab }) {
 //  if (ticket) console.log(ticket);
 const currentTask = ticket?.module_type.includes("Campaign") ? "Campaign" : "Engagement";
 return (
  <div className="bg-white mb-4 rounded-lg md:p-6 p-4 border font-medium flex">
   <div className="w-[25%] min-w-fit lg:min-w-[360px] flex flex-col justify-between border-r space-y-4 pr-5 max-w-[300px]">
    <div>
     <div className="flex mt-4 space-x-3 font-xs items-center">
      <Chip label={ticket?.status.split("_").join(" ")} color={TICKET_STATUS[ticket?.status?.toLowerCase()]} />
      <div className="flex gap-x-2">
       <FlagIcon color={PRIORITY_STATUS[ticket?.priority?.toLowerCase()]} />
       <span className="text-sm capitalize">{ticket?.priority} priority</span>
      </div>
     </div>
    </div>

    <div className="mt-4 flex flex-wrap items-center gap-3 text-[#8585A3]">
     {/* <InfluencerIcon color={"#8585A3"} /> */}
     {ticket?.module_id && (
      <Tooltip title={"This ticket contains an engagement"} color="white" placement="top" arrow>
       <div>
        <DoubleMessageIcon />
       </div>
      </Tooltip>
     )}

     {ticket?.attachment && (
      <Tooltip title={"This ticket contains an attachment"} color="white" placement="top" arrow>
       <div>
        <PaperClip2 />
       </div>
      </Tooltip>
     )}
     {ticket?.module_id && (
      <Link to={`/${currentTask?.toLowerCase()}s/${ticket?.module_id}`} className="font-semibold text-primary mt-auto">
       View {currentTask} {""}
       <ArrowForwardIcon />
      </Link>
     )}
    </div>

    <p className="flex gap-2 items-center font-medium text-xs text-grey50">
     <CalendarIcon2 />
     Issued on: {moment(ticket?.created_at).format("DD MMM YYYY, h:mm a")}
    </p>
    {tab !== "new" && (
     <p className="w-fit flex items-center gap-3 px-3 py-[6px] rounded-[8px] bg-[#F2F4F7] font-medium text-xs text-grey50">
      <span>
       {" "}
       Last message:{" "}
       {ticket?.last_message && typeof ticket?.last_message === "object"
        ? moment(ticket?.last_message?.created_at).format("DD MMM, h:mm a")
        : "not available"}
      </span>
      <span className="inline-flex items-center">
       <Avatar
        sx={{
         width: "24px",
         height: "24px",
        }}
        src={ticket?.user?.profile_image}
       />

       <span className="ml-2 text-grey10 truncate max-w-[10ch]">
        {ticket?.last_message && typeof ticket?.last_message === "object"
         ? ticket?.last_message.response
         : ticket?.last_message
         ? ticket?.last_message
         : " "}
       </span>
      </span>
     </p>
    )}
   </div>

   <div className="flex-grow pl-5 flex flex-col justify-between">
    <div className="flex flex-wrap gap-2 justify-between items-center">
     <h4 className="heading-4 font-semibold">{ticket?.title}</h4>
    </div>
    <p className="my-4 line-clamp-2">{ticket?.description}</p>
    <UserDisplay
     name={ticket?.user.first_name + " " + ticket?.user.last_name}
     img={ticket?.user?.profile_image}
     email={ticket?.user_role_type}
     badge={ticket?.user?.user_type === "creator" && ticket?.user?.kyc_verified}
    />
    <Link to={`${ticket?.id}`} className="font-semibold text-primary mt-4">
     View Ticket
    </Link>
   </div>
  </div>
 );
}
export default TicketCardSummary;

export function TicketCard({ ticket }) {
 const status = ticket?.status;
 console.log(ticket);
 return (
  <div className="border border-[#EAEAF0] bg-white rounded-2xl">
   <header className="border border-b-[#EAEAF0] p-4">
    <h4 className="text-[#2C2C3A] text-[18px] font-semibold">{ticket?.title}</h4>

    <div className="flex items-center justify-between mt-2">
     <div className="flex items-center gap-2">
      <span
       className={`flex items-center justify-center border border-[#DCDCE5] rounded-[4px] h-[24px] px-2 text-xs font-semibold !text-[${
        status === "New" ? "#8937CE" : status.includes("Progress") ? "#1989D4" : "#00A04A"
       }] uppercase`}
      >
       {ticket?.status}
      </span>

      <div className="flex gap-x-2">
       <FlagIcon color={PRIORITY_STATUS[ticket?.priority?.toLowerCase()]} />
       <span className="text-sm capitalize">{ticket?.priority} priority</span>
      </div>
     </div>

     <p className="flex gap-2 items-center font-medium text-xs text-grey50">
      {moment(ticket?.created_at).format("DD MMM YYYY, h:mm a")}
     </p>
    </div>
   </header>

   <div className="p-4">
    <p className="text-[#424257] text-sm font-medium">{ticket?.description}</p>

    <div className="bg-[#F6F6F7] h-[60px] rounded-xl mt-4 flex items-center p-4 justify-between">
     <div className="flex items-center gap-2">
      <div className="h-[40px] w-[36px] rounded-md flex items-center justify-center bg-white relative">
       <span className="bg-[#D61E34] h-[16px] w-[17px] rounded-full flex items-center justify-center text-white text-[10px] font-medium absolute right-0 top-0">
        {ticket?.responses_count}
       </span>
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
         d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
         fill="#8937CE"
        />
        <path
         d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
         fill="#8937CE"
        />
       </svg>
      </div>

      <h4 className="text-[#424257] font-medium text-sm">
       {ticket?.responses_count} {ticket?.responses_count > 1 ? "replies" : "reply"}
      </h4>
     </div>

     {status === "In Progress" && (
      <p className="flex gap-2 items-center font-medium text-xs text-[#424257]">
       Last message: {moment(ticket?.last_message?.created_at).format("DD MMM YYYY, h:mm a")}
      </p>
     )}
    </div>

    <div className="mt-4 flex items-center justify-between">
     <Link to={`/tickets/${ticket?.id}`}>
      <Button
       sx={{
        height: "3rem",
        border: "1px solid #BFBFCF",
        color: "#181820",
        borderRadius: "8px",
        width: "128px",
       }}
      >
       View Ticket
      </Button>
     </Link>
    </div>
   </div>
  </div>
 );
}
