const GenderIcon = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 22.25C5.56 22.25 1.75 18.44 1.75 13.75C1.75 9.06 5.56 5.25 10.25 5.25C14.94 5.25 18.75 9.06 18.75 13.75C18.75 18.44 14.94 22.25 10.25 22.25ZM10.25 6.75C6.39 6.75 3.25 9.89 3.25 13.75C3.25 17.61 6.39 20.75 10.25 20.75C14.11 20.75 17.25 17.61 17.25 13.75C17.25 9.89 14.11 6.75 10.25 6.75Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M16 8.75043C15.81 8.75043 15.62 8.68043 15.47 8.53043C15.18 8.24043 15.18 7.76043 15.47 7.47043L20.97 1.97043C21.26 1.68043 21.74 1.68043 22.03 1.97043C22.32 2.26043 22.32 2.74043 22.03 3.03043L16.53 8.53043C16.38 8.68043 16.19 8.75043 16 8.75043Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M21.5 9.75C21.09 9.75 20.75 9.41 20.75 9V3.25H15C14.59 3.25 14.25 2.91 14.25 2.5C14.25 2.09 14.59 1.75 15 1.75H21.5C21.91 1.75 22.25 2.09 22.25 2.5V9C22.25 9.41 21.91 9.75 21.5 9.75Z"
        fill={color || "#8937CE"}
      />
    </svg>
  );
};

export default GenderIcon;
