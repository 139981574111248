import {useEffect} from "react";
import PhoneInputField from "./PhoneInput/index";
import "./style.scss";

import {Controller, get, useFormContext} from "react-hook-form";

function PhoneInputWrapped({
  id,
  name,
  className = "",
  label,
  required = true,
  variant = "condensed",
  bg = "white",
  spaceY = true,
  ...props
}) {
  const {
    control,
    register,
    formState: {errors},
  } = useFormContext();
  useEffect(() => {
    register(name, {required});
  }, [name, register, required]);

  const error = get(errors, name);
  return (
    <div className="form-group-wrapper">
      <div
        className={`form-group  bg-${bg} ${
          !spaceY ? "" : variant === "free" ? "mt-10 mb-5" : "my-5"
        }  ${className}`}
      >
        {label && (
          <label
            className={`font-medium  bg-${bg} ${
              variant === "condensed" ? "-top-3" : "-top-6 -ml-4"
            }  px-2 text-sm`}
            htmlFor={id || name}
          >
            {label}
          </label>
        )}

        <Controller
          control={control}
          name={name}
          rules={{
            required: required ? "This field is required" : false,
            minLength: {
              value: 11,
              message: "Incorrect phone number",
            },
          }}
          render={({field: {onChange, value}}) => (
            <PhoneInputField
              // required={required}
              defaultValue={props?.defaultValue}
              onChangeHandler={(val) => {
                onChange(val);
              }}
            />
          )}
        />
      </div>
      {error && <div className="input-err-msg">{error.message}</div>}
    </div>
  );
}

export default PhoneInputWrapped;
