import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import MessageModal from "components/Modal/MessageModal";
import { useSuspendStateMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const SuspendStateModal = ({ open, close, state }) => {
 const [suspendState, { isLoading }] = useSuspendStateMutation();
 const handleSuspend = async () => {
  try {
   const res = await suspendState(state?.id).unwrap();
   showToast(res?.message);
   close();
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   title={`Are you sure you want to delete ${state?.name}?`}
   description="Deleting doesn’t mean lorem ipsum"
   cta={
    <div className="grid grid-cols-2 gap-4 w-full">
     <Button variant="outlined" color="error" onClick={close}>
      Cancel
     </Button>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      onClick={handleSuspend}
      loading={isLoading}
      variant="contained"
      color="error"
     >
      Delete
     </LoadingButton>
    </div>
   }
  />
 );
};

export default SuspendStateModal;
