import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import Modal from "components/Modal";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import { FormProvider, useForm } from "react-hook-form";
import { useSuspendUnSuspendJobMutation } from "services/jobs.api";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const SuspendJobModal = ({ openModal, closeModal, job }) => {
 const methods = useForm();
 const [suspendUnSuspend, { isLoading: isUnSuspending }] = useSuspendUnSuspendJobMutation();
 const onSubmit = async (data) => {
  const body = {
   ...data,
   status: "Suspended",
   redirect_url: window.location.href,
  };
  try {
   const res = await suspendUnSuspend({ id: job?.id, body }).unwrap();
   showToast(res?.message);
   closeModal();
   methods.reset();
  } catch (err) {
   handleError(err);
  }
 };
 return (
  <Modal
   title="Suspend job"
   openModal={openModal}
   // icon={<BlockIcon color="error" sx={{ fontSize: "32px" }} />}
   iconBg="bg-red-200"
   closeModal={closeModal}
  >
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
     <ValidatedTextArea name="message" label="Reason for suspension" />
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isUnSuspending}
      type="submit"
      fullWidth
      variant="contained"
      color="error"
      sx={{ mt: 7 }}
     >
      Suspend Job
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
};

export default SuspendJobModal;
