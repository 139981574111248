import { useEffect, useState } from "react";
import OpenTicketContent from "./components/OpenTicketContent";
import { Link } from "react-router-dom";
import { ReactComponent as Ticket } from "assets/icons/ticket.svg";
import EmptyResponse from "components/EmptyResponse";
import { useGetTicketStatsQuery } from "services/tickets.api";
import { useGetAllTicketsQuery } from "services";
import { formatTimeElapsed } from "utils";
import EmptyTickets from "./components/EmptyTickets";

const OpenTicket = () => {
 const [hideTicket, setHideTicket] = useState(true);

 const { data: ticketStats, isLoading: ticketStatsLoading } = useGetTicketStatsQuery();
 const { data } = useGetAllTicketsQuery();

 //  if (data) console.log(data);
 const tickets = data?.data?.data;
 //  console.log(tickets);
 const newTickets = tickets?.filter((ticket) => ticket?.status === "In Progress" || ticket?.status === "New");

 //  if (data) {
 //   data?.data?.data?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
 //   console.log(data);
 //  }

 //  const currentTime = new Date();

 useEffect(() => {
  setTimeout(() => setHideTicket(false), 5000);
 }, []);

 //  if (ticketStats) {
 //   console.log(ticketStats);
 //  }

 return (
  <div className="p-[24px] flex flex-col border rounded-xl h-full ">
   <section className="flex justify-between">
    <h2 className="dashboard-h2 text-prop ">Open Tickets</h2>
    <button className="dashboard-mini-btn text-prop ">
     <Link to="tickets"> View all</Link>
    </button>
   </section>

   <section
    className="mt-[20px] border-[#EAEAF0] border-[1px] p-[24px] rounded-[12px] flex 
      items-center gap-[30px] md:gap-[20px] lg:gap-[50px] "
   >
    <div className="">
     <h4 className="ticket__texts">New Tickets</h4>
     <span className="ticket__span text-primary ">{ticketStatsLoading ? "..." : ticketStats?.data?.New}</span>
    </div>
    <span className="border-[#DCDCE5] h-full w-[1px] border-[1px] "></span>
    <div className="">
     <h4 className="ticket__texts">Resolved</h4>
     <span className="ticket__span text-success ">{ticketStatsLoading ? "..." : ticketStats?.data?.Resolved}</span>
    </div>
    <span className="border-[#DCDCE5] h-full w-[1px] border-[1px] "></span>
    <div className="">
     <h4 className="ticket__texts">In Progress</h4>
     <span className="ticket__span text-grey50">{ticketStatsLoading ? "..." : ticketStats?.data["In Progress"]}</span>
    </div>
   </section>

   {newTickets?.length === 0 ? (
    <div className="h-[400px] flex items-center justify-center">
     <EmptyTickets />
    </div>
   ) : (
    <section className="mt-[2rem] h-[450px] overflow-y-auto scrollbar-style pr-[.65rem] flex flex-col gap-[2rem]  ">
     {newTickets?.length !== 0 &&
      newTickets?.slice(0, 10).map((data, index) => (
       <div key={index}>
        <OpenTicketContent
         time={formatTimeElapsed(data.created_at)}
         name={`${data?.user?.first_name} ${data?.user?.last_name}`}
         category={data?.status}
         senderImg={data?.user?.profile_image}
         title={data?.title}
         status={data?.status}
         badge={data?.user?.user_type === "creator" && data?.user?.kyc_verified}
         description={data?.description}
         responses={data?.responses_count}
         attachment={data?.attachment}
         userRole={data?.user_role_type}
         id={data?.id}
         last_message={data?.last_message?.created_at}
        />
       </div>
      ))}
    </section>
   )}
  </div>
 );
};
export default OpenTicket;
