import { createSlice } from "@reduxjs/toolkit";
const initialState = {
 user: null,
 token: "",
 userEmail: "",
};

// auth slice
const authSlice = createSlice({
 name: "auth",
 initialState,
 reducers: {
  setUserDetails: (state, { payload: user }) => {
   state.user = user;
  },
  setUserToken: (state, { payload: token }) => {
   state.token = token;
  },
  setUserEmail: (state, { payload: email }) => {
   state.userEmail = email;
  },
  removeProfilePhoto: (state) => {
   if (state.user) {
    state.user.profile_image = "";
   }
  },
  logOut: (state, { payload: { redirect = true } }) => {
   state.token = "";
   state.user = null;
   state.userEmail = "";

   // check if the redirect payload is passed
   window.localStorage.clear();
   if (redirect) {
    let redirectTo = window.location.pathname;
    window.location.href = `/login?redirectTo=${redirectTo}`;
   }
   // else {
   //   window.location.href = `/login`;
   // }
  },
 },
});

const { actions, reducer } = authSlice;
export const { setUserDetails, logOut, setUserToken, setUserEmail, removeProfilePhoto } = actions;

// selector to select user details from the store
export const selectCurrentUser = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectEmail = (state) => state.auth.userEmail;

export default reducer;
