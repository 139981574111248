import {
    createApi
} from "@reduxjs/toolkit/query/react";
import {
    baseQueryWithReauth
} from "services";

export const transactionsApi = createApi({
    baseQuery: baseQueryWithReauth,
    reducerPath: "transactionsApi",
    tagTypes: ["transactions"],
    endpoints: (builder) => ({
        getTransactionsStats: builder.query({
            query: () => "/transaction/stats",
            providesTags: ["transactions"],
        }),
        getCreatorPayoutStats: builder.query({
            query: () => "/transaction/creator/payout/stats",
            providesTags: ["transactions"],
        }),
        getCreatorPayoutList: builder.query({
            query: () => "/transaction/creator/payout/list",
            providesTags: ["transactions"],
        }),
        getInfluencerPayoutStats: builder.query({
            query: () => "/transaction/influencer/payout/stats",
            providesTags: ["transactions"],
        }),

        getRefundStats: builder.query({
            query: () => "/transaction/refund/stats",
            providesTags: ["transactions"],
        }),
        completeRefunds: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "/transaction/refund/complete-refund",
                body,
            }),
            invalidatesTags: ["transactions"],
        }),
        declineRefunds: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "/transaction/refund/decline-refund",
                body,
            }),
            invalidatesTags: ["transactions"],
        }),
        getJobTransactions: builder.query({
            //    query: () => "/transaction/job/list",
            query: ({
                params
            }) => ({
                url: `/transaction/job/list`,
                params,
            }),
            providesTags: ["transactions"],
        }),
        getCampaignTransactions: builder.query({
            query: ({
                params
            }) => ({
                url: "transaction/campaign/list",
                params,
            }),
            providesTags: ["transactions"],
        }),
        getInfluencerEarnings: builder.query({
            query: ({
                params
            }) => ({
                url: "transaction/influencer/earning/list",
                params,
            }),
            providesTags: ["transactions"],
        }),
        getCreatorEarnings: builder.query({
            query: ({
                params
            }) => ({
                url: "transaction/creator/earning/list",
                params
            }),
            providesTags: ["transactions"],
        }),
        getCreatorPayouts: builder.query({
            query: ({
                params
            }) => ({
                url: "transaction/creator/payout/list",
                params
            }),
            providesTags: ["transactions"],
        }),
        getPayouts: builder.query({
            query: ({
                params,
                user
            }) => ({
                url: `transaction/${user}/payout/list`,
                params
            }),
            providesTags: ["transactions"],
        }),
        getAllPayoutsByStatus: builder.query({
            query: ({
                params,
                status
            }) => ({
                url: `/transaction/payout/${status}
`,
                params,
            }),
            providesTags: ["transactions"],
        }),
        getReferralPayoutsByStatus: builder.query({
            query: ({
                params,
                status
            }) => ({
                url: `/transaction/referral/payout/${status}
`,
                params,
            }),
            providesTags: ["transactions"],
        }),
        getPayoutsStats: builder.query({
            query: (user) => ({
                url: `/transaction/${user}/payout/stats`
            }),
            providesTags: ["transactions"],
        }),
        getInfluencerPayouts: builder.query({
            query: ({
                params
            }) => ({
                url: "transaction/influencer/payout/list",
                params,
            }),
            providesTags: ["transactions"],
        }),
        getUserDetails: builder.query({
            query: ({
                user,
                id
            }) => ({
                url: `/${user}/show/${id}`,
            }),
            providesTags: ["transactions"],
        }),
        getRefundHistories: builder.query({
            query: ({
                params
            }) => ({
                url: "/transaction/refund/list",
                params,
            }),
            providesTags: ["transactions"],
        }),
        markAsCompleted: builder.mutation({
            query: ({
                body
            }) => ({
                url: `transaction/payout/mark-as-completed`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["transactions"],
        }),
        getSingleReferralPayout: builder.query({
            query: (id) => ({
                url: `/transaction/referral-payout/${id} `,
            }),
            providesTags: ["transactions"],
        }),
        //   /admin/transaction/referral-payout/show/:payoutId
    }),
});

export const {
    useGetTransactionsStatsQuery,
    useGetCreatorPayoutStatsQuery,
    useGetInfluencerPayoutStatsQuery,
    useGetRefundStatsQuery,
    useGetCreatorPayoutListQuery,
    useGetJobTransactionsQuery,
    useGetCampaignTransactionsQuery,
    useGetInfluencerEarningsQuery,
    useGetCreatorEarningsQuery,
    useGetCreatorPayoutsQuery,
    useGetInfluencerPayoutsQuery,
    useGetRefundHistoriesQuery,
    useGetPayoutsStatsQuery,
    useGetPayoutsQuery,
    useGetUserDetailsQuery,
    useMarkAsCompletedMutation,
    useGetSingleReferralPayoutQuery,
    useGetAllPayoutsByStatusQuery,
    useGetReferralPayoutsByStatusQuery,
    useCompleteRefundsMutation,
    useDeclineRefundsMutation,
} = transactionsApi;