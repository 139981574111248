import { Avatar, CircularProgress } from "@mui/material";
import { useState } from "react";

import CarouselModal from "./Modal/CarouselModal";

const MultipleImageVideo = ({ images, source }) => {
  const imgArr = [
    { url: "/images/campaign_placeholders/img1.png" },
    { url: "/images/campaign_placeholders/img2.png" },
    { url: "/images/campaign_placeholders/img3.png" },
    { url: "/images/campaign_placeholders/img4.png" },
  ];
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(1);
  const [imgVal, setImgVal] = useState("");

  const imageContent = images ? images : imgArr;

  const handleClick = (i) => {
    setImgVal(imageContent[i]?.url);
    setOpenModal(true);
    setIndex((prev) => (prev = i));
    console.log(index);
  };

  const handleNext = () => {
    if (index < imageContent.length - 1) {
      setImgVal(imageContent[index + 1]?.url);
      setIndex((prev) => prev + 1);
    } else {
      setIndex(0);
      setImgVal(imageContent[0]?.url);
    }
  };

  const handlePrev = () => {
    if (index > 0) {
      setImgVal(imageContent[index - 1]?.url);
      setIndex((prev) => prev - 1);
    } else {
      setIndex(imageContent.length - 1);
      setImgVal(imageContent[imageContent.length - 1]?.url);
    }
  };

  return (
    <div> 
      <div className="relative !bg-black rounded-[12px] flex items-center justify-center  ">
        <div className=" absolute z-1  ">
          <CircularProgress
            sx={{
              color: "#fff",
            }}
            size="2.2rem"
          />
        </div>

        <iframe
          className={`!h-[200px] z-10 md:!h-[400px] w-full rounded-[12px] `}
          loading="lazy"
          controls
          src={source || "https://www.youtube.com/embed/_Z3QKkl1WyM"}
          title="video player"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
          Your browser does not support HTML video.
        </iframe>
      </div>
      <div className="mt-[12px] ">
        <div className="grid grid-cols-4 gap-4 cursor-pointer ">
          {imageContent?.map((el, i) => (
            <Avatar
              key={`img_carousel_${i}`}
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "16px",
              }}
              className="aspect-square"
              src={imageContent[i]?.url}
              onClick={() => handleClick(i)}
            >
              <img
                className="w-full"
                alt="broken"
                src="/images/broken-image.png"
              />
            </Avatar>
          ))}
        </div>
      </div>
      <CarouselModal
        img={imgVal}
        openModal={openModal}
        setopenModal={setOpenModal}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    </div>
  );
};
export default MultipleImageVideo;
