import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const jobsApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "jobsApi",
 tagTypes: ["jobs"],
 endpoints: (builder) => ({
  getJobsStatistics: builder.query({
   query: () => "job/statistics",
   providesTags: ["jobs"],
  }),
  getJobsByStatus: builder.query({
   query: ({ status, params }) => ({
    url: `job/status/${status}`,
    params,
   }),
   providesTags: ["jobs"],
  }),
  getSingleJob: builder.query({
   query: (id) => `job/show/${id}`,
   providesTags: ["jobs"],
  }),
  suspendUnSuspendJob: builder.mutation({
   query: ({ id, body }) => ({
    url: `job/suspend-unsuspend/${id}`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["jobs"],
  }),
 }),
});

export const {
 useGetJobsStatisticsQuery,
 useGetJobsByStatusQuery,
 useGetSingleJobQuery,
 useSuspendUnSuspendJobMutation,
} = jobsApi;
