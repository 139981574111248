import StatCard from "components/StatCard";
import TxnIcon from "components/Vectors/TxnIcon";
import WalletAddIcon from "components/Vectors/WalletAddIcon";
import WalletIcon from "components/Vectors/WalletIcon";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useState } from "react";
import CampaignTransactionsTab from "../Innertabs/CampaignTransactionsTab";
import InfluencerEarningTabs from "../Innertabs/InfluencerEarningTabs";
import { useGetTransactionsStatsQuery } from "services/transactions.api";
import { toCurrency } from "utils";

function TransactionsTab() {
 const [activeTab, setactiveTab] = useState("campaign");
 const { data: transactionStats, isLoading: isStatsLoading } = useGetTransactionsStatsQuery();
 //  console.log(transactionStats);

 return (
  <>
   <div className="grid grid-cols-4 gap-4">
    <StatCard
     name={"Available Balance"}
     icon={null}
     value={isStatsLoading ? "..." : toCurrency(transactionStats?.data?.available_balance)}
     containerClass="col-span-2"
    />
    <StatCard
     name={"All Earning Payouts"}
     icon={<WalletIcon color="#8585A3" />}
     iconBg="bg-grey100"
     value={isStatsLoading ? "..." : toCurrency(0.0)}
    />
    <StatCard
     name={"All Referral Payouts"}
     icon={<WalletIcon color="#8585A3" />}
     iconBg="bg-grey100"
     value={isStatsLoading ? "..." : toCurrency(0.0)}
    />
   </div>

   <div className="grid grid-cols-3 gap-4 mt-4">
    <StatCard
     name={"All Time Sales on Campaigns + Commission Charges"}
     icon={<WalletAddIcon />}
     iconBg="bg-primary-100"
     value={isStatsLoading ? "..." : toCurrency(0.0)}
    />
    <StatCard
     name={"All Time Commission on Campaigns Only"}
     icon={<TxnIcon />}
     iconBg="bg-success-100"
     value={isStatsLoading ? "..." : toCurrency(0.0)}
    />
    <StatCard
     name={"Current Transaction Volume of Ongoing Campaigns"}
     icon={<WalletIcon color="#D61E34" />}
     iconBg="bg-error-100"
     value={isStatsLoading ? "..." : toCurrency(0.0)}
    />
   </div>

   <TabContext value={activeTab}>
    <TabList
     variant="scrollable"
     scrollButtons="auto"
     className="mt-10 font-semibold"
     value={activeTab}
     onChange={(e, newValue) => setactiveTab(newValue)}
     aria-label="basic tabs example"
    >
     <Tab label="Campaign Transactions" value="campaign" />
     <Tab label="Influencers Earnings" value="influencers" />
    </TabList>

    <TabPanel sx={{ padding: "0 !important" }} value="campaign">
     <CampaignTransactionsTab />
    </TabPanel>
    <TabPanel sx={{ padding: "0 !important" }} value="influencers">
     <InfluencerEarningTabs />
    </TabPanel>
   </TabContext>
  </>
 );
}

export default TransactionsTab;
