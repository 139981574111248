import flag from "../../../../assets/icons/flag.svg";
import userIcon from "../../../../assets/icons/chat-user.svg";
import messageIcon from "../../../../assets/icons/messages.svg";
import paperclipIcon from "../../../../assets/icons/paperclip.svg";
import { Avatar, Button } from "@mui/material";
import SmallTickIcon from "components/Vectors/SmallTickIcon";

import { Link } from "react-router-dom";
import moment from "moment";

const OpenTicketContent = ({
 time,
 name,
 userRole,
 category,
 status,
 senderImg,
 title,
 description,
 responses,
 attachment,
 badge,
 id,
 last_message,
}) => {
 return (
  <div className="flex flex-col border-b-[#BFBFCF] border-b-[1px] pb-[24px] ">
   <div className="flex justify-between ">
    <div className="flex items-center gap-2  ">
     <div className="h-[40px] w-[40px] rounded-full ">
      {/* <img src={senderImg} alt="buyer" className="h-[40px] w-[40px] rounded-full " /> */}
      {/* <Avatar
                        src={senderImg}
                        alt="avatar"
                        className="w-full"
                    /> */}

      <div className="relative h-[40px] w-[40px]">
       <Avatar
        src={senderImg}
        alt="avatar"
        className="w-full"
        // sx={{
        //     borderRadius: imgRounded ? '50%' : '4px',
        //     width: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
        //     height: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
        // }}
       />
       {badge && (
        <div className="absolute -top-1 -right-1">
         <SmallTickIcon TooltipTitle="verified" color="#8937CE" />
        </div>
       )}
      </div>
     </div>
     <div className=" ">
      <h4 className="font-medium text-[1rem] text-prop text-grey10 ">{name}</h4>
      <span className="text-prop font-medium text-grey text-[14px]  ">{userRole}</span>
     </div>
    </div>
    <div className="flex justify-between gap-[1rem] items-center  ">
     <span
      className="flex items-center justify-center  h-[27px] w-[52px] rounded-[20px] bg-[#FAF5FF] text-primary 
              text-[13px] text-prop whitespace-nowrap"
     >
      {status}
     </span>
     <div className="h-[20px] w-[20px] ">
      <img src={flag} alt="flag" />
     </div>
     <p className="text-prop text-[1rem] text-grey font-medium ">{time}</p>
    </div>
   </div>
   <div className="mt-[14px] ">
    <h5 className="font-semibold text-[18px] text-prop text-grey10 ">{title}</h5>
    <p className="mt-[8px] font-medium text-[14px] tracking-[-2%] leading-[24px] text-grey30  ">{description}</p>
   </div>

   {(status === "In Progress" || attachment) && (
    <div
     className={`mt-4 flex items-center ${
      attachment && status === "In Progress"
       ? "justify-between"
       : attachment & (status !== "In Progress")
       ? "justify-end"
       : ""
     }`}
    >
     {status === "In Progress" && (
      <p className="text-[#803CD0] text-sm font-medium">
       Last message: {moment(last_message).format("DD MMM, hh:mm A")}
      </p>
     )}
    </div>
   )}

   <div className="mt-[24px] flex justify-between items-center ">
    <Link to={`/tickets/${id}`}>
     <Button
      sx={{
       height: "3rem",
       border: "1px solid #BFBFCF",
       color: "#181820",
       borderRadius: "8px",
       width: "128px",
      }}
     >
      View Ticket
     </Button>
    </Link>
    <div className="flex gap-[22px]">
     <button className="">
      <img src={userIcon} alt="icon" />
     </button>

     {responses !== 0 ? (
      <button className="flex items-center gap-[4px]">
       <img src={messageIcon} alt="icon" />
       <span className="text-[1rem] text-prop font-medium text-grey ">{responses}</span>
      </button>
     ) : null}

     {attachment && (
      <button className="flex items-center gap-[4px]">
       <img src={paperclipIcon} alt="icon" />
       <span className="text-[1rem] text-prop font-medium text-grey ">{attachment ? 1 : ""}</span>
      </button>
     )}
    </div>
   </div>
  </div>
 );
};
export default OpenTicketContent;
