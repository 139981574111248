import { Button, Chip, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import EditIcon2 from "components/Vectors/EditIcon2";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import MyDataTable, { TableDropDown } from "components/TableComponent/MyDataTable";
import moment from "moment";
import ManageStateModal from "../Modals/ManageStateModal";
import SuspendStateModal from "../Modals/SuspendStateModal";
import ManageCityModal from "../Modals/ManageCityModal";
import { useGetStatesByCountryQuery, useGetOneCountryQuery } from "services";

const CountryDetail = () => {
 const navigate = useNavigate();
 const [searchParams] = useSearchParams();
 const id = searchParams.get("id");
 const [showEditStateModal, setShowEditStateModal] = useState(false);
 const [showAddStateModal, setShowAddStateModal] = useState(false);
 const [showSuspendStateModal, setShowSuspendStateModal] = useState(false);
 const [showAddCityModal, setShowAddCityModal] = useState(false);
 const [selectedEl, setSelectedEl] = useState(null);
 const { data, isLoading, error } = useGetStatesByCountryQuery(id);
 const { data: countryDetail } = useGetOneCountryQuery(id);
 const headCells = [
  {
   label: "S/N",
   name: "sn",
  },
  {
   label: "States",
   name: "states",
  },
  {
   label: "Zip Code",
   name: "zipcode",
  },
  {
   label: "Cities",
   name: "cities",
  },
  {
   label: "Last Modified",
   name: "lastModified",
  },
  {
   label: "Status",
   name: "status",
   options: {
    customBodyRender: (value) => (
     <Chip
      color={
       value?.toLowerCase() === "active"
        ? "successInverse"
        : value?.toLowerCase() === "suspended"
        ? "errorInverse"
        : "greyBorder"
      }
      label={value}
     />
    ),
   },
  },
  {
   label: "",
   name: "action",
   options: {
    customBodyRender: (state) => (
     <TableDropDown tableId={"table"}>
      <Button
       onClick={() => {
        setSelectedEl(state);
        setShowAddCityModal(true);
       }}
       startIcon={<CorporateFareRoundedIcon />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       Add city
      </Button>
      <Button
       onClick={() => navigate(`?tab=location&id=${id}&state=${state?.id}`)}
       startIcon={<CorporateFareRoundedIcon />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       View cities
      </Button>
      <Button
       onClick={() => {
        setSelectedEl(state);
        setShowEditStateModal(true);
       }}
       startIcon={<EditIcon2 />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       Edit state
      </Button>
      <Button
       onClick={() => {
        setSelectedEl(state);
        setShowSuspendStateModal(true);
       }}
       startIcon={<DoDisturbAltRoundedIcon color="#D61E34" />}
       color="error"
       sx={{ py: "0.5rem !important" }}
      >
       Delete state
      </Button>
     </TableDropDown>
    ),
   },
  },
 ];
 const cellData = data?.data?.map((state, index) => ({
  sn: index + 1,
  states: state?.name,
  zipcode: state?.zip_code,
  cities: state?.cities_count,
  lastModified: moment(state?.updated_at).format("DD MMMM YYYY"),
  status: state?.status,
  action: state,
 }));

 return (
  <>
   <div className="flex mb-4 items-center justify-between">
    <div className="flex items-center">
     <IconButton onClick={() => navigate("?tab=location")}>
      <KeyboardBackspaceOutlinedIcon />
     </IconButton>
     <h1 className="font-semibold capitalize inline-flex items-center gap-x-2 ml-2 text-prop text-[20px] text-grey30 my-3">
      <img src={countryDetail?.data?.country_flag} alt={countryDetail?.data?.name} className="h-10 w-16" />
      {countryDetail?.data?.name}
     </h1>
    </div>
    <Button onClick={() => setShowAddStateModal(true)} variant="contained">
     Add State
    </Button>
   </div>
   <MyDataTable
    options={{
     searchOpen: true,
     viewColumns: false,
     filter: false,
    }}
    isLoading={isLoading}
    error={error}
    headCells={headCells}
    scrollX={false}
    data={cellData}
    emptyData={{
     icon: LocationCityRoundedIcon,
     message: "No States yet",
    }}
   />
   <ManageStateModal
    open={showEditStateModal}
    close={() => setShowEditStateModal(false)}
    isEditing
    state={selectedEl}
   />
   <ManageStateModal open={showAddStateModal} close={() => setShowAddStateModal(false)} />
   <SuspendStateModal open={showSuspendStateModal} close={() => setShowSuspendStateModal(false)} state={selectedEl} />
   <ManageCityModal open={showAddCityModal} close={() => setShowAddCityModal(false)} state={selectedEl} />
  </>
 );
};
export default CountryDetail;
