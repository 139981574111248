import { Avatar, Button, Chip, CircularProgress, IconButton } from "@mui/material";
import UserDisplay from "components/TableComponent/UserDisplay";
import FlagIcon from "components/Vectors/FlagIcon";
import { useEffect, useState } from "react";
import { PRIORITY_STATUS, TICKET_STATUS } from "utils/constants";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import EmptyImgIconFilled from "./Vectors/EmptyImgIconFilled";
import BackBtn from "./BackBtn";
import DurationFilter from "./Vectors/DurationFilter";
import { useUpdateTicketStatusMutation } from "services/tickets.api";
import { useReplyTicketMutation } from "services/tickets.api";
import moment from "moment";
import { getFileNameFromUrl, getFileSize, handleError, truncateString } from "utils";
import { showToast } from "store/store.hooks";
import { FormProvider, useForm } from "react-hook-form";
import FileDisplayUi from "./FileDisplayUI";
import ValidatedInput from "./forms/ValidatedInput";
import { LoadingButton } from "@mui/lab";
import { Link, useParams } from "react-router-dom";
// import { mock_ticket } from "./TicketCardSummary";
import { useGetSingleTicketQuery } from "services";

const TicketForm = ({ ticket_tag }) => {
 const [attachment, setAttachment] = useState(null);
 const methods = useForm({
  mode: "all",
 });
 const [reply, { isLoading }] = useReplyTicketMutation();

 const handleSubmit = async (data) => {
  const replyData = new FormData();
  console.log(data);
  replyData.append("ticket_id", ticket_tag);
  replyData.append("response", data.response);
  if (attachment) replyData.append("attachment", attachment);

  if (attachment) console.log(attachment[0]);
  try {
   let res = await reply({
    ticket_id: ticket_tag,
    response: data.response,
    attachment: attachment ? attachment[0] : "",
   }).unwrap();
   showToast(res?.message, "success");
   methods.reset();
   setAttachment(null);
  } catch (err) {
   handleError(err);
  }
 };

 return (
  <FormProvider {...methods}>
   <form onSubmit={methods.handleSubmit(handleSubmit)} className="chat-form gap-3 !items-end mt-5 mb-2">
    <div className={`w-full rounded-lg bg-[#F9F9FB]`}>
     {attachment && (
      <div className="p-3">
       <FileDisplayUi
        name={truncateString(attachment.name, 30)}
        isFileLarge={attachment?.size > 1000000}
        downloadType={false}
        onCancel={() => setAttachment(null)}
        size={getFileSize(attachment?.size)}
       />
      </div>
     )}
     <ValidatedInput
      showErrMsg={false}
      name="response"
      bg="transparent"
      variant={false}
      placeholder="Say something"
      className="chat-input !mt-0"
      suffixIcon={
       <IconButton className="!static" component="label" htmlFor="file">
        <input
         onChange={(e) => setAttachment(e.target.files[0])}
         type="file"
         id="file"
         name="file"
         accept="image/*"
         className="sr-only"
        />
       </IconButton>
      }
     />
    </div>
    <LoadingButton
     className="!bg-[#8937CE] !h-[48px] !w-[48px]"
     loadingIndicator={
      <CircularProgress
       sx={{
        color: "#fff",
       }}
       size="1.2rem"
      />
     }
     disabled={(attachment && attachment?.size > 1000000) || !methods.formState.isValid}
     loading={isLoading}
     type="submit"
     variant=""
     color=""
    >
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M16.1401 2.96004L7.11012 5.96004C1.04012 7.99004 1.04012 11.3 7.11012 13.32L9.79012 14.21L10.6801 16.89C12.7001 22.96 16.0201 22.96 18.0401 16.89L21.0501 7.87004C22.3901 3.82004 20.1901 1.61004 16.1401 2.96004ZM16.4601 8.34004L12.6601 12.16C12.5101 12.31 12.3201 12.38 12.1301 12.38C11.9401 12.38 11.7501 12.31 11.6001 12.16C11.3101 11.87 11.3101 11.39 11.6001 11.1L15.4001 7.28004C15.6901 6.99004 16.1701 6.99004 16.4601 7.28004C16.7501 7.57004 16.7501 8.05004 16.4601 8.34004Z"
       fill="white"
      />
     </svg>
    </LoadingButton>
   </form>
  </FormProvider>
 );
};

function EachTicket() {
 const { id } = useParams();
 // const {data, isLoading, error} = useGetSingleTicketQuery(id);
 //  const ticket = mock_ticket;

 const [updateTicketStatus, { isLoading: isUpdateLoading }] = useUpdateTicketStatusMutation();
 const { data } = useGetSingleTicketQuery(id);
 const [selectedStatus, setselectedStatus] = useState("");

 useEffect(() => {
  if (data && data?.data?.status === "New") {
   setselectedStatus("In Progress");
  }
 }, [data]);

 if (data) console.log(data);
 const currentTask = data?.data?.module_type.includes("Campaign") ? "Campaign" : "Engagement";
 const handleUpdateTicket = () => {
  updateTicketStatus({ ticket_id: id, status: selectedStatus })
   .unwrap()
   .then((res) => showToast(`Ticket status changed to ${selectedStatus} sucessfully`, "success"))
   .catch((err) => handleError(err));
 };

 return (
  <>
   <div className="flex items-center mb-3 justify-between ">
    <BackBtn />
    <div className="flex gap-3">
     <DurationFilter
      options={[
       {
        label: "In Progress",
        value: "In Progress",
       },
       {
        label: "Resolved",
        value: "Resolved",
       },
      ]}
      defaultValue={data?.data?.status}
      onChange={(value) => setselectedStatus(value)}
     />
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#fff",
        }}
        size="1.2rem"
       />
      }
      loading={isUpdateLoading}
      onClick={handleUpdateTicket}
      variant="contained"
     >
      Update
     </LoadingButton>
    </div>
   </div>

   <div className="bg-white mb-4 rounded-lg md:p-6 p-4 border font-medium flex ">
    <div className="w-[25%] min-w-fit lg:min-w-[280px] hidden md:block border-r space-y-4 pr-5 max-w-[300px]">
     <UserDisplay
      name={data?.data?.user.first_name + " " + data?.data?.user?.last_name}
      img={data?.data?.user?.profile_image}
      email={data?.data?.user?.username}
     />
     <div className="flex space-x-3 font-xs items-center ">
      <Chip label={data?.data?.status.split("_").join(" ")} color={TICKET_STATUS[data?.data?.status.toLowerCase()]} />
      <div className="flex gap-x-2 capitalize">
       <FlagIcon color={PRIORITY_STATUS[data?.data?.priority.toLowerCase()]} />
       <span>{data?.data?.priority} Priority</span>
      </div>
     </div>

     {data?.data?.module_id && (
      <div className="pt-5">
       <p className="text-grey text-sm uppercase mb-1">{currentTask}</p>
       <Link
        to={`/${currentTask?.toLowerCase()}s/${data?.data?.module_id}`}
        className="p-2 block bg-grey100 rounded-lg"
       >
        <UserDisplay
         imgRounded={false}
         name={data?.data?.title}
         img={data?.data?.module?.job_cover_image}
         size="small"
        />
       </Link>
      </div>
     )}
    </div>

    <div className="flex-grow pl-5">
     <div className="flex flex-wrap gap-2 justify-between items-center">
      <h4 className="heading-4 font-semibold">{data?.data?.title}</h4>
      <p className="text-grey text-sm">{moment(data?.data?.created_at).format("DD MMM YYYY, h:mm a")}</p>
     </div>
     <p className="mt-6">{data?.data?.description}</p>

     {data?.data?.attachment && (
      <div className="flex flex-wrap gap-4 my-5">
       <a
        href={data?.data?.attachment}
        download
        className="px-4 items-center py-2 rounded-[50px] border flex gap-3 font-semibold"
       >
        <EmptyImgIconFilled color="#8937CE" />
        <span>{truncateString(getFileNameFromUrl(data?.data?.attachment), 60)}</span>
       </a>
      </div>
     )}

     <div className="flex md:hidden mt-4 flex-wrap gap-4">
      <UserDisplay
       name={`${data?.data?.user.first_name} ${data?.data?.user.last_name}`}
       img={data?.data?.user?.profile_image}
       email={data?.data?.user?.username}
      />
      {data?.data?.module_id && (
       <div className="py-5">
        <p className="text-grey text-sm uppercase mb-3">Engagement</p>
        <UserDisplay imgRounded={false} name="Music & Dance Promotions" img="/images/serviceTable.png" size="small" />
        <Button variant="text" sx={{ padding: "0px", mt: 2 }}>
         View Engagement
        </Button>
       </div>
      )}
     </div>

     {data?.data?.responses?.length === 0 && (
      <div className="my-4">
       <p className="font-semibold ">Replies</p>
       <div className="flex items-center justify-center flex-col gap-2 mt-8 mb-12">
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
         <rect width="120" height="120" rx="16" fill="#EAEAF0" />
         <path
          d="M76.9838 72.1787L78.0075 80.4738C78.27 82.6525 75.9338 84.175 74.07 83.0462L64.9875 77.6387C64.3575 77.2712 64.2 76.4837 64.5413 75.8537C65.8538 73.4387 66.5625 70.7087 66.5625 67.9787C66.5625 58.3712 58.32 50.5487 48.1875 50.5487C46.1138 50.5487 44.0925 50.8638 42.2025 51.4937C41.2313 51.8088 40.2863 50.9163 40.5225 49.9188C42.9113 40.3638 52.0988 33.25 63.0713 33.25C75.8813 33.25 86.25 42.9362 86.25 54.88C86.25 61.9675 82.6013 68.2412 76.9838 72.1787Z"
          fill="#53536F"
         />
         <path
          d="M62.625 67.9788C62.625 71.1026 61.47 73.9901 59.5275 76.2738C56.9287 79.4238 52.8075 81.4451 48.1875 81.4451L41.3362 85.5138C40.1812 86.2226 38.7113 85.2513 38.8688 83.9126L39.525 78.7413C36.0075 76.3001 33.75 72.3888 33.75 67.9788C33.75 63.3588 36.2175 59.2901 39.9975 56.8751C42.3338 55.3526 45.1425 54.4863 48.1875 54.4863C56.1675 54.4863 62.625 60.5238 62.625 67.9788Z"
          fill="#A2A2B9"
         />
        </svg>
        <h4 className="text-black font-semibold text-2xl">No Replies yet</h4>
       </div>
      </div>
     )}
     {data?.data?.responses?.map((el, i) => (
      <div key={`response_${el.id}`} className="flex space-x-4 text-sm md:text-base mb-5">
       <Avatar src={el?.is_admin ? el?.admin?.profile_image : el?.user?.profile_image} />
       <div>
        <div className="flex space-x-2 items-center">
         <p className="font-semibold">
          {el?.is_admin ? `${el?.admin?.first_name} ${el?.admin?.last_name}` : el?.user?.full_name}
         </p>
         <span className="text-primary text-sm capitalize">{el?.is_admin ? "Admin" : el?.user_role}</span>
        </div>
        <p className="text-sm text-grey mb-4 mt-2">{moment(el?.created_at).format("DD MMM YYYY, h:mm a")}</p>
        <p>{el?.response}</p>
        {el?.attachment && (
         <div className="flex flex-wrap gap-4 my-5">
          <a
           href={el?.attachment}
           download
           className="px-4 items-center py-2 rounded-[50px] border flex gap-3 font-semibold"
          >
           <EmptyImgIconFilled color="#8937CE" />
           <span>{truncateString(getFileNameFromUrl(el?.attachment), 60)}</span>
          </a>
         </div>
        )}
       </div>
      </div>
     ))}
     {data?.data?.status.toLowerCase() !== "resolved" && <TicketForm ticket_tag={id} />}
    </div>
   </div>
  </>
 );
}

export default EachTicket;
