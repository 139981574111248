function LogoIcon({typeWhite = false, ...props}) {
  return (
    <img //
      src={`/images/logo-icon-filled.png`}
      width={56}
      height={56}
      alt="Logo"
      {...props}
    />
  );
}

export default LogoIcon;
