import { Chip, Grid, IconButton } from "@mui/material";
import StatCard from "components/StatCard";
import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import UserTick from "components/Vectors/UserTick";
import { Link } from "react-router-dom";
import { STATUS } from "utils/constants";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useGetAllClientsQuery, useGetClientStatsQuery } from "services";
import { useState, useEffect } from "react";

import moment from "moment";

const Clients = () => {
  const JOBS_PER_PAGE = 10;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(JOBS_PER_PAGE);
  const {
    data: allClients,
    isLoading,
    error,
  } = useGetAllClientsQuery({
    params: { is_paginated: "yes", page, per_page: perPage },
  });
  const {
    data: allClientsData,
    isLoading: isLoadingAllClients
  } = useGetAllClientsQuery({
    params: { is_paginated: "no" }
  });

  console.log(allClientsData);

  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (allClients?.data?.data) {
      const initialClients = allClients?.data?.data?.filter((client) => client);
      setClients(initialClients);
    }
  }, [allClients]);

  const { data: stats, isLoading: isLoadingStats } = useGetClientStatsQuery();
  const searchFunc = (value) => {
    const matchedClients = allClients?.data?.data?.filter((client) =>
      client?.first_name?.toLowerCase().includes(value.toLowerCase()),
    );

    setClients(matchedClients);
  };

  const options = {
    searchOpen: true,
    viewColumns: false,
    filter: true,
    serverSide: true,
    selectableRows: "none",
    count: allClients?.data?.total,
    rowsPerPage: perPage,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page + 1);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
  };

  const headCells = [
    {
      name: "id",
      label: <p className="truncate flex text-center first-letter:font-medium text-prop text-sm">ID</p>,
      options: {
        customBodyRender: (value) => <p className="w-full ml-[-5px] text-center">{value}</p>,
      },
    },
    {
      name: "user",
      label: "Client",
      options: {
        customBodyRender: (value) => (
          <Link to={`/clients/${value?.id}`}>
            <UserDisplay name={value?.name} email={value?.type} img={value?.img} />
          </Link>
        ),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
      },
    },
    {
      name: "all_campaigns",
      label: "All Campaigns",
      options: {
        customBodyRender: (value) => (
          <p className="text-grey">
            <span className="text-black mr-2">{value}</span>
            {value <= 1 ? "Campaign" : "Campaigns"}
          </p>
        ),
      },
    },
    // {
    //  name: "all_jobs",
    //  label: "All Jobs",
    //  options: {
    //   customBodyRender: (value) => (
    //    <p className="text-grey">
    //     <span className="text-black mr-2">{value}</span>
    //     {value <= 1 ? "Job" : "Jobs"}
    //    </p>
    //   ),
    //  },
    // },
    {
      name: "joined",
      label: "Joined",
      options: {
        customBodyRender: (value) => (
          <div>
            <p>{value?.name}</p>
            <p className="text-xs text-grey">{value?.ago}</p>
          </div>
        ),
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Chip
            color={`${value === "SOFT DELETE" ? "error" : STATUS[value?.toLowerCase()] || "default"}`}
            variant={value === "SOFT DELETE" ? "outlined" : "contained"}
            sx={{ minWidth: 100, borderRadius: "20px" }}
            label={value}
          />
        ),
      },
    },

    {
      name: "actions",
      label: "actions",
      options: {
        customBodyRender: (value) => (
          <IconButton to={"" + value} component={Link}>
            <RemoveRedEyeOutlinedIcon />
          </IconButton>
        ),
      },
    },
  ];

  const data = clients?.map((client, i) => ({
    id: (allClients?.data?.current_page - 1) * allClients?.data?.per_page + (i + 1),
    user: {
      id: client.id,
      name: client?.first_name + " " + client?.last_name,
      email: client.email,
      img: client.profile_image,
      email: client.email,
      number: client?.phone_number
    },
    location: client?.state?.name ? `${client?.state?.name} , ${client?.country?.name}` : "None",
    all_campaigns: client?.campaigns_count,
    // all_jobs: client?.client_jobs_count,
    joined: {
      name: moment(client.created_at).format("MMM DD, YYYY"),
      ago: moment(client.created_at).fromNow(),
    },
    status: client?.deleted_at ? "SOFT DELETE" : client.status,
    actions: client.id,
  }));

  const fullClientsData = allClientsData?.data?.filter((client) => client)?.map((client, i) => ({
    id: i + 1,
    user: {
      id: client.id,
      name: client?.first_name + " " + client?.last_name,
      email: client.email,
      img: client.profile_image,
      number: client?.phone_number
    },
    location: client?.state?.name ? `${client?.state?.name} , ${client?.country?.name}` : "None",
    all_campaigns: client?.campaigns_count,
    // all_jobs: client?.client_jobs_count,
    joined: {
      name: moment(client.created_at).format("MMM DD, YYYY"),
      ago: moment(client.created_at).fromNow(),
    },
    status: client?.deleted_at ? "SOFT DELETE" : client.status,
    actions: client.id,
  }));

  return (
    <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
      <div className="flex justify-between items-center mb-4">
        <h1 className="title">Clients</h1>
      </div>
      {/* Cards */}
      <Grid container spacing={2}>
        {/* <Grid item xs={12} lg={3} md={3}>
     <StatCard
      name="All Clients"
      isLoading={isLoadingStats}
      value={stats?.data?.clients?.total}
      iconBg="bg-[#FAF5FF]"
      icon={<UserTick width={32} height={32} fill="#8937CE" />}
      percentage={null}
     />
    </Grid> */}
        <Grid item xs={12} lg={4} md={4}>
          <StatCard
            name="Active Clients"
            isLoading={isLoadingStats}
            value={stats?.data?.clients?.active}
            iconBg="bg-success-100"
            icon={<UserTick width={32} height={32} fill="#00A04A" />}
            percentage={null}
          />
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <StatCard
            name="Inactive Clients"
            isLoading={isLoadingStats}
            value={stats?.data?.clients?.inactive}
            iconBg="bg-gray-100"
            icon={<UserTick width={32} height={32} fill="#8585A3" />}
            percentage={null}
          />
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <StatCard
            name="Suspended Clients"
            isLoading={isLoadingStats}
            value={stats?.data?.clients?.suspended}
            iconBg="bg-[#D61E341A]"
            icon={<UserTick width={32} height={32} fill="#D61E34" />}
            percentage={null}
          />
        </Grid>
      </Grid>

      {/* Table */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"></div>
      <div className="flex justify-between mt-8">
        <h5 className="text-2xl font-semibold">All Clients</h5>
      </div>

      <div className="p-4 bg-white py-6 border rounded-xl mt-5">
        <MyDataTable
          options={options}
          headCells={headCells}
          isLoading={isLoading}
          error={error}
          data={data}
          isLoadingAll={isLoadingAllClients}
          type="users"
          dataAll={fullClientsData}
          searchFunc={searchFunc}
          emptyData={{
            icon: UserTick,
            message: "No Clients",
            extraText: "There are no clients yet.",
          }}
          searchPlaceholder="Search clients..."
        />
      </div>
    </div>
  );
};

export default Clients;
