// import CalendarIcon2 from "components/Vectors/CalendarIcon2";
import TimeIcon from "components/Vectors/TimeIcon";
import { formatDate } from "utils";
// import moment from "moment";

const ChipColor = {
 terminated: "#D61E34",
 cancelled: "#D61E34",
 completed: "#00A04A",
 ongoing: "#53536F",
};

const StatusEngagementPanel = ({engagementData, paymentStatus, duration, status, isLoading }) => {


    function formatDate(inputDate) {
        const dateOnly = inputDate.split(' ')[0]; // Extract the date part
        const parsedDate = new Date(dateOnly);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = parsedDate.toLocaleDateString('en-US', options);
        return formattedDate;
      }

const getMainText = ()=>{
    if(engagementData?.status.toLowerCase() === 'ongoing'){

        if(engagementData?.start_date){
            if(engagementData?.days_left <= 0){
                return(
                    <p className="text-[#D61E34]">Engagement time expired</p>
                )
                
            }else{

                return `${engagementData?.days_left} days left`
            }
        }else if(engagementData?.payment_status === 'pending'){
            return "Pending payment"
        }else if(engagementData?.payment_status === 'paid') {
            return "pending start"
        }
    }else if(engagementData?.status.toLowerCase() === "terminated"){
        return "Terminated"
    }else if(engagementData?.status.toLowerCase() === "cancelled"){
        return "Cancelled"
    }else if(engagementData?.status.toLowerCase() === "completed"){
        return "Completed"
    }

}


const getSecondaryText = ()=>{
    if(engagementData?.status.toLowerCase() === 'ongoing' || engagementData?.status.toLowerCase() === "completed"){
         if(engagementData?.start_date){
            return (
                <span> {`${formatDate(engagementData?.start_date)} -`  }   <TimeIcon width={20} height={20} color="#8937CE" /> {formatDate(engagementData?.end_date)}</span>
            )
         }else{
            return "duration"
         }
    }else if(engagementData?.status.toLowerCase() === "terminated"){
        return `Terminated on  ${formatDate(engagementData?.terminated_at)}`
    }else if(engagementData?.status.toLowerCase() === "cancelled"){
        return `Cancelled on  ${formatDate(engagementData?.cancelled_at)}`
    }
}



 let textToRender;
 if (status === "Terminated") {
  textToRender = "Terminated";
 } else {
  textToRender = paymentStatus === "pending" ? "Pending payment" : "Pending start";
 }

 return (
  <div className="flex flex-col rounded-[24px] bg-grey100 px-4 py-[25px] md:px-[35px] mb-8">
   <div className={`flex flex-col w-full flex-wrap justify-between gap-3 items-start `}>
    {isLoading ? (
     <p>loading...</p>
    ) : (
     <>
      <p className={`text-prop capitalize text-[18px] font-semibold text-[${ChipColor[status?.toLowerCase()]}]`}>
       {getMainText()}{" "}
      </p>

      <div className="flex items-center gap-2.5  ">
       <span className=" mt-[-4px]">
        {/* <CalendarIcon2 /> */}
        <TimeIcon width={20} height={20} color="#8937CE" />
       </span>
       <div className="flex items-center gap-2">
        {/* {status === "Terminated" && <p className="text-prop capitalize text-grey50 font-medium">{status} on</p>}{" "} */}
        <p className="text-prop font-medium text-grey30">
         {/* {status === "Terminated" ? formatDate(duration) : duration} */}

        {getSecondaryText()}
        </p>
       </div>
      </div>
     </>
    )}
   </div>
  </div>
 );
};
export default StatusEngagementPanel;
