import { Button, Chip } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { useState } from "react";
import ApproveModal from "../pages/Dashboard/Influencers/Modal/ApproveModal";
import DeclineModal from "../pages/Dashboard/Influencers/Modal/DeclineModal";
import BVNApprove from "../pages/Dashboard/Influencers/Modal/BVNApprove";
import UploadedId from "../pages/Dashboard/Influencers/Widgets/UploadedId";
import EmptyResponse from "components/EmptyResponse";
import { ReactComponent as IDIcon } from "assets/icons/user-square.svg";

const UserKyc = ({ user }) => {
 const [showBVNModal, setShowBVNModal] = useState(false);
 const [showApprove, setShowApprove] = useState(false);
 const [showDecline, setShowDecline] = useState(false);

 return (
  <div className="flex-1 flex flex-col">
   <div>
    <h2 className="text-2xl font-semibold">KYC</h2>
    <p className="text-prop capitalize font-medium text-[14px] text-grey">{user?.kyc?.id_type.split("-").join(" ")}</p>
   </div>
   {/* id images  */}
   {!user?.kyc ? (
    <EmptyResponse icon={<IDIcon />} message={"No Valid ID Uploaded"} />
   ) : (
    <>
     <UploadedId
      frontImg={user?.kyc?.front_image}
      // backImg={user?.kyc?.back_image}
     />
    </>
   )}

   {/* verified  */}
   {user?.kyc?.status === "Approved" && (
    <Chip
     variant="outlined"
     size="small"
     className="border !border-[#DCDCE5] w-fit mt-2 !text-[#8937CE]"
     sx={{ borderRadius: "6px" }}
     label="VERIFIED"
     color="default"
    />
   )}

   {/* reupload  */}
   {user?.kyc?.status === "Declined" && (
    <Chip
     variant="outlined"
     size="small"
     className="border !border-[#DCDCE5] w-fit mt-2"
     sx={{ borderRadius: "6px" }}
     label="REJECTED"
     color="error"
    />
   )}

   {user?.kyc && user?.kyc?.status === "Pending" && (
    <div className={`flex mt-[15px] gap-2 flex-wrap`}>
     <div className="h-[48px]">
      <Button variant="contained" color="successInverse" onClick={() => setShowApprove(true)}>
       Approve
      </Button>
     </div>
     <div className="h-[48px]">
      <Button variant="contained" color="errorInverse" onClick={() => setShowDecline(true)}>
       Decline
      </Button>
     </div>
    </div>
   )}

   {/* address  */}
   {/* <div className="mt-[24px] ">
        <p className="text-grey70 font-medium text-4 text-prop ">
          Residential Address
        </p>
        <p className="text-prop text-grey font-medium mt-1 ">
          {user?.kyc?.residential_address || "N/A"}{" "}
        </p>
      </div> */}

   {/* BVN  */}
   {/* <div className="mt-[24px] ">
        <p className="text-grey70 font-medium text-4 text-prop ">BVN</p>

        <div className="flex items-center ">
          <input
            type={showApproveBvn ? "text" : "password"}
            readOnly
            value="1234567898987"
            className="w-[130px] text-prop text-grey font-medium "
          />
          <div className="select-none">
            {showApproveBvn ? (
              <button
                type="button"
                className="select-none"
                onClick={() => setShowApproveBvn(false)}
              >
                <VisibilityOffOutlinedIcon sx={{color: "#8937CE"}} />
              </button>
            ) : (
              <button
                type="button"
                className="select-none"
                onClick={() => setShowBVNModal(true)}
              >
                <RemoveRedEyeOutlinedIcon sx={{color: "#8937CE"}} />
              </button>
            )}
          </div>
        </div>
      </div> */}

   <ApproveModal
    openModal={showApprove}
    setOpenModal={setShowApprove}
    userId={user?.id}
    button2Title="Cancel"
    buttonTitle="Approve"
    btn2={true}
    btn2Color="primaryInverse"
    btnColor="primary"
    desc={`Are you sure you want to approve ${user?.kyc?.id_type.split("-").join(" ")} for ${
     user?.first_name + " " + user?.last_name
    }?`}
    iconBg="bg-[#FAF5FF]"
    icon={
     <CheckCircleOutlineRoundedIcon
      sx={{
       width: "60px",
       height: "60px",
      }}
      color="primary"
     />
    }
   />

   <DeclineModal
    title={`Decline ${user?.kyc?.id_type.split("-").join(" ")}`}
    buttonTitle="Decline"
    btnColor="error"
    openModal={showDecline}
    setOpenModal={setShowDecline}
    userId={user?.id}
   />

   <BVNApprove
    desc="Enter password to view user’s BVN"
    openModal={showBVNModal}
    setOpenModal={setShowBVNModal}
    button2Title="Cancel"
    buttonTitle="View BVN"
    btn2={true}
    // setShowBVN={setShowApproveBvn}
    btn2Color="primaryInverse"
    btnColor="primary"
   />
  </div>
 );
};
export default UserKyc;
