import { Avatar, Button, Chip } from "@mui/material";
import UserDisplay from "components/TableComponent/UserDisplay";
// import {useState} from "react";
// import AcceptRequestModal from "../Modals/AcceptRequestModal";
// import DeclineRequestModal from "../Modals/DeclineRequestModal";
import moment from "moment";
import StarFilledIcon from "components/Vectors/StarFilledIcon";
import { getUserDetails, handleError, chipStatusColor, toCurrency } from "utils";
import { Link } from "react-router-dom";
// import {useGetUser} from "hooks/getUserHook";
// import ValidatedInput from "components/forms/ValidatedInput";
// import {FormProvider, useForm} from "react-hook-form";
// import {LoadingButton} from "@mui/lab";
// import SendIconOutlined from "components/Vectors/SendIconOutlined";
// import {useNavigate} from "react-router-dom";
// import {useGetMsgInOfferQuery, useSendMsgInOfferMutation} from "services";
// import {showToast} from "store/store.hooks";
// import ChatList from "./ChatList";
// import OfferPaymentModal from "../Modals/OfferPaymentModal";

const EachOfferCard = ({ offer }) => {
//  console.log('This is the offer',offer?.status);
//  console.log(chipStatusColor[offer?.status?.toLowerCase()])

 // const {user} = useGetUser();
 // const methods = useForm();
 // const navigate = useNavigate();
 // const [openAcceptModal, setOpenAcceptModal] = useState(false);
 // const [openDeclineModal, setOpenDeclineModal] = useState(false);
 // const [openPayModal, setOpenPayModal] = useState(false);
 // const [reply, {isLoading}] = useSendMsgInOfferMutation();
 // const {data, isLoading: isLoadingMsgs} = useGetMsgInOfferQuery(offer.id);
 // const conversations = data?.conversations;

 // const handleReply = (data) => {
 //   const body = {
 //     message: data.message,
 //     module: "Offer",
 //     module_id: offer.id,
 //     receiver_id: offer.user_id,
 //   };
 //   reply(body)
 //     .unwrap()
 //     .then((res) => {
 //       showToast(res?.message);
 //       methods.reset();
 //     })
 //     .catch((err) => handleError(err));
 // };
 const isPaymentPending =
  offer?.engagement?.payment_status === "pending" && offer?.engagement?.status?.toLowerCase() === "ongoing";

 const hasEngagementStarted =
  !isPaymentPending && !offer?.engagement?.start_date && offer?.engagement?.status?.toLowerCase() === "ongoing";

 const engagementStatus = isPaymentPending
  ? "Pending payment"
  : hasEngagementStarted
  ? "Pending Start"
  : offer?.engagement?.status;
 // const handleMakePayment = () => {
 //   setOpenPayModal(true);
 // };
 // const handleStartEngagement = () => {
 //   console.log("start engagement");
 // };
 // const handleGoToEngagement = () => navigate("engagement");
 return (
  <div className=" border mt-4 rounded-xl bg-[#F9F9FB] p-4 lg:p-6">
   <div className="flex items-start justify-between">
    <UserDisplay
     name={getUserDetails(offer?.user).name}
     img={getUserDetails(offer?.user).img}
     email={`@${offer?.user?.username}`}
     badge={offer?.user?.kyc_verified}
    />

    <span className="text-xs flex flex-col capitalize items-end md:text-sm">
     <span className="">
      <StarFilledIcon color="#FFB33E" />
      <span className="font-semibold inline-block ml-2">{offer?.creator?.average_rating || "0.0"}</span>
     </span>
     <span className="uppercase font-semibold text-xs">
      <span className="text-grey40">time offered: </span>
      {moment(offer?.created_at).fromNow()}
     </span>
    </span>
   </div>

   <div className="flex justify-between mt-4">
    <div className="flex gap-2">
     <Chip
      sx={{
       borderRadius: "8px",
       textTransform: "uppercase",
       fontSize: "10px",
       height: "26px",
       fontWeight: 600,
      }}
      label={"offer " + offer?.status}
      color={chipStatusColor[offer?.status?.toLowerCase()]}
     />
     {/* {offer?.engagement && (
      <Chip
       sx={{
        borderRadius: "8px",
        fontSize: "10px",
        height: "26px",
        textTransform: "uppercase",
        fontWeight: 600,
       }}
       label={engagementStatus}
       color={chipStatusColor[engagementStatus?.toLowerCase()]}
      />
     )} */}
    </div>

    <p className="text-sm font-bold text-grey_20">{toCurrency(offer?.amount_offered)}</p>
   </div>
   {offer?.status === "Accepted" && (
    <Link to={`/engagements/${offer?.engagement.id}`}>
     <Button variant="contained" sx={{ padding: "0px", mt: 2 }}>
      View Engagement
     </Button>
    </Link>
   )}
   <div className="my-4 bg-white p-4 pt-3 rounded-xl border">
    <p className="mb-1 font-bold text-grey10 text-sm">Offer Message</p>
    <p className="text-xs font-medium text-grey30 md:text-[13px]">{offer?.pitch_message}</p>
   </div>

   {/* {offer?.status === "Declined" || offer?.status === "Withdrawn" ? null : (
        <div className="flex justify-end items-center gap-2">
          {offer?.status !== "Accepted" && (
            <Button
              variant="contained"
              color="errorInverse"
              onClick={() => setOpenDeclineModal(true)}
            >
              Decline
            </Button>
          )}
          {offer?.status === "Accepted" ? (
            <Button
              variant="contained"
              color="primary"
              onClick={
                isPaymentPending
                  ? handleMakePayment
                  : hasEngagementStarted
                  ? handleGoToEngagement
                  : handleStartEngagement
              }
            >
              {isPaymentPending
                ? "Make Payment"
                : hasEngagementStarted
                ? "Start Engagement"
                : "View Engagement"}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAcceptModal(true)}
            >
              Accept
            </Button>
          )}
        </div>
      )} */}
   {/* <AcceptRequestModal
        openModal={openAcceptModal}
        setOpenModal={setOpenAcceptModal}
        offer={offer}
      />
      <DeclineRequestModal
        openModal={openDeclineModal}
        setOpenModal={setOpenDeclineModal}
        offer={offer}
      /> */}
   {/* <OfferPaymentModal
        engagementId={offer?.engagement?.id}
        openPayModal={openPayModal}
        setOpenPayModal={setOpenPayModal}
        engagement={offer?.engagement}
        skipStep1
      /> */}
  </div>
 );
};

export default EachOfferCard;
