import { LoadingButton } from "@mui/lab";
import { Checkbox, CircularProgress, FormControlLabel } from "@mui/material";
import UploadImage from "components/forms/UploadImage";
import ValidatedInput from "components/forms/ValidatedInput";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAddCategoryMutation, useUpdateCategoryMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const CreateCategoryModal = ({ openModal, setopenModal, isEditing, category }) => {
 const methods = useForm();
 const [addCategory, { isLoading }] = useAddCategoryMutation();
 const [updateCategory, { isLoading: isUpdatingCategory }] = useUpdateCategoryMutation();
 useEffect(() => {
  if (isEditing) {
   methods.setValue("name", category?.name);
   methods.setValue("description", category?.description);
   methods.setValue("cover_photo", category?.cover_photo);
   methods.setValue("app_icon", category?.app_icon);
   methods.setValue("is_featured", category?.featured === "Yes");
  }
 }, [category, isEditing, methods]);

 const onSubmit = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  if (typeof data.cover_photo === "object") formData.append("cover_photo", data?.cover_photo[0]);
  if (typeof data.app_icon === "object") formData.append("app_icon", data?.app_icon[0]);
  formData.append("description", data.description);
  formData.append("is_featured", data.is_featured ? "Yes" : "No");
  try {
   const res = isEditing
    ? await updateCategory({ id: category?.id, body: formData }).unwrap()
    : await addCategory(formData).unwrap();
   showToast(res?.message);
   methods.reset();
   setopenModal(false);
  } catch (err) {
   handleError(err);
  }
 };
 return (
  <Modal
   openModal={openModal}
   closeModal={() => {
    methods.reset();
    setopenModal(false);
   }}
   title={isEditing ? "Edit Category" : "Create Category"}
  >
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col justify-between flex-grow">
     <div>
      <ValidatedInput name="name" label="Category name" placeholder="Enter Name" />
      <UploadImage name="cover_photo" label="Cover Photo" required={isEditing ? false : true} />
      <ValidatedTextArea name="description" label="Description" placeholder="Some description about the category" />
      <UploadImage name="app_icon" label="Mobile app icon" required={isEditing ? false : true} />
      <div className="mt-6">
       <FormControlLabel
        control={
         <Checkbox
          defaultChecked={isEditing && category?.featured === "Yes"}
          name="is_featured"
          onChange={(e) => methods.setValue("is_featured", e.target.checked)}
         />
        }
        label="Featured"
       />
      </div>
     </div>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading || isUpdatingCategory}
      type="submit"
      variant="contained"
      sx={{ mt: 2 }}
      fullWidth
     >
      {isEditing ? "Update" : "Create"}
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
};

export default CreateCategoryModal;
