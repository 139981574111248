import Bag2Icon from 'components/Bag2Icon';
import EmptyResponse from 'components/EmptyResponse';
import ImageSlideShow from 'components/ImageSlideshow';
import PaintPaletteIcon from 'components/Vectors/PaintPaletteIcon';
import Video from 'components/Video';

function CreatorService({isLoading, about }) {


    return (
        <div className=" w-full border p-6 rounded-lg mb-6 ">
          {about ? <> <div className="pb-4 font-semibold mb-6 border-b text-lg text-grey20  ">{about?.category?.name}</div>
            <p className="text-sm font-medium mb-2">About</p>
            <p className="text-sm font-medium text-grey50">
                {about?.about_your_experience}
            </p>

            <div className="flex items-center mt-4 justify-between gap-2 pb-6 mb-6 border-b">
                <p className="flex gap-2 items-center">
                    <Bag2Icon fill="#292D32" /> <span>Years of Experience</span>
                </p>
                <p className="text-grey50">{about?.years_of_experience} Years</p>
            </div>
            <p className="text-grey50 font-medium">Skills</p>
            <div className="grid grid-cols-2 text-black mt-2 gap-2 pb-6 mb-6 border-b">

                {
                    about?.skills.map((skill) => (
                        <div key={skill?.id} className="bg-grey100 rounded-sm p-4">{skill?.name}</div>

                    ))
                }
            </div>

        

            <p className="text-grey50 font-medium mb-2">Gallery</p>
            {
                about?.professional_information_images.length > 0 && <ImageSlideShow images={about?.professional_information_images} />

            }
            {
                about?.professional_information_videos.length > 0 &&
                <Video videoUrl={about?.professional_information_videos[0]?.video_url} />
            }</>:                 <EmptyResponse icon={<PaintPaletteIcon />} message={isLoading? 'loading...': 'No Services Available'} />
        }
        </div>
    );
}

export default CreatorService;

{/* <div className="w-full flex flex-col justify-center items-center py-32">
                <PaintPaletteIcon/>
                <p>No Services Available</p>
                </div> */}
