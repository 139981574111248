import {LoadingButton} from "@mui/lab";
import ValidatedInput from "components/forms/ValidatedInput";
import MailIcon from "components/Vectors/MailIcon";
import {FormProvider, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useForgotPwdMutation} from "services/auth.api";
import {setUserEmail} from "store/slice/AuthSlice";
import BasicAuthLayout from "templates/AuthLayout/BasicAuthLayout";
import {handleError} from "utils";
import {useState} from "react";
import MessageModal from "components/Modal/MessageModal";
import {ReactComponent as MailSvg} from "assets/icons/mail-bg.svg";
import {CircularProgress} from "@mui/material";
import {showToast} from "store/store.hooks";
import {useNavigate} from "react-router-dom";

function ResetPassword() {
  const [openModal, setOpenModal] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  const methods = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendMail, {isLoading}] = useForgotPwdMutation();

  const onSubmit = async (body) => {
    try {
      const res = await sendMail({
        ...body,
        redirect_url: `${window.location.origin}/set-password`,
      }).unwrap();
      showToast(res?.message);
      setEmailValue(body.email);
      setOpenModal(true);
      dispatch(setUserEmail(body.email));
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <BasicAuthLayout showBackBtn>
      <div className="max-w-[430px] text-center">
        <h1 className="title text-[#2C2C3A]">Reset Password</h1>
        <p>
          You don’t have you to panic, enter the email associated to your
          account, a reset link will be sent to you.
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="pt-8">
            <ValidatedInput
              name="email"
              label="Email Address"
              type="email"
              placeholder="Enter Email Address"
              suffixIcon={<MailIcon fontSize="12px" color="#8585A3" />}
            />
            <LoadingButton
              loadingIndicator={
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size="1.2rem"
                />
              }
              loading={isLoading}
              type="submit"
              fullWidth
              sx={{mt: 2}}
              variant="contained"
            >
              Send Reset Link
            </LoadingButton>
          </form>
        </FormProvider>
      </div>
      <MessageModal
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
          navigate("/login");
        }}
        title={<span className="text-[#424257]">Check Email</span>}
        description={
          <p className="text-center font-medium text-[14px] leading-[150%] tracking-[-0.02em] text-grey ">
            A reset password link has been sent to
            <span className="block text-[#181820]">{emailValue}</span>
          </p>
        }
        icon={<MailSvg width={64} height={64} color="primary" />}
      />
    </BasicAuthLayout>
  );
}

export default ResetPassword;
