const TxnIcon = (props) => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22.75C11.22 22.75 10.46 22.35 9.94002 21.65L8.93001 20.3C8.72001 20.02 8.44001 19.86 8.14001 19.84C7.84001 19.83 7.54002 19.96 7.30002 20.21L6.75002 19.7L7.30002 20.21C5.86002 21.75 4.75002 21.63 4.22002 21.42C3.68002 21.21 2.77002 20.52 2.77002 18.3V7.04C2.77002 2.6 4.05002 1.25 8.24002 1.25H15.8C19.99 1.25 21.27 2.6 21.27 7.04V18.3C21.27 20.51 20.36 21.2 19.82 21.42C19.29 21.63 18.19 21.75 16.74 20.21C16.5 19.95 16.2 19.82 15.89 19.84C15.59 19.86 15.3 20.02 15.09 20.3L14.08 21.65C13.54 22.35 12.78 22.75 12 22.75ZM8.08002 18.33C8.12002 18.33 8.17002 18.33 8.21002 18.33C8.95002 18.37 9.65001 18.76 10.12 19.39L11.13 20.74C11.62 21.39 12.37 21.39 12.86 20.74L13.87 19.39C14.35 18.76 15.04 18.37 15.79 18.33C16.54 18.29 17.27 18.6 17.81 19.18C18.57 19.99 19.06 20.09 19.24 20.02C19.48 19.92 19.74 19.34 19.74 18.3V7.04C19.74 3.43 19.11 2.75 15.77 2.75H8.21002C4.87002 2.75 4.24002 3.43 4.24002 7.04V18.3C4.24002 19.35 4.50002 19.93 4.74002 20.02C4.91002 20.09 5.41001 19.99 6.17001 19.18C6.72001 18.63 7.39002 18.33 8.08002 18.33Z" fill={props.color || "#00A04A"} />
                <path d="M14.75 10.75H9.25C8.84 10.75 8.5 10.41 8.5 10C8.5 9.59 8.84 9.25 9.25 9.25H14.75C15.16 9.25 15.5 9.59 15.5 10C15.5 10.41 15.16 10.75 14.75 10.75Z" fill={props.color || "#00A04A"} />
            </svg>
        </>
    );
}

export default TxnIcon;