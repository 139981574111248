import { Chip } from "@mui/material";
import { useState } from "react";
import { useGetCampaignTransactionsQuery } from "services/transactions.api";
import { getUserDetails, toCurrency } from "utils";
import { STATUS2 } from "utils/constants";

import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import WalletIcon from "components/Vectors/WalletIcon";
import moment from "moment";
import SharedModal from "components/Modal/SharedModal";
import CampaignTransactionDetailsModal from "../Widgets/CampaignTransactionDetailsModal";

const CampaignTransactionsTab = () => {
 const [openTxnDetModal, setOpenTxnDetModal] = useState(false);
 const JOBS_PER_PAGE = 16;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);

 const { data, isLoading } = useGetCampaignTransactionsQuery({
  params: { is_paginated: "yes", page, per_page: perPage },
 });
 // if(data) console.log(data)

 //  console.log(data);
 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  serverSide: true,
  selectableRows: "none",
  count: data?.total,
  rowsPerPage: perPage,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 return (
  <div className="p-4 bg-white py-6 border rounded-xl mt-5">
   {isLoading || data?.data?.length > 0 ? (
    <MyDataTable
     searchPlaceholder="Select Service, influencer, buyer,..."
     options={options}
     isLoading={isLoading}
     headCells={[
      {
       name: "id",
       label: "ID",
      },
      {
       name: "campaign",
       label: "Campaign",
       options: {
        customBodyRender: (value) => (
         <div className="flex items-center gap-2">
          <img src={value?.icon} alt="" className="h-[2rem] w-[2rem]" />
          <div>
           <h4 className="text-[#8585A3] text-xs font-medium">{value?.source}</h4>
           <p className="text-[#181820] text-sm font-semibold">{value?.channel}</p>
          </div>
         </div>
        ),
       },
      },
      {
       name: "client",
       label: "client",
       options: {
        customBodyRender: (value) => <UserDisplay name={value?.name} email={value?.searchBy} img={value?.img} />,
       },
      },
      {
       name: "amt_paid",
       label: "Amount Paid ",
       options: {
        customBodyRender: (value) => (
         <div>
          <h4 className="text-[#2C2C3A] text-sm font-medium">{value?.amt_paid}</h4>
          <p className="text-[#8585A3] text-xs font-medium">{value?.unit} unit price</p>
         </div>
        ),
       },
      },
      {
       name: "influencers_paid",
       label: "Influencers paid",
       options: {
        customBodyRender: (value) => (
         <h4 className="text-[#2C2C3A] text-sm font-medium">
          {value?.influencers_paid} of {value?.influencers_count}
         </h4>
        ),
       },
      },
      { name: "charges", label: "Hookik Charges" },
      { name: "date", label: "Date Initiated" },
      {
       name: "status",
       label: "Status",
       options: {
        filter: true,
        customBodyRender: (value) => (
         <Chip
          color={`${STATUS2[value.toLowerCase()] || "greyBorder"}`}
          // sx={{minWidth: 90}}
          label={`${value}`}
         />
        ),
       },
      },
      {
       name: "actions",
       label: null,
       options: {
        customBodyRender: (children) => <>{children}</>,
       },
      },
     ]}
     data={data?.data?.map((el, i) => ({
      id: el?.id,
      campaign: {
       icon: el?.campaign?.campaign_channel?.icon,
       source: el?.campaign?.campaign_source?.name,
       channel: el?.campaign?.campaign_channel?.name,
      },
      client: {
       name: getUserDetails(el?.campaign?.client).name,
       searchBy: `@${el?.campaign?.client?.username}`,
       img: !el?.campaign?.client?.profile_image ? "/default_avatar.png" : el?.campaign?.client?.profile_image,
      },
      amt_paid: {
       amt_paid: toCurrency(el?.amount_paid),
       unit: toCurrency(el?.campaign?.amount_per_influencer),
      },
      influencers_paid: {
       influencers_paid: el?.paid_influencers_count,
       influencers_count: el?.campaign?.number_of_influencers_needed,
      },
      charges: toCurrency(el?.campaign?.service_charge),
      date: moment(el?.campaign?.created_at).format("DD MM, YYYY"),
      status: el?.status,
      actions: (
       <>
        <button onClick={() => setOpenTxnDetModal(el)}>
         <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="34" height="34" rx="8" fill="#EAEAF0" />
          <path
           d="M16.9999 20.2474C15.2074 20.2474 13.7524 18.7924 13.7524 16.9999C13.7524 15.2074 15.2074 13.7524 16.9999 13.7524C18.7924 13.7524 20.2474 15.2074 20.2474 16.9999C20.2474 18.7924 18.7924 20.2474 16.9999 20.2474ZM16.9999 14.8774C15.8299 14.8774 14.8774 15.8299 14.8774 16.9999C14.8774 18.1699 15.8299 19.1224 16.9999 19.1224C18.1699 19.1224 19.1224 18.1699 19.1224 16.9999C19.1224 15.8299 18.1699 14.8774 16.9999 14.8774Z"
           fill="#292D32"
          />
          <path
           d="M17.0001 23.7649C14.1801 23.7649 11.5176 22.1149 9.68756 19.2499C8.89256 18.0124 8.89256 15.9949 9.68756 14.7499C11.5251 11.8849 14.1876 10.2349 17.0001 10.2349C19.8126 10.2349 22.4751 11.8849 24.3051 14.7499C25.1001 15.9874 25.1001 18.0049 24.3051 19.2499C22.4751 22.1149 19.8126 23.7649 17.0001 23.7649ZM17.0001 11.3599C14.5776 11.3599 12.2601 12.8149 10.6401 15.3574C10.0776 16.2349 10.0776 17.7649 10.6401 18.6424C12.2601 21.1849 14.5776 22.6399 17.0001 22.6399C19.4226 22.6399 21.7401 21.1849 23.3601 18.6424C23.9226 17.7649 23.9226 16.2349 23.3601 15.3574C21.7401 12.8149 19.4226 11.3599 17.0001 11.3599Z"
           fill="#292D32"
          />
         </svg>
        </button>
       </>
      ),
     }))}
    />
   ) : (
    <div className="flex items-center justify-center flex-col gap-4 h-[500px]">
     <WalletIcon height={80} width={80} color="#A2A2B9" />
     <h4 className="text-[#53536F] text-2xl font-semibold">No Transactions yet</h4>
    </div>
   )}

   <SharedModal direction="up" openModal={openTxnDetModal} closeModal={() => setOpenTxnDetModal(false)}>
    <div className="m-4 max-w-[51.375rem] w-full">
     <CampaignTransactionDetailsModal data={openTxnDetModal} setopenModal={setOpenTxnDetModal} />
    </div>
   </SharedModal>
  </div>
 );
};

export default CampaignTransactionsTab;
