import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const payoutsApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "payoutsApi",
 tagTypes: ["payouts"],
 endpoints: (builder) => ({
  getAllPayouts: builder.query({
   query: () => ({
    url: `/transaction/creator/payout/list`,
   }),
  }),
  //   getAllpayoutsByStatus: builder.query({
  //    query: ({ status }) => ({
  //     url: `campaign/status/${status}`,
  //    }),
  //   }),
  getAllPayoutsStatistics: builder.query({
   query: () => ({
    url: `transaction/creator/payout/stats`,
   }),
  }),
  //   getSingleEngagement: builder.query({
  //    query: ({ id }) => ({
  //     url: `engagement/show/${id}`,
  //    }),
  //    providesTags: ["engagements"],
  //   }),
  //   terminateEngagement: builder.mutation({
  //    query: ({ body }) => ({
  //     url: `engagement/terminate`,
  //     method: "POST",
  //     body,
  //    }),
  //    invalidatesTags: ["engagements"],
  //   }),
 }),
});

export const { useGetAllPayoutsQuery, useGetAllPayoutsStatisticsQuery } = payoutsApi;
