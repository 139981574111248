import React, { useState } from "react";
import { Button, Chip, IconButton, Avatar, CircularProgress } from "@mui/material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Modal from "components/Modal";
import UserDisplay from "components/TableComponent/UserDisplay";
import { AttachFileOutlined } from "@mui/icons-material";
import FileDisplayUi from "components/FileDisplayUI";
import {
  useGetUserDetailsQuery,
  useMarkAsCompletedMutation,
  useCompleteRefundsMutation,
  useDeclineRefundsMutation,
} from "services/transactions.api";
import {
  extractLinkName,
  formatDateTime,
  getChipColor,
  getFileSize,
  getUserDetails,
  handleError,
  toCurrency,
  truncateString,
} from "utils";
import { showToast } from "store/store.hooks";
import { LoadingButton } from "@mui/lab";

const StatusColor = {
  ongoing: "primaryInverse",
  completed: "successInverse",
  exited: "errorInverse",
  escrow: "yellowInverse",
  pending: "greyBorder",
};

function TransactionDetailsModal({ openModal, setopenModal, data, status }) {
  const [attachment, setAttachment] = useState(null);
  const { data: userDetails } = useGetUserDetailsQuery({
    user: status === "payout" ? data?.user?.user_type : data?.client?.user_type,
    id: status === "payout" ? data?.user?.id : data?.client?.id,
  });
  const [markAsCompleted, { isLoading: isMarkLoading }] = useMarkAsCompletedMutation();
  const [completeRefunds, { isLoading: isCompleteLoading }] = useCompleteRefundsMutation();
  const [declineRefunds, { isLoading: isDeclineLoading }] = useDeclineRefundsMutation();

  // console.log("this is attachment",attachment)
  // console.log(data, status, userDetails);

  const handleMarkAsCompleted = async () => {
    const userId = parseInt(data?.user_id);
    const markFormData = new FormData();
    markFormData.append("payout_id", data?.id);
    markFormData.append("user_id", userId);
    // console.log(attachment, 'here')
    if (attachment) markFormData.append("receipt", attachment);
    try {
      const res = await markAsCompleted({
        body: markFormData,
      }).unwrap();
      showToast(res?.message);
      setAttachment(null);
      setopenModal(false);
    } catch (err) {
      handleError(err);
    }
  };

  const handleCompleteRefunds = async () => {
    const completeRefundsData = new FormData();
    completeRefundsData.append("refund_history_id", data?.id);
    completeRefundsData.append("client_id", data?.client_id);
    if (attachment) completeRefundsData.append("receipt", attachment);
    try {
      const res = await completeRefunds(completeRefundsData).unwrap();
      showToast(res?.message);
      setAttachment(null);
      setopenModal(false);
    } catch (err) {
      handleError(err);
    }
  };
  const handleDeclineRefunds = async () => {
    const declineRefundsData = new FormData();
    declineRefundsData.append("refund_history_id", data?.id);
    declineRefundsData.append("client_id", data?.client_id);
    try {
      const res = await declineRefunds(declineRefundsData).unwrap();
      showToast(res?.message);
      setAttachment(null);
      setopenModal(false);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Modal maxWidth={500} title={"Transaction Details"} openModal={openModal} closeModal={() => setopenModal(false)}>
      <div className="font-medium space-y-6">
        <div className="text-sm mt-5 md:text-base items-center flex flex-col gap-y-3 text-grey50">
          <p>Amount</p>
          <p className="text-4xl text-grey10">
            {status === "payout" ? toCurrency(data?.amount - data?.commission) : toCurrency(data?.engagement?.amount)}
          </p>
          {/* <p className="text-black">Date requested: <span className="text-grey10">{formatDateTime(data?.request_date_time)}</span></p> */}

          {status === "payout" && (
            <>
              <p className="text-black">{`Date requested: ${formatDateTime(data?.request_date_time)}`}</p>

              <p className="text-black">{`Date completed: ${data?.paid_date_time ? formatDateTime(data?.paid_date_time) : "N/A"
                }`}</p>
            </>
          )}
          {status === "refund" && (
            <p className="text-black">{`Date completed: ${data?.date_paid ? formatDateTime(data?.date_paid) : "N/A"}`}</p>
          )}
        </div>
        {status !== "payout" && (
          <div className="p-2 rounded-lg bg-grey100">
            <UserDisplay
              imgRounded={false}
              img={data?.engagement?.job_without_relationship?.cover_image}
              name={data?.engagement?.job_without_relationship?.job_title}
            />
          </div>
        )}
        <div className="flex justify-between gap-4">
          {/* <div>
            <p className="text-sm text-grey50 mb-2">Initiator</p>
            <UserDisplay
              img={"/images/avatar.png"}
              email={"@druids"}
              name={"Jide Kosoko"}
            />
          </div> */}
          <div className="text-left">
            <p className="text-sm text-grey50 mb-2">Recipient</p>
            <UserDisplay
              // reverse
              img={data?.user?.profile_image || data?.client?.profile_image}
              email={data?.user?.username || data?.client?.username}
              name={getUserDetails(data?.user).name}
            // name={status === "payout" ? getUserDetails(data?.user).name : getUserDetails(data?.client).name}
            />
          </div>
        </div>
        <div className="flex justify-between gap-4">
          <div>
            <p className="text-sm text-grey50 mb-2">Transaction Ref</p>
            <p>{data?.transaction_ref ? data?.transaction_ref : "N/A"}</p>
          </div>
          {status === "payout" && (
            <div className="text-right">
              <p className="text-sm text-grey50 mb-2">Service charge</p>
              <p>{toCurrency(data?.commission)}</p>
            </div>
          )}
        </div>
        <div>
          <p className="text-sm text-grey50 mb-2">Account details</p>
          <p>{`${data?.bank_account?.account_number} • ${data?.bank_account?.bank_name} • ${data?.bank_account?.account_name} `}</p>
        </div>
        {status === "payout" && (
          <div>
            <p className="text-sm text-grey50 mb-2">Extra Comments</p>
            <p>{`${data?.description}`}</p>
          </div>
        )}
        <div className="flex justify-between ">
          <div className="">
            <p className="text-sm text-grey50 mb-2">Status</p>
            <Chip label={data?.status} color={getChipColor(data?.status)} />
          </div>
        </div>

        {!data?.client_id && (
          <LoadingButton
            disabled={data?.status === "completed"}
            loading={isMarkLoading}
            loadingIndicator={<CircularProgress size={"1.2rem"} sx={{ ml: "8px", color: "#ffffff" }} />}
            onClick={handleMarkAsCompleted}
            variant="contained"
            sx={{ display: "flex", ml: "auto" }}
          >
            Mark as completed
          </LoadingButton>
        )}
        {data?.client_id && (
          <div className="flex items-center space-x-3">
            <LoadingButton
              disabled={["refunded", "declined"].includes(data?.status)}
              loading={isCompleteLoading}
              loadingIndicator={<CircularProgress size={"1.2rem"} sx={{ ml: "8px", color: "#ffffff" }} />}
              onClick={handleCompleteRefunds}
              variant="contained"
              sx={{ display: "flex", ml: "auto" }}
            >
              Complete Refund
            </LoadingButton>
            <LoadingButton
              disabled={["refunded", "declined"].includes(data?.status)}
              loading={isDeclineLoading}
              loadingIndicator={<CircularProgress size={"1.2rem"} sx={{ ml: "8px", color: "#ffffff" }} />}
              onClick={handleDeclineRefunds}
              color="error"
              variant="contained"
              sx={{ display: "flex", ml: "auto" }}
            >
              Decline Refund
            </LoadingButton>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default TransactionDetailsModal;
