import { Star } from "@mui/icons-material";
import { Avatar, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getUserDetails } from "utils";
import SmallTickIcon from "components/Vectors/SmallTickIcon";
import { useGetSingleClientQuery, useGetOneCreatorQuery } from "services";

const UserProfileCard = ({ badge, user, btnTitle, type = "client" }) => {
 const userId = user?.id;
 const { data } = useGetSingleClientQuery(userId);
 const { data: creator } = useGetOneCreatorQuery(userId);
 const client = data?.data;
 const creators = creator?.data;
 return (
  <div className="border-[#EAEAF0] flex justify-between mb-[8px] border-[1px] rounded-[8px] px-[24px] py-[22px]">
   <div className="flex gap-4">
    <div className="relative h-[40px] w-[40px]">
     <Avatar
      src={user?.profile_image}
      alt="avatar"
      className="w-full"
      // sx={{
      //     borderRadius: imgRounded ? '50%' : '4px',
      //     width: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
      //     height: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
      // }}
     />
     {badge && (
      <div className="absolute -top-1 -right-1">
       <SmallTickIcon TooltipTitle="verified" color="#8937CE" />
      </div>
     )}
    </div>
    {/* <div className="h-[48px] w-[48px] rounded-full border border-1 border-red-500">
     <Avatar src={user?.profile_image}/>
    </div> */}
    <div className="flex gap-2 flex-wrap items-start">
     <div className="flex flex-col justify-between">
      <h4 className="font-semibold">{getUserDetails(user)?.name}</h4>
      <span className="text-prop font-medium text-[12px]">@{user?.username}</span>
     </div>
     <div className="flex gap-1 items-center font-semibold">
      <Star sx={{ color: "#FFB33E", fontSize: "16px" }} />
      <span>{client?.reviews_received_avg || creators?.reviews_received_avg || 0}</span>
     </div>
    </div>
   </div>
   <Button component={Link} to={`/${type || user?.role.slug}s/${user?.id}`} variant="contained" color="primaryInverse">
    View {btnTitle || user?.role.name}
   </Button>
  </div>
 );
};
export default UserProfileCard;
