import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const engagementsApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "engagementsApi",
 tagTypes: ["engagements"],
 endpoints: (builder) => ({
  getAllEngagements: builder.query({
   query: ({ params }) => ({
    url: `engagement/list`,
    params,
   }),
  }),
  getAllEngagementsByStatus: builder.query({
   query: ({ status }) => ({
    url: `engagement/status/${status}`,
   }),
  }),

  getAllEngagementsStatistics: builder.query({
   query: () => ({
    url: `engagement/statistics`,
   }),
  }),
  getSingleEngagement: builder.query({
   query: ({ id }) => ({
    url: `engagement/show/${id}`,
   }),
   providesTags: ["engagements"],
  }),
  getSingleEngagementRelatedData: builder.query({
   query: ({ id }) => `engagement/related-data/${id}`,
   providesTags: ["engagements"],
  }),
  getSingleUserEngagementReviews: builder.query({
   query: ({ id }) => `/user/ratings/engagement/${id}`,
   providesTags: ["engagement"],
  }),
  terminateEngagement: builder.mutation({
   query: ({ body }) => ({
    url: `engagement/terminate`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["engagements"],
  }),
 }),
});

export const {
 useGetAllEngagementsQuery,
 useGetSingleUserEngagementReviewsQuery,
 useGetAllEngagementsByStatusQuery,
 useGetAllEngagementsStatisticsQuery,
 useGetSingleEngagementQuery,
 useGetSingleEngagementRelatedDataQuery,
 useTerminateEngagementMutation,
} = engagementsApi;
