import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useState, useEffect } from "react";
import WalletIcon from "components/Vectors/WalletIcon";
import StatCard from "components/StatCard";
import PayoutDetailsModal from "../Widgets/PayoutDetailsModal";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import MyDataTable, {
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import { useGetCreatorPayoutStatsQuery, useGetCreatorPayoutsQuery, useGetInfluencerPayoutStatsQuery, useGetInfluencerPayoutsQuery, useGetPayoutsQuery, useGetPayoutsStatsQuery } from "services/transactions.api";
import { formatDate, getChipColor, getUserDetails, toCurrency } from "utils";



const UserPayoutTab = ({ type }) => {


  const JOBS_PER_PAGE = 16;
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(JOBS_PER_PAGE);

  const [transactionData, setTransactionData] = useState('');

  const [openModal, setopenModal] = useState(false);
  // const [currentStats ,setCurrentStats] = useState('');
  // const [currentData , setCurrentData] = useState('');
  // const [isDataLoading, setIsDataLoading] = useState(false)
  // const {data: creatorPayoutStats, isLoading: isCreatorPayoutStatsLoading} = useGetCreatorPayoutStatsQuery();
  // const {data: influencerPayoutStats, isLoading: isInfluencerPayoutStatsLoading} = useGetInfluencerPayoutStatsQuery();
  const { data: currentStats, isLoading: isCurrentStatsLoading } = useGetPayoutsStatsQuery(type);

  const { data: currentData, isLoading: isCurrentDataLoading } = useGetPayoutsQuery({
    params: { is_paginated: "yes", page, per_page: perPage },
    user: type
  })
  if (currentStats) console.log(currentStats)
  // const {data: creatorPayoutsData, isLoading: isCreatorPayoutsLoading} = useGetCreatorPayoutsQuery({
  //   params: { is_paginated: "yes", page, per_page: perPage},
  // });
  // const {data: influencerPayoutsData, isLoading: isInfluencerPayoutsLoading} = useGetInfluencerPayoutsQuery({
  //   params: { is_paginated: "yes", page, per_page: perPage},
  // });



  if (currentData) console.log(currentData);



  // useEffect(()=>{
  //   if(isCreatorPayoutsLoading || isInfluencerPayoutsLoading ){
  //     setIsDataLoading(true)
  //   }else{
  //     setIsDataLoading(false)
  //   }
  // },[isCreatorPayoutsLoading , isInfluencerPayoutsLoading , creatorPayoutsData ,  influencerPayoutsData])

  const options = {
    searchOpen: true,
    viewColumns: false,
    filter: true,
    selectableRows: "none",
    count: currentData?.data?.total,
    rowsPerPage: perPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page + 1);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
  };




  // useEffect(()=>{
  // if(type === 'influencer'){
  //   setCurrentStats(influencerPayoutStats)
  //   setCurrentData(influencerPayoutsData)
  // }else{
  //   setCurrentStats(creatorPayoutStats)
  //   setCurrentData(creatorPayoutsData)
  // }
  // },[influencerPayoutStats,creatorPayoutStats,type, creatorPayoutsData, influencerPayoutsData])

  return (
    <div>
      <div className="grid grid-cols-3 gap-4">
        <StatCard
          name={"Total payouts"}
          icon={<WalletIcon color="#8937CE" />}
          iconBg="bg-primary-100"
          value={isCurrentStatsLoading || currentStats?.data?.total === undefined ? "..." : toCurrency(currentStats?.data?.total)}
        />
        <StatCard
          name={"Pending payouts"}
          icon={<WalletIcon color="#8585A3" />}
          iconBg="bg-grey100"
          value={isCurrentStatsLoading || currentStats?.data?.pending === undefined ? "..." : toCurrency(currentStats?.data?.pending)}

        />
        <StatCard
          name={"Completed payouts"}
          icon={<WalletIcon color="#00A04A" />}
          iconBg="bg-success-100"
          value={isCurrentStatsLoading || currentStats?.data?.completed === undefined ? "..." : toCurrency(currentStats?.data?.completed)}

        />
      </div>
      <div className="p-4 bg-white py-6 border rounded-xl mt-5">
        <MyDataTable
          searchPlaceholder="Search..."
          options={options}
          isLoading={isCurrentDataLoading}
          headCells={[
            {
              name: "sn",
              label: "S/N",
            },
            {
              name: "user",
              label: type,
              options: {
                customBodyRender: value => (
                  <UserDisplay
                    name={value?.name}
                    badge={value?.badge}
                    email={value?.type}
                    img={value?.img}
                  />
                ),
              },
            },

            { name: "price", label: "Amount" },
            { name: "commission", label: "commission" },
            { name: "date_start", label: "Date requested" },
            { name: "date_paid", label: "Date completed" },
            {
              name: "status",
              label: "Status",
              options: {
                filter: true,
                customBodyRender: value => (
                  <Chip
                    color={getChipColor(value)}
                    sx={{ minWidth: 90 }}
                    label={`${value}`}
                  />
                ),
              },
            },
            {
              name: "actions",
              label: null,
              options: {
                customBodyRender: children => (
                  <TableDropDown tableId={"table"}>{children}</TableDropDown>
                ),
              },
            },
          ]}
          data={currentData?.data?.map((el, i) => ({
            sn: i + 1,
            user: {
              name: getUserDetails(el?.user).name,
              type: el?.user?.user_type,
              badge: el?.user?.kyc_verified,
              img: el?.user?.profile_image,
            },
            price: toCurrency(el?.amount),
            commission: toCurrency(el?.commission),
            date_start: formatDate(el?.created_at),
            date_paid: formatDate(el?.paid_date_time),
            status: el?.status,
            actions: (
              <>
                <button onClick={() => {
                  setTransactionData(el)
                  setopenModal(true)
                }}>
                  <RemoveRedEyeOutlined /> View
                </button>
              </>
            ),
          }))}
        />
      </div>
      <PayoutDetailsModal status='payout' data={transactionData} openModal={openModal} setopenModal={setopenModal} />
    </div>
  );
};

function PayoutsTab() {
  const [activeTab, setactiveTab] = useState("Influencers");

  return (
    <>
      <UserPayoutTab type="influencer" />
      {/* <TabContext value={activeTab}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          className="font-semibold"
          value={activeTab}
          onChange={(e, newValue) => setactiveTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Influencers" value="Influencers" />
          <Tab label="Creators Payout" value="creators" />
        </TabList>
        <TabPanel value="Influencers">
        </TabPanel>
        <TabPanel value="creators">
          <UserPayoutTab type="creator" />
        </TabPanel>
      </TabContext> */}
    </>
  );
}

export default PayoutsTab;
