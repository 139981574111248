const UserSquare = ({width, height, fill}) => {
  return (
    <svg
      width={width || "65"}
      height={height || "64"}
      viewBox="0 0 65 64"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.674 5.33301H21.3273C11.6207 5.33301 5.83398 11.1197 5.83398 20.8263V43.173C5.83398 50.6663 9.27398 55.813 15.3273 57.7597C17.0873 58.373 19.114 58.6663 21.3273 58.6663H43.674C45.8873 58.6663 47.914 58.373 49.674 57.7597C55.7273 55.813 59.1673 50.6663 59.1673 43.173V20.8263C59.1673 11.1197 53.3807 5.33301 43.674 5.33301ZM55.1673 43.173C55.1673 48.8797 52.9273 52.4797 48.4207 53.973C45.834 48.8797 39.7007 45.253 32.5007 45.253C25.3007 45.253 19.194 48.853 16.5807 53.973H16.554C12.1007 52.533 9.83398 48.9063 9.83398 43.1997V20.8263C9.83398 13.3063 13.8073 9.33301 21.3273 9.33301H43.674C51.194 9.33301 55.1673 13.3063 55.1673 20.8263V43.173Z"
        fill={fill || "#DCDCE5"}
      />
      <path
        d="M32.4998 21.333C27.2198 21.333 22.9531 25.5997 22.9531 30.8797C22.9531 36.1597 27.2198 40.453 32.4998 40.453C37.7798 40.453 42.0465 36.1597 42.0465 30.8797C42.0465 25.5997 37.7798 21.333 32.4998 21.333Z"
        fill={fill || "#DCDCE5"}
      />
    </svg>
  );
};

export default UserSquare;
