import "./style.scss";
import CustomLink from "components/CustomRouteLink";
import { createElement, useState } from "react";
import AdminMenuLinks from "./widgets/AdminMenuLinks";
import { Avatar, Badge, IconButton, Tooltip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { Link, Outlet, useLocation } from "react-router-dom";
import LogOutIcon from "components/Vectors/LogOutIcon";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MessageIcon from "components/Vectors/MessageIcon";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoIcon from "components/LogoIcon";
import UserDisplay from "components/TableComponent/UserDisplay";
import { useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { logOutHandler } from "store/store.hooks";
import { getUserDetails } from "utils";
import { useGetUser } from "hooks/getUserHook";
import { useGetLoggedInUserQuery, useGetNotificationQuery } from "services";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import DropDownWrapper from "components/DropDownWrapper";
import NotificationCard from "pages/Dashboard/Notifications/NotificationCard";
import LogOutModal from "./widgets/LogOutModal";
import { useGetAllCampaignsStatisticsQuery } from "services/campaigns.api";
import { useGetTicketStatsQuery } from "services/tickets.api";
import { useGetTransactionsStatsQuery } from "services/transactions.api";

export const BadgeText = ({ number, color = "bg-error" }) => (
 <span className={`flex ${color} items-center text-white px-[6px] rounded-[10px] min-w-[20px] h-[20px] text-xs`}>
  {number?.toLocaleString()}
 </span>
);

export const NotificationLinkIcon = () => (
 <Badge color="error" variant="dot" overlap="circular" badgeContent={3}>
  <IconButton component={Link} to={`/notifications`} sx={{ backgroundColor: "#ffffff19" }}>
   <NotificationsNoneOutlinedIcon sx={{ color: "#fff" }} />
  </IconButton>
 </Badge>
);

function DasboardLayout({ type = "admin" }) {
  const [isSideBarOpen, setisSideBarOpen] = useState(false);
  const [isSubMenuOpen, setisSubMenuOpen] = useState(false);
  const [subMenuOpened, setsubMenuOpened] = useState("");
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const location = useLocation();
  const { user } = useGetUser();
  const [currentUser, setcurrentUser] = useState(null);
  const { data } = useGetLoggedInUserQuery();
  const { data: campaignStats } = useGetAllCampaignsStatisticsQuery();
  const { data: ticketStats } = useGetTicketStatsQuery();
const {data: transactionStats} = useGetTransactionsStatsQuery();
// console.log(transactionStats?.data?.withdrawal?.pending_withdrawals_count + transactionStats?.data?.refund?.pending_refund_count + transactionStats?.data?.referral?.total_referred_users)

// console.log(transactionStats?.data?.refund?.pending_refund_count)
  const { data: allNotifications } = useGetNotificationQuery({
    params: {
      is_paginated: "yes",
      per_page: 10,
      page: 1,
    },
  })
  

 useEffect(() => {
  if (data) setcurrentUser(data?.data);
  else setcurrentUser(user);
 }, [data, user]);
 useEffect(() => {
  setisSideBarOpen(false);
 }, [location.pathname]);

 const SideBarLink = ({ badgeContent, route: { icon, name, link, border, submenu, permission_name } }) => (
  <>
   <li
    onClick={() => {
     submenu && setisSubMenuOpen(!isSubMenuOpen);
     submenu && setsubMenuOpened(name);
    }}
    className="min-w-[52px]"
   >
    <CustomLink baseUrl={`/`} to={link || null}>
     <Tooltip placement="right-end" title={name}>
      <div>{createElement(icon, { width: 24, height: 24 })}</div>
     </Tooltip>
     <span className={`ml-4 duration-200 ${!isSideBarOpen ? "hidden" : "flex"} flex-grow justify-between items-center`}>
      <span> {name} </span>

      {badgeContent && <BadgeText number={badgeContent} />}
     </span>
     {submenu &&
      isSideBarOpen &&
      (isSubMenuOpen && subMenuOpened === name ? (
       <KeyboardArrowUpIcon className={`ml-auto`} />
      ) : (
       <KeyboardArrowDownIcon className={`ml-auto`} />
      ))}
    </CustomLink>
    {submenu && subMenuOpened === name && isSubMenuOpen && (
     <ul className={`pl-8 ml-7 border-l  duration-200 ${!isSideBarOpen ? "hidden" : "block"}`}>
      {submenu.map((el) => (
       <li key={el.name}>
        <CustomLink baseUrl={`/${type}/`} to={el.link}>
         <span>{el.name}</span>
        </CustomLink>
       </li>
      ))}
     </ul>
    )}
    {border && <span className="border-b block w-full -my-2 h-1 border-[#FFFFFF26]" />}
   </li>
  </>
 );

 return (
  <div className="DashboardLayout overflow-hidden flex items-stretch max-h-screen">
   {/* aside */}
   <aside
    className={`fixed z-[100] scrollbar-style white-scrollbar lg:sticky top-0 ${
     isSideBarOpen ? "w-[70%] left-0 lg:min-w-[290px]" : "lg:w-[100px] -left-[100vw] lg:left-0"
    } transition-all duration-500 max-w-[290px] min-h-screen max-h-screen min-w-[80px] pt-10 flex flex-col ${
     isSideBarOpen ? "px-6" : "px-4"
    }  bg-secondary text-primary-100`}
   >
    {/* arrow to reduce width */}
    <IconButton
     onClick={() => setisSideBarOpen((prev) => !prev)}
     sx={{
      backgroundColor: "#000042 !important",
      position: "absolute",
      display: {
       xs: "none",
       md: "flex",
      },
     }}
     className={`hidden md:flex -right-4 top-16 duration-200 ${isSideBarOpen && "rotate-180"}`}
    >
     <ArrowForwardIcon color="white" />
    </IconButton>

    {/* logo */}
    <div className="logo hidden lg:block pb-8">
     <img
      src={`/images/secondary-logo.png`}
      className={`w-[171px] h-[36px] ml-2 transition-all duration-200 ${isSideBarOpen ? "block" : "hidden"}`}
      alt="logo"
     />

     <img
      src={`/images/logo-icon-filled.png`}
      className={`w-[38px] h-[30px] mx-auto duration-200 ${!isSideBarOpen ? "block" : "hidden"}`}
      alt="logo"
     />
    </div>
    <Link to={`/profile`} className="lg:hidden flex justify-between items-center text-white pb-3 pl-2">
     <UserDisplay
      textWhite
      size="medium"
      name={getUserDetails(currentUser).name}
      img={getUserDetails(currentUser).img}
      email={getUserDetails(currentUser).username}
     />
     <ArrowForwardIosIcon color="white" fontSize="small" />
    </Link>

        {/* links */}
        <ul
          style={{ maxHeight: "calc(100vh - 40px)" }}
          className={`flex-grow min-w-full overflow-y-auto ${!isSideBarOpen ? "max-w-[56px]" : "max-w-unset"} mr-auto`}
        >
          {currentUser?.roles[0]?.name.toLowerCase() === "super admin"
            ? AdminMenuLinks.map((route) => (
              <SideBarLink
                key={route.name}
                route={route}
                badgeContent={
                  route.name === "Campaigns"
                    ? campaignStats?.data?.pending
                    : route.name === "Tickets"
                      ? ticketStats?.data?.New
                    : route.name === "Transactions"
                      ? transactionStats?.data?.withdrawal?.pending_withdrawals_count + transactionStats?.data?.refund?.pending_refunds_count + transactionStats?.data?.referral?.total_referred_users
                      : route.badgeContent
                }
              />
            ))
            : AdminMenuLinks.filter((item) =>
              currentUser?.permissions?.some((el) => el.name?.toLowerCase() === item?.permission_name?.toLowerCase()),
            ).map((route) => (
              <SideBarLink
                key={route.name}
                route={route}
                badgeContent={route.name === "Campaigns" ? campaignStats?.data?.pending : route.badgeContent}
              />
            ))}
          <li className="min-w-[52px] mt-20">
            <button
              onClick={() => setOpenLogoutModal(true)}
              className={`menu-link w-full my-7 text-[#D0D0EF] hover:bg-[#d3d3f031]`}
            >
              <Tooltip placement="right-end" title={"Log out"}>
                <div>{createElement(LogOutIcon, { width: 24, height: 24 })}</div>
              </Tooltip>
              <span className={`ml-4 duration-200 ${!isSideBarOpen ? "hidden" : "block"}`}>Log Out</span>
            </button>
          </li>
        </ul>
      </aside>
      <main id="scroller" className="lg:pt-5 flex flex-col min-h-screen scrollbar-style overflow-y-auto overflow-x-hidden">
        {/* mobile nav */}
        <nav className="flex sticky top-0 z-[70] bg-secondary-dark py-4 lg:hidden justify-between items-center border-b px-4">
          <Link to={`/${type}`}>
            <LogoIcon typeWhite width={45} height={45} />
          </Link>
          <div className="flex gap-2">
            <NotificationLinkIcon baseUrl={type} />
            <IconButton sx={{ backgroundColor: "#ffffff19" }} onClick={() => setisSideBarOpen((prev) => !prev)}>
              {!isSideBarOpen ? <MenuIcon color="white" /> : <CloseIcon color="white" />}
            </IconButton>
          </div>
        </nav>
        {/* desktop nav */}
        <nav className="hidden lg:flex justify-between items-center border-b px-6 pb-5">
          {/* <InputField
            prefixIcon={
              <SearchIcon sx={{ color: "greyBorder.contrastText" }} />
            }
            className="max-w-[420px] w-full"
            spaceY={false}
            placeholder="Search, user, services, campaigns..."
          /> */}
     <div className="flex space-x-4 justify-end w-full flex-grow items-center">
      {type === "influencer" && (
       <IconButton
        component={Link}
        to="chats"
        sx={{ backgroundColor: "#F9F9FB" }}
        // aria-label={notificationsLabel(100)}
       >
        <Badge color="error" variant="dot" badgeContent={3}>
         <MessageIcon color="#424257" />
        </Badge>
       </IconButton>
      )}

      <DropDownWrapper
       contentPadding="!p-0"
       className={"!max-w-[600px]"}
       action={
        <IconButton
         sx={{
          backgroundColor: "#F9F9FB",
          minWidth: "65px",
          borderRadius: "50px",
         }}
        >
         <Badge color="error" sx={{ fontSize: "10px" }} badgeContent={allNotifications?.unread_notifications || 0}>
          <NotificationsNoneOutlinedIcon sx={{ color: "#424257" }} />
         </Badge>
         <ExpandMoreOutlinedIcon sx={{ ml: 1 }} />
        </IconButton>
       }
      >
       <div className="bg-grey100 p-4 !max-w-[600px]">
        <div className="flex items-center justify-between py-4">
         <p className="text-xl font-semibold">Notifications</p>
         <Link to="/notifications" className="text-primary !mt-0 !w-auto">
          View all
         </Link>
        </div>
        <div className="flex flex-col gap-y-4">
         {allNotifications?.data?.data?.slice(0, 4)?.map((notification, i) => (
          <NotificationCard key={i} notification={notification} isPreview={true} />
         ))}
        </div>
       </div>
      </DropDownWrapper>
      <Link to="profile" className="flex space-x-3 text-right items-center">
       <div>
        <p className="font-semibold">{getUserDetails(currentUser).name}</p>
        <p className="text-sm text-grey">{currentUser?.role?.name}</p>
       </div>
       <Avatar
        sx={{
         backgroundColor: "#EFDCFE",
         color: "primary.main",
         fontSize: "1rem",
        }}
        src={getUserDetails(currentUser).img}
        alt="Walter Alan"
       />
      </Link>
     </div>
    </nav>
    <div className="flex-grow text-[#424257]">
     <Outlet />
    </div>
   </main>

   <LogOutModal
    open={openLogoutModal}
    close={() => setOpenLogoutModal(false)}
    onClick={() => logOutHandler()}
    btnText="Cancel"
   />
  </div>
 );
}

export default DasboardLayout;
