import {Avatar, IconButton} from "@mui/material";
import EditIcon from "components/Vectors/EditIcon";
import ImagePlusIcon from "components/Vectors/ImagePlusIcon";
import VideoIcon from "components/Vectors/VideoIcon";
import {useState, useRef, useEffect} from "react";
import {useFormContext, get, Controller} from "react-hook-form";

const UploadImage = ({img, name, videoType = false, label, icon, ...props}) => {
  const imgRef = useRef();
  const [localImgUrl, setLocalImgUrl] = useState("");
  const {
    register,
    setError,
    control,
    clearErrors,
    setValue,
    watch,
    formState: {errors},
  } = useFormContext();
  const error = get(errors, name);
  const imgValue = watch(name);
  useEffect(() => {
    register(name, {
      required: props.required ? "This field is required" : false,
    });
    if (typeof imgValue === "string") setLocalImgUrl(imgValue);
    else if (typeof imgValue === "object")
      setLocalImgUrl(URL.createObjectURL(imgValue[0]));
  }, [imgValue, name, props.required, register, setValue]);

  return (
    <div className="mt-4 mb-8">
      {label && <p className="text-sm text-gray-500 font-medium">{label}</p>}
      <label
        htmlFor="logo"
        className="-mb-2 h-[160px] relative cursor-pointer mt-2 border rounded-lg p-5 flex justify-center items-center"
      >
        {localImgUrl ? (
          <div className="w-full z-10 group h-full cursor-pointer absolute inset-0">
            <Avatar
              variant="rounded"
              sx={{width: "100%", height: "100%"}}
              src={localImgUrl}
            />
            <IconButton
              onClick={() => imgRef.current.click()}
              component="label"
              sx={{background: "#8937CE !important", position: "absolute"}}
              className="group-hover:opacity-100 opacity-0 transition top-2 right-2"
            >
              <EditIcon />
            </IconButton>
          </div>
        ) : (
          <div className="flex flex-col text-xs md:text-sm gap-y-3 items-center">
            {videoType ? (
              <VideoIcon />
            ) : (
              <ImagePlusIcon width={70} height={60} />
            )}
            <p className="font-medium text-center">
              Drag and drop {videoType ? "video" : "photo"} here or{" "}
            </p>
            <p className="text-primary font-semibold">Browse</p>
          </div>
        )}
        <Controller
          rules={{
            required: props.required ? "This field is required" : "",
          }}
          control={control}
          name={name}
          render={({field}) => {
            const {onChange} = field;
            return (
              <input
                required
                {...props}
                accept="image/*"
                type="file"
                {...register(name, {
                  required: props.required ? "This field is required" : false,
                })}
                id={name}
                name={name}
                onChange={(e) => {
                  const selectedFiles = e.target.files;
                  onChange(e);
                  if (selectedFiles.length > 0) {
                    if (selectedFiles[0]?.size > 1_000_000) {
                      setLocalImgUrl("");
                      setError(name, {
                        message: "File size should be less than 1MB",
                      });
                      setValue(name, undefined);
                    } else {
                      clearErrors(name);
                      setValue(name, selectedFiles);
                      setLocalImgUrl(URL.createObjectURL(e.target.files[0]));
                    }
                  }
                }}
                className="absolute inset-0 opacity-0"
                ref={imgRef}
              />
            );
          }}
        />
      </label>
      {error && error?.message && (
        <div className="input-err-msg pt-2">
          {error?.message || error?.name?.message}
        </div>
      )}
    </div>
  );
};

export default UploadImage;
