import CancelOutlineIcon from "components/Vectors/CancelOutlineIcon";
import {useEffect, useState} from "react";
import "./style.scss";
import {get, useFormContext} from "react-hook-form";

const TagsInput = ({setData, name, required}) => {
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");

  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext();

  const handleKeyDown = (e) => {
    const {key} = e;
    const trimmedInput = input.trim();

    if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }
  };

  const onChange = (e) => {
    const {value} = e.target;
    setInput(value);
  };

  const handleDelete = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  useEffect(() => {
    register(name, {
      required: required ? "This field is required" : false,
    });
    setValue(name, tags);
  }, [register, name, required, tags, setValue]);

  const error = get(errors, name);

  return (
    <>
      <div className="border-[1px] border-borderColor flex flex-wrap gap-2 items-center p-4 rounded-[12px] h-[auto] ">
        {tags.map((tag, i) => (
          <div
            key={i}
            className="bg-[#EAEAF0] rounded-[4px] py-[5px] px-[8px]
      inline-block gap-[4px] items-center w-[auto] mr-1 "
          >
            <span className="font-medium text-prop text-[12px] ">{tag}</span>
            <span
              className="ml-2 cursor-pointer "
              onClick={() => handleDelete(i)}
            >
              <CancelOutlineIcon color="#2C2C3A" />
            </span>
          </div>
        ))}
        <div className="">
          <input
            onKeyDown={handleKeyDown}
            type="text"
            value={input}
            placeholder="Separate words by comma"
            className="tag-input placeholder:text-xs"
            onChange={onChange}
          />
        </div>
      </div>
      {error && error?.message && (
        <div className={` ${!error && "hidden"} input-err-msg pt-[-10rem] `}>
          {error?.message || error?.name?.message || ""}
        </div>
      )}
    </>
  );
};
export default TagsInput;
