import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useLocation } from "react-router-dom";

import { Button, CircularProgress } from "@mui/material";
import UserIconFilled from "components/Vectors/UserIconFilled";

import DropDownWrapper from "components/DropDownWrapper";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import SuspendUserModal from "components/Modal/SuspendUserModal";
import MessageModal from "components/Modal/MessageModal";
import DeleteUserModal from "components/Modal/DeleteUserModal";
import UnsuspendUsermodal from "components/Modal/UnsuspendUsermodal";
import TrashIcon from "components/Vectors/TrashIcon";

import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { useActivateClientAccountMutation } from "services";

const ClientCta = ({ user }) => {
 const { pathname } = useLocation();
 const type = pathname.split("/")[1].slice(0, -1);

 const [openSuspendModal, setOpenSuspendModal] = useState(false);
 const [openUnSuspendModal, setOpenUnsuspendModal] = useState(false);
 const [openDeleteModal, setOpenDeleteModal] = useState(false);
 const isSuspended = user?.status?.toLowerCase() === "suspended";
 const [confirmRestore, setConfirmRestore] = useState(false);

 const [activateClient, { isLoading }] = useActivateClientAccountMutation();

 const handleActivateAccount = async () => {
  try {
   const res = await activateClient(user?.id).unwrap();
   showToast(res?.message);
   setConfirmRestore(true);
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <>
   <div className="flex gap-2 items-center ">
    {user && !user?.email_verified_at && (
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      onClick={handleActivateAccount}
      loading={isLoading}
      variant="contained"
     >
      Activate Account
     </LoadingButton>
    )}

    {/* <DropDownWrapper
                    className="more-actions"
                    position="right"
                    border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
                    closeOnBtnClick={false}
                    action={
                        <button
                            type="button"
                            className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]"
                        >
                            <MoreHorizOutlinedIcon color="primary" fontSize="large" />
                        </button>
                    }
                > */}
    {/* <Button
                    onClick={() => (isSuspended ? handleActivateAccount() : setOpenSuspendModal(true))}
                    color="errorInverse"
                    variant='contained'
                    startIcon={<DoDisturbAltRoundedIcon />}
                >
                    {isSuspended ? 'Unsuspend Account' : 'Suspend Account'}
                </Button> */}

    <DropDownWrapper
     className="more-actions"
     position="right"
     border="dropdown-boxShadow border border-[#E4E7EC] !rounded-16"
     closeOnBtnClick={false}
     action={
      <button type="button" className="flex h-[52px] w-[52px] items-center justify-center rounded-2xl bg-[#F2F4F7]">
       <MoreHorizOutlinedIcon color="primary" fontSize="large" />
      </button>
     }
    >
     <Button
      onClick={() => (isSuspended ? setOpenUnsuspendModal(true) : setOpenSuspendModal(true))}
      color="inherit"
      startIcon={<DoDisturbAltRoundedIcon />}
     >
      {isSuspended ? "Unsuspend Account" : "Suspend Account"}
     </Button>
     {/* <Button onClick={() => setOpenDeleteModal(true)} className="!text-[#D61E34]" startIcon={<TrashIcon />}>
      Delete User
     </Button> */}
    </DropDownWrapper>

    {/* </DropDownWrapper> */}
   </div>
   <SuspendUserModal user={user} setOpenModal={setOpenSuspendModal} openModal={openSuspendModal} />
   <DeleteUserModal user={user} setOpenModal={setOpenDeleteModal} openModal={openDeleteModal} />
   <UnsuspendUsermodal user={user} setOpenModal={setOpenUnsuspendModal} openModal={openUnSuspendModal} />

   <MessageModal
    openModal={confirmRestore}
    closeModal={() => setConfirmRestore(false)}
    title={<span className="capitalize">{`${type} restored`}</span>}
    // title={<span className="capitalize">{`${type}  ${!isSuspended ? 'restored' : 'suspended'}`}</span>}
    iconBg={isSuspended ? "bg-[#EAEAF0]" : "bg-[#EAEAF0]"}
    description={`An email has been sent to ${user?.first_name + " " + user?.last_name}`}
    icon={<UserIconFilled color={isSuspended ? null : "#8585A3"} />}
   />
  </>
 );
};

export default ClientCta;
