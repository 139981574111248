import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import CategoriesSettings from "./Tabs/CategoriesSettings";
import BannedWords from "./Tabs/BannedWords";
import ServiceCharge from "./Tabs/ServiceCharge";
import Referral from "./Tabs/ReferralTab";
import CampaignTab from "./Tabs/CampaignTab";
import LocationSettingTab from "./Tabs/LocationSettingTab";

function AdminSettings() {
    const [activeTab, setactiveTab] = useState("service");
    const navigate = useNavigate();
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const tab = params.get("tab");
    useEffect(() => {
        if (tab) setactiveTab(tab);
    }, [tab]);

    return (
        <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
            <h1 className="title">Settings</h1>
            <div className="py-5 px-6 bg-white divide-x flex border rounded-xl mt-5">
                <TabContext value={activeTab}>
                    <TabList
                        variant="scrollable"
                        orientation="vertical"
                        scrollButtons="auto"
                        value={activeTab}
                        className="min-w-max sticky top-0 max-h-screen pr-6"
                        onChange={(e, newValue) => {
                            navigate(`?tab=${newValue}`);
                            setactiveTab(newValue);
                        }}
                        sx={{
                            "& .MuiTabs-indicator": {
                                left: "0 !important",
                            },
                            button: {
                                alignItems: "flex-start",
                            },
                        }}
                        aria-label="basic tabs example"
                    >
                        {/* <Tab label="Creators Categories" value="categories" /> */}
                        <Tab label="Service Charges" value="service" />
                        {/* <Tab label="Creator Referrals" value="creator referrals" /> */}
                        <Tab label="Referral Settings" value="referrals" />
                        <Tab label="Location Settings" value="location" />
                        <Tab label="Campaign Settings" value="campaign" />
                        <Tab label="Banned Words" value="banned" />
                    </TabList>
                    <div className="pl-6 overflow-auto w-full">
                        {/* <TabPanel value="categories">
                            <CategoriesSettings />
                        </TabPanel> */}
                        <TabPanel value="service">
                            <ServiceCharge />
                        </TabPanel>
                        {/* <TabPanel value="creator referrals">
                            <Referral referralType="Creator Referrals" />
                        </TabPanel> */}
                        <TabPanel value="referrals">
                            <Referral referralType="Referral Settings" />
                        </TabPanel>
                        <TabPanel value="location">
                            <LocationSettingTab />
                        </TabPanel>
                        <TabPanel value="campaign">
                            <CampaignTab />
                        </TabPanel>
                        <TabPanel value="banned">
                            <BannedWords />
                        </TabPanel>
                    </div>
                </TabContext>
            </div>
        </div>
    );
}

export default AdminSettings;
