import { Avatar, Button, CircularProgress, IconButton } from "@mui/material";
import { useRef, useState } from "react";
// import { Screenshot, Summary, URL } from "./Types";
import FileDownloadIcon from "components/Vectors/FileDownloadIcon";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { useAcceptRejectTaskCompletionMutation } from "services/campaigns.api";
import { LoadingButton } from "@mui/lab";
import { OpenInNew } from "@mui/icons-material";

const SubmissionCard = ({ submission, id, clientId }) => {
     const containerRef = useRef(null);
     const [showContent, setShowContent] = useState(false);
     console.log(submission, id);

     const height = containerRef.current?.scrollHeight;

     const getStatusColor = (status) => {
          let color;
          switch (status) {
               case "Completed":
                    color = "#00A04A";
                    break;
               case "rejected":
                    color = "#D61E34";
                    break;
               case "Failed Timeline":
                    color = "#D61E34";
                    break;
               case "Exited":
                    color = "#D61E34";
                    break;
               case "Submitted":
                    color = "#8937CE";
                    break;
               case "pending":
                    color = "#F49600";
                    break;

               case "Removed":
                    color = "#4B5E5F";
                    break;

               case "No Submission Yet":
                    color = "#F49600";
                    break;

               default:
                    break;
          }

          return color;
     };

     const [accept, { isLoading }] = useAcceptRejectTaskCompletionMutation();

     const handleTaskAcception = async (status) => {
          const body = {
               campaign_task_id: submission?.campaign_task_id,
               client_id: clientId,
               status,
               reason_for_rejection: null,
          };

          try {
               const response = await accept(body).unwrap();
               showToast(response?.message);
          } catch (error) {
               handleError(error);
          }
     };

     //  console.log(submission);

     return (
          <div className="bg-[#F6F6F7] p-4 rounded-xl my-6">
               <header className="flex items-center justify-between cursor-pointer" onClick={() => setShowContent(!showContent)}>
                    <h3 className="text-black text-[18px] font-semibold">Submission {id + 1}</h3>
                    <div className="flex items-center gap-2">
                         <span
                              className={`flex items-center justify-center h-[24px] px-2 font-semibold text-xs  bg-white uppercase
           border border-[#DCDCE5] rounded-[4px] w-fit whitespace-nowrap 
           `}
                              style={{ color: getStatusColor(submission?.status?.toLowerCase() === "pending" ? "Submitted" : submission?.status?.toLowerCase()) }}
                         >
                              {submission?.status?.toLowerCase() === "pending" ? "Submitted" : submission?.status}
                         </span>
                         <svg
                              width="24"
                              height="25"
                              viewBox="0 0 24 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className={`transition-all duration-500  ${!showContent ? "rotate-180" : ""}`}
                         >
                              <path
                                   d="M6.08001 16.32L12.31 16.32H17.92C18.88 16.32 19.36 15.16 18.68 14.48L13.5 9.30001C12.67 8.47001 11.32 8.47001 10.49 9.30001L8.52001 11.27L5.31001 14.48C4.64001 15.16 5.12001 16.32 6.08001 16.32Z"
                                   fill="#2C2C3A"
                              />
                         </svg>
                    </div>
               </header>

               <div
                    ref={containerRef}
                    className="overflowhidden max-h0 transition-all duration-500"
                    style={{
                         maxHeight: showContent ? height : null,
                    }}
               >
                    {/* screenshot only */}
                    {submission?.screenshot && (
				<>
					<div className="mt4 mb-6">
						<p className="font-medium mb-2 text-sm text-grey50">Screenshot</p>
						<div className="rounded-lg bg-white p-4 py-8 flex justify-center w-full items-center spacex-3 h-[367px]">
							<Avatar
								src={submission?.screenshot}
								sx={{ width: "100%", height: "100%", borderRadius: "4px" }}
							/>
						</div>
					</div>
					<a
						href={submission?.screenshot}
						download
						style={{
							width: 'fit-content',
							backgroundColor: '#fff',
							border: '1px solid #BFBFCF',
							color: '#181820',
							padding: '8px 12px',
							borderRadius: '8px',
							fontWeight: 600,
							fontSize: '14px',
							display: "flex",
							alignItems: "center",
							gap: "6px",
							marginBottom: "24px",
						}}
					>
						<FileDownloadIcon />
						Download Image
					</a>
				</>
			)}
			{submission?.url && (
				<div className="mt4 mb-6">
					<p className="font-medium mb-2 w1/5 text-sm text-grey50">Task URL</p>
					<div className="rounded-lg bg-white border p-4 flex justify-between w-full items-center space-x-3">
						{/* <p className="mb2 font-semibold w-1/5 text-base text-grey10">URL</p> */}


						<span className="font-semibold w[70%] text-right text-sm text-[#2C2C3A] flex gap-3.5 items-center justify-end">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.9883 17.5H16.4983C19.5183 17.5 21.9983 15.03 21.9983 12C21.9983 8.98 19.5283 6.5 16.4983 6.5H14.9883" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M9 6.5H7.5C4.47 6.5 2 8.97 2 12C2 15.02 4.47 17.5 7.5 17.5H9" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M8 12H16" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</svg>

							{submission?.url}
							<IconButton>
								<a
									href={`http://${submission?.url}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<OpenInNew color="primary" />
								</a>
							</IconButton>
						</span>
					</div>
				</div>
			)}

			{submission?.summary && (
				<div className="mb-6">
					<p className="mb-2 font-semibold text-base text-grey10">Summary</p>
					<p className="font-medium text-grey30">
						{submission?.summary || "N/A"}
					</p>
				</div>
			)}
                    {/* {submission?.submission_type === "screenshot_only" ? (
                         <Screenshot img_url="/images/screenshot.png" />
                    ) : submission?.submission_type === "url_and_screenshot" ? (
                         <>
                              <URL url="hookik.com/task/tasksss" />
                              <Screenshot img_url="/images/screenshot.png" />
                         </>
                    ) : submission?.submission_type === "url_only" ? (
                         <URL url="hookik.com/task/tasksss" />
                    ) : submission?.submission_type === "screenshot_and_summary" ? (
                         <>
                              <Summary summary="Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. " />
                              <Screenshot img_url="/images/screenshot.png" />
                         </>
                    ) : submission?.submission_type === "summary" ? (
                         <Summary summary={submission?.summary} />
                    ) : submission?.submission_type === "url_and_summary" ? (
                         <>
                              <URL url="hookik.com/task/tasksss" />
                              <Summary summary="Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. " />
                         </>
                    ) : submission?.submission_type === "url_and_summary_screenshot" ? (
                         <>
                              <URL url="hookik.com/task/tasksss" />
                              <Summary summary="Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis arcu, vel tempor, dictum arcu. " />
                              <Screenshot img_url="/images/screenshot.png" />
                         </>
                    ) : (
                         <></>
                    )} */}

                    <footer className="flex items-center gap-2">
                         <Button
                              className="flex items-center justify-center text-sm !rounded-md !text-[#D61E34] whitespace-nowrap !mt-4"
                              sx={{
                                   height: "48px",
                                   width: "143px",
                                   background: "#FAEBED",
                              }}
                         >
                              Reject
                         </Button>
                         <LoadingButton
                              loadingIndicator={
                                   <CircularProgress
                                        sx={{
                                             color: "#ffffff",
                                        }}
                                        size="1.2rem"
                                   />
                              }
                              loading={isLoading}
                              type="submit"
                              variant="contained"
                              className="flex items-center justify-center text-sm !rounded-md !text-[#FFF] whitespace-nowrap !mt-4 w-full"
                              sx={{
                                   height: "48px",
                                   background: "#8937CE",
                              }}
                              onClick={() => handleTaskAcception("accepted")}
                         >
                              {!isLoading && "Mark as completed"}
                         </LoadingButton>
                    </footer>
               </div>
          </div>
     );
};

export default SubmissionCard;
