import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import PhoneInputWrapped from "components/forms/PhoneInputWrapped";
import ValidatedInput from "components/forms/ValidatedInput";
import Modal from "components/Modal";
import MessageModal from "components/Modal/MessageModal";
import SecurityUserIcon from "components/Vectors/SecurityUserIcon";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useAddAdminMutation, useEditAdminMutation} from "services";
import {showToast} from "store/store.hooks";
import {handleError, removeEmpty} from "utils";

function AddNewAdminModal({
  setOpenAdminModal,
  openAdminModal,
  isEditing,
  details,
}) {
  const methods = useForm();
  const {id} = useParams();
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [addAdmin, {isLoading}] = useAddAdminMutation();
  const [editAdmin, {isLoading: isEditingAdmin}] = useEditAdminMutation();

  useEffect(() => {
    if (isEditing) methods.reset(details);
  }, [details, isEditing, methods, openAdminModal]);

  const onSubmit = async (body) => {
    const formData = new FormData();
    formData.append("first_name", body.first_name);
    formData.append("last_name", body.last_name);
    formData.append("email", body.email);
    formData.append("phone_number", body.phone_number);
    if (isEditing) {
      if (details.permissions.length > 0) {
        details.permissions.forEach((permission) => {
          formData.append("permissions[]", permission.name);
        });
      } else formData.append("permissions", []);
    }

    try {
      let res = isEditing
        ? await editAdmin({
            adminId: id,
            body: formData,
          }).unwrap()
        : await addAdmin(
            removeEmpty({
              ...body,
            })
          ).unwrap();
      methods.reset();
      setResMsg(res?.data?.first_name + " " + res?.data?.last_name);
      setOpenAdminModal(!openAdminModal);
      if (!isEditing) setOpenMessageModal(!openMessageModal);
      showToast(res.message);
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <>
      <Modal
        openModal={openAdminModal}
        closeModal={() => setOpenAdminModal(false)}
        title={`${isEditing ? "Edit" : "Create"} Admin`}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex gap-2">
              <ValidatedInput
                placeholder="Input First Name"
                label="First Name"
                name="first_name"
              />
              <ValidatedInput
                name="last_name"
                placeholder="Input Last Name"
                label="Last Name"
              />
            </div>
            <ValidatedInput
              name="email"
              placeholder="Enter Email"
              label="Email"
            />
            <div className="pb-2"></div>
            <PhoneInputWrapped
              id="1"
              spaceY={false}
              className="mt-8"
              name="phone_number"
              variant="free"
              label="Phone Number"
              defaultValue={isEditing ? details?.phone_number : ""}
            />
            <LoadingButton
              type="submit"
              loadingIndicator={<CircularProgress size={20} color="white" />}
              variant="contained"
              fullWidth
              loading={isLoading || isEditingAdmin}
              sx={{marginTop: 8}}
            >
              {isEditing ? "Edit " : "Create "}
              Admin
            </LoadingButton>
          </form>
        </FormProvider>
      </Modal>

      <MessageModal
        openModal={openMessageModal}
        closeModal={() => setOpenMessageModal(!openMessageModal)}
        title="Admin Added"
        description={
          <>
            You have successfully added{" "}
            <span className="font-bold">{resMsg}</span> as an admin. An Email
            has been sent to the user.
          </>
        }
        icon={<SecurityUserIcon sx={{width: 80, height: 80}} color="primary" />}
      />
    </>
  );
}

export default AddNewAdminModal;
