import ControlPointIcon from "@mui/icons-material/ControlPoint";
// import { Button } from '@mui/material';
import {Button} from "@mui/material";
import {useState} from "react";
import AdministratorTab from "./Tabs/AdministratorTab";
import AddNewAdminModal from "./Widgets/AddNewAdminModal";
import {useGetUser} from "hooks/getUserHook";

const AdminAdministrators = () => {
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const {user} = useGetUser();
  return (
    <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
      <div className="flex flex-col md:flex-row gap-5 md:justify-between md:items-center mb-4">
        <h1 className="title">Administrators</h1>
        {/* to be updated when API and roles are implemented */}
        {user?.role?.slug === "super-admin" && null}
        <Button
          variant="contained"
          startIcon={<ControlPointIcon />}
          onClick={() => setOpenAdminModal(!openAdminModal)}
        >
          Create Admin
        </Button>
      </div>
      <div className="mt-8">
        <AdministratorTab />
      </div>
      <AddNewAdminModal
        openAdminModal={openAdminModal}
        setOpenAdminModal={setOpenAdminModal}
      />
    </div>
  );
};

export default AdminAdministrators;
