import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const ticketsApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "ticketsApi",
 tagTypes: ["tickets"],
 endpoints: (builder) => ({
  getAllTickets: builder.query({
   query: () => `admin/tickets`,
   providesTags: ["tickets"],
  }),
  getTicketStats: builder.query({
   query: () => `/tickets/stats`,
  }),
  getSingleTicket: builder.query({
   query: (id) => `admin/tickets/${id}`,
   providesTags: ["tickets"],
  }),
  updateTicketStatus: builder.mutation({
   query: (body) => ({
    url: `tickets/update-status`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["tickets"],
  }),
  replyTicket: builder.mutation({
   query: (body) => ({
    url: `ticket-responses`,
    method: "POST",
    body,
   }),
   invalidatesTags: ["tickets"],
  }),
 }),
});

export const {
 useGetAllTicketsQuery,
 useGetTicketStatsQuery,
 useUpdateTicketStatusMutation,
 useReplyTicketMutation,
 useGetSingleTicketQuery,
} = ticketsApi;
