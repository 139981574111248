import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControlLabel, Chip } from "@mui/material";
import Modal from "components/Modal";
import ToggleSwitch from "components/ToggleSwitch";
import ValidatedInput from "components/forms/ValidatedInput";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useAddStateMutation, useEditStateMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const ManageStateModal = ({ open, close, isEditing, state, location }) => {
   const [searchParams] = useSearchParams();
   const id = searchParams.get("id");
   const [addState, { isLoading: isAddingState }] = useAddStateMutation();
   const [editState, { isLoading: isEditingState }] = useEditStateMutation();
   const [status, setStatus] = useState("");
   const methods = useForm();

   useEffect(() => {
      if (isEditing) {
         methods.reset({
            ...state,
         });
      }
   }, [state, isEditing, methods]);

   const statusMap = [
      {
         label: "Inactive",
         name: "inactive",
      },
      {
         label: "Active",
         name: "active",
         color: "primaryInverse"
      },
   ]

   const handleSubmit = (data) => {
      const formData = new FormData();
      formData.append("name", data?.name);
      formData.append("zip_code", data?.zip_code);
      formData.append("status", isEditing ? status : "active");
      formData.append("country_id", id ? parseInt(id) : parseInt(state.id));

      try {
         isEditing
            ? editState({ id: state?.id, body: formData })
               .unwrap()
               .then((res) => {
                  showToast(res?.message);
                  methods.reset();
                  close();
               })
               .catch((err) => {
                  handleError(err);
               })
            : addState(formData)
               .unwrap()
               .then((res) => {
                  showToast(res?.message);
                  methods.reset();
                  close();
               })
               .catch((err) => {
                  handleError(err);
               });
      } catch (e) {
         handleError(e);
      }
   };
   return (
      <Modal
         openModal={open}
         closeModal={() => {
            methods.reset();
            close();
         }}
         imgClass="modal-content scrollbar-style !min-h-[280px]"
         title={isEditing ? "Edit State" : "Add State"}
      >
         <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
               <ValidatedInput label="State Name" name="name" />
               {location !== "Nigeria" && <ValidatedInput label="ZIP Code" name="zip_code" />}
               {/*<FormControlLabel
      name="zip_code"
      control={<Checkbox onChange={(e) => methods.setValue("zip_code", e.target.checked)} />}
      label="Include zip code"
      sx={{ mt: 2, mb: isEditing ? 2 : 8 }}
/>*/}

               {isEditing && (
                  <div className="my-6 mt-8">
                     <p className="text-grey30 text-sm mb-2">Status</p>
                     <div className="flex items-center gap-x-3.5">
                        {statusMap?.map((el, i) =>
                           <Chip
                              key={i}
                              label={el?.label}
                              variant={status === el?.name ? "outlined" : "contained"}
                              onClick={() => setStatus(el?.name)}
                              color={status === el?.name ? "black" : el?.color}
                           />
                        )}
                        {/* <Chip label="Active" onClick={() => setStatus("active")} color="primaryInverse" /> */}
                     </div>
                  </div>
               )}
               <LoadingButton
                  loadingIndicator={
                     <CircularProgress
                        sx={{
                           color: "#ffffff",
                        }}
                        size="1.2rem"
                     />
                  }
                  loading={isAddingState || isEditingState}
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{ mt: isEditing ? 1 : 5 }}
               >
                  {isEditing ? "Save State" : "Add State"}
               </LoadingButton>
            </form>
         </FormProvider>
      </Modal>
   );
};

export default ManageStateModal;
