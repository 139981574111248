import { FormProvider, useForm } from "react-hook-form";
import UserDisplay from "components/TableComponent/UserDisplay";
import { useDeleteUserMutation } from "services";
import { LoadingButton } from "@mui/lab";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const DeleteUserModal = ({ user, openModal, setOpenModal }) => {
 // console.log(user);
 const navigate = useNavigate();
 const location = useLocation();

 const [deleteUser, { isLoading }] = useDeleteUserMutation();

 const methods = useForm({
  mode: "all",
 });

 const onSubmit = async (body) => {
  // methods.reset();

  const data = new FormData();
  data.append("user_id", user?.id);
  data.append("reason_for_deletion", body.reason_for_deletion);

  // console.log(data);

  try {
   let res = await deleteUser({
    ...body,
    user_id: user?.id,
   }).unwrap();
   //  console.log(res)
   showToast(res?.message);
   navigate(`${location.pathname.includes("clients") ? "/clients" : "/influencers"}`);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <>
   <Modal openModal={openModal} closeModal={() => setOpenModal(!openModal)} title="Delete User">
    {/* <div className="w-full mb-[18px]">
     <UserDisplay
      size="large"
      max_width="200px"
      name={user?.full_name}
      handle={user?.username}
      img={user?.profile_image}
     />
    </div> */}

    <FormProvider {...methods}>
     <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col justify-between flex-grow">
      <ValidatedTextArea
       name="reason_for_deletion"
       type="text"
       label="Reason for Deleting"
       // required={false}
       placeholder="Enter reason"
      />
      <div className="mt-[auto]">
       <LoadingButton
        loadingIndicator={
         <CircularProgress
          sx={{
           color: "#fff",
          }}
          size="1.2rem"
         />
        }
        loading={isLoading}
        type="submit"
        color="error"
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
       >
        Delete User
       </LoadingButton>
      </div>
     </form>
    </FormProvider>
   </Modal>
  </>
 );
};

export default DeleteUserModal;
