import { IconButton } from "@mui/material";
import { Avatar } from "@mui/material";
import CustomRating from "components/Ratings";
import DeleteIcon from "components/Vectors/DeleteIcon";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EachClientReview = ({ review, showDelete = true }) => {
 const navigate = useNavigate();
 const ratedBy = review?.rated_by || review?.rateable;
 const avatar = ratedBy?.profile_image;
 const date = review?.updated_at;
 const description = review?.comment;
 const name = `${ratedBy?.first_name} ${ratedBy?.last_name}`;
 const value = review?.star_rating_count;
 const username = ratedBy?.username;
 const engagementTitle = review?.engagement?.job_without_relationship?.job_title;
 const engagementCoverImg = review?.engagement?.job_without_relationship?.cover_image;
 const id = review?.engagement_id;
 return (
  <div>
   <div className="border p-4 rounded-2xl">
    <div className="flex items-center justify-between">
     <div className="flex items-start space-x-3 mb-3">
      <Avatar src={avatar} />
      <div className="flex-col flex justify-between">
       <p className="font-semibold">{name}</p>
       <p className="text-xs text-grey">{username}</p>
       <CustomRating responsive value={value} />
      </div>
     </div>
     {showDelete && (
      <IconButton
       sx={{
        bgcolor: "#FAEBED",
        borderRadius: "100%",
        width: { xs: "auto", md: "56px" },
        height: { xs: "auto", md: "56px" },
       }}
      >
       <DeleteIcon />
      </IconButton>
     )}
    </div>
    <div
     onClick={() => navigate(`/engagements/${id}`, { replace: true })}
     className="cursor-pointer flex gap-2 mb-2 items-center bg-grey100 p-2 justify-between rounded-lg"
    >
     <div className="h-8 w-10 rounded">
      <img src={engagementCoverImg} alt="serviceTable" className="h-full w-full rounded" />
     </div>
     <p className="text-black font-semibold text-sm">{engagementTitle}</p>
    </div>

    <p className="text-grey30 text-sm">{description}</p>
    <p className="text-grey40 mt-3 text-sm">{moment(date).format("MMMM D, YYYY")}</p>
   </div>
  </div>
 );
};
export default EachClientReview;
