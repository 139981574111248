import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, Skeleton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ValidatedInput from "components/forms/ValidatedInput";
import { FormProvider, useForm } from "react-hook-form";
import { useGetAllServiceChargeQuery, useUpdateSingleServiceChargeOptionMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const ServiceCharge = () => {
    const { data: allOptions, isLoading: isAllOptionsLoading } = useGetAllServiceChargeQuery();
    const methods = useForm({ mode: "all" });
    const { trigger, setValue, formState } = methods;
    const [clickedId, setClickedId] = useState(null);
    const [activeTab, setActiveTab] = useState("service_charge");
    const errors = formState.errors;
    const [update, { isLoading: isUpdateLoading }] = useUpdateSingleServiceChargeOptionMutation();
    useEffect(() => {
        if (allOptions) {
            allOptions?.data.forEach(({ option, value }) => {
                setValue(option, value);
            });
        }
    }, [allOptions, setValue]);

    console.log(allOptions);

    return (
        <div>
            <p className="font-semibold text-grey30 text-xl mb-10">Service Charge</p>
            <TabContext value={activeTab}>
                <TabList
                    variant="scrollable"
                    value={activeTab}
                    className="client_dashboardfourth mb3 w-fit rounded-2xl border border-[#DCDCE5] p-2"
                    onChange={(_, newValue) => {
                        setActiveTab(newValue);
                    }}
                    aria-label="basic tabs example"
                >
                    <Tab
                        sx={{
                            "&.Mui-selected": {
                                backgroundColor: "#F6F6F7 !important",
                                border: "1px solid #DCDCE5 !important",
                                borderRadius: "12px !important",
                                color: "#181820 !important",
                                fontWeight: "semibold !important",
                            },
                            "&.MuiTabs-indicator": {
                                "&.MuiTabs-indicator": {
                                    backgroundColor: "none !important",
                                },
                            },
                        }}
                        label={<p>service charge</p>}
                        value="service_charge"
                    />
                    <Tab
                        sx={{
                            "&.Mui-selected": {
                                backgroundColor: "#F6F6F7 !important",
                                border: "1px solid #DCDCE5 !important",
                                borderRadius: "12px !important",
                                color: "#181820 !important",
                                fontWeight: "semibold !important",
                            },
                            "&.MuiTabs-indicator": {
                                backgroundColor: "none !important",
                            },
                        }}
                        label={<p>Currency Conversion</p>}
                        value="currency_conversion"
                    />
                </TabList>
            </TabContext>
            <TabContext value={activeTab}>
                <TabPanel value="service_charge">
                    <div className="flex-grow max-w-[100%] md:max-w-[95%] lg:max-w-[55%]">
                        <FormProvider {...methods}>
                            <form className="flex mt-4 flex-col gap-y-10">
                                {isAllOptionsLoading
                                    ? [1, 2, 3, 4, 5, 6].map((val) => <Skeleton key={val} variant="rounded" height={70} />)
                                    : allOptions?.data?.slice(1)?.map(({ description, option, label, id, symbol }) => (
                                        <div key={id} className="flex flex-col">
                                            <p className="font-bold text-grey10 -mb-4">{description}</p>
                                            <div className="w-full">
                                                <div className={`flex w-full gap-4 ${errors[option] ? "items-start" : "items-center"}`}>
                                                    <ValidatedInput
                                                        type="number"
                                                        name={option}
                                                        label={label}
                                                        // variant="nil"
                                                        prefixIcon={
                                                            symbol
                                                            // id === 9
                                                            // ? <p className="!text-[13px] !font-medium">Day:</p>
                                                            // : (id === 8 || id === 1 || id === 6)
                                                            //     ? <p>%</p>
                                                            //     : <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            //         <path
                                                            //             d="M11.9 4.526V3.742H10.374V0.199999H8.974V3.742H5.936L3.094 0.199999H1.946V3.742H0.42V4.526H1.946V5.674H0.42V6.458H1.946V10H3.332V6.458H6.37L9.226 10H10.374V6.458H11.9V5.674H10.374V4.526H11.9ZM3.332 2.678L4.186 3.742H3.332V2.678ZM3.332 5.674V4.526H4.816L5.74 5.674H3.332ZM8.974 5.674H7.49L6.566 4.526H8.974V5.674ZM8.974 7.522L8.12 6.458H8.974V7.522Z"
                                                            //             fill="#53536F"
                                                            //         />
                                                            //     </svg>
                                                        }
                                                    />
                                                    <LoadingButton
                                                        loadingIndicator={
                                                            <CircularProgress
                                                                sx={{
                                                                    color: "#ffffff",
                                                                }}
                                                                size="1.2rem"
                                                            />
                                                        }
                                                        sx={{ mt: 5.5 }}
                                                        variant="contained"
                                                        loading={id === clickedId && isUpdateLoading}
                                                        onClick={async () => {
                                                            setClickedId(id);
                                                            trigger(option).then(async (res) => {
                                                                // console.log({
                                                                //             id,
                                                                //             body: {
                                                                //                 option,
                                                                //                 value: methods.getValues(option),
                                                                //                 description,
                                                                //                 label,
                                                                //             },
                                                                //         });
                                                                if (res) {
                                                                    try {
                                                                        let res = await update({
                                                                            id,
                                                                            body: {
                                                                                option,
                                                                                value: methods.getValues(option),
                                                                                description,
                                                                                label,
                                                                            },
                                                                        }).unwrap();
                                                                        if (res.error === false) showToast(res?.message);
                                                                    } catch (error) {
                                                                        handleError(error);
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        Update
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </form>
                        </FormProvider>
                    </div>
                </TabPanel>
                <TabPanel value="currency_conversion">
                    <div className="flex-grow max-w-[100%] md:max-w-[95%] lg:max-w-[55%]">
                        <FormProvider {...methods}>
                            <form className="flex mt-4 flex-col gap-y-10">
                                {isAllOptionsLoading
                                    ? [1, 2, 3,].map((val) => <Skeleton key={val} variant="rounded" height={70} />)
                                    : allOptions?.data?.slice(0, 1)?.map(({ description, option, label, id }) => (
                                        <div key={id} className="flex flex-col">
                                            <p className="font-bold text-grey10 -mb-4">{description}</p>
                                            <div className="w-full">
                                                <div className={`flex w-full gap-4 ${errors[option] ? "items-start" : "items-center"}`}>
                                                    <ValidatedInput
                                                        type="number"
                                                        name={option}
                                                        label={label}
                                                        prefixIcon={
                                                            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M3.976 13.68V0.519999H4.872V13.68H3.976ZM4.326 12.112C3.57933 12.112 2.86533 12 2.184 11.776C1.50267 11.5427 0.966 11.244 0.574 10.88L1.092 9.788C1.46533 10.1147 1.94133 10.3853 2.52 10.6C3.09867 10.8147 3.70067 10.922 4.326 10.922C4.89533 10.922 5.35733 10.8567 5.712 10.726C6.06667 10.5953 6.328 10.418 6.496 10.194C6.664 9.96067 6.748 9.69933 6.748 9.41C6.748 9.074 6.636 8.80333 6.412 8.598C6.19733 8.39267 5.91267 8.22933 5.558 8.108C5.21267 7.97733 4.83 7.86533 4.41 7.772C3.99 7.67867 3.56533 7.57133 3.136 7.45C2.716 7.31933 2.32867 7.156 1.974 6.96C1.62867 6.764 1.34867 6.50267 1.134 6.176C0.919333 5.84 0.812 5.41067 0.812 4.888C0.812 4.384 0.942667 3.922 1.204 3.502C1.47467 3.07267 1.88533 2.732 2.436 2.48C2.996 2.21867 3.70533 2.088 4.564 2.088C5.13333 2.088 5.698 2.16267 6.258 2.312C6.818 2.46133 7.30333 2.676 7.714 2.956L7.252 4.076C6.832 3.796 6.38867 3.59533 5.922 3.474C5.45533 3.34333 5.00267 3.278 4.564 3.278C4.01333 3.278 3.56067 3.348 3.206 3.488C2.85133 3.628 2.59 3.81467 2.422 4.048C2.26333 4.28133 2.184 4.54267 2.184 4.832C2.184 5.17733 2.29133 5.45267 2.506 5.658C2.73 5.86333 3.01467 6.02667 3.36 6.148C3.71467 6.26933 4.102 6.38133 4.522 6.484C4.942 6.57733 5.362 6.68467 5.782 6.806C6.21133 6.92733 6.59867 7.086 6.944 7.282C7.29867 7.478 7.58333 7.73933 7.798 8.066C8.01267 8.39267 8.12 8.81267 8.12 9.326C8.12 9.82067 7.98467 10.2827 7.714 10.712C7.44333 11.132 7.02333 11.4727 6.454 11.734C5.894 11.986 5.18467 12.112 4.326 12.112Z"
                                                                    fill="#53536F"
                                                                />
                                                            </svg>
                                                        }
                                                    />
                                                    <LoadingButton
                                                        loadingIndicator={
                                                            <CircularProgress
                                                                sx={{
                                                                    color: "#ffffff",
                                                                }}
                                                                size="1.2rem"
                                                            />
                                                        }
                                                        sx={{ mt: 5.5 }}
                                                        variant="contained"
                                                        loading={id === clickedId && isUpdateLoading}
                                                        onClick={async () => {
                                                            setClickedId(id);
                                                            trigger(option).then(async (res) => {
                                                                if (res) {
                                                                    try {
                                                                        let res = await update({
                                                                            id,
                                                                            body: {
                                                                                option,
                                                                                value: methods.getValues(option),
                                                                                description,
                                                                                label,
                                                                            },
                                                                        }).unwrap();
                                                                        if (res.error === false) showToast(res?.message);
                                                                    } catch (error) {
                                                                        handleError(error);
                                                                    }
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        Update
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </form>
                        </FormProvider>
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
};

export default ServiceCharge;
