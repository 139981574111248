import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
function BackBtn(props) {
  const navigate = useNavigate();
  return (
    <Button
      color={props.color || "secondary"}
      onClick={() => navigate(-1)}
      startIcon={<ArrowBackIcon />}
      sx={{
        padding: "0 !important",
        textTransform: "lowercase",
        mb: {
          xs: 0,
          md: 2,
        },
      }}
      {...props}
    >
      {props.text ? props.text : "back"}
    </Button>
  );
}

export default BackBtn;
