import { Button } from "@mui/material";
import ValidatedInput from "components/forms/ValidatedInput";
import Modal from "components/Modal";
import "./style.scss";
import { useForm, FormProvider } from "react-hook-form";

const BVNApprove = ({
  openModal,
  setOpenModal,
  btnColor,
  btn2Color,
  title,
  desc,
  buttonTitle,
  button2Title,
  btn2,
  setShowBVN,
}) => {
  const methods = useForm();

  const { setValue } = methods;

  const onSubmit = async () => {
    setOpenModal(false);
    setShowBVN((prev) => !prev);
    setValue("password", null);
  };

  return (
    <Modal
      hideCancel={true}
      openModal={openModal}
      closeModal={() => setOpenModal(!openModal)}
      title={title}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col  justify-between flex-grow"
        >
          <div className="w-full flex justify-center ">
            <p className="mt-4 font-semibold text-[20px] text-prop w-[290px] text-center ">
              {desc}
            </p>
          </div>
          <ValidatedInput
            label=""
            placeholder="Enter Your Password"
            type="password"
            name="password"
            rules={{
              required: "This field is required",
              minLength: {
                value: 11,
                message: "Incorrect Password",
              },
            }}
          />
          <div className="mt-[auto] ">
            <div className="flex items-center gap-4 justify-between ">
              {btn2 && (
                <Button
                  onClick={() => setOpenModal(!openModal)}
                  color={btn2Color}
                  variant="contained"
                  sx={{ width: "100%", mt: 2 }}
                >
                  {button2Title}
                </Button>
              )}
              <Button
                type="submit"
                color={btnColor}
                variant="contained"
                sx={{ width: "100%", mt: 2 }}
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};
export default BVNApprove;
