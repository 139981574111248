function TimeIcon(props) {
 const { width, height, color } = props;
 const defaultWidth = 32;
 const defaultHeight = 32;

 return (
  <svg
   width={width || defaultWidth}
   height={height || defaultHeight}
   viewBox="0 0 32 32"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
   {...props}
  >
   <path
    d="M16 12.668V18.0013L19.3334 20.0013M16 6.66797C9.74079 6.66797 4.66669 11.7421 4.66669 18.0013C4.66669 24.2605 9.74079 29.3346 16 29.3346C22.2592 29.3346 27.3334 24.2605 27.3334 18.0013C27.3334 11.7421 22.2592 6.66797 16 6.66797ZM16 6.66797V2.66797M13.3334 2.66797H18.6667M27.1053 7.45736L25.1053 5.45736L26.1053 6.45736M4.89471 7.45736L6.89471 5.45736L5.89471 6.45736"
    stroke={color || "#D61E34"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
   />
  </svg>
 );
}

export default TimeIcon;
