import {Button} from "@mui/material";
import Modal from "components/Modal";

const LogOutModal = ({open, close, onClick, btnText = "No continue", desc}) => {
  return (
    <Modal openModal={open} closeModal={close} maxWidth={500}>
      <p className="mt-10 text-center font-Bai text-2xl font-medium md:mt-20 md:text-4xl">
        Are you sure you want to log out?
      </p>
      {desc && (
        <p className="mt-4 text-center font-[15px] text-grey_30">{desc}</p>
      )}
      <div className="mt-auto grid grid-cols-2 gap-x-4">
        <Button variant="contained" color="primary" onClick={close}>
          {btnText}
        </Button>
        <Button variant="contained" color="error" onClick={onClick}>
          Yes, log out
        </Button>
      </div>
    </Modal>
  );
};

export default LogOutModal;
