import {useFormContext, get} from "react-hook-form";
import TextAreaField from "./TextAreaField";
import {useEffect, useState} from "react";

function ValidatedTextArea({
  name,
  required = true,
  rules = {},
  errMsg,
  showErrMsg = true,
  maxLength,
  minLength,
  ...otherProps
}) {
  const {
    register,
    formState: {errors},
    watch,
  } = useFormContext();
  const error = get(errors, name);
  const value = watch(name);
  const [valueLength, setvalueLength] = useState(value?.length || 0);
  useEffect(() => {
    setvalueLength(value?.length || 0);
  }, [value]);
  return (
    <div className="form-group-wrapper">
      <TextAreaField
        isInvalid={error}
        {...otherProps}
        {...register(name, {
          required: required ? "This field is required" : false,
          maxLength: {
            value: maxLength ? maxLength : null,
            message: "Maximum length exceeded",
          },
          minLength: {
            value: minLength ? minLength : null,
            message: `Minimum length is ${minLength}`,
          },
          onChange: e =>
            maxLength ? setvalueLength(e.target.value.length) : null,
          ...rules,
        })}
      />
      {maxLength && (
        <span
          className={`mt-2 font-Mulish text-[11px] font-[400] ${
            valueLength > 250 ? "text-[#D92D20]" : "text-grey_30"
          }`}
        >
          {valueLength} / {maxLength}
        </span>
      )}
      {showErrMsg && error && (
        <div className="input-err-msg">{error.message || errMsg}</div>
      )}
    </div>
  );
}

export default ValidatedTextArea;
