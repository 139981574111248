import Modal from "components/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteCampaignMutation } from "services/campaigns.api";
import { CircularProgress } from "@mui/material";

export default function DeleteCampaignModal({ campaign, openModal, setOpenModal }) {
 const methods = useForm();
 const { id } = useParams();
 const [deleteCampaign, { isLoading: isDeleting }] = useDeleteCampaignMutation();
 const navigate = useNavigate();

 //  console.log(campaign);

 const onSubmit = async () => {
  try {
   let res = await deleteCampaign(id).unwrap();
   showToast(res?.message);
   navigate("/campaigns");
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <Modal openModal={openModal} closeModal={() => setOpenModal(false)} title="Delete Campaign">
   <div className="">
    <img src={campaign?.campaign_channel?.icon} alt="" />
    <h4 className="text-[#8585A3] font-medium text-sm pt-2">{campaign?.campaign_source?.name}</h4>
    <p className="text-[#181820] text-[18px] font-semibold">{campaign?.campaign_channel?.name}</p>
   </div>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      fullWidth
      variant="contained"
      type="submit"
      loading={isDeleting}
      color="error"
      className="bg-red-500"
      sx={{ mt: "72px", bgColor: "red" }}
     >
      Delete
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
}
