import { LoadingButton } from "@mui/lab";
import { Avatar, Button, CircularProgress } from "@mui/material";
import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import MessageModal from "components/Modal/MessageModal";
import ShieldTickIcon from "components/Vectors/ShieldTickIcon";
import ValidatedInput from "components/forms/ValidatedInput";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useChangePasswordMutation, useGetLoggedInUserQuery } from "services";
import { logOutHandler, showToast } from "store/store.hooks";
import { getUserDetails, handleError } from "utils";

const ProfilePage = () => {
 const [showCompletedModal, setShowCompletedModal] = useState(false);
 const { data, isLoading: isFetchProfileLoading, isError, error } = useGetLoggedInUserQuery();
 const user = data?.data;
 const methods = useForm();
 const password = methods.watch("password");
 const [changePassword, { isLoading }] = useChangePasswordMutation();

 const onSubmit = async (body) => {
  try {
   const res = await changePassword(body).unwrap();
   showToast(res?.message);
   setShowCompletedModal(true);
   logOutHandler();
  } catch (error) {
   handleError(error);
  }
 };

 if (isFetchProfileLoading) return <Loader />;
 if (isError) return <ErrorMsg error={error} />;

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-white">
   <h1 className="title">Profile</h1>
   <div className="border p-4 rounded-lg mb-8 mt-5">
    <div className="flex justify-between">
     <Avatar sx={{ width: 100, height: 100 }} src={user?.profile_image} />
     <div className="flex gap-2 items-center">
      <Button component={Link} to="edit-profile" variant="contained" sx={{ px: "16px !important" }}>
       Edit Profile
      </Button>
     </div>
    </div>
    <div className="w-full max-w-[485px] flex flex-col mt-4 gap-4">
     <div className="flex justify-between">
      <p className="text-base w-full font-medium text-[#8585A3]">Full Name </p>
      <span className="self-start font-medium text-[#2C2C3A] w-full">{getUserDetails(user)?.name}</span>
     </div>
     {/* <div className="flex justify-between">
            <p className="text-base w-full font-medium text-[#8585A3]">Role </p>
            <span className="self-start text-[#2C2C3A] w-full">
              <Chip
                label={user?.roles[0]?.name}
                color="primaryInverse"
                sx={{borderRadius: "4px"}}
              />
            </span>
          </div> */}
     <div className="flex justify-between">
      <p className="text-base w-full font-medium text-[#8585A3]">Email </p>
      <span className="self-start font-medium text-[#2C2C3A] w-full">{user?.email}</span>
     </div>
     <div className="flex justify-between">
      <p className="text-base w-full font-medium text-[#8585A3]">Phone Number </p>
      <span className="self-start font-medium text-[#2C2C3A] w-full">{user?.phone_number || "_"}</span>
     </div>
     <div className="flex justify-between">
      <p className="text-base w-full font-medium text-[#8585A3]">Gender </p>
      <span className="self-start capitalize font-medium text-[#2C2C3A] w-full">{user?.gender || "N/A"}</span>
     </div>
    </div>
   </div>
   <FormProvider {...methods}>
    <p className="font-semibold text-xl text-grey10 my-4">Change Password</p>
    <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full space-y-8 max-w-[424px]">
     <ValidatedInput name="old_password" type="password" label="Current Password" placeholder="Your current password" />
     <div className="my-6">
      <ValidatedInput name="password" type="password" label="New Password" placeholder="Create new password" />
     </div>
     <ValidatedInput
      type="password"
      name="password_confirmation"
      label="Confirm Password"
      rules={{
       validate: (value) => value === password || "The passwords do not match",
      }}
      placeholder="Confirm your password"
     />
     <LoadingButton
      loading={isLoading}
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      type="submit"
      variant="contained"
     >
      Save Changes
     </LoadingButton>
    </form>
   </FormProvider>
   <CompletedModal open={showCompletedModal} close={() => setShowCompletedModal(false)} />
  </div>
 );
};

export default ProfilePage;
const CompletedModal = ({ open, close }) => {
 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   icon={<ShieldTickIcon />}
   title="Password Changed"
   description="You have successfully changed your password, this will be effected across all devices"
  />
 );
};
