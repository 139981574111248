import {useRef, useState, useEffect} from "react";
import {generateId} from "utils";
import {getRect} from "hooks/useRect";
import {callingCodes} from "./callingCodes";

function disableScroll() {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
}

function enableScroll() {
  document.body.style.overflow = "auto";
  document.body.style.userSelect = "auto";
}

const PhoneInputField = ({
  placeholder = "00 0000 0000",
  defaultValue,
  onChangeHandler = () => {},
  onBlur = () => {},
  disabled = false,
  ...props
}) => {
  const id = generateId();

  const [inputValue, setinputValue] = useState({
    code: "234",
    number: "",
  });
  const [selectOpen, setSelectOpen] = useState(false);
  const [query, setQuery] = useState("");

  const parentRef2 = useRef(null);
  const ref = useRef();
  // remove dropdown when outside box is clicked
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (selectOpen && ref.current && !ref.current?.contains(e.target)) {
        setSelectOpen(false);
        enableScroll();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [selectOpen]);

  useEffect(() => {
    if (defaultValue) {
      let number = defaultValue.replace(/\D/g, "").slice(-10);
      let code = defaultValue.replace(/\D/g, "").split(number).join("");
      setinputValue({
        code,
        number,
      });
    }
  }, [defaultValue]);

  const handleInput = e => {
    let text = e.target.value;
    setinputValue(prev => {
      onChangeHandler(prev.code + text);
      return {...prev, number: text};
    });
  };

  const handleSelectCode = code => {
    setinputValue(prev => {
      onChangeHandler(code + prev.number);
      return {...prev, code: code};
    });
    setSelectOpen(false);
    enableScroll();
  };

  const selected = callingCodes.find(
    country => country.callingCode === (inputValue.code || "234")
  );
  const filteredCallingCodes = query.trim()
    ? callingCodes.filter(country =>
        country.name.toLowerCase().includes(query.trim().toLowerCase())
      )
    : callingCodes;

  const [styles, setstyles] = useState({
    top: "0px",
    left: "0px",
    width: "0px",
  });

  const openSelectHandler = () => {
    let rect = getRect(parentRef2.current);
    let top = Math.round(rect.top) + Math.round(rect.height + 10);

    setstyles({
      top: window.innerHeight - 270 > top ? top + "px" : top - 320 + "px",
      left: Math.round(rect.left) + "px",
      width: rect.width + "px",
    });
    disableScroll();
    setSelectOpen(true);
  };

  return (
    <div className="w-full">
      <div
        ref={parentRef2}
        className="h-12 rounded-lg w-full flex items-center text-base font-normal px-2 select-none"
      >
        <div
          className={`flex items-center space-x-[0.3125rem] border-[#DDE2E5] border-r pr-2 ${
            disabled ? "pointer-events-none" : "cursor-pointer"
          }`}
          onClick={() => (disabled ? {} : openSelectHandler())}
        >
          <div className="flex space-x-2 items-center">
            <span>+{selected?.callingCode}</span>
            <img
              className="w-4 h-4"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${selected?.alpha2Code}.svg`}
              alt={selected?.name}
            />
            <svg
              width="7"
              height="4"
              viewBox="0 0 7 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.289025 1.48938L2.81392 3.74524C3.19412 4.08492 3.80828 4.08492 4.18848 3.74524L6.71338 1.48938C7.32754 0.940664 6.88885 0 6.02122 0H0.97143C0.103801 0 -0.325139 0.940664 0.289025 1.48938Z"
                fill="#858C94"
              />
            </svg>
          </div>
        </div>
        <input
          // required={true}
          type="tel"
          autoComplete="phone"
          onInput={handleInput}
          value={inputValue.number}
          placeholder={placeholder}
          maxLength={10}
          {...props}
          disabled={disabled}
          className="outline-none pl-2 focus:outline-none flex-grow w-auto"
        />
        {selectOpen && (
          <div
            style={{...styles, zIndex: 4000}}
            className={`rounded fixed bg-white shadow-xl py-2 border border-gray-100 ${
              disabled ? "pointer-events-none" : "pointer-events-auto"
            }`}
            ref={ref}
          >
            <div className="px-2">
              <input
                type="search"
                value={query}
                onChange={e => setQuery(e.target.value)}
                placeholder="Search"
                className="border-[#DDE2E5] rounded border focus:outline-none w-full py-1 px-2 mb-2"
              />
            </div>
            <div className="max-h-48 overflow-y-auto px-1">
              {filteredCallingCodes.map((country, countryIndex) => (
                <div
                  key={`calling_code-${id}_${countryIndex}`}
                  className="flex items-center justify-between w-full py-1 px-2 hover:bg-gray-200 rounded bg-opacity-25 cursor-pointer"
                  onClick={() => handleSelectCode(country.callingCode)}
                >
                  <span className="text-sm font-medium flex items-center">
                    <img
                      className="w-4 h-4 mr-2"
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${country?.alpha2Code}.svg`}
                      alt={country?.name}
                    />
                    <span>{country.name}</span>
                  </span>
                  <span>+{country.callingCode}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectOpen && <div className="react-overlay" />}
    </div>
  );
};

export default PhoneInputField;
