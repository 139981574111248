import { Grid } from "@mui/material";
import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import StatCard from "components/StatCard";
import ServicesIcon from "components/Vectors/ServicesIcon";

const ServiceCards = ({ data, error, isLoading }) => {
  // console.log(data);

  if (isLoading) {
    <Loader />;
  }
  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Total Services"
          value={data?.all_services}
          iconBg="bg-primary"
          icon={<ServicesIcon width={32} height={32} color="white" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Active Services"
          value={data?.active_services}
          iconBg="bg-success-100"
          icon={<ServicesIcon width={32} height={32} color="#00A04A" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Promoted Services"
          value={data?.promoted_services}
          iconBg="bg-gray-100"
          icon={<ServicesIcon width={32} height={32} color="#8585A3" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Archived Services"
          value={data?.archived_services}
          iconBg="bg-gray-100"
          icon={<ServicesIcon width={32} height={32} color="#8585A3" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Disabled Services"
          value={data?.suspended_services}
          iconBg="bg-error-100"
          icon={<ServicesIcon width={32} height={32} color="#D61E34" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Drafted Services"
          value={data?.drafted_services}
          iconBg="bg-[#E8E8FC]"
          icon={<ServicesIcon width={32} height={32} color="#0C0C6A" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Influencer's Services"
          value={"-"}
          iconBg="bg-primary"
          icon={<ServicesIcon width={32} height={32} color="white" />}
        />
      </Grid>
      <Grid item xs={12} lg={3} md={4}>
        <StatCard
          name="Creator's Services"
          value={"-"}
          iconBg="bg-success-100"
          icon={<ServicesIcon width={32} height={32} color="#00A04A" />}
        />
      </Grid>
    </Grid>
  );
};
export default ServiceCards;
