import { Fade, IconButton, Slide, Chip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import "./style.scss";
const Modal = ({
 children,
 closeModal,
 maxWidth = 500,
 openModal = true,
 showCancel = true,
 showChip = false,
 hideScroll,
 title,
 chipLabel,
 chipColor,
 chipBgColor,
}) => {
 return (
  <Fade in={openModal}>
   <div onClick={(e) => e.target === e.currentTarget && closeModal()} className="modal-wrap">
    <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
     <div style={{ maxWidth }} className={`modal-content ${hideScroll ? "noScrollBar" : "scrollbar-style"}`}>
      <div className="flex space-x-3">
       <p className="modal-title mt-[-4px] ">{title}</p>{" "}
       {showChip && <Chip label={chipLabel} sx={{ backgroundColor: chipBgColor, color: chipColor }} />}
      </div>
      {showCancel && (
       <IconButton className="close-modal-btn" onClick={closeModal}>
        <ClearIcon />
       </IconButton>
      )}
      {children}
     </div>
    </Slide>
   </div>
  </Fade>
 );
};

export default Modal;
