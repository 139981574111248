import {forwardRef} from "react";
import "./style.scss";

const TextAreaField = forwardRef(
  (
    {
      label,
      height,
      className = "",
      spaceY = true,
      variant = "free",
      bg = "white",
      isInvalid,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={`form-group ${isInvalid ? "invalid" : ""} textarea-wrap ${
          height ? height : "min-h-[120px]"
        } bg-${bg} ${
          !spaceY ? "" : variant === "free" ? "mt-12" : "mt-5"
        }  ${className}`}
      >
        {label && (
          <label
            className={`font-medium text-gray-500 ${
              variant === "condensed" ? "-top-3" : "-top-7 -ml-4"
            }  px-2 text-sm`}
          >
            {label}{" "}
            {props.required ? <span className="text-error">*</span> : ""}
          </label>
        )}
        <textarea
          ref={ref}
          className={`focus:outline-0 placeholder-neutral bg-transparent w-full z-10 resize-none  ${
            height ? height : "min-h-[120px]"
          }`}
          {...props}
        />
      </div>
    );
  }
);
export default TextAreaField;
