import UserDisplay from "components/TableComponent/UserDisplay";
import moment from "moment";
import { getStatusColor, getUserDetails, toCurrency } from "utils";

function CampaignTransactionDetailsModal({ setopenModal, data }) {
 return (
  <div className=" bg-white top-4 right-4  rounded-xl">
   <header className="flex itecen justify-between border-b border-b-[#DCDCE5] p-6">
    <h3 className="text-[#424257] font-semibold text-xl">Campaign Transaction Detials</h3>
    <button onClick={() => setopenModal(false)}>
     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M22.3637 11.0501L11.05 22.3638C10.6635 22.7504 10.0224 22.7504 9.63582 22.3638C9.24927 21.9773 9.24927 21.3362 9.63582 20.9496L20.9495 9.63592C21.3361 9.24937 21.9772 9.24937 22.3637 9.63592C22.7503 10.0225 22.7503 10.6636 22.3637 11.0501Z"
       fill="#2C2C3A"
      />
      <path
       d="M22.3633 22.3641C21.9767 22.7506 21.3356 22.7506 20.949 22.3641L9.63533 11.0504C9.24878 10.6638 9.24878 10.0227 9.63533 9.63615C10.0219 9.2496 10.663 9.2496 11.0495 9.63616L22.3633 20.9499C22.7498 21.3364 22.7498 21.9775 22.3633 22.3641Z"
       fill="#2C2C3A"
      />
     </svg>
    </button>
   </header>

   <div className="flex items-center justify-center mt-8 flex-col">
    <h4 className="text-[#181820] text-2xl font-semibold">{toCurrency(data?.amount_paid)}</h4>
    <p className="text-[#53536F] font-medium text-sm">
     {moment(data?.campaign?.created_at).format("MMMM DD, YYYY, h:mm a")}
    </p>
   </div>

   <div className="p-6">
    <div className="bg-[#F9F9FB] border border-[#DCDCE5]  p-4 rounded-lg flex items-center gap-2">
     <img src={data?.campaign?.campaign_channel?.icon} alt="" className="h-[2rem] w-[2rem]" />
     <div>
      <h4 className="text-[#8585A3] text-xs font-medium">{data?.campaign?.campaign_source?.name}</h4>
      <p className="text-[#181820] text-sm font-semibold">{data?.campaign?.campaign_channel?.name}</p>
     </div>
    </div>

    <div className="pt-6">
     <h4 className="text-[#8585A3] text-sm font-medium">Client</h4>
     <UserDisplay
      name={getUserDetails(data?.campaign?.client).name}
      email={data?.campaign?.client?.username}
      img={!data?.campaign?.client?.profile_image ? "/default_avatar.png" : data?.campaign?.client?.profile_image}
     />
    </div>

    <div className="pt-6 flex items-center justify-between">
     <div>
      <h4 className="text-[#8585A3] text-sm font-medium">Hookik Charges</h4>
      <p className="text-[#2C2C3A] text-sm font-semibold pt-2">{toCurrency(data?.campaign?.service_charge)}</p>
     </div>
     <div className="text-right">
      <h4 className="text-[#8585A3] text-sm font-medium">Transaction Ref</h4>
      <p className="text-[#2C2C3A] text-sm font-semibold pt-2">{data?.transaction_ref}</p>
     </div>
    </div>

    <div className="pt-6 flex items-center justify-between">
     <div>
      <h4 className="text-[#8585A3] text-sm font-medium">Unit Price</h4>
      <p className="text-[#2C2C3A] text-sm font-semibold pt-2">{toCurrency(data?.campaign?.amount_per_influencer)}</p>
     </div>
     <div className="text-right">
      <h4 className="text-[#8585A3] text-sm font-medium">Influencers</h4>
      <p className="text-[#2C2C3A] text-sm font-semibold pt-2">{data?.campaign?.number_of_influencers_needed}</p>
     </div>
    </div>

    <div className="pt-6 flex items-center justify-between">
     <div>
      <h4 className="text-[#8585A3] text-sm font-medium">Status</h4>
      <span
       style={{ color: getStatusColor(data?.status) }}
       className="h-[24px] flex items-center justify-center px-2 rounded-[4px] bg-white uppercase border border-[#DCDCE5] text-xs font-semibold mt-2"
      >
       {data?.status}
      </span>
     </div>
     <div className="text-right">
      <h4 className="text-[#8585A3] text-sm font-medium">Influencers Paid</h4>
      <p className="text-[#2C2C3A] text-sm font-semibold pt-2">{data?.paid_influencers_count}</p>
     </div>
    </div>
   </div>
  </div>
 );
}

export default CampaignTransactionDetailsModal;
