import {Button} from "@mui/material";
import {ReactComponent as Icon} from "assets/icons/pwd-successful.svg";
import {Link} from "react-router-dom";
import BasicAuthLayout from "templates/AuthLayout/BasicAuthLayout";

function SetPasswordSuccessful() {
  return (
    <BasicAuthLayout>
      <div className="max-w-[430px] text-sm text-center">
        <Icon className="mx-auto mb-6 w-[100px] h-[100px]" />
        <h1 className="title text-[#2C2C3A]">Reset successful</h1>
        <p>Your password reset is successful</p>
        <Button
          component={Link}
          to={`/login`}
          sx={{width: "100%", mt: 6, mb: 2}}
          variant="contained"
        >
          Proceed to Login
        </Button>
      </div>
    </BasicAuthLayout>
  );
}

export default SetPasswordSuccessful;
