import {Avatar, Button, Chip} from "@mui/material";
import BackBtn from "components/BackBtn";
import {useParams} from "react-router-dom";
import AddSubCatgryModal from "./Modals/AddSubCatgryModal";
import {useState} from "react";
import MyDataTable, {
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import moment from "moment";
import EditIcon2 from "components/Vectors/EditIcon2";
import TrashIcon from "components/Vectors/TrashIcon";
import EditSubCatgryModal from "./Modals/EditSubCatgryModal";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteSubCategoryModal from "./Modals/DeleteSubCategoryModal";
import {useGetSingleCategoryQuery} from "services";
import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import CreateCategoryModal from "./Modals/CreateCategoryModal";
import ServicesIcon from "components/Vectors/ServicesIcon";

const AdminSettingsCategory = () => {
  const [openSubCategoryModal, setOpenSubCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [openEditSubCategoryModal, setOpenEditSubCategoryModal] =
    useState(false);
  const [openDeleteSubCategoryModal, setOpenDeleteSubCategoryModal] =
    useState(false);
  const [editData, setEditData] = useState(null);

  const {id, userType} = useParams();
  const {data, error, isLoading} = useGetSingleCategoryQuery(id);
  if (isLoading) return <Loader />;
  if (error) return <ErrorMsg error={error} />;

  return (
    <section className="bg-[#F9F9FB] md:px-[30px] md:py-[40px] h-full">
      <h1 className="font-semibold text-prop text-[32px]">Settings</h1>
      <div className="mt-[24px] bg-[#fff] px-[40px] py-[24px] rounded-[12px]">
        <div className="flex justify-between items-center">
          <div>
            <BackBtn text="back to categories" />
            <h2 className="font-semibold text-[20px] text-prop text-grey30">
              {data?.data?.name}
            </h2>
          </div>
          <Button
            variant="contained"
            onClick={() => setShowEditCategoryModal(true)}
          >
            Edit Category
          </Button>
        </div>
        <div className="md:w-[80%]">
          <div className="mt-5 flex items-center justify-between">
            <div className="flex gap-x-3">
              <Avatar
                sx={{width: "50px", height: "50px", borderRadius: "8px"}}
                src={data?.data?.cover_photo}
              />
              <div className="flex justify-end flex-col gap-y-1">
                <p className="flex gap-x-2">
                  <Chip
                    color={
                      data?.data?.status === "Active"
                        ? "successInverse"
                        : "default"
                    }
                    label={data?.data?.status}
                  />
                  {data?.data?.featured === "Yes" && (
                    <Chip
                      label="Featured"
                      color="greyBorder"
                      sx={{color: "#8937CE"}}
                      avatar={
                        <CheckCircleOutlineOutlinedIcon
                          sx={{
                            color: "#8937CE !important",
                            width: "18px !important",
                            height: "18px !important",
                          }}
                        />
                      }
                    />
                  )}
                  <Chip
                    label={`Created: ${moment(data?.data?.created_at).format(
                      "DD/MM/YYYY"
                    )}`}
                    sx={{color: "#69698C"}}
                    avatar={
                      <CheckCircleOutlineOutlinedIcon
                        sx={{
                          width: "18px !important",
                          height: "18px !important",
                        }}
                      />
                    }
                  />
                  <Chip
                    label={`Modified: ${moment(data?.data?.updated_at).format(
                      "DD/MM/YYYY"
                    )}`}
                    sx={{color: "#69698C"}}
                    avatar={
                      <CheckCircleOutlineOutlinedIcon
                        sx={{
                          width: "18px !important",
                          height: "18px !important",
                        }}
                      />
                    }
                  />
                </p>
              </div>
            </div>
            <Button
              variant="contained"
              color="primaryInverse"
              onClick={() => setOpenSubCategoryModal((prev) => !prev)}
            >
              Add Sub Category
            </Button>
          </div>
          <div className="general-border my-6 text-sm p-6">
            <p className="mb-2 text-grey40 text-xs">Description</p>
            {data?.data?.description}
          </div>
          <div className="p-4 bg-white py-6 border rounded-xl mt-5">
            <div className="flex justify-between flex-wrap space-y-3"></div>

            <MyDataTable
              scrollX={false}
              options={{
                searchOpen: true,
                viewColumns: false,
                filter: false,
              }}
              isLoading={isLoading}
              emptyData={{
                icon: ServicesIcon,
                message: "No Sub categories found",
                extraText: "There are no sub categories yet.",
              }}
              headCells={[
                {
                  name: "sn",
                  label: "S/N",
                  options: {
                    customBodyRender: (value) => (
                      <div className="pl-3"> {value} </div>
                    ),
                  },
                },
                {name: "subCategory", label: "Sub Category"},
                {
                  name: "lastModified",
                  label: "Last Modified",
                },
                {
                  name: "status",
                  label: "Status",
                  options: {
                    customBodyRender: (value) => (
                      <Chip
                        color={
                          value?.toLowerCase() === "active"
                            ? "successInverse"
                            : "errorInverse"
                        }
                        sx={{minWidth: "fit-content"}}
                        label={`${value}`}
                      />
                    ),
                  },
                },
                {
                  name: "actions",
                  label: "",
                  options: {
                    customBodyRender: (value) => (
                      <TableDropDown tableId={"table"}>
                        <Button
                          startIcon={<EditIcon2 />}
                          color="inherit"
                          onClick={() => {
                            setOpenEditSubCategoryModal((prev) => !prev);
                            setEditData(value);
                          }}
                        >
                          Edit Subcategory
                        </Button>
                        <Button
                          startIcon={<TrashIcon />}
                          color="error"
                          onClick={() => {
                            setEditData(value);
                            setOpenDeleteSubCategoryModal(true);
                          }}
                        >
                          Delete Subcategory
                        </Button>
                      </TableDropDown>
                    ),
                  },
                },
              ]}
              data={data?.data?.sub_categories?.map((category, i) => ({
                sn: i + 1,
                subCategory: category?.name,
                lastModified: moment(category?.updated_at).format(
                  "MMM Do YYYY, h:mm a"
                ),
                status: category?.status,
                actions: category,
              }))}
            />
          </div>
        </div>
      </div>
      <CreateCategoryModal
        openModal={showEditCategoryModal}
        setopenModal={setShowEditCategoryModal}
        category={data?.data}
        isEditing
      />
      <AddSubCatgryModal
        id={id}
        userType={userType}
        openSubCategoryModal={openSubCategoryModal}
        handleCloseSubCategory={() => setOpenSubCategoryModal((prev) => !prev)}
        handleOpenSubCategory={() => setOpenSubCategoryModal((prev) => !prev)}
      />
      <EditSubCatgryModal
        id={id}
        data={editData}
        userType={userType}
        openSubCategoryModal={openEditSubCategoryModal}
        handleCloseSubCategory={() =>
          setOpenEditSubCategoryModal((prev) => !prev)
        }
        handleOpenSubCategory={() =>
          setOpenEditSubCategoryModal((prev) => !prev)
        }
      />
      <DeleteSubCategoryModal
        open={openDeleteSubCategoryModal}
        close={() => setOpenDeleteSubCategoryModal(false)}
        detail={editData}
      />
    </section>
  );
};
export default AdminSettingsCategory;
