import {useDeleteAdminMutation} from "services";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import {FormProvider, useForm} from "react-hook-form";
import {showToast} from "store/store.hooks";
import {handleError} from "utils";
import Modal from "components/Modal";
import {LoadingButton} from "@mui/lab";
import {useNavigate, useParams} from "react-router-dom";
import { CircularProgress } from "@mui/material";

const DeleteAdminModal = ({openModal, setopenModal}) => {
  const methods = useForm();
  const {id} = useParams();
  const navigate = useNavigate();
  const [deleteAdmin, {isLoading: isDeleting}] = useDeleteAdminMutation();
  const onSubmit = async (body) => {
    try {
      let res = await deleteAdmin({body, id}).unwrap();
      showToast(res.message);
      setopenModal(false);
      methods.reset();
      navigate("/administrators");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      openModal={openModal}
      closeModal={() => setopenModal(false)}
      title="Delete Account"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ValidatedTextArea
            maxLength={160}
            name="reason_for_deletion"
            label="Message"
          />
          <LoadingButton
          loadingIndicator={
									<CircularProgress
										sx={{
											color: "#fff",
										}}
										size="1.2rem"
									/>
								}
            variant="contained"
            fullWidth
            color="error"
            type="submit"
            loading={isDeleting}
            sx={{marginTop: 8}}
          >
            Send Message & Delete
          </LoadingButton>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default DeleteAdminModal;
