import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Avatar, Button, Chip, IconButton } from "@mui/material";
import MyAccordion from "components/MyAccordion";
import moment from "moment";
import { useState } from "react";
// import AcceptFinalSubmissionModal from "../Modals/AcceptFinalSubmissionModal";
// import DeclineFinalSubmissionModal from "../Modals/DeclineFinalSubmission";

const FinalFile = ({ isProvider, submissions }) => {
 console.log(submissions);
 return (
  <div className="mt-8">
   <h4 className="mb-[18px] text-[24px] font-semibold text-grey10">
    Submission {submissions?.length > 0 ? " log" : ""}
   </h4>
   {submissions?.length > 0 ? (
    <MyAccordion
     bgcolor="#F9F9FB"
     data={submissions?.map((submission, index) => ({
      title: (
       <span className="flex w-full items-center justify-between">
        <span className="mr-24">Submission {index + 1}</span>

        <Chip
         color={
          submission?.status === "Approved"
           ? "successInverse"
           : submission?.status === "Declined"
           ? "errorInverse"
           : "default"
         }
         label={submission?.status}
         sx={{ borderRadius: "8px" }}
        />
       </span>
      ),
      content: <EachSubmission submission={submission} />,
     }))}
    />
   ) : (
    <div className="flex min-h-[250px] flex-col items-center justify-center rounded-2xl bg-grey100">
     <IconButton sx={{ bgcolor: "#F2F4F7", borderRadius: "8px" }}>
      <SaveAltOutlinedIcon color="#AAAAAA" />
     </IconButton>
     <p className="mt-3 font-semibold">Pending</p>
     <p className="mt-1 text-sm text-grey30">
      {isProvider ? "You have not uploaded a file yet" : "The Creator has not uploaded a file yet"}
     </p>
    </div>
   )}
  </div>
 );
};

export default FinalFile;

const EachSubmission = ({ submission, isProvider }) => {
 console.log(submission);
 const fileName = submission?.file_url?.split("/").pop();
 const [openAcceptFinalSubmissionModal, setOpenAcceptFinalSubmissionModal] = useState(false);
 const [openDeclineFinalSubmissionModal, setOpenDeclineFinalSubmissionModal] = useState(false);
 return (
  <div className="ml-2">
   {submission?.file_url ? (
    <div className="flex items-center justify-between">
     <div className="flex items-center gap-x-2">
      <Avatar src={submission?.file_url} sx={{ width: "64px", height: "64px", borderRadius: "12px" }} />
      <div className="flex flex-col gap-y-1 font-medium">
       <span className="text-grey_10">{fileName}</span>
       <span className="text-grey_30">{submission?.file_size}</span>
      </div>
     </div>
     <div className="flex items-center gap-2">
      <IconButton
       onClick={() => {
        window.open(submission?.file_url, "_blank");
       }}
       sx={{ bgcolor: "#F2F4F7", borderRadius: "8px" }}
      >
       <VisibilityOutlinedIcon color="primary" />
      </IconButton>
      <IconButton
       download={true}
       href={submission?.file_url}
       target="_blank"
       sx={{ bgcolor: "#F2F4F7", borderRadius: "8px" }}
      >
       <SaveAltOutlinedIcon color="primary" />
      </IconButton>
     </div>
    </div>
   ) : (
    <p className="text-prop my-3 text-lg font-medium text-grey_10">No file uploaded</p>
   )}

   <div className="mt-4 flex items-center justify-between">
    <p className="font-Bai text-xl font-medium text-grey_30">Comment</p>
    <p className="text-sm font-medium text-grey_20">{moment(submission?.created_at).format("DD MMM yyy, hh:mm a")}</p>
   </div>
   <p className="my-1 font-medium text-grey_20">{submission?.comment}</p>
   {/* {!isProvider && submission?.status === "Pending" && (
        <div className="grid grid-cols-2">
          <Button
            onClick={() => setOpenDeclineFinalSubmissionModal(true)}
            variant="contained"
            color="errorInverse"
          >
            Decline
          </Button>
          <Button
            onClick={() => setOpenAcceptFinalSubmissionModal(true)}
            variant="contained"
          >
            Accept
          </Button>
        </div>
      )} */}
   {/* <AcceptFinalSubmissionModal
        openModal={openAcceptFinalSubmissionModal}
        closeModal={() => setOpenAcceptFinalSubmissionModal(false)}
      />
      <DeclineFinalSubmissionModal
        openModal={openDeclineFinalSubmissionModal}
        setOpenModal={setOpenDeclineFinalSubmissionModal}
      /> */}
  </div>
 );
};
