const EmptyTickets = () => {
 return (
  <div className="flex flex-col items-center justify-center w-full mt-10 border border-[#DCDCE5] max-w-[21.75rem] bg-white rounded-2xl p-8 gap-4">
   <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="120" height="120" rx="16" fill="#EAEAF0" />
    <path d="M23 76C23 71.5817 26.5817 68 31 68H48V91H31C26.5817 91 23 87.4183 23 83V76Z" fill="#DCDCE5" />
    <path d="M50 68H88C92.4183 68 96 71.5817 96 76V83C96 87.4183 92.4183 91 88 91H50V68Z" fill="#DCDCE5" />
    <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M23 35C18.5817 35 15 38.5817 15 43V48.2011C20.5013 48.4175 24.8947 52.9457 24.8947 58.5C24.8947 64.0543 20.5013 68.5825 15 68.7989V74C15 78.4183 18.5817 82 23 82H46.3333V35H23Z"
     fill="#8585A3"
    />
    <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M96.8772 35H47.9824V82H96.8772C101.295 82 104.877 78.4183 104.877 74V68.7989C104.74 68.8043 104.603 68.807 104.465 68.807C98.7725 68.807 94.1579 64.1924 94.1579 58.5C94.1579 52.8076 98.7725 48.193 104.465 48.193C104.603 48.193 104.74 48.1957 104.877 48.2011V43C104.877 38.5817 101.295 35 96.8772 35Z"
     fill="#A2A2B9"
    />
   </svg>

   <h4 className="text-black font-semibold text-2xl">No Tickets</h4>
  </div>
 );
};

export default EmptyTickets;
