const ToggleSwitch = ({
  toggleState,
  setToggleState,
  parentClass,
  ...inputProps
}) => {
  return (
    <div className={parentClass}>
      <label className={toggleState ? "toggle__active" : "toggle__switch"}>
        <input
          {...inputProps}
          onChange={setToggleState}
          type="checkbox"
          checked={toggleState}
        />
        <span className="toggler"></span>
      </label>
    </div>
  );
};
export default ToggleSwitch;
