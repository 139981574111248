import { useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { CircularProgress, } from "@mui/material";
import Modal from "components/Modal";
import AutoCompleteField from "components/forms/AutoCompleteField";
import ValidatedInput from "components/forms/ValidatedInput";
import { FormProvider, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useCreateSubTaskMutation, useEditSubTaskMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import ToggleSwitch from "components/ToggleSwitch";

const ManageSubTaskModal = ({ open, close, isEditing, subTask }) => {
  console.log(subTask);
  const { setValue, ...methods } = useForm({
    defaultValues: {
      title: subTask?.title || "",
      data_type_option: subTask?.data_type_option || "",
      status: subTask?.status,
      // data_type:
      //   subTask?.data_type_option === "url"
      //     ? subTask?.data_type || undefined
      //     : undefined,

      multiple_sub_task_data_types:
        // subTask?.data_type_option === "multiple" ?
        subTask?.multiple_sub_task_data_types?.map((item) => item.data_type) || undefined,
      // : undefined,
      submission_type:
        // subTask?.data_type_option === "url" ?
        subTask?.submission_type || undefined,
      // : undefined,

      // multiple_sub_task_submission_types:
      //   subTask?.data_type_option === "multiple"
      //     ? subTask?.multiple_sub_task_submission_types?.map(
      //       (item) => item.submission_type
      //     ) || undefined
      //     : undefined,
    },
  });

  const status = methods.watch("status");
  const [searchParams] = useSearchParams();
  const channelId = searchParams.get("channel");
  const [createSubtask, { isLoading: isCreating }] = useCreateSubTaskMutation();
  const [editSubtask, { isLoading: isEditingSubTask }] = useEditSubTaskMutation();
  const dataTypesOptions = useMemo(() => ["Audio", "Image", "Video", "URL", "Text"], []);
  const submissionOptions = useMemo(
    () => [
      {
        label: "URL Only",
        value: "url"
      },
      {
        label: "URL and Screenshot",
        value: "url_screenshot"
      },
      {
        label: "URL and Summary",
        value: "url_summary"
      },
      {
        label: "Screenshot and Summary",
        value: "screenshot_summary"
      },
      {
        label: "Screenshot Only",
        value: "screenshot"
      },
      {
        label: "URL and Screenshot and Summary",
        value: "url_summary_screenshot"
      },
      {
        label: "Summary Only",
        value: "summary"
      },
    ],
    [],
  );

  // const typeOption = methods.watch("data_type_option");
  const typeOption = "multiple";

  const handleSubmit = async (data) => {
    const formData = new FormData();
    formData.append("channel_id", channelId);
    formData.append("title", data.title);
    // formData.append("data_type_option", data.data_type_option);
    if (isEditing) formData.append("status", data.status);
    // if (data.data_type_option === "url") {
    // formData.append("data_type", data.data_type);
    formData.append("submission_type", data.submission_type);
    // } else if (data.data_type_option === "multiple") {
    //   formData.append("submission_type", data.submission_type);
    if (typeof data.multiple_sub_task_data_types === "string") {
      formData.append("multiple_sub_task_data_types[]", data.multiple_sub_task_data_types);
    } else {
      data.multiple_sub_task_data_types.forEach((item) => {
        formData.append("multiple_sub_task_data_types[]", item);
      });
    }
    // if (typeof data.multiple_sub_task_submission_types === "string") {
    //   formData.append(
    //     "multiple_sub_task_submission_types[]",
    //     data.multiple_sub_task_submission_types
    //   );
    // } else {
    //   data.multiple_sub_task_submission_types.forEach((item) => {
    //     formData.append("multiple_sub_task_submission_types[]", item);
    //   });
    // }
    // // }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }

    try {
      const res = isEditing
        ? await editSubtask({ id: subTask?.id, body: formData }).unwrap()
        : await createSubtask(formData).unwrap();
      showToast(res?.message || res?.data?.message);
      // console.log(res)
      methods.reset();
      close();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal title={isEditing ? "Edit Sub task" : "Create Sub task"} openModal={open} closeModal={close}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <ValidatedInput name="title" label="Title" placeholder="Title of sub task" />
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-500">Data Type Label</p>
            <p className="text-sm">What do you want Influencers to share on their Status?</p>
            {/* <Stack direction="row" spacing={1} my={1}>
              <FormControlLabel
                name="data_type_option"
                control={
                  <Chip
                    variant="outlined"
                    label="Multiple"
                    onClick={() => {
                      setValue("data_type_option", "multiple");
                      methods.resetField("data_type");
                      methods.resetField("submission_type");
                    }}
                    sx={{
                      color: typeOption === "multiple" ? "#8937CE" : "#A2A2B9",
                      bgcolor: typeOption === "multiple" ? "#FAF5FF" : "#FFF",
                    }}
                  />
                }
              />
              <FormControlLabel
                name="data_type_option"
                control={
                  <Chip
                    variant="outlined"
                    label="URL"
                    sx={{
                      color: typeOption === "url" ? "#8937CE" : "#A2A2B9",
                      bgcolor: typeOption === "url" ? "#FAF5FF" : "#FFF",
                    }}
                    onClick={() => {
                      setValue("data_type_option", "url");
                      methods.resetField("multiple_sub_task_data_types");
                      methods.resetField("multiple_sub_task_submission_types");
                    }}
                  />
                }
              />
            </Stack> */}
          </div>

          <AutoCompleteField
            label="Select data type"
            required={isEditing ? false : true}
            name={
              // typeOption === "multiple" ?
              "multiple_sub_task_data_types"
              //  :                "data_type"
            }
            multiple={typeOption === "multiple" ? true : false}
            selectOption={dataTypesOptions}
          />
          <AutoCompleteField
            label="Select submission type"
            required={isEditing ? false : true}
            name={
              "submission_type"
              // typeOption === "multiple"
              //   ? "multiple_sub_task_submission_types"
              //   : "submission_type"
            }
            // multiple={typeOption === "multiple" ? true : false}
            selectOption={submissionOptions}
          />

          {isEditing && (
            <div className="my-6">
              <p className="text-grey30 text-sm mb-2">Status</p>
              <ToggleSwitch
                name="status"
                toggleState={status === "active"}
                setToggleState={() => {
                  setValue("status", status === "active" ? "inactive" : "active");
                }}
              />
            </div>
          )}
          <LoadingButton
            loadingIndicator={
              <CircularProgress
                sx={{
                  color: "#ffffff",
                }}
                size="1.2rem"
              />
            }
            fullWidth
            loading={isCreating || isEditingSubTask}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
          >
            {isEditing ? "Update" : "Create"}
          </LoadingButton>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ManageSubTaskModal;
