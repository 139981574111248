const ImagePlusIcon = ({color, width, height}) => {
  return (
    <svg
      width={width || "74"}
      height={height || "65"}
      viewBox="0 0 74 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="52.5" cy="43.5" r="21.5" fill="#FAF5FF" />
      <path
        d="M37.3334 10.6654H16C14.5855 10.6654 13.229 11.2273 12.2288 12.2275C11.2286 13.2277 10.6667 14.5842 10.6667 15.9987V42.6654M10.6667 42.6654V47.9987C10.6667 49.4132 11.2286 50.7697 12.2288 51.7699C13.229 52.7701 14.5855 53.332 16 53.332H48C49.4145 53.332 50.7711 52.7701 51.7713 51.7699C52.7715 50.7697 53.3334 49.4132 53.3334 47.9987V37.332M10.6667 42.6654L22.896 30.436C23.8962 29.4362 25.2525 28.8745 26.6667 28.8745C28.0809 28.8745 29.4372 29.4362 30.4374 30.436L37.3334 37.332M53.3334 26.6654V37.332M53.3334 37.332L49.104 33.1027C48.1039 32.1029 46.7476 31.5412 45.3334 31.5412C43.9191 31.5412 42.5628 32.1029 41.5627 33.1027L37.3334 37.332M37.3334 37.332L42.6667 42.6654M48 10.6654H58.6667M53.3334 5.33203V15.9987M37.3334 21.332H37.36"
        stroke={color || "#8937CE"}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImagePlusIcon;
