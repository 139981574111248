const RoundBag = ({ transparent, fill, width, height }) => {
    return (
        <svg
            width={width || "52"}
            height={height || "52"}
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {transparent ? null : (
                <rect width="52" height="52" rx="26" fill="#E8E8FC" fillOpacity="0.5" />
            )}
            <path
                d="M32 21.5067C31.4533 21.5067 31 21.0534 31 20.5067V18.6667C31 17.2667 30.4 15.9067 29.36 14.9601C28.3067 14.0001 26.9467 13.5601 25.5067 13.6934C23.1067 13.9201 21 16.3734 21 18.9334V20.2267C21 20.7734 20.5467 21.2267 20 21.2267C19.4533 21.2267 19 20.7734 19 20.2267V18.9201C19 15.3334 21.8933 12.0267 25.32 11.6934C27.32 11.5067 29.24 12.1334 30.7067 13.4801C32.16 14.8001 33 16.6934 33 18.6667V20.5067C33 21.0534 32.5467 21.5067 32 21.5067Z"
                fill={fill || "#0C0C6A"}
            />
            <path
                d="M29.9998 40.3334H21.9998C15.8398 40.3334 14.6931 37.4667 14.3998 34.6801L13.3998 26.6934C13.2531 25.2534 13.1998 23.1867 14.5998 21.6401C15.7998 20.3067 17.7864 19.6667 20.6664 19.6667H31.3331C34.2264 19.6667 36.2131 20.3201 37.3998 21.6401C38.7864 23.1867 38.7464 25.2534 38.5998 26.6667L37.5998 34.6801C37.3064 37.4667 36.1598 40.3334 29.9998 40.3334ZM20.6664 21.6667C18.4131 21.6667 16.8664 22.1067 16.0798 22.9867C15.4264 23.7067 15.2131 24.8134 15.3864 26.4667L16.3864 34.4534C16.6131 36.5867 17.1998 38.3467 21.9998 38.3467H29.9998C34.7998 38.3467 35.3864 36.6001 35.6131 34.4801L36.6131 26.4667C36.7864 24.8401 36.5731 23.7334 35.9198 23.0001C35.1331 22.1067 33.5864 21.6667 31.3331 21.6667H20.6664Z"
                fill={fill || "#0C0C6A"}
            />
            <path
                d="M30.56 27.5334C29.8134 27.5334 29.2134 26.9334 29.2134 26.2C29.2134 25.4667 29.8134 24.8667 30.5467 24.8667C31.28 24.8667 31.88 25.4667 31.88 26.2C31.88 26.9334 31.2934 27.5334 30.56 27.5334Z"
                fill={fill || "#0C0C6A"}
            />
            <path
                d="M21.2265 27.5334C20.4799 27.5334 19.8799 26.9334 19.8799 26.2C19.8799 25.4667 20.4799 24.8667 21.2132 24.8667C21.9465 24.8667 22.5465 25.4667 22.5465 26.2C22.5465 26.9334 21.9599 27.5334 21.2265 27.5334Z"
                fill={fill || "#0C0C6A"}
            />
        </svg>
    );
};

export default RoundBag;
