import { Button, CircularProgress } from "@mui/material";
import Modal from "components/Modal";
import "./style.scss";
import { useApproveUserKycMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";

const ApproveModal = ({
 openModal,
 setOpenModal,
 btnColor,
 btn2Color,
 title,
 desc,
 buttonTitle,
 button2Title,
 btn2,
 icon,
 iconBg,
 userId,
}) => {
 const [approveKyc, { isLoading }] = useApproveUserKycMutation();
 const handleSubmit = async () => {
  try {
   const res = await approveKyc(userId).unwrap();
   showToast(res?.message);
   setOpenModal(false);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <Modal
   maxWidth={450}
   hideCancel={true}
   openModal={openModal}
   closeModal={() => setOpenModal(!openModal)}
   title={title}
  >
   <div className="flex flex-col items-center justify-center ">
    <div className={`h-[130px] w-[130px] flex items-center justify-center rounded-full ${iconBg || "bg-primary-100"}`}>
     {icon}
    </div>
    <p className="mt-4 font-semibold text-[20px] text-prop w-[290px] text-center ">{desc}</p>
   </div>
   <div className="mt-[auto]">
    <div className="flex items-center gap-4 justify-between">
     {btn2 && (
      <Button
       onClick={() => setOpenModal(!openModal)}
       color={btn2Color}
       variant="contained"
       sx={{ width: "100%", mt: 2 }}
      >
       {button2Title}
      </Button>
     )}
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading}
      onClick={() => handleSubmit()}
      color={btnColor}
      variant="contained"
      sx={{ width: "100%", mt: 2 }}
     >
      {buttonTitle}
     </LoadingButton>
    </div>
   </div>
  </Modal>
 );
};
export default ApproveModal;
