import { Chip } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UserDisplay from "components/TableComponent/UserDisplay";
import { Link } from "react-router-dom";
import moment from "moment";
import MyDataTable, {
 filterOption,
 // TableDropDown,
} from "components/TableComponent/MyDataTable";
import { STATUS2 } from "utils/constants";
import BagIcon from "components/Vectors/BagIcon";
import { useGetAllCampaignsByStatusQuery } from "services/campaigns.api";
import { useState, useEffect } from "react";
import { getUserDetails, toCurrency } from "utils";

function CampaignsTab({ status = "ongoing" }) {
 const CAMPAIGNS_PER_PAGE = 10;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(CAMPAIGNS_PER_PAGE);
 // const JOBS_PER_PAGE = 16;
 // const [page, setPage] = useState(1);
 // const [perPage, setPerPage] = useState(JOBS_PER_PAGE);

 const {
  data: allCampaignsByStatus,
  isLoading,
  isError,
  error,
 } = useGetAllCampaignsByStatusQuery({
  status,
  is_paginated: "yes",
  page,
  per_page: perPage,
 });
 //  const campaignsList = allCampaignsByStatus?.data?.data;
 const [campaigns, setCampaigns] = useState([]);

 useEffect(() => {
  if (allCampaignsByStatus?.data?.data) {
   const initialCampaigns = allCampaignsByStatus?.data?.data?.filter((campaign) => campaign);
   setCampaigns(initialCampaigns);
  }
 }, [allCampaignsByStatus]);

 const searchFunc = (val) => {
  const matchedCampaigns = allCampaignsByStatus?.data?.data?.filter((campaign) =>
   campaign?.campaign_channel?.name?.toLowerCase().includes(val.toLowerCase()),
  );

  setCampaigns(matchedCampaigns);
 };

 // console.log(allCampaignsByStatus?.data)
 return (
  <div className="p-4 rounded-lg bg-white">
   <MyDataTable
    searchPlaceholder="Search campaigns..."
    hideScrollbar={false}
    isLoading={isLoading}
    isError={isError}
    error={error}
    searchFunc={searchFunc}
    options={{
     searchOpen: true,
     viewColumns: false,
     selectableRows: "none",
     serverSide: true,
     count: allCampaignsByStatus?.data?.total,
     rowsPerPage: perPage,
     onTableChange: (action, tableState) => {
      switch (action) {
       case "changePage":
        setPage(tableState.page + 1);
        break;
       case "changeRowsPerPage":
        setPerPage(tableState.rowsPerPage);
        break;
       default:
        break;
      }
     },
    }}
    headCells={[
     {
      name: "sn",
      label: "S/N",
      options: {
       customBodyRender: (value) => <p className="w-full ml-[-8px] text-center ">{value}</p>,
      },
     },
     {
      name: "channel",
      label: "Channel",
      options: {
       customBodyRender: (value) => (
        <a href={`http://${value?.path}`} target="_blank" rel="noopener noreferrer">
         <UserDisplay name={value?.name} img={value?.img} />
        </a>
        // <a to={value.path}>
        //   <UserDisplay name={value.name} img={value.img} />
        // </a>
       ),
      },
     },
     {
      name: "category",
      label: "Category",
     },
     {
      name: "client",
      label: "Client",
      options: {
       customBodyRender: (value) => <UserDisplay name={value?.name} email={value?.username} img={value?.img} />,
      },
     },
     {
      name: "price",
      label: "Unit price",
     },
     {
      name: "influencer_count",
      label: "Influencers needed",
     },
     {
      name: "influencer_slots",
      label: "Influencer slots",
     },

     {
      name: "dateCreated",
      label: "DATE CREATED",
     },

     {
      name: "duration",
      label: "Campaign Timeline",
      ...filterOption,
     },
     {
      name: "location",
      label: "Location",
      // options: {
      //   customBodyRender: (value) => (
      //     <UserDisplay name={value.name} img={value.img} />
      //   ),
      // },
     },
     {
      name: "status",
      label: "status",
      options: {
       filter: true,
       customBodyRender: (value) => (
        <Chip
         color={`${STATUS2[value?.toLowerCase()]}`}
         sx={{ minWidth: "100%", borderRadius: "20px" }}
         label={`${value}`}
        />
       ),
      },
     },
     {
      name: "actions",
      label: "actions",
      // options: {
      //   customBodyRender: (value) => (
      //     <TableDropDown tableId={"table"}>{value}</TableDropDown>
      //   ),
      // },
     },
    ]}
    emptyData={{
     icon: BagIcon,
     message: "No Campaigns to display",
    }}
    data={campaigns?.map((el, i) => ({
     sn: (allCampaignsByStatus?.data?.current_page - 1) * allCampaignsByStatus?.data?.per_page + (i + 1),
     channel: {
      name: el?.campaign_channel?.name,
      img: el?.campaign_channel?.icon,
      // path: `/campaigns/${el?.id}?status=${status} `,
      path: `${el?.url_link_to_follow}`,
     },
     client: {
      name: getUserDetails(el?.client).name,
      username: getUserDetails(el?.client).username,
      img: getUserDetails(el?.client).img,
     },
     status: el?.status,
     category: el?.campaign_source?.name,
     duration: `${el?.duration_count} ${el?.duration_type}`,
     influencer_count: el?.number_of_influencers_needed,
     location: el?.location_type === "any_Location" ? "Anywhere" : `${el?.city?.name}, ${el?.state?.name}`,
     influencer_slots: (
      <Chip
       label={
        <span className="flex items-center text-dark-text font-semibold gap-x-1">
         <span className="flex rounded-full justify-center font-normal items-center h-4 w-4 text-white bg-primary">
          {el?.occupied_slots_count}
         </span>
         of {el?.number_of_influencers_needed}
        </span>
       }
       sx={{ bgcolor: "#F9F9FB" }}
      />
     ),
     price: toCurrency(el?.amount_per_influencer),
     dateCreated: moment(el?.created_at).format("MMM DD, YYYY"),

     actions: (
      <Link to={`/campaigns/${el?.id}`}>
       <RemoveRedEyeOutlinedIcon /> View Campaign
      </Link>
     ),
    }))}
   />
  </div>
 );
}

export default CampaignsTab;
