import { DoNotDisturbAltRounded } from "@mui/icons-material";
import { useState } from "react";
import { Button, IconButton, Chip, Avatar, Skeleton, CircularProgress } from "@mui/material";
import ManageChannelModal from "../Modals/ManageChannelModal";
import EditIcon2 from "components/Vectors/EditIcon2";
import { MoreHoriz } from "@mui/icons-material";
import DropDownWrapper from "components/DropDownWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetOneCampaignSourceQuery,
  useUpdateChannelStatusMutation,
} from "services";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import DeleteIcon from "components/Vectors/DeleteIcon";
import { icons } from "../Tabs/CampaignTab";
import EmptyResponse from "components/EmptyResponse";
import DoubleMessageIcon from "components/Vectors/DoubleMessageIcon";
import DeleteChannelModal from "../Modals/DeleteChannelModal";
import SuspendChannelModal from "../Modals/SuspendChannelModal";
import ErrorMsg from "components/ErrorMsg";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";

const CampaignDetail = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("campaign");
  const { data, isLoading, error } = useGetOneCampaignSourceQuery(id);
  const [unSuspendChannel, { isLoading: isUnSuspending }] =
    useUpdateChannelStatusMutation();
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const campaign = data?.data?.channels;
  // console.log(data?.data)

  const handleUnSuspendChannel = (channel) => {
    unSuspendChannel({ channel_id: String(channel.id), status: "active" })
      .unwrap()
      .then((res) => {
        showToast(res?.message);
      })
      .catch((err) => {
        handleError(err);
      });
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <IconButton onClick={() => navigate("?tab=campaign")}>
            <KeyboardBackspaceOutlinedIcon />
          </IconButton>
          <h1 className="font-semibold text-prop text-[20px] flex gap-x-2 items-center text-black my-3">
            {
              icons.find((icon) =>
                data?.data?.name?.toLowerCase()?.includes(icon.name)
              )?.icon
            }
            {data?.data?.name}
          </h1>
        </div>
        <Button
          onClick={() => {
            setSelectedItem(null);
            setShowAddModal(true);
          }}
          variant="contained"
        >
          Add Channel
        </Button>
      </div>
      <p className="text-grey30 text-sm font-medium my-2.5">
        {campaign?.length || 0} channels
      </p>
      <div className="flex flex-col gap-y-2">
        {isLoading ? (
          [1, 2, 3, 4, 5].map(() => ( 
            <div className="my-6">
              <Skeleton variant="rounded" height={50} width={450} />
            </div>
          ))
        ) : error ? (
          <ErrorMsg error={error} />
        ) : campaign?.length === 0 ? (
          <EmptyResponse
            message="No channels yet"
            icon={<DoubleMessageIcon color="#424257" />}
          />
        ) : (
          campaign?.map((item) => {
            return (
              <div
                key={item?.id}
                className="general-border flex justify-between items-center"
              >
                <div className="flex gap-x-2 items-center">
                  <Avatar sx={{ width: 28, height: 28 }} src={item?.icon} />
                  <span className="text-grey10 font-medium">{item?.name}</span>
                  <Chip
                    label={
                      <span className="text-grey10">
                        {item?.sub_tasks_count}
                        <span className="text-grey40 ml-2">Sub Tasks</span>
                      </span>
                    }
                    color="greyBorder"
                  />
                  <Chip
                    label={item?.status}
                    color={
                      item?.status === "active"
                        ? "successInverse"
                        : "errorInverse"
                    }
                  />
                </div>
                <DropDownWrapper
                  position="right"
                  contentPadding="px-0"
                  closeOnBtnClick={false}
                  action={
                    <IconButton
                      size="large"
                      sx={{
                        height: "54.2px",
                        width: "54.2px",
                        bgcolor: "#F2F4F7",
                        borderRadius: "16px",
                        "&:hover": {
                          bgcolor: "#F2F4F7",
                        },
                      }}
                    >
                      <MoreHoriz width={24} height={24} />
                    </IconButton>
                  }
                >
                  <div className="space-y-3">
                    <Button
                      color="inherit"
                      onClick={() =>
                        navigate(
                          `?tab=campaign&campaign=${id}&channel=${item?.id}`
                        )
                      }
                      startIcon={<CorporateFareRoundedIcon />}
                    >
                      Manage Sub-Tasks
                    </Button>
                    <Button
                      onClick={() => {
                        setSelectedItem(item);
                        setShowEditModal(true);
                      }}
                      color="inherit"
                      startIcon={<EditIcon2 />}
                    >
                      Edit Channel
                    </Button>
                    <LoadingButton
                      loadingIndicator={
                        <CircularProgress
                          sx={{
                            color: "#fff",
                          }}
                          size="1.2rem"
                        />
                      }
                      loading={isUnSuspending}
                      color={item?.status === "active" ? "error" : "success"}
                      onClick={
                        item?.status === "active"
                          ? () => {
                            setSelectedItem(item);
                            setShowSuspendModal(true);
                          }
                          : () => handleUnSuspendChannel(item)
                      }
                      startIcon={<DoNotDisturbAltRounded />}
                    >
                      {item?.status === "active" ? "Suspend " : "Activate "}
                      Channel
                    </LoadingButton>
                    <Button
                      color="error"
                      onClick={() => {
                        setSelectedItem(item);
                        setShowDeleteModal(true);
                      }}
                      startIcon={<DeleteIcon />}
                    >
                      Delete Channel
                    </Button>
                  </div>
                </DropDownWrapper>
              </div>
            )
          })
        )}
      </div>
      <ManageChannelModal
        open={showEditModal}
        close={() => setShowEditModal(false)}
        isEditing
        channel={selectedItem}
      />
      <ManageChannelModal
        open={showAddModal}
        close={() => setShowAddModal(false)}
      />
      <SuspendChannelModal
        open={showSuspendModal}
        close={() => setShowSuspendModal(false)}
        channel={selectedItem}
      />
      <DeleteChannelModal
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        channel={selectedItem}
      />
    </>
  );
};
export default CampaignDetail;

// const SuspendCampaignModal = ({show, setShow}) => {
//   return (
//     <MessageModal
//       openModal={show}
//       closeModal={() => setShow(false)}
//       title="Are you sure you want to suspend Whatsapp?"
//       description="Suspending doesn’t mean lorem ipsum"
//       icon={<TrashIcon />}
//       cta={
//         <div className="grid grid-cols-2 gap-4 w-full">
//           <Button
//             variant="outlined"
//             color="error"
//             onClick={() => setShow(false)}
//           >
//             Cancel
//           </Button>
//           <Button variant="contained" color="error">
//             Suspend
//           </Button>
//         </div>
//       }
//     />
//   );
// };
// const DeleteCampaignModal = ({show, setShow}) => {
//   return (
//     <MessageModal
//       openModal={show}
//       closeModal={() => setShow(false)}
//       title="Are you sure you want to delete Whatsapp?"
//       description="Suspending doesn’t mean lorem ipsum"
//       icon={<TrashIcon />}
//       cta={
//         <div className="grid grid-cols-2 gap-4 w-full">
//           <Button
//             variant="outlined"
//             color="error"
//             onClick={() => setShow(false)}
//           >
//             Cancel
//           </Button>
//           <Button variant="contained" color="error">
//             Delete
//           </Button>
//         </div>
//       }
//     />
//   );
// };
