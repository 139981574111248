import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import MessageModal from "components/Modal/MessageModal";
import { useDeleteCountryMutation } from "services";
import { showToast } from "store/store.hooks";

const SuspendCountryModal = ({ open, close, country }) => {
 const [deleteCountry, { isLoading: isDeletingCountry }] = useDeleteCountryMutation();
 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   title={`Are you sure you want to delete ${country?.name}?`}
   description="Deleting doesn’t mean lorem ipsum"
   icon={<img src={country?.country_flag} alt={country?.name} height="40" width="75" />}
   cta={
    <div className="grid grid-cols-2 gap-4 w-full">
     <Button variant="outlined" color="error" onClick={close}>
      Cancel
     </Button>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isDeletingCountry}
      onClick={() => {
       deleteCountry(country?.id)
        .unwrap()
        .then((res) => {
         showToast(res?.message);
         close();
        });
      }}
      variant="contained"
      color="error"
     >
      Delete
     </LoadingButton>
    </div>
   }
  />
 );
};
export default SuspendCountryModal;
