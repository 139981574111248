const FacebookIcon = (props) => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5213_88621)">
                    <path d="M24 12.0003C24 5.37285 18.6274 0.000270659 12 0.000270659C5.37258 0.000270659 0 5.37285 0 12.0003C0 17.9898 4.38823 22.9543 10.125 23.8545V15.469H7.07813V12.0003H10.125V9.35652C10.125 6.34902 11.9165 4.68777 14.6576 4.68777C15.9705 4.68777 17.3438 4.92215 17.3438 4.92215V7.87527H15.8306C14.3399 7.87527 13.875 8.80028 13.875 9.74926V12.0003H17.2031L16.6711 15.469H13.875V23.8545C19.6118 22.9543 24 17.9898 24 12.0003Z" fill="#1877F2" />
                    <path d="M16.6711 15.4687L17.2031 12H13.875V9.74899C13.875 8.80001 14.3399 7.875 15.8306 7.875H17.3438V4.92187C17.3438 4.92187 15.9705 4.6875 14.6576 4.6875C11.9165 4.6875 10.125 6.34875 10.125 9.35625V12H7.07812V15.4687H10.125V23.8542C10.7359 23.9501 11.3621 24 12 24C12.6379 24 13.2641 23.9501 13.875 23.8542V15.4687H16.6711Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_5213_88621">
                        <rect width="24" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}

export default FacebookIcon;