import { Chip } from "@mui/material";

function ChipsWrap({ chipsArr, title, className }) {
 return (
  <div>
   <p className={`${className ? className : "text-lg font-semibold pt-4 mt-2 text-grey10"} `}>{title}</p>

   {(!chipsArr || chipsArr?.length === 0) && <span className="mt-1">No {title}</span>}

   <div className="flex flex-wrap">
    {chipsArr &&
     chipsArr.map((el, i) => (
      <Chip
       key={`${title}_${el.id}_${i}`}
       sx={{ height: 43, px: 2, borderRadius: "60px", mt: 1, mr: 1 }}
       label={el?.tag}
       color="greyBorder"
      />
     ))}
   </div>
  </div>
 );
}

export default ChipsWrap;
