function MailIcon(props) {
  return (
    <svg
      width="25"
      height="24"
      {...props}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 21.25H7.5C3.85 21.25 1.75 19.15 1.75 15.5V8.5C1.75 4.85 3.85 2.75 7.5 2.75H17.5C21.15 2.75 23.25 4.85 23.25 8.5V15.5C23.25 19.15 21.15 21.25 17.5 21.25ZM7.5 4.25C4.64 4.25 3.25 5.64 3.25 8.5V15.5C3.25 18.36 4.64 19.75 7.5 19.75H17.5C20.36 19.75 21.75 18.36 21.75 15.5V8.5C21.75 5.64 20.36 4.25 17.5 4.25H7.5Z"
        fill={props?.color || "#8937CE"}
      />
      <path
        d="M12.4998 12.87C11.6598 12.87 10.8098 12.61 10.1598 12.08L7.02978 9.57997C6.70978 9.31997 6.64978 8.84997 6.90978 8.52997C7.16978 8.20997 7.63978 8.14997 7.95978 8.40997L11.0898 10.91C11.8498 11.52 13.1398 11.52 13.8998 10.91L17.0298 8.40997C17.3498 8.14997 17.8298 8.19997 18.0798 8.52997C18.3398 8.84997 18.2898 9.32997 17.9598 9.57997L14.8298 12.08C14.1898 12.61 13.3398 12.87 12.4998 12.87Z"
        fill={props?.color || "#8937CE"}
      />
    </svg>
  );
}

export default MailIcon;
