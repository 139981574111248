import { Avatar, Button, Chip, IconButton } from "@mui/material";
import MyDataTable, {
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import UserSquare from "components/UserSquare";
import EditIcon2 from "components/Vectors/EditIcon2";
import TrashIcon from "components/Vectors/TrashIcon";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ManageSubTaskModal from "../Modals/ManageSubTaskModal";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useGetOneCampaignChannelQuery } from "services";
import DeleteSubTaskModal from "../Modals/DeleteSubTaskModal";

const SubTaskDetail = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEl, setSelectedEl] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get("campaign");
  const channelId = searchParams.get("channel");
  const {
    data: channel,
    isLoading,
    isError,
    error,
  } = useGetOneCampaignChannelQuery(channelId);
  // console.log(channel?.data?.sub_tasks)

  const data = channel?.data?.sub_tasks?.map((item, index) => ({
    sn: index + 1,
    sub_task: item.title || "",
    data_type:
      // item?.data_type_option === "multiple" ?
      item.multiple_sub_task_data_types
        ?.map((item) => item.data_type)
        .join(", "),
    // : item.data_type,
    submission_format:
      // item?.data_type_option === "multiple"
      //   ? item.multiple_sub_task_submission_types
      //     ?.map((item) => item.submission_type)
      //     .join(", ") :
        item.submission_type?.split("_").join(" "),
    status: item?.status,
    actions: item,
  }));
  return (
    <>
      <div className="flex items-center mb-3 justify-between">
        <div className="flex gap-x-3 items-center">
          <IconButton
            size="large"
            onClick={() => navigate(`?tab=campaign&campaign=${campaignId}`)}
          >
            <KeyboardBackspaceOutlinedIcon fontSize="large" />
          </IconButton>
          <h1 className="font-semibold flex items-center text-prop text-[20px] gap-x-3 text-grey30 my-3">
            <Avatar
              src={channel?.data?.icon}
              alt={channel?.data?.name}
              sx={{ width: 45, height: 45 }}
            />
            {channel?.data?.name}
          </h1>
        </div>

        <Button
          onClick={() => {
            setShowAddModal(true);
          }}
          variant="contained"
        >
          Add Sub task
        </Button>
      </div>
      <MyDataTable
        options={{
          searchOpen: true,
          viewColumns: false,
          filter: false,
        }}
        headCells={[
          {
            name: "sn",
            label: "S/N",
            options: {
              customBodyRender: (value) => <div className="pl-3">{value}</div>,
            },
          },
          {
            name: "sub_task",
            label: "sub task",
            options: {
              customBodyRender: (value) => (
                <p className="truncate max-w-[35ch]">{value}</p>
              ),
            },
          },
          {
            name: "data_type",
            label: "data type",
            options: {
              customBodyRender: (value) => (
                <p className="capitalize">{value}</p>
              ),
            },
          },
          {
            name: "submission_format",
            label: "submission Format",
            options: {
              customBodyRender: (value) => (
                <p className="capitalize">{value}</p>
              ),
            },
          },
          {
            name: "status",
            label: "Status",
            options: {
              customBodyRender: (value) => (
                <Chip
                  color={
                    value?.toLowerCase() === "active"
                      ? "successInverse"
                      : "greyBorder"
                  }
                  sx={{ minWidth: "fit-content" }}
                  label={value}
                />
              ),
            },
          },
          {
            name: "actions",
            label: "",
            options: {
              customBodyRender: (value) => (
                <TableDropDown tableId="table">
                  <Button
                    onClick={() => {
                      setSelectedEl(value);
                      setShowEditModal(true);
                    }}
                    startIcon={<EditIcon2 />}
                    color="inherit"
                    sx={{ py: "0.5rem !important" }}
                  >
                    Edit Sub task
                  </Button>
                  <Button
                    startIcon={<TrashIcon color="#D61E34" />}
                    color="error"
                    sx={{ py: "0.5rem !important" }}
                    onClick={() => {
                      setSelectedEl(value);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete Sub task
                  </Button>
                </TableDropDown>
              ),
            },
          },
        ]}
        scrollX={false}
        isError={isError}
        data={data}
        error={error}
        isLoading={isLoading}
        emptyData={{
          icon: UserSquare,
          message: "No Tasks yet",
        }}
      />
      {showEditModal && (
        <ManageSubTaskModal
          open={showEditModal}
          close={() => setShowEditModal(false)}
          isEditing
          subTask={selectedEl}
        />
      )}
      {showAddModal && (
        <ManageSubTaskModal
          open={showAddModal}
          close={() => setShowAddModal(false)}
          subTask={selectedEl}
        />
      )}
      <DeleteSubTaskModal
        open={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        subTask={selectedEl}
      />
    </>
  );
};
export default SubTaskDetail;
