import { useState, useEffect } from "react";
import moment from "moment";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import EmptyResponse from "components/EmptyResponse";
import TwoUserIcon from "components/Vectors/TwoUserIcon";
import MoneyIcon from "components/Vectors/MoneyIcon";
import TimeIcon from "components/Vectors/TimeIcon";

// const mock_data = [
//   {
//     id: 1,
//     title: "Engagement created",
//     message: "A new engagement was created between Bosun and Benjamin Lokwaje",
//     created_at: new Date(),
//   },
//   {
//     id: 2,
//     title: "Payment made",
//     message: "Bosun paid paid ₦5,000 to Benjamin Lokwaje",
//     created_at: new Date(),
//   },
//   {
//     id: 1,
//     title: "Engagement terminated",
//     message: "Benjamin Lokwaje terminated the engagement",
//     created_at: new Date(),
//   },
// ];

const ColorStatus = {
 warning: {
  color: "#E98100",
  bg: "#E981001A",
 },
 error: {
  color: "#D92D20",
  bg: "#D92D201A",
 },
 success: {
  color: "#12B76A",
  bg: "#12b76A1A",
 },
};

const getUserIcon = (status = "warning") => ({
 icon: <TwoUserIcon color={ColorStatus[status].color} />,
 iconBg: `${ColorStatus[status].bg}`,
});
const getMoneyIcon = (status = "warning") => ({
 icon: <MoneyIcon color={ColorStatus[status].color} />,
 iconBg: `${ColorStatus[status].bg}`,
});
const getTimeIcon = (status = "warning") => ({
 icon: <TimeIcon color={ColorStatus[status].color} />,
 iconBg: `${ColorStatus[status].bg}`,
});

const activityData = {
 "engagement created": getUserIcon("success"),
 "payment made": getMoneyIcon(),
 "engagement started": getTimeIcon(),
 "engagement cancelled": getTimeIcon("error"),
 "engagement terminated": getTimeIcon("error"),
 "deadline extension requested": getTimeIcon(),
 "deadline extension request accepted": getTimeIcon("success"),
 "deadline extension request declined": getTimeIcon("error"),
};

const getActivity = (title) => {
 let iconBg = activityData[title.toLowerCase()]?.iconBg;
 let icon = activityData[title.toLowerCase()]?.icon;
 if (!iconBg && !icon) {
  for (const key in activityData) {
   if (title.toLowerCase().includes(key.toLowerCase())) {
    iconBg = activityData[key]?.iconBg;
    icon = activityData[key]?.icon;
    break;
   }
  }
 }
 return { icon, iconBg };
};

const ActivityCards = ({ activities }) => {
 const [activity, setActivities] = useState([]);
 const [sortedActivites, setSortedActivites] = useState([]);
 // console.log(data);
 useEffect(() => {
  setActivities(activities?.data);
 }, [activities?.data]);

 useEffect(() => {
  setSortedActivites([...activity].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
 }, [activity]);
 return (
  <div className="flex flex-col gap-3 p-3 border rounded-lg">
   {sortedActivites.length <= 0 && (
    <EmptyResponse message={"No Activity recorded yet"} icon={<AnalyticsOutlinedIcon />} />
   )}
   {/* {data.map((value, index) => (
        <div
          className="flex items-start justify-between bg-grey100 rounded-lg p-6"
          key={value?.id}
        >
          <div className="flex items-center gap-4">
            <span
              className={`flex h-[64px] min-w-[64px] items-center justify-center rounded-lg bg-white`}
            >
              {getActivity(value.title)?.icon}
            </span>
            <div>
              <h4 className="text-prop text-base font-semibold text-[#000]">
                {value.title}
              </h4>
              <p className="text-prop mt-1 text-[14px] font-[400] text-grey30 ">
                {value.message || ""}
              </p>
            </div>
          </div>

          <p className="text-prop min-w-fit text-base font-[400] text-grey_30">
            {moment(value?.created_at).format("DD MMM YYYY")}
          </p>
        </div>
      ))} */}
   {sortedActivites.map((value, index) => (
    <div className="flex items-start justify-between bg-grey100 rounded-lg p-6" key={value?.id}>
     <div className="flex items-center gap-4">
      <span className={`flex h-[64px] min-w-[64px] items-center justify-center rounded-lg bg-white `}>
       {getActivity(value.title)?.icon}
      </span>
      <div>
       <h4 className="text-prop text-base font-semibold text-[#000]">{value.title}</h4>
       <p className="text-prop mt-1 text-[14px] font-[400] text-grey30 ">{value.message || ""}</p>
      </div>
     </div>

     <p className="text-prop min-w-fit text-base font-[400] text-grey_30">
      {moment(value?.created_at).format("DD MMM YYYY")}
     </p>
    </div>
   ))}
  </div>
 );
};
export default ActivityCards;
