import UserDisplay from "components/TableComponent/UserDisplay";
import { useGetNewUsersQuery, useGetNewVerifiedUsersQuery } from "services";
import moment from "moment";
import { formatDate, getUserDetails } from "utils";
import { ArrowForward } from "@mui/icons-material";
import RightIcon from "components/Vectors/RightIcon";
import { Link } from "react-router-dom";
import EmptyUsers from "./Components/EmptyUsers";

const NewUsers = () => {
 const { data } = useGetNewVerifiedUsersQuery();

 const sortedData = [...(data?.data || [])]
  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  .slice(0, 12);

 console.log(sortedData);

 return (
  <section>
   <div className="p-4 py-6 border rounded-xl h-full">
    <div className="flex mb-4 justify-between items-center">
     <h2 className="text-[18px] font-semibold text-prop text-grey30">New Users</h2>
    </div>
    <div className="overflow-y-auto scrollbar-style max-h-[620px]">
     {data?.data?.length === 0 ? (
      <div className="h-[400px] flex items-center justify-center">
       <EmptyUsers />
      </div>
     ) : (
      sortedData?.map((user) => (
       <Link
        to={`/${user.user_type}s/${user.id}`}
        //   className="flex p-4 border-b justify-between gap-4"
        key={user.id}
       >
        <span className="flex p-4 border-b justify-between gap-4">
         <UserDisplay
          // badge={user?.kyc?.status === 'Approved'}
          badge={user?.user_type === "creator" && user?.kyc_verified}
          email={user?.user_type?.charAt(0).toUpperCase() + user?.user_type?.slice(1) || "N/A"}
          name={getUserDetails(user).name}
          img={getUserDetails(user).img}
         />
         <span className="flex gap-2 items-center text-sm text-grey30">
          <span className="mr-10">
           <span className="text-grey50">Joined</span>{" "}
           {/* <span>{moment(user?.created_at).format('DD MMMM, YYY')}</span> */}
           <span>{formatDate(user?.created_at)}</span>
          </span>
          {/* <ArrowForward /> */}
          <RightIcon />
         </span>
        </span>
       </Link>
      ))
     )}
    </div>
   </div>
  </section>
 );
};
export default NewUsers;
