import { Button, CircularProgress, Skeleton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { useState } from "react";
import BankNote from "components/Vectors/BankNote";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { LoadingButton } from "@mui/lab";
import Modal from "components/Modal";
import MessageModal from "components/Modal/MessageModal";

const RefundModal = ({ open, close, client, transactionId }) => {
 const isLoading = false;

 const [showConfirmModal, setShowConfirmModal] = useState(false);
 const bankName = "Access Bank";

 const handleCopyToClipboard = () => {
  navigator.clipboard
   .writeText(client?.bank_account?.account_number)
   .then(() => {
    showToast("Copied to clipboard");
   })
   .catch((err) => {
    handleError(err);
   });
 };
 const handleRefund = async () => {
  close();
  setShowConfirmModal(false);
  // try {
  // let res =  await    markAsRefunded({refund_history_id: transactionId, client_id: client?.id})
  //   .unwrap();
  //   showToast(res?.message);
  // } catch (error) {
  //   handleError(error);
  // }
 };
 return (
  <>
   <Modal openModal={open} closeModal={close} maxWidth={550}>
    <div className="flex flex-col items-center justify-center gap-y-6">
     <p className="text-center font-Bai text-3xl font-semibold text-grey10">Refund Client</p>

     <div className="min-w-[280px] space-y-2 rounded-xl bg-grey100 p-5 text-center">
      <p>
       <AccountBalanceOutlinedIcon /> Bank Name
      </p>
      <p className="text-center font-Bai text-2xl font-semibold">
       {isLoading ? <Skeleton height={50} /> : String(bankName)}
      </p>
     </div>

     <div className="my-2 min-w-[280px] space-y-2 rounded-xl bg-grey100 p-5 text-center">
      <p className="flex items-center justify-center text-grey10">
       <BankNote stroke="#000" />
       <span className="ml-2">Account number</span>
      </p>
      <p className="text-center font-Bai text-2xl font-semibold">{client?.bank_account?.account_number}</p>
     </div>

     <Button startIcon={<ContentCopyOutlinedIcon color="primary" />} onClick={handleCopyToClipboard}>
      Copy number
     </Button>
    </div>
    <div className="mt-6 grid grid-cols-2 gap-x-4">
     <Button variant="outlined" onClick={close}>
      Cancel
     </Button>
     <Button
      variant="contained"
      onClick={() => {
       close();
       setShowConfirmModal(true);
      }}
     >
      Mark as refunded
     </Button>
    </div>
   </Modal>
   <MessageModal
    openModal={showConfirmModal}
    closeModal={() => setShowConfirmModal(false)}
    title="Are you sure you have made the transaction?"
    icon={<BankNote stroke="#8937CE" />}
    iconBg="bg-[#FAF5FF]"
    cta={
     <div className="grid grid-cols-2 w-full gap-x-4">
      <Button variant="outlined" onClick={() => setShowConfirmModal(false)}>
       No, I haven’t
      </Button>
      <LoadingButton
       loadingIndicator={
        <CircularProgress
         sx={{
          color: "#ffffff",
         }}
         size="1.2rem"
        />
       }
       variant="contained"
       onClick={handleRefund}
      >
       Yes
      </LoadingButton>
     </div>
    }
   />
  </>
 );
};

export default RefundModal;
