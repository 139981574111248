import {LoadingButton} from "@mui/lab";
import {CircularProgress} from "@mui/material";
import ValidatedInput from "components/forms/ValidatedInput";
import {FormProvider, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {useResetPwdMutation} from "services/auth.api";
import {showToast} from "store/store.hooks";
import BasicAuthLayout from "templates/AuthLayout/BasicAuthLayout";
import {handleError} from "utils";

function SetNewPassword() {
  const methods = useForm({
    mode: "onSubmit",
  });

  const password = methods.watch("password");
  const navigate = useNavigate();
  const [resetPwd, {isLoading}] = useResetPwdMutation();

  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const onSubmit = async (data) => {
    try {
      const res = await resetPwd({
        token,
        ...data,
      }).unwrap();
      showToast(res?.message);
      navigate(`/set-password-success`);
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <BasicAuthLayout showBackBtn>
      <div className="max-w-[430px] text-center">
        <>
          <h1 className="title text-[#2C2C3A]">Set new password</h1>
          <p>Set a new password.</p>
        </>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="pt-8">
            <div className="block">
              <ValidatedInput
                showErrMsg={false}
                name="password"
                label="Password"
                placeholder="Set Password"
                type="password"
                rules={{
                  pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
                }}
              />
              <ul className="ml-5 font-normal list-disc text-xs mt-3 mb-4 text-left">
                <li
                  className={`my-1 ${
                    password?.length > 7 ? "text-success" : "text-error"
                  }`}
                >
                  At least 8 character
                </li>
                <li
                  className={`my-1 ${
                    password?.match(/(?=.*[A-Z])/)
                      ? "text-success"
                      : "text-error"
                  }`}
                >
                  Should contain Capital letters
                </li>
                <li
                  className={`my-1 ${
                    password?.match(/(?=.*\W)/) ? "text-success" : "text-error"
                  }`}
                >
                  Should contain Symbols
                </li>
              </ul>
              <ValidatedInput
                name="password_confirmation"
                label="Confirm Password"
                placeholder="Confirm your Password"
                type="password"
                rules={{
                  validate: (value) =>
                    value === password || "The passwords do not match",
                }}
              />
            </div>

            <div className="flex gap-4 mt-8">
              <LoadingButton
                className="flex-1"
                loadingIndicator={
                  <CircularProgress
                    sx={{
                      color: "#fff",
                    }}
                    size="1.2rem"
                  />
                }
                loading={isLoading}
                type="submit"
                variant="contained"
              >
                Set Password
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </BasicAuthLayout>
  );
}

export default SetNewPassword;
