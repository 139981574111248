import {AvatarGroup, Chip, Grid, Tooltip} from "@mui/material";
import StatCard from "components/StatCard";
import UserDisplay from "components/TableComponent/UserDisplay";
//
import {useState} from "react";
import ServicesIcon from "components/Vectors/ServicesIcon";
import {Link} from "react-router-dom";
import RemoveRedEyeOutlined from "@mui/icons-material/RemoveRedEyeOutlined";
import ArchiveIcon from "components/Vectors/ArchiveIcon";
import BlockIcon from "@mui/icons-material/Block";
import DisableModal from "./Widgets/DisableModal";
import MyDataTable, {
  filterOption,
  TableDropDown,
} from "components/TableComponent/MyDataTable";
import {STATUS2} from "utils/constants";
// import StarIcon from "components/Vectors/StarIcon";
import ServiceCards from "./Widgets/ServiceCards";
import {
  useGetAllServicesQuery,
  useGetServicesStatQuery,
  useUpdateServiceStatusMutation,
} from "services";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import moment from "moment";
import SquareTickIcon from "components/Vectors/SquareTickIcon";
import {showToast} from "store/store.hooks";
import {handleError} from "utils";

export const avatar_group_style = {
  justifyContent: "flex-end",
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    borderRadius: "0",
    border: 0,
    marginLeft: 0,
    marginRight: "8px",
    "&.MuiAvatar-colorDefault": {
      borderRadius: "100%",
      fontWeight: 500,
      fontSize: "12px",
      color: "black",
      background: "#DCDCE5",
    },
  },
};

function AdminServices() {
  const [openFilterModal, setopenFilterModal] = useState(false);
  const [openDisableModal, setopenDisableModal] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [userSlug, setUserSlug] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const {
    data: statsData,
    isLoading: isStatLoading,
    error: isStatError,
  } = useGetServicesStatQuery();

  const [updateStatus] = useUpdateServiceStatusMutation();

  const {data, isLoading, error} = useGetAllServicesQuery();

  const services = data?.data;

  console.log(services);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorMsg error={error} />;
  }
  const handleDisable = (id, slug, name) => {
    setStoreId(id);
    setUserSlug(slug);
    setopenDisableModal(true);
    setStoreName(name);
  };

  return (
    <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
      <div className="flex justify-between items-center mb-4">
        <h1 className="title">Services</h1>
      </div>
      <ServiceCards
        data={statsData?.data}
        isLoading={isStatLoading}
        error={isStatError}
      />
      <div className="flex justify-between mt-8">
        <h5 className="text-2xl font-semibold">All Services</h5>
        {/* <ExportBtn /> */}
      </div>
      <div className="p-4 bg-white py-6 border rounded-xl mt-5">
        <MyDataTable
          searchPlaceholder="Select Service, influencer, buyer,..."
          options={{
            searchOpen: true,
          }}
          headCells={[
            {
              name: "sn",
              label: "S/N",
              options: {
                customBodyRender: value => (
                  <p className="pl-[2px] "> {value}</p>
                ),
              },
            },
            {
              name: "service",
              label: "service",
              options: {
                customBodyRender: value => (
                  <UserDisplay
                    name={value.name}
                    imgRounded={false}
                    img={value.img}
                  />
                ),
              },
            },
            {
              name: "users",
              label: "Users",
              options: {
                customBodyRender: value => (
                  <UserDisplay
                    truncate
                    name={value.name}
                    email={value.type}
                    img={value.img}
                  />
                ),
              },
            },
            {
              name: "userType",
              label: " Type",
              ...filterOption,
            },
            {
              name: "requests",
              label: "requests",
            },
            {
              name: "createdAt",
              label: "date created",
            },

            {
              name: "duration",
              label: "Duration",
              ...filterOption,
            },
            {name: "views", label: "views"},
            {
              name: "categories",
              label: "CATEGORIES",
              options: {
                filter: true,
                customBodyRender: value => (
                  <Tooltip title={value}>
                    <AvatarGroup sx={avatar_group_style}>
                      <Chip sx={{mr: 1}} label={value} />
                    </AvatarGroup>
                  </Tooltip>
                ),
              },
            },
            {name: "price", label: "Price Range"},
            {
              name: "status",
              label: "status",
              options: {
                filter: true,
                customBodyRender: value => (
                  <Chip
                    color={`${STATUS2[value.toLowerCase()]}`}
                    sx={{minWidth: "100%", borderRadius: "20px"}}
                    label={`${value}`}
                  />
                ),
              },
            },
            {
              name: "actions",
              label: null,
              options: {
                customBodyRender: value => (
                  <TableDropDown tableId={"table"}>{value}</TableDropDown>
                ),
              },
            },
          ]}
          data={services.map((el, i) => ({
            sn: i + 1,
            service: {
              name: `${el?.title}`,
              img: el?.images[0]?.url,
            },
            users: {
              name: el?.user?.full_name,
              type: el?.user?.username,
              img: el?.user?.profile_image,
            },
            userType: el?.user?.role?.name,
            requests: "12",
            categories: `${el?.category?.name}`,
            // socials: ["facebook", "instagram", "tiktok"],
            status: `${el?.status}`,
            duration: ` ${el?.duration_count} ${el?.duration_type} `,
            views: el?.views_count,
            price: `₦${el?.price.toLocaleString()}`,
            createdAt: moment(el?.created_at).format("MMM DD, YYYY"),
            actions: (
              <>
                <Link to={`${el?.id}`}>
                  <RemoveRedEyeOutlined /> View Service
                </Link>
                <>
                  {el?.status.toLowerCase() !== "suspended" ? (
                    <button
                      onClick={() =>
                        handleDisable(
                          el?.id,
                          el?.user?.role?.slug,
                          el?.user?.full_name
                        )
                      }
                      className="text-error"
                    >
                      <BlockIcon color="error" /> Disable
                    </button>
                  ) : (
                    <button
                      onClick={async () => {
                        try {
                          let res = await updateStatus({
                            id: el?.id,
                            body: {
                              status: "Active",
                            },
                          }).unwrap();
                          console.log(res);
                          showToast(res?.message);
                        } catch (error) {
                          handleError(error);
                        }
                      }}
                      className="text-[#00A04A]"
                    >
                      <SquareTickIcon /> Enable
                    </button>
                  )}
                </>
              </>
            ),
          }))}
        />
      </div>
      <DisableModal
        id={storeId}
        userSlug={userSlug}
        openModal={openDisableModal}
        setopenModal={setopenDisableModal}
        fullName={storeName}
      />
    </div>
  );
}

export default AdminServices;
