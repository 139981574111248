import { Avatar } from "@mui/material";
import { Chip } from "@mui/material";
import UserBio from "components/UserBio";
import UserKyc from "components/UserKyc";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StatCard from "components/StatCard";
import Bag2Icon from "components/Bag2Icon";
import CreatorService from "./Widgets/CreatorService";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { getUserDetails } from "utils";
import { useParams } from "react-router-dom";
import {
 useGetOneCreatorQuery,
 useGetSingleCreatorStatsQuery,
 useGetUserReviewsReceivedQuery,
 useGetUserReviewsGivenQuery,
} from "services";
import CreatorCta from "./Widgets/CreatorCta";

const EachCreator = () => {
 const { id } = useParams();
 const { data, isLoading } = useGetOneCreatorQuery(id);
 const { data: stats, isLoading: isStatsLoading } = useGetSingleCreatorStatsQuery(id);
 const creator = data?.data;
 const { data: reviewsGiven } = useGetUserReviewsGivenQuery({ id });
 const { data: reviewsReceived } = useGetUserReviewsReceivedQuery({ id });

 if (stats) {
  console.log(stats);
 }

 return (
  <div className="min-h-screen p-8 bg-grey100">
   <div className="px-5 py-8 rounded-lg bg-white flex flex-col">
    <div className="flex justify-between items-start pb-[32px] border-b border-[#EAEAF0] ">
     <div className="flex flex-col ">
      <div className="flex gap-4 items-center ">
       <div className="relative">
        <Avatar sx={{ width: 100, height: 100 }} src={creator?.profile_image} />
        {creator?.kyc_verified && (
         <span className="absolute right-0 top-0">
          <VerifiedRoundedIcon color="primary" />
         </span>
        )}
       </div>
       {/* <VerifiedRoundedIcon color="primary" /> */}

       {creator?.kyc?.status === "Approved" && (
        <span className="absolute -right-4 -top-1">
         <VerifiedRoundedIcon color="primary" />
        </span>
       )}

       <div>
        <h2 className="text-xl md:text-3xl inline-flex items-center gap-2 mb-1 text-grey10 font-semibold">
         {getUserDetails(creator).name || ""}
         <StarRoundedIcon
          fontSize="large"
          sx={{
           color: "#FFB33E",
          }}
         />{" "}
         {creator?.reviews_received_avg || 0}
        </h2>
        <div className="flex">
         <p className="text-sm text-grey md:text-base">@{creator?.username}</p>
         <Chip
          label={creator?.status}
          sx={{ ml: 1, minWidth: { xs: "auto", md: "100px" } }}
          color={creator?.status === "active" ? "successInverse" : "default"}
         />

         <Chip
          label={creator?.kyc?.status === "Approved" ? "Verified" : "Pending Verification"}
          sx={{ ml: 1, minWidth: { xs: "auto", md: "100px" } }}
          color={creator?.kyc?.status === "Approved" ? "successInverse" : "default"}
         />
        </div>
       </div>
      </div>
      {creator?.status.toLowerCase() === "suspended" && (
       <div className="flex gap-2 rounded-[24px] bg-grey100 my-4 px-4 py-[25px] md:px-[35px]">
        <ErrorOutlineIcon /> {creator?.reason_for_suspension}
       </div>
      )}
     </div>
     <CreatorCta user={creator} />
    </div>

    <div className="flex flex-col items-start justify-between py-4 gap-4 md:gap-[4rem] md:flex-row">
     <UserBio user={creator} receivedReviews={reviewsReceived} givenReviews={reviewsGiven} isClient isAdmin={false} />
     <div className="flex w-1/2 items-start flex-col justify-start">
      <UserKyc user={creator} />
      <div className="my-8 w-full">
       <h2 className="text-2xl mb-2 font-semibold">Services</h2>
       <CreatorService isLoading={isLoading} about={creator?.professional_information} />
      </div>
      <div className="w-full">
       <h2 className="text-2xl mb-2 font-semibold">Stats</h2>
       <div className="grid md:grid-cols-2 gap-2">
        <StatCard
         icon={<Bag2Icon fill="#8937CE" />}
         iconBg="bg-[#FAF5FF]"
         name="All Engagements"
         value={stats?.data?.engagement?.total}
         isLoading={isStatsLoading}
         reverse
        />
        <StatCard
         icon={<Bag2Icon fill="#D07E00" />}
         iconBg="bg-[#FAF5FF]"
         name="Ongoing Engagements"
         value={stats?.data?.engagement?.ongoing}
         isLoading={isStatsLoading}
         reverse
        />
        <StatCard
         icon={<Bag2Icon fill="#00A04A" />}
         iconBg="bg-[#FAF5FF]"
         name="Completed Engagements"
         value={stats?.data?.engagement?.completed}
         isLoading={isStatsLoading}
         reverse
        />
        <StatCard
         icon={<Bag2Icon fill="#D61E34" />}
         iconBg="bg-[#FAF5FF]"
         name="Terminated Engagements"
         value={stats?.data?.engagement?.terminated}
         isLoading={isStatsLoading}
         reverse
        />
        <StatCard
         icon={<Bag2Icon fill="#424257" />}
         iconBg="bg-[#FAF5FF]"
         name="Cancelled Engagements"
         value={stats?.data?.engagement?.cancelled}
         isLoading={isStatsLoading}
         reverse
        />
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
};

export default EachCreator;
