import { createTheme, ThemeProvider } from "@mui/material/styles";
import AllRoutes from "Routes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ToastWidget from "components/ToastWidget";
// Test
export const CustomTabStyle = {
   ".MuiTab-root": {
      fontSize: {
         xs: "0.95rem",
         md: "1.2rem",
      },
      textTransform: "inherit",
      "&.Mui-selected": {
         color: "black",
      },
   },
   ".MuiTabs-indicator": {
      height: "4px",
   },
};

export const CustomTabStyle2 = {
   ".MuiTab-root": {
      fontSize: {
         xs: "1.2rem",
         md: "2rem",
      },
      textTransform: "inherit",
      "&.Mui-selected": {
         color: "black",
      },
   },
   ".MuiTabs-indicator": {
      height: "0px",
   },
};

const theme = createTheme({
   typography: {
      fontFamily: "Montserrat, sans-serif",
   },
   shape: {
      borderRadius: 12,
   },
   palette: {
      action: {
         disabledBackground: "",
         disabled: "",
      },
      primary: {
         main: "#8937CE",
         contrastText: "white",
         light: "#FAF5FF",
      },
      primaryInverse: {
         main: "#FAF5FF",
         contrastText: "#8937CE",
      },
      secondary: { main: "#000042" },
      secondaryLight: { main: "#BABAEE", contrastText: "white" },
      success: { main: "#00A04A", light: "#E5F7ED", dark: "#113924" },
      successInverse: { contrastText: "#00A04A", main: "#E5F7ED" },
      successInverse2: { contrastText: "#E5F7ED", main: "#00A04A", },
      errorInverse: { contrastText: "#D61E34", main: "#FAEBED" },
      draftInverse: { contrastText: "#000042", main: "#E8E8FC" },
      archiveButton: { contrastText: "#8585A3", main: "#EAEAF0" },
      promotedButton: { contrastText: "##000042", main: "#ECECF8" },
      error: { main: "#D61E34", light: "#FAEBED", dark: "#6E2931" },
      greyBorder: { main: "#EAEAF0", contrastText: "#69698C" },
      blueLight: { main: "#EAEAF0", contrastText: "#1989D4" },
      blueLight2: { contrastText: "#EAEAF0", main: "#1989D4" },
      yellow: { main: "#F6A001", contrastText: "#69698C" },
      yellowInverse: { contrastText: "#F6A001", main: "#F6A0011A" },
      white: {
         main: "#ffffff",
         contrastText: "#161616",
      },
      black: {
         contrastText: "#ffffff",
         main: "#8585A3",
      },
   },
   components: {
      MuiMenuItem: {
         styleOverrides: {
            root: { "&.Mui-selected": { color: "#8937CE !important" } },
         },
      },
      MuiButton: {
         styleOverrides: {
            root: {
               fontWeight: 600,
               textTransform: "capitalize",
               boxShadow: "none !important",
               "&:disabled": {
                  opacity: 0.45,
                  cursor: "not-allowed",
                  color: "default",
                  backgroundColor: "default",
               },
               "&.MuiButton-sizeMedium": {
                  padding: "0.6rem 1.3rem",
                  fontSize: "0.875rem !important",
                  "@media (max-width: 760px)": {
                     fontSize: "0.8rem !important",
                     padding: "0.6rem",
                  },
               },
               "&.MuiButton-sizeSmall": {
                  padding: "6px 16px",
               },
            },
         },
      },
      MuiOutlinedInput: {
         styleOverrides: {
            root: {
               height: "3rem",
            },
            fieldSet: {
               borderRadius: 12,
            },
            input: {
               padding: "14px 14px",
               paddingRight: 0,
            },
         },
      },
      MuiTab: {
         styleOverrides: {
            root: {
               textTransform: "capitalize",
               fontWeight: 600,
               fontSize: "1rem",
               maxWidth: "unset",
               "&.Mui-selected": {
                  color: "#8937CE",
               },
               "& .MuiTabs-indicator": {
                  display: "none",
               },
            },
         },
      },
      MuiChip: {
         styleOverrides: {
            root: {
               fontWeight: 500,
               textTransform: "capitalize",
            },
         },
      },
      MuiTooltip: {
         styleOverrides: {
            tooltip: {
               // background: "#181820",
               // borderRadius: 6,
               background: "#ffffff",
               borderRadius: "12px",
               padding: "12px 14px",
               fontSize: "13px",
               fontWeight: 400,
               color: "#3b3d3b",
            },
         },
      },
   },
});

function App() {
   return (
      <ThemeProvider theme={theme}>
         <LocalizationProvider dateAdapter={AdapterMoment}>
            <ToastWidget />
            <AllRoutes />
         </LocalizationProvider>
      </ThemeProvider>
   );
}

export default App;
