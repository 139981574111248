import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import EmptyResponse from "components/EmptyResponse";
import { engagementData } from "./Components/data";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { BadgeText } from "templates/DashboardLayout/DashboardLayout";
import EachCampaignCard from "./Components/EachCampaignCard";
import CampaignIcon from "components/Vectors/CampaignIcon";
import { useGetAllCampaignsByStatusQuery } from "services/campaigns.api";

function CampaignsWidget() {
 const [emptyState, setEmptyState] = useState(true);
 // const {
 //   data: pendingData,
 //   // isLoading: pendingLoading,
 //   // isError: pendingError,
 // } = useGetAllCampaignsByStatusQuery({
 //   status: "pending",
 //   is_paginated: "yes",
 //   per_page: 10,
 // });

 const {
  data: ongoingData,
  // isLoading: ongoingLoading,
  // isError: ongoingError,
 } = useGetAllCampaignsByStatusQuery({
  status: "ongoing",
  is_paginated: "yes",
  per_page: 10,
 });

 // if (pendingData) {
 // //  console.log(pendingData);
 // }
 // if (ongoingData) {
 //  console.log(ongoingData);
 // }

 useEffect(() => {
  setTimeout(() => setEmptyState(false), 5000);
 }, []);

 const [activeTab, setactiveTab] = useState("ongoing");

 return (
  <div className="p-4 flex flex-col py-6 border rounded-xl">
   <div className="flex justify-between">
    <h2 className="dashboard-h2 text-prop ">Ongoing Campaigns</h2>
    <button className="dashboard-mini-btn text-prop ">
     <Link to="campaigns">View all</Link>
    </button>
   </div>

   <TabContext value={activeTab}>
    {/* <TabList
     variant="scrollable"
     scrollButtons="auto"
     className="mt-6 font-semibold"
     value={activeTab}
     onChange={(e, newValue) => {
      setactiveTab(newValue);
     }}
     aria-label="basic tabs example"
    >
     <Tab
      label={
       <div className="flex gap-2 items-center">
        Ongoing
        <BadgeText number={ongoingData?.data?.total} />
       </div>
      }
      value="ongoing"
     />
    </TabList> */}
    <div className="mt-[20px]">
     <div className="engagement__div overflow-hidden">
      <div
       className={`overflow-y-scroll ${
        emptyState ? "noScrollBar" : "scrollbar-style"
       } pr-[.45rem] h-[450px] flex-grow pb-[1rem] `}
      >
       <div className="flex flex-col gap-[1rem] tab__panel">
        {/* <TabPanel
                  sx={{
                    padding: "0 !important",
                  }}
                  value="pending"
                >
                  <div className="space-y-4">
                    {pendingData?.data?.data?.length === 0 ? (
                      <EmptyResponse icon={<CampaignIcon />} message="No Pending campaign to display" />
                    ) : (
                      pendingData?.data?.data?.map((value, index) => (
                        <div key={index}>
                          <EachCampaignCard status="pending" data={value} />
                        </div>
                      ))
                    )}
                  </div>
                </TabPanel> */}
        <TabPanel
         sx={{
          padding: "0 !important",
         }}
         value="ongoing"
        >
         <div className="space-y-4">
          {ongoingData?.data?.data?.length === 0 ? (
           <EmptyResponse icon={<CampaignIcon />} message="No ongoing campaign to display" />
          ) : (
           ongoingData?.data?.data?.map((value, index) => (
            <div key={index}>
             <EachCampaignCard status="ongoing" data={value} />
            </div>
           ))
          )}
         </div>
        </TabPanel>
       </div>
      </div>
     </div>
    </div>
   </TabContext>
  </div>
 );
}

export default CampaignsWidget;
