import CampaignIcon from "components/Vectors/CampaignIcon";

import NotificationCard from "./NotificationCard";

import ActionImg from "../../../assets/images/action-img.jpg";
import { useGetNotificationQuery, useMarkMultipleAsReadMutation } from "services";
import LoadingTable from "components/loadingTable";
import ErrorMsg from "components/ErrorMsg";
import TablePagination from "components/TablePagination";
import { useState } from "react";

function Notifications({ type = "influencer" }) {
  // const methods = useForm();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data: allNotifications, isLoading, isError, error } = useGetNotificationQuery({
    params: {
      is_paginated: "yes",
      per_page: perPage,
      page,
    },
  })

  const [markAsRead] = useMarkMultipleAsReadMutation();



  return (
    <div
      className={`h-full py-6 ${type === "buyer" ? "px-0" : "lg:px-10 px-4"
        } bg-grey100`}
    >
      <div className="flex justify-between items-center mb-4">
        <h1 className="title title">Notifications</h1>
        <button onClick={() => markAsRead().unwrap()} className="font-semibold text-primary text-sm md:text-base">
          Mark all as read
        </button>
      </div>

      {isLoading ? <LoadingTable className='md:max-w-[90%] mx-auto' />
        : isError ? <ErrorMsg error={error} />
          :
          <section className=''>
            <div className="bg-white rounded-xl space-y-6 border border-borderColor p-2 md:p-8">
              {/* {notifications.map((notification, index) => ( */}
              <DayEngagement notifications={allNotifications?.data} />
              {/* ))} */}
            </div>
            <TablePagination
              // type={type}
              perPage={perPage}
              meta={{
                totalCount: allNotifications?.data?.total,
                rowCount: allNotifications?.data?.data?.length,
                totalPages: allNotifications?.data?.last_page,
                currentPage: page,
              }}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </section>
      }
      {/* <div className="flex justify-between items-center mb-4">
        <p className="text-grey text-lg font-semibold">Today</p>
        <Controller
          control={methods.control}
          name={"dateFrom"}
          defaultValue={null}
          render={({ field: { ref, ...fieldRest } }) => (
            <DatePicker
              {...fieldRest}
              className="select-mui"
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ background: "white", maxWidth: 160 }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Go to Date",
                  }}
                />
              )}
            />
          )}
        />
      </div> */}
      {/* <NotificationCard
        name="Kayode orilowo "
        description={"Created an account"}
        btnText="View Profile"
        icon={ServicesIcon}
        senderImg={Sender1}
      />
      <NotificationCard
        name="Nneka Chukwu"
        description={"Requests Payout"}
        btnText="View Request"
        icon={MessageIcon}
        senderImg={Sender2}
      />
      <NotificationCard
        // status="success"
        name="Adebayo Salami"
        description={"Adeboro Gbajabiamila accepted your campaign offer"}
        btnText="View campaign"
        icon={CampaignIcon}
        senderImg={Sender4}
      />

      <p className="text-grey text-lg font-semibold mt-5 mb-4">Yesterday</p>
      <NotificationCard
        name="Tunde Idiagbon"
        // status="error"
        description={"Replied ticket"}
        btnText="View Ticket"
        // icon={CampaignIcon}
        senderImg={Sender5}
      />
      <NotificationCard
        // status="success"
        name="M. I. Wushishi"
        description={"Starts a new engagement with Kunle Chukwuka"}
        btnText="View Engagemrnt"
        icon={CampaignIcon}
        senderImg={Sender4}
      />

      <p className="text-grey text-lg font-semibold mt-5 mb-4">22 June, 2022</p>

      <NotificationCard
        // status="success"
        name="Paul Omu"
        description={"Requests Payout"}
        btnText="View Requests"
        icon={CampaignIcon}
        senderImg={Sender3}
      />

      <NotificationCard
        // status="success"
        actionNotif={true}
        name="Enagegment/service/campaign/request title"
        description={"Action performed"}
        btnText="View..."
        icon={CampaignIcon}
        senderImg={ActionImg}
      /> */}
    </div>
  );
}


const DayEngagement = ({ notifications }) => {
  // console.log(notifications)
  return (
    <>
      <div className="">
        <h2 className="text-grey font-semibold md:text-lg">
          {/* {notifications?.createdAt || 'Today'} */}
        </h2>
        <div className="flex flex-col mt-3 gap-y-4 md:gap-y-2">
          {notifications?.data?.map((notification, index) => (

            <NotificationCard
              notification={notification}
              actionNotif={true}
              name="Enagegment/service/campaign/request title"
              description={"Action performed"}
              btnText="View..."
              icon={CampaignIcon}
              senderImg={ActionImg}
            />
          ))}
        </div>
      </div>

    </>
  )
};
export default Notifications;
