import { useState, useEffect } from "react";
import { Chip, IconButton } from "@mui/material";
import StatCard from "components/StatCard";
import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import { Link } from "react-router-dom";
import { STATUS } from "utils/constants";
import moment from "moment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VideoCameraIcon from "components/Vectors/VideoCameraIcon";
import { useGetAllCreatorsQuery, useGetCreatorsStatsQuery } from "services";
import { getUserDetails, handleError } from "utils";
//import { useLazySearchUserQuery } from "services/search.api";

const AdminCreators = () => {
 const JOBS_PER_PAGE = 10;
 const [page, setPage] = useState(1);

 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);
 const {
  data: allCreators,
  isLoading,
  error,
 } = useGetAllCreatorsQuery({
  params: { is_paginated: "yes", page, per_page: perPage },
 });
 const [creators, setCreators] = useState([]);

 useEffect(() => {
  if (allCreators?.data?.data) {
   const initialCreators = allCreators?.data?.data?.filter((creator) => creator);
   setCreators(initialCreators);
  }
 }, [allCreators]);
 console.log(creators);
 const searchFunc = (value) => {
  const matchedCreators = allCreators?.data?.data?.filter((creator) =>
   creator?.first_name?.toLowerCase().includes(value.toLowerCase()),
  );
  setCreators(matchedCreators);
 };

 const { data: allCreatorsStats } = useGetCreatorsStatsQuery();

 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  selectableRows: "none",
  count: allCreators?.data?.total,
  rowsPerPage: perPage,
  serverSide: true,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 // if (allCreators) console.log(allCreators)

 const statCards = [
  {
   name: "All Creators",
   value: allCreatorsStats?.data?.total,
   iconBg: "bg-[#FAF5FF]",
   icon: <VideoCameraIcon width={32} height={32} color="#8937CE" />,
   percentage: null,
  },
  {
   name: "Active Creators",
   value: allCreatorsStats?.data?.active,
   iconBg: "bg-success-100",
   icon: <VideoCameraIcon width={32} height={32} color="#00A04A" />,
   percentage: null,
  },
  {
   name: "Inactive Creators",
   value: allCreatorsStats?.data?.inactive,
   iconBg: "bg-gray-100",
   icon: <VideoCameraIcon width={32} height={32} color="#8585A3" />,
   percentage: null,
  },
  {
   name: "Suspended Creators",
   value: allCreatorsStats?.data?.suspended,
   iconBg: "bg-[#D61E341A]",
   icon: <VideoCameraIcon width={32} height={32} color="#D61E34" />,
   percentage: null,
  },
 ];

 const headCells = [
  {
   name: "id",
   label: <p className="truncate flex text-center first-letter:font-medium text-prop text-sm">ID</p>,
   options: {
    customBodyRender: (value) => <p className="w-full ml-[-5px] text-center ">{value}</p>,
   },
  },
  {
   name: "creator",
   label: "creator",
   options: {
    customBodyRender: (value) => (
     <Link to={value?.id}>
      <UserDisplay name={value?.name} badge={value?.badge} email={value?.type} img={value?.img} />
     </Link>
    ),
   },
  },
  {
   name: "location",
   label: "Location",
   options: {
    customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
   },
  },
  {
   name: "ongoing_jobs",
   label: "Ongoing Engagements",
  },
  {
   name: "joined",
   label: "Date Joined",
   options: {
    customBodyRender: (value) => (
     <div>
      <p>{value?.name}</p>
      <p className="text-xs text-grey">{value?.ago}</p>
     </div>
    ),
   },
  },
  {
   name: "status",
   label: "status",
   options: {
    filter: true,
    customBodyRender: (value) => (
     <Chip
      color={`${STATUS[value?.toLowerCase()] || "default"}`}
      sx={{ minWidth: 100, borderRadius: "20px" }}
      label={`${value}`}
     />
    ),
   },
  },

  {
   name: "actions",
   label: "actions",
   options: {
    customBodyRender: (value) => (
     <IconButton to={value} component={Link}>
      <RemoveRedEyeOutlinedIcon />
     </IconButton>
    ),
   },
  },
 ];

 const data = creators?.map((creator, i) => ({
  // (current_page - 1) * per_page + number of data entries on the current page
  id: (allCreators?.data?.current_page - 1) * allCreators?.data?.per_page + (i + 1),
  creator: {
   id: String(creator.id),
   name: getUserDetails(creator).name,
   type: creator?.username ? "@" + creator?.username : "",
   img: creator?.profile_image,
   badge: creator?.kyc_verified,
  },
  location: creator.state?.name ? creator.state?.name + ", " + creator?.country?.name : "N/A",
  ongoing_jobs: creator?.creator_ongoing_jobs,
  joined: {
   name: moment(creator.created_at).format("MMM DD, YYYY h:m a"),
   ago: moment(creator.created_at).fromNow(),
  },
  status: creator.status,
  actions: String(creator.id),
 }));

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Creators</h1>
   </div>

   {/* Cards */}
   <div className="grid-cols-2 lg:grid-cols-4 grid gap-4">
    {statCards.map((statCard, index) => (
     <StatCard key={index} {...statCard} />
    ))}
   </div>

   {/* Table */}
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8"></div>
   <div className="flex justify-between mt-8">
    <h5 className="text-2xl font-semibold">All Creators</h5>
   </div>
   <div className="p-4 bg-white py-6 border rounded-xl mt-5">
    <MyDataTable
     options={options}
     headCells={headCells}
     isLoading={isLoading}
     error={error}
     searchFunc={searchFunc}
     data={data}
     emptyData={{
      icon: VideoCameraIcon,
      message: "No Creators",
      extraText: "There are no creators yet.",
     }}
     searchPlaceholder="Search creators..."
    />
   </div>
  </div>
 );
};

export default AdminCreators;
