import { Button, Chip } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UserDisplay from "components/TableComponent/UserDisplay";
import { Link } from "react-router-dom";
import MyDataTable from "components/TableComponent/MyDataTable";
import { useGetAllEngagementsByStatusQuery } from "services/engagements.api";
import { getUserDetails, toCurrency } from "utils";
import BagIcon from "components/Vectors/BagIcon";
import { useState, useEffect } from "react";

const ChipColor = {
 terminated: "errorInverse",
 cancelled: "greyBorder",
 ongoing: "primaryInverse",
 completed: "successInverse",
};

function EngagementTab({ status = "Ongoing" }) {
 const JOBS_PER_PAGE = 16;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);
 const { data, isLoading, isError } = useGetAllEngagementsByStatusQuery({
  status,
  params: { is_paginated: "yes", page, per_page: perPage },
 });
 const [engagements, setEngagements] = useState([]);

 useEffect(() => {
  if (data?.data) {
   const initialEngagements = data?.data?.filter((engagement) => engagement);
   setEngagements(initialEngagements);
  }
 }, [data]);

 console.log(engagements);

 const searchFunc = (val) => {
  const matchedEngagements = data?.data?.filter((engagement) =>
   engagement?.job_without_relationship?.job_title?.toLowerCase().includes(val.toLowerCase()),
  );

  setEngagements(matchedEngagements);
 };

 //  if (data) {
 //   console.log(data);
 //  }

 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  serverSide: true,
  selectableRows: "none",
  count: data?.data?.total,
  rowsPerPage: perPage,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 const bodyCells = engagements?.map((job, i) => ({
  sn: i + 1,
  job: {
   name: job?.job_without_relationship?.job_title,
   img: job?.job_without_relationship?.cover_image,
   id: job?.id,
  },
  creator: {
   name: getUserDetails(job?.service_provider).name,
   type: job?.service_provider?.username,
   badge: job?.service_provider?.kyc_verified,
   img: getUserDetails(job?.service_provider).img,
  },
  client: {
   name: getUserDetails(job?.client).name,
   username: job?.client?.user_type,
   img: getUserDetails(job?.client).img,
  },
  start: job?.start_date || "not started",
  end: job?.end_date || "not started",
  // price: toCurrency(job?.),
  price: toCurrency(parseFloat(job?.offer?.agreed_amount)),

  location: "Any Location",

  status,
  actions: (
   <Button component={Link} to={`/engagements/${job?.id}`} startIcon={<RemoveRedEyeOutlinedIcon />} color="inherit">
    View Engagement
   </Button>
  ),
 }));

 return (
  <div className="p-4 rounded-lg bg-white">
   <MyDataTable
    searchPlaceholder="Search Engagements..."
    searchFunc={searchFunc}
    options={options}
    headCells={[
     {
      name: "sn",
      label: "S/N",
      options: {
       customBodyRender: (value) => (
        <p
         className="truncate flex pl-[18px]
                   font-medium text-prop text-[14px]"
        >
         #{value}
        </p>
       ),
      },
     },
     {
      name: "job",
      label: "job",
      options: {
       customBodyRender: (value) => (
        <UserDisplay name={value.name} truncate={true} imgRounded={false} img={value.img} />
       ),
      },
     },
     {
      name: "creator",
      label: "creator",
      options: {
       customBodyRender: (value) => (
        <UserDisplay maxWidth="200px" name={value.name} badge={value.badge} email={value.type} img={value.img} />
       ),
      },
     },
     {
      name: "client",
      label: "client",
      options: {
       customBodyRender: (value) => <UserDisplay name={value.name} email={value.type} img={value.img} />,
      },
     },
     {
      name: "start",
      label: "Start",
      options: {
       customBodyRender: (value) => <p className="inline-block font-medium text-prop text-[14px]">{value}</p>,
      },
     },
     {
      name: "end",
      label: "End",
      options: {
       customBodyRender: (value) => <p className="flex font-medium text-prop text-[14px]">{value}</p>,
      },
     },
     {
      name: "price",
      label: "Price",
     },
     {
      name: "location",
      label: "Location",
      // options: {
      //  customBodyRender: (value) => <UserDisplay name={value.name} img={value.img} />,
      // },
     },
     {
      name: "status",
      label: "status",
      options: {
       filter: true,
       customBodyRender: (value) => (
        <Chip
         color={`${ChipColor[value.toLowerCase()]}`}
         sx={{ minWidth: 90, borderRadius: "20px" }}
         label={`${value}`}
        />
       ),
      },
     },

     {
      name: "actions",
      label: "actions",
      //   options: {
      //    customBodyRender: (value) => <TableDropDown tableId={"table"}>{value}</TableDropDown>,
      //   },
     },
    ]}
    data={bodyCells}
    isError={isError}
    isLoading={isLoading}
    emptyData={{
     message: `No ${status} engagements yet`,
     icon: BagIcon,
    }}
   />
  </div>
 );
}

export default EngagementTab;
