import { Avatar, Button, Grid } from "@mui/material";
import StatCard from "components/StatCard";
import TransactionIcon from "components/Vectors/TransactionIcon";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CampaignIcon from "components/Vectors/CampaignIcon";
import NewUsers from "./Widgets/NewUsers";
import { Link } from "react-router-dom";
import { useGetUser } from "hooks/getUserHook";
import PenIcon from "components/PenIcon";
import TicketsIcon from "components/Vectors/TicketIcon";
import WalletIcon from "components/Vectors/WalletIcon";
import OpenTicket from "../TickectAndPayout/OpenTicket";
import PayoutRequest from "../TickectAndPayout/PayoutRequest";
import CampaignsWidget from "./Widgets/CampaignsWidget";
import { useGetDashboardStatsQuery } from "services";
import PendingVerification from "./Widgets/PendingVerification";

const AdminDashboard = () => {
 const { data } = useGetDashboardStatsQuery();
 const stats = data?.data;
 //  console.log(stats);
 const { user } = useGetUser();
 //  console.log(user);
 //  const isSuperAdmin = user?.roles[0]?.name === "Super Admin";

 //  console.log(stats);

 return (
  <div className="h-full p-6 lg:px-10 px-4">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Dashboard</h1>
   </div>
   {user ? (
    <>
     <Grid container spacing={2}>
      <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="Ongoing Campaigns"
        value={stats?.campaign?.ongoing}
        iconBg="bg-primary-100"
        icon={<CampaignIcon width={32} height={32} color="#8937CE" />}
       />
      </Grid>
      <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="Completed Campaigns"
        value={stats?.campaign?.completed}
        iconBg="bg-primary-100"
        icon={<CampaignIcon width={32} height={32} color="#8937CE" />}
       />
      </Grid>
      {/* <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="Open Jobs"
        value={stats?.job?.open}
        iconBg="bg-primary-100"
        icon={<ServicesIcon width={32} height={32} color="#8937CE" />}
       />
      </Grid>
      <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="Completed engagements"
        value={stats?.engagement?.completed || 0}
        iconBg="bg-primary-100"
        icon={<TwoUserIcon width={32} height={32} color="#8937CE" />}
       />
      </Grid> */}
      <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="All Clients"
        value={stats?.clients?.total}
        iconBg="bg-primary-100"
        icon={<AccountCircleOutlinedIcon sx={{ width: 32, height: 32 }} color="primary" />}
       />
      </Grid>
      <Grid item xs={12} lg={3} md={6}>
       <StatCard name="All Influencers" value={stats?.influencer?.total} iconBg="bg-primary-100" icon={<PenIcon />} />
      </Grid>
      {/* <Grid item xs={12} lg={3} md={6}>
       <StatCard
        name="All Creators"
        value={stats?.creator?.total}
        iconBg="bg-primary-100"
        icon={<VideoCameraIcon color="#8937CE" />}
       />
      </Grid> */}
      <Grid item xs={12} lg={4} md={6}>
       <StatCard
        name="All Withdrawals"
        value={stats?.payouts?.completed_withdrawals_count}
        iconBg="bg-primary-100"
        icon={<TransactionIcon />}
       />
      </Grid>
      <Grid item xs={12} lg={4} md={6}>
       <StatCard
        name="Payout Requests"
        titleStyle="text-primary"
        value={stats?.payouts?.payout_requests_count}
        iconBg="bg-white"
        icon={<WalletIcon color="#8937CE" />}
        containerClass="!bg-[#efdcfe99]"
       />
      </Grid>
      <Grid item xs={12} lg={4} md={6}>
       <StatCard
        name="Open Tickets Count"
        titleStyle="text-primary"
        value={stats?.ticket?.New + stats?.ticket["In Progress"]}
        iconBg="bg-white"
        icon={<TicketsIcon color="#8937CE" />}
        containerClass="!bg-[#efdcfe99]"
       />
      </Grid>
     </Grid>

     <div className="grid gap-6 mt-6 grid-cols-2">
      <div className="flex-1">
       <PayoutRequest country="Nigeria" />
      </div>
      <div className="flex-1">
       <PayoutRequest country="United States of America" />
      </div>
      <div className="flex-1">
       <CampaignsWidget />
      </div>
      <PendingVerification />
      {/* <div className="flex-1">
                            <EngagementsWidget />
                        </div> */}

      <OpenTicket />
      <NewUsers />
     </div>
    </>
   ) : (
    <div className="flex min-h-[500px] flex-col items-center justify-center rounded-2xl md:my-8 bg-white">
     <Avatar sx={{ height: "80px", width: "80px" }} src={user.profile_image} alt={user?.first_name} />
     <h1 className="my-2 text-[32px] font-semibold capitalize">
      <span className="text-grey">Welcome, </span>
      {user?.first_name}
     </h1>
     <p className="text-lg font-medium text-grey">
      {user?.permissions?.map((permission) => permission?.name).join(", ") || ""}
     </p>
     <Button variant="contained" component={Link} to="/profile" className="!mt-4">
      View profile
     </Button>
    </div>
   )}
  </div>
 );
};

export default AdminDashboard;
