import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NewFileIcon from 'components/Vectors/NewFileIcon';
import { getUserDetails } from 'utils';

const EachVerification = ({ user }) => {
    const { id, user_type, created_at } = user;

    function getUser(userType){
        if(userType === 'client'){
            return 'Client'
        }else if (userType === 'creator'){
            return 'Creator'
        }else if( userType === 'influencer'){
            return 'Influencer'
        }
        return
    }

    return (
        <div>
            <div className="rounded-xl bg-grey100 p-3">
                <div className="mb-3 flex items-center gap-3">
                    <div className="icon-box flex h-[56px] w-[56px] items-center justify-center rounded bg-white">
                        <NewFileIcon />
                    </div>
                    <div className="stats">
                        <p className="text-base font-bold text-grey_30">KYC Verification</p>
                    </div>
                </div>
                <p className="text-[13px] font-medium capitalize text-[#aaaaaa]">{moment(created_at).fromNow()}</p>
                <Link
                    to={`/${user_type}s/${id}`}
                    className="mt-2 flex w-full items-center justify-between rounded p-1 transition-all hover:bg-grey_70"
                >
                    <div className="flex items-center gap-2">
                        <Avatar src={getUserDetails(user).img} alt={getUserDetails(user).name} />
                        <h4 className="text-base font-normal text-grey_20">{`${getUserDetails(user).name} (${getUser(user_type)})`}</h4>
                    </div>
                    <KeyboardArrowRightIcon color="secondary" />
                </Link>
            </div>
        </div>
    );
};

export default EachVerification;
