// import React from 'react';
// import './style.scss';
import React from "react";
import { Avatar, Button, MobileStepper } from "@mui/material";
import { useState } from "react";
import { Fade, IconButton, Slide } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

const imgList = [
 "https://media.istockphoto.com/id/1449364000/es/foto/habitaci%C3%B3n-peque%C3%B1a-de-estilo-minimalista.jpg?s=612x612&w=0&k=20&c=19UTeQkTwaKGMZEVJyEHd_gg0dqFA2qLQYzQ63GzvwU=",
 "https://media.istockphoto.com/id/1395324831/es/foto/equipo-de-trabajadores-manuales-examinando-el-proyecto-de-vivienda-mientras-trabajan-en-la-obra.jpg?s=612x612&w=0&k=20&c=s7hGWI72mZKWBSo06L-IChUyOGwA1gwk0LnoaIFM_ow=",
 "https://cdn.pixabay.com/photo/2016/06/24/10/47/house-1477041__340.jpg",
 "https://media.istockphoto.com/id/1393885905/es/foto/agente-de-bienes-ra%C3%ADces-mostrando-a-una-pareja-madura-una-nueva-casa.jpg?s=612x612&w=0&k=20&c=uacxMnPz94JsAu_EGA4g8azwhVR4_G2sh4FLLjFlZ0Q=",
];

const ImageSlideShow = ({ images = imgList }) => {
 const CollectionSize = images?.length;
 const theme = useTheme();
 const [activeStep, setActiveStep] = useState(0);
 const [openModal, setOpenModal] = useState(false);

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handlePrev = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };
 const closeModal = () => setOpenModal(false);

 const handleImageClick = () => {
  setOpenModal(true);
 };

 return (
  <div>
   {/* <h2>How to Create Image Slider in ReactJS?</h2> */}

   <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
    {images?.map((el, i) => (
     <img
      key={i}
      onClick={() => {
       handleImageClick();
       setActiveStep(i);
      }}
      className="aspect-square cursor-pointer rounded-2xl object-cover "
      src={el?.image_url  || el?.url}
      alt="images"
     />
    ))}
   </div>

   <Fade in={openModal}>
    <div onClick={(e) => e.target === e.currentTarget && closeModal()} className="modal-wrap">
     <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
      <div
       style={{
        maxWidth: 700,
        flexGrow: 1,
        position: "relative",
       }}
       className="w-[90%]"
      >
       <IconButton
        sx={{ background: "rgba(0, 0, 0, 0.04) !important" }}
        className="close-modal-btn z-10 "
        onClick={closeModal}
       >
        <ClearIcon sx={{ fontSize: "30px" }} color="white" />
       </IconButton>
       <Avatar
        src={images[activeStep]?.url || images[activeStep]?.image_url || images[activeStep]}
        sx={{
         height: 500,
         width: "100%",
         maxWidth: 700,
         display: "block",
         overflow: "hidden",
         borderRadius: "20px",
        }}
        alt={"active pic"}
       />
       <MobileStepper
        variant="text"
        position="static"
        activeStep={activeStep}
        steps={CollectionSize}
        sx={{ background: "transparent" }}
        nextButton={
         <Button size="medium" onClick={handleNext} color="white" disabled={activeStep === CollectionSize - 1}>
          Next
          {theme.direction !== "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
         </Button>
        }
        backButton={
         <Button size="medium" onClick={handlePrev} color="white" disabled={activeStep === 0}>
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          Back
         </Button>
        }
       />
      </div>
     </Slide>
    </div>
   </Fade>
  </div>
 );
};

export default ImageSlideShow;

// const ImageSlideShow = ({ }) => {

//   return (
//     <div className="image_container">
//       {imgList.map((img, i) =>
//         <div className="mySlides">
//           <div className="numbertext">{i + 1} / {imgList.length}</div>
//           <img src={img} style={{ width: "100%" }} />
//         </div>
//       )}

//       {/* <div className="mySlides">
//         <div className="numbertext">2 / 6</div>
//         <img src="img_5terre_wide.jpg" style={{ width: "100%" }} />
//       </div>

//       <div className="mySlides">
//         <div className="numbertext">3 / 6</div>
//         <img src="img_mountains_wide.jpg" style={{ width: "100%" }} />
//       </div>

//       <div className="mySlides">
//         <div className="numbertext">4 / 6</div>
//         <img src="img_lights_wide.jpg" style={{ width: "100%" }} />
//       </div>

//       <div className="mySlides">
//         <div className="numbertext">5 / 6</div>
//         <img src="img_nature_wide.jpg" style={{ width: "100%" }} />
//       </div>

//       <div className="mySlides">
//         <div className="numbertext">6 / 6</div>
//         <img src="img_snow_wide.jpg" style={{ width: "100%" }} />
//       </div> */}

//       <a className="prev"
//       // onClick="plusSlides(-1)"
//       >❮</a>
//       <a className="next"
//       //  onClick="plusSlides(1)"
//       >❯</a>

//       <div className="caption-container">
//         <p id="caption"></p>
//       </div>

//       {/* <div className="row">
//         <div className="column">
//           <img className="demo cursor" src="img_woods.jpg" style={{width:"100%"}} onClick="currentSlide(1)" alt="The Woods" />
//         </div>
//         <div className="column">
//           <img className="demo cursor" src="img_5terre.jpg" style={{width:"100%"}} onClick="currentSlide(2)" alt="Cinque Terre" />
//         </div>
//         <div className="column">
//           <img className="demo cursor" src="img_mountains.jpg" style={{width:"100%"}} onClick="currentSlide(3)" alt="Mountains and fjords" />
//         </div>
//         <div className="column">
//           <img className="demo cursor" src="img_lights.jpg" style={{width:"100%"}} onClick="currentSlide(4)" alt="Northern Lights" />
//         </div>
//         <div className="column">
//           <img className="demo cursor" src="img_nature.jpg" style={{width:"100%"}} onClick="currentSlide(5)" alt="Nature and sunrise" />
//         </div>
//         <div className="column">
//           <img className="demo cursor" src="img_snow.jpg" style={{width:"100%"}} onClick="currentSlide(6)" alt="Snowy Mountains" />
//         </div>
//       </div> */}
//     </div>
//   )
// }

// export default ImageSlideShow
