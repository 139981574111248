import {RemoveRedEyeOutlined} from "@mui/icons-material";
import {Chip} from "@mui/material";
import MyDataTable from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import moment from "moment";
import {Link} from "react-router-dom";
import {useGetAdminsQuery} from "services";
import {getUserDetails} from "utils";
import {STATUS3} from "utils/constants";

const AdministratorTab = () => {
  const {data, isLoading, isError, error} = useGetAdminsQuery();

  return (
    <div className="p-4 bg-white py-6 border rounded-xl">
      <MyDataTable
        isLoading={isLoading}
        isError={isError}
        error={error}
        searchPlaceholder="Search Administrators"
        options={{
          searchOpen: true,
        }}
        headCells={[
          {name: "sn", label: "s/n"},
          {
            name: "admin",
            label: "admin",
            options: {
              customBodyRender: (value) => (
                <UserDisplay name={value?.name} img={value?.img} />
              ),
            },
          },
          {
            name: "email",
            label: "email address",
          },
          {name: "permissions", label: "permissions"},

          {
            name: "joined",
            label: "Date Added",
          },
          {
            name: "status",
            label: null,
            options: {
              filter: true,
              customBodyRender: (value) => (
                <Chip
                  color={`${
                    value === "suspended"
                      ? "errorInverse"
                      : STATUS3[value.toLowerCase()] || "default"
                  }`}
                  sx={{minWidth: 100}}
                  label={`${value}`}
                />
              ),
            },
          },
          {
            name: "actions",
            label: "",
            options: {
              customBodyRender: (value) => (
                <Link to={`admin/${value}`}>
                  <RemoveRedEyeOutlined /> View Admin
                </Link>
              ),
            },
          },
        ]}
        data={data?.data?.map((el, i) => ({
          sn: i + 1,
          admin: {
            name: getUserDetails(el).name,
            img: getUserDetails(el).img,
          },
          permissions: el?.permissions_count || 0,
          status: el?.status,
          email: el?.email,
          joined: moment(el?.created_at).format("MMM DD, YYYY"),
          actions: el?.id,
        }))}
      />
    </div>
  );
};

export default AdministratorTab;
