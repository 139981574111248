import ChipsWrap from "components/ChipsWrap";
import { mock_ct } from "utils/mock_data";
import UserProfileCard from "components/UserProfileCard";
import InfoCard from "components/InfoCard";
import ImageSlideShow from "components/ImageSlideshow";
import Video from "components/Video";
import UserDisplay from "components/TableComponent/UserDisplay";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Button, Chip } from "@mui/material";
import RefundModal from "./Widgets/RefundModal";
import { initialUser } from "hooks/getUserHook";
import { useState } from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

const EachCampaignComp = ({ data, status, terminationReason, cancellationReason }) => {
 const [showRefundModal, setShowRefundModal] = useState(false);
 console.log(data);

 return (
  <div>
   <div className="flex flex-col ">
    {(status?.toLowerCase() === "cancelled" || status?.toLowerCase() === "terminated") && (
     <div className="mb-6 rounded-[18px] bg-grey100 -mt-2 p-5 ">
      <div className="flex flex-wrap items-center justify-between gap-3">
       <p className={`font-bold text-grey10 ${status?.toLowerCase() === "cancelled" ? "text-sm" : ""}`}>
       <span className="text-lg text-[#FFD009]">
        ⚠   </span> Reason for{" "}
     {status?.toLowerCase() === "cancelled" ? "Cancellation" : "Termination"}
       </p>
      </div>
      <p className="mb-1 mt-3 text-sm text-grey30">{status?.toLowerCase() === "terminated"? terminationReason: cancellationReason}</p>
      {status?.toLowerCase() === "terminated" && (
       <div className="flex justify-between">
        <div className="mt-3 flex flex-wrap items-center justify-between gap-3">
         <Chip
          sx={{
           borderRadius: "8px",
           bgcolor: "#fff",
           color: "#000",
          }}
          size="small"
          label="Pending refund"
         />
        </div>
        <Button
         endIcon={<ChevronRightOutlinedIcon />}
         sx={{ p: "0.5rem !important" }}
         onClick={() => setShowRefundModal(true)}
        >
         Refund Client
        </Button>
       </div>
      )}
     </div>
    )}

    <div className="flex mb-6 gap-2 justify-between">
     <div className="">
      <span className="text-prop font-medium text-grey50 text-[14px] ">Price</span>
      <p className="font-semibold leading-[140%] text-[32px] tracking-[-0.06em] text-grey10 ">₦{data?.amount}</p>
     </div>
     <div className="">
      <p className={`font-semibold text-[16px] text-prop mb-[4px] text-grey10`}>Location</p>
      {data?.job?.location_type === "any_Location" ? (
       "Any Location"
      ) : (
       <UserDisplay name={"Texas, US"} img={"/images/texas.png"} />
      )}
     </div>
    </div>

    {/* info cards  */}
    <div>
     <UserProfileCard user={data?.client} btnTitle="Client" />
     <UserProfileCard type="creator" user={data?.service_provider} badge={data?.service_provider?.kyc_verified} btnTitle="Creator" />

     {/* info 2  */}
     <div className="mt-[24px] flex gap-4 justify-between ">
      <InfoCard title="Category" description={data?.job?.category?.name} />
      {/* <InfoCard title="Expected Delivery" description={`${data?.job?.duration_count} ${data?.job?.duration_type}`} /> */}
     </div>

     {/* chip  */}
     <ChipsWrap
      chipsArr={data?.job?.tags}
      title="Tags"
      className="text-grey10 mb-[12px] text-[18px] font-semibold mt-[24px] text-prop "
     />
     {/* description  */}
     <div className={`mt-[24px]`}>
      <span className={`font-semibold text-[16px] text-prop mb-[4px] text-grey10`}>Description</span>
      <p className={`text-prop font-medium text-[1rem] text-grey`}>{data?.job?.description}</p>
     </div>
     <div className="my-7">
      <p className="font-semibold mt-4 mb-2">Images</p>
      <ImageSlideShow images={data?.job.images} />
     </div>
     <div className="my-7">
      <p className="font-semibold mt-4 mb-2">Videos</p>
      {/* {data?.job?.videos?.length !== 0 && <Video videoUrl={data?.job?.video[0].video_url} />} */}
     </div>
    </div>
   </div>

   <RefundModal
    open={showRefundModal}
    close={() => setShowRefundModal(false)}
    client={initialUser}
    transactionId={123}
   />
  </div>
 );
};
export default EachCampaignComp;
