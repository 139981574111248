function UserIconFilled(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40 6.6665C31.2666 6.6665 24.1666 13.7665 24.1666 22.4998C24.1666 31.0665 30.8666 37.9998 39.6 38.2998C39.8666 38.2665 40.1333 38.2665 40.3333 38.2998C40.4 38.2998 40.4333 38.2998 40.5 38.2998C40.5333 38.2998 40.5333 38.2998 40.5666 38.2998C49.1 37.9998 55.8 31.0665 55.8333 22.4998C55.8333 13.7665 48.7333 6.6665 40 6.6665Z"
        fill={props.color ? props.color : "#8585A3"}
      />
      <path
        d="M56.9333 47.1666C47.6333 40.9666 32.4667 40.9666 23.1 47.1666C18.8667 49.9999 16.5333 53.8333 16.5333 57.9333C16.5333 62.0333 18.8667 65.8333 23.0667 68.6333C27.7333 71.7666 33.8667 73.3333 40 73.3333C46.1333 73.3333 52.2667 71.7666 56.9333 68.6333C61.1333 65.7999 63.4667 61.9999 63.4667 57.8666C63.4333 53.7666 61.1333 49.9666 56.9333 47.1666Z"
        fill={props.color ? props.color : "#8585A3"}
      />
    </svg>
  );
}
export default UserIconFilled;
