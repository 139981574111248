import {useState} from "react";
import {Button, Chip, Skeleton, Stack} from "@mui/material";
import SearchInput from "components/forms/SearchInput";
import AddBannedWordModal from "pages/Dashboard/Settings/Modals/AddBannedWordModal";
import {useDeleteBannedWordMutation, useGetAllBannedWordsQuery} from "services";
import {showToast} from "store/store.hooks";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const BannedWords = () => {
  const [showModal, setShowModal] = useState(false);
  const {data: allWords, isLoading} = useGetAllBannedWordsQuery();
  const [search, setSearch] = useState("");
  const [deleteWord] = useDeleteBannedWordMutation();

  return (
    <section className="h-screen">
      <h2 className="font-semibold text-prop text-[20px] text-grey30">
        Banned Words
      </h2>
      <p className="text-prop font-medium text-[14px] text-grey mb-4 mt-[35px]">
        The Following words are not allowed during conversations
      </p>
      <div>
        <div className="flex justify-between">
          <SearchInput
            placeholder="Search Word"
            value={search}
            onChange={({target}) => setSearch(target.value)}
          />
          <Button variant="contained" onClick={() => setShowModal(true)}>
            Add words
          </Button>
        </div>
        <Stack direction="row" sx={{mt: "24px", flexWrap: "wrap", gap: 1}}>
          {isLoading
            ? [1, 2, 3, 4].map((item) => (
                <Skeleton key={item} width={70} height={55} />
              ))
            : search.length > 0
            ? allWords?.data
                .filter((word) =>
                  word?.name?.toLowerCase().includes(search.toLowerCase())
                )
                .map((word) => (
                  <Chip
                    key={word?.id}
                    label={word?.name}
                    onDelete={() => {
                      deleteWord(word?.id)
                        .unwrap()
                        .then(() => {
                          showToast(
                            `${word?.name} deleted successfully`,
                            "success"
                          );
                        });
                    }}
                    deleteIcon={<ClearOutlinedIcon />}
                    sx={{
                      bgcolor: "#F2F4F7",
                      borderRadius: "8px",
                      color: "#1d1d1d8f",
                      minHeight: "40px",
                      minWidth: "100px",
                      textTransform: "capitalize",
                      fontSize: "14px",
                    }}
                  />
                ))
            : allWords?.data.map((word) => (
                <Chip
                  key={word?.id}
                  label={word?.name}
                  onDelete={() => {
                    deleteWord(word?.id)
                      .unwrap()
                      .then(() => {
                        showToast(
                          `${word?.name} deleted successfully`,
                          "success"
                        );
                      });
                  }}
                  deleteIcon={<ClearOutlinedIcon />}
                  sx={{
                    bgcolor: "#F2F4F7",
                    borderRadius: "8px",
                    color: "#1d1d1d8f",
                    minHeight: "40px",
                    minWidth: "100px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                  }}
                />
              ))}
        </Stack>
      </div>
      <AddBannedWordModal open={showModal} close={() => setShowModal(false)} />
    </section>
  );
};
export default BannedWords;
