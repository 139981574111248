function CancelOutlineIcon(props) {
  return (
   
  

    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1824 5.52482L5.5255 11.1817C5.33223 11.375 5.01167 11.375 4.8184 11.1817C4.62512 10.9884 4.62512 10.6678 4.8184 10.4746L10.4753 4.81772C10.6685 4.62444 10.9891 4.62444 11.1824 4.81772C11.3756 5.01099 11.3756 5.33155 11.1824 5.52482Z"
        fill={props.color || "#2C2C3A"}
      />
      <path
        d="M11.1814 11.1823C10.9881 11.3756 10.6676 11.3756 10.4743 11.1823L4.81742 5.52543C4.62414 5.33215 4.62414 5.0116 4.81742 4.81832C5.0107 4.62505 5.33125 4.62505 5.52453 4.81832L11.1814 10.4752C11.3747 10.6685 11.3747 10.989 11.1814 11.1823Z"
        fill={props.color || "#2C2C3A"}
      />
    </svg>
  );
}

export default CancelOutlineIcon;
