import StatCard from "components/StatCard";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import JobsTab from "./Widgets/JobsTab";
import VideoCameraIcon from "components/Vectors/VideoCameraIcon";
import { useGetJobsStatisticsQuery } from "services/jobs.api";
import { useGetSingleEngagementQuery } from "services/engagements.api";

export const avatar_group_style = {
 justifyContent: "flex-end",
 "& .MuiAvatar-root": {
  width: 32,
  height: 32,
  borderRadius: "0",
  border: 0,
  marginLeft: 0,
  marginRight: "8px",
  "&.MuiAvatar-colorDefault": {
   borderRadius: "100%",
   fontWeight: 500,
   fontSize: "12px",
   color: "black",
   background: "#DCDCE5",
  },
 },
};

const AdminJobs = () => {
 const [searchParams] = useSearchParams();
 const { data: stat, isLoading: isStatsLoading, isError: isStatsError } = useGetJobsStatisticsQuery();
 const stats = stat?.data;
 const tab = searchParams.get("tab");
 const [activeTab, setActiveTab] = useState("open");
 const navigate = useNavigate();
 const id = 11;
 const { data, isLoading, isError } = useGetSingleEngagementQuery({
  id,
 });

//  if (data) console.log(data);

 useEffect(() => {
  if (tab) setActiveTab(tab);
 }, [tab]);

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-4">
    <h1 className="title">Jobs</h1>
   </div>
   <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4">
    <StatCard
     iconDown
     name="Open Jobs"
     value={stats?.open}
     isLoading={isStatsLoading}
     isError={isStatsError}
     iconBg="bg-[#FAF5FF]"
     icon={<VideoCameraIcon width={32} height={32} color="#8937CE" />}
    />
    <StatCard
     iconDown
     name="Closed Jobs"
     value={stats?.closed}
     isLoading={isStatsLoading}
     isError={isStatsError}
     iconBg="bg-[#FAEBED]"
     icon={<VideoCameraIcon width={32} height={32} color="#D61E34" />}
    />
    <StatCard
     iconDown
     name="Drafted Jobs"
     value={stats?.drafted}
     isLoading={isStatsLoading}
     isError={isStatsError}
     iconBg="bg-[#EAEAF094]"
     icon={<VideoCameraIcon width={32} height={32} color="#8585A3" />}
    />
    <StatCard
     iconDown
     name="Suspended Jobs"
     value={stats?.suspended}
     isLoading={isStatsLoading}
     isError={isStatsError}
     iconBg="bg-[#FAEBED]"
     icon={<VideoCameraIcon width={32} height={32} color="#D61E34" />}
    />
   </div>

   <div className="mt-5">
    <TabContext value={activeTab}>
     <TabList
      variant="scrollable"
      scrollButtons="auto"
      className="mt-6 font-semibold"
      value={activeTab}
      sx={{
       textTransform: "capitalize !important",
      }}
      onChange={(e, newValue) => {
       navigate(`?tab=${newValue}`);
       setActiveTab(newValue);
      }}
      aria-label="basic tabs example"
     >
      <Tab label={"Open Jobs"} value="open" />
      <Tab label={"Closed Jobs"} value="closed" />
      <Tab label={"Drafted Jobs"} value="drafted" />
      <Tab label={"Suspended Jobs"} value="suspended" />
     </TabList>
     <TabPanel value="open">
      <JobsTab status="open" />
     </TabPanel>
     <TabPanel value="closed">
      <JobsTab status="closed" />
     </TabPanel>
     <TabPanel value="drafted">
      <JobsTab status="drafted" />
     </TabPanel>
     <TabPanel value="suspended">
      <JobsTab status="suspended" />
     </TabPanel>
    </TabContext>
   </div>
  </div>
 );
};

export default AdminJobs;
