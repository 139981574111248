const ShieldTickIcon = ({color, width, height}) => {
  return (
    <svg
      width={width || "80"}
      height={height || "80"}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9999 75.8674C36.3666 75.8674 32.7666 74.8008 29.9332 72.7008L15.5999 62.0008C11.7999 59.1674 8.83325 53.2341 8.83325 48.5341V23.7341C8.83325 18.6008 12.5999 13.1341 17.4333 11.3341L34.0666 5.10078C37.3666 3.86745 42.5666 3.86745 45.8666 5.10078L62.4999 11.3341C67.3332 13.1341 71.0999 18.6008 71.0999 23.7341V48.5008C71.0999 53.2341 68.1332 59.1341 64.3332 61.9674L49.9999 72.6674C47.2332 74.8008 43.6333 75.8674 39.9999 75.8674ZM35.8333 9.80078L19.1999 16.0341C16.3666 17.1008 13.8666 20.7008 13.8666 23.7674V48.5341C13.8666 51.7008 16.0999 56.1341 18.5999 58.0008L32.9333 68.7008C36.7666 71.5674 43.2332 71.5674 47.0999 68.7008L61.4333 58.0008C63.9666 56.1008 66.1666 51.7008 66.1666 48.5341V23.7341C66.1666 20.7008 63.6666 17.1008 60.8332 16.0008L44.1999 9.76745C41.9333 8.96745 38.0666 8.96745 35.8333 9.80078Z"
        fill={color || "#8937CE"}
      />
      <path
        d="M35.5334 47.4344C34.9 47.4344 34.2667 47.201 33.7667 46.701L28.4 41.3344C27.4334 40.3677 27.4334 38.7677 28.4 37.801C29.3667 36.8344 30.9667 36.8344 31.9334 37.801L35.5334 41.401L48.1 28.8344C49.0667 27.8677 50.6667 27.8677 51.6334 28.8344C52.6 29.801 52.6 31.401 51.6334 32.3677L37.3 46.701C36.8 47.201 36.1667 47.4344 35.5334 47.4344Z"
        fill={color || "#8937CE"}
      />
    </svg>
  );
};

export default ShieldTickIcon;
