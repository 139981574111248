function TwoUserIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.2128 15.4927C12.1728 15.4927 12.1461 15.4927 12.1061 15.4927C12.0395 15.4793 11.9461 15.4793 11.8661 15.4927C7.99947 15.3727 5.07947 12.3327 5.07947 8.58602C5.07947 4.77268 8.18613 1.66602 11.9995 1.66602C15.8128 1.66602 18.9195 4.77268 18.9195 8.58602C18.9061 12.3327 15.9728 15.3727 12.2528 15.4927C12.2395 15.4927 12.2261 15.4927 12.2128 15.4927ZM11.9995 3.66602C9.2928 3.66602 7.07947 5.87935 7.07947 8.58602C7.07947 11.2527 9.15947 13.3993 11.8128 13.4927C11.8928 13.4793 12.0661 13.4793 12.2395 13.4927C14.8528 13.3727 16.9061 11.226 16.9195 8.58602C16.9195 5.87935 14.7061 3.66602 11.9995 3.66602Z"
        fill={props.color || "white"}
      />
      <path
        d="M22.0528 15.6673C22.0128 15.6673 21.9728 15.6673 21.9328 15.654C21.3861 15.7073 20.8261 15.3207 20.7728 14.774C20.7195 14.2273 21.0528 13.734 21.5995 13.6673C21.7595 13.654 21.9328 13.654 22.0795 13.654C24.0261 13.5473 25.5461 11.9473 25.5461 9.98732C25.5461 7.96065 23.9061 6.32065 21.8795 6.32065C21.3328 6.33398 20.8795 5.88065 20.8795 5.33398C20.8795 4.78732 21.3328 4.33398 21.8795 4.33398C24.9995 4.33398 27.5461 6.88065 27.5461 10.0007C27.5461 13.0673 25.1461 15.5473 22.0928 15.6673C22.0795 15.6673 22.0661 15.6673 22.0528 15.6673Z"
        fill={props.color || "white"}
      />
      <path
        d="M12.2261 30.0673C9.61281 30.0673 6.98615 29.4006 4.99948 28.0673C3.14615 26.8406 2.13281 25.1607 2.13281 23.334C2.13281 21.5073 3.14615 19.814 4.99948 18.574C8.99948 15.9207 15.4795 15.9207 19.4528 18.574C21.2928 19.8007 22.3195 21.4806 22.3195 23.3073C22.3195 25.134 21.3061 26.8273 19.4528 28.0673C17.4528 29.4006 14.8395 30.0673 12.2261 30.0673ZM6.10615 20.254C4.82615 21.1073 4.13281 22.2007 4.13281 23.3473C4.13281 24.4807 4.83948 25.574 6.10615 26.414C9.42615 28.6406 15.0261 28.6406 18.3461 26.414C19.6261 25.5606 20.3195 24.4673 20.3195 23.3206C20.3195 22.1873 19.6128 21.094 18.3461 20.254C15.0261 18.0407 9.42615 18.0407 6.10615 20.254Z"
        fill={props.color || "white"}
      />
      <path
        d="M24.4528 27.6667C23.9862 27.6667 23.5728 27.3467 23.4795 26.8667C23.3728 26.32 23.7195 25.8 24.2528 25.68C25.0928 25.5067 25.8662 25.1734 26.4662 24.7067C27.2262 24.1334 27.6395 23.4134 27.6395 22.6534C27.6395 21.8934 27.2262 21.1734 26.4795 20.6134C25.8928 20.16 25.1595 19.84 24.2928 19.64C23.7595 19.52 23.4128 18.9867 23.5328 18.44C23.6528 17.9067 24.1862 17.56 24.7328 17.68C25.8795 17.9334 26.8795 18.3867 27.6928 19.0134C28.9328 19.9467 29.6395 21.2667 29.6395 22.6534C29.6395 24.04 28.9195 25.36 27.6795 26.3067C26.8528 26.9467 25.8128 27.4134 24.6662 27.64C24.5862 27.6667 24.5195 27.6667 24.4528 27.6667Z"
        fill={props.color || "white"}
      />
    </svg>
  );
}

export default TwoUserIcon;
