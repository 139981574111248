function WalletAddIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5 16.1914H9.5C9.09 16.1914 8.75 15.8514 8.75 15.4414C8.75 15.0314 9.09 14.6914 9.5 14.6914H14.5C14.91 14.6914 15.25 15.0314 15.25 15.4414C15.25 15.8514 14.91 16.1914 14.5 16.1914Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V13C11.25 12.59 11.59 12.25 12 12.25C12.41 12.25 12.75 12.59 12.75 13V18C12.75 18.41 12.41 18.75 12 18.75Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M5.2 10.4599C5.01 10.4599 4.82 10.3799 4.67 10.2399C4.46 10.0299 4.39 9.69988 4.51 9.41988L6.37 4.97988C6.41 4.88988 6.43 4.82988 6.46 4.77988C7.94 1.36988 9.83 0.539877 13.17 1.81988C13.36 1.88988 13.51 2.03988 13.59 2.22988C13.67 2.41988 13.67 2.62988 13.59 2.81988L10.66 9.61988C10.54 9.88988 10.27 10.0699 9.97 10.0699H7.12C6.55 10.0699 6.01 10.1799 5.49 10.3999C5.4 10.4399 5.3 10.4599 5.2 10.4599ZM10.61 2.74988C9.37 2.74988 8.61 3.55988 7.82 5.39988C7.81 5.42988 7.79 5.45988 7.78 5.48988L6.47 8.59988C6.69 8.57988 6.9 8.56988 7.12 8.56988H9.47L11.88 2.96988C11.41 2.81988 10.99 2.74988 10.61 2.74988Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M18.29 10.2686C18.22 10.2686 18.14 10.2586 18.07 10.2386C17.7 10.1286 17.29 10.0686 16.87 10.0686H9.96998C9.71998 10.0686 9.47998 9.93856 9.33998 9.72856C9.20998 9.51856 9.17998 9.24856 9.27998 9.01856L12.18 2.28856C12.33 1.92856 12.77 1.68856 13.14 1.80856C13.26 1.84856 13.37 1.89856 13.49 1.94856L15.85 2.93856C17.23 3.50856 18.15 4.10856 18.75 4.82856C18.87 4.96856 18.97 5.11856 19.06 5.26856C19.17 5.43856 19.27 5.64856 19.34 5.85856C19.37 5.92856 19.42 6.05856 19.45 6.19856C19.73 7.13856 19.59 8.30856 18.99 9.80856C18.87 10.0886 18.59 10.2686 18.29 10.2686ZM11.11 8.56856H16.88C17.2 8.56856 17.51 8.59856 17.82 8.64856C18.1 7.77856 18.16 7.10856 18 6.56856C17.98 6.47856 17.96 6.43856 17.95 6.39856C17.89 6.23856 17.85 6.14856 17.8 6.06856C17.73 5.95856 17.68 5.86856 17.6 5.77856C17.17 5.25856 16.41 4.77856 15.28 4.31856L13.3 3.48856L11.11 8.56856Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M15.9 22.7503H8.1C7.82 22.7503 7.56 22.7303 7.3 22.7003C3.79 22.4603 1.79 20.4603 1.55 16.9103C1.52 16.6903 1.5 16.4203 1.5 16.1503V14.2003C1.5 11.9503 2.84 9.92031 4.91 9.02031C5.61 8.72031 6.36 8.57031 7.13 8.57031H16.89C17.46 8.57031 18.01 8.65031 18.52 8.81031C20.87 9.52031 22.52 11.7403 22.52 14.2003V16.1503C22.52 16.3703 22.51 16.5803 22.5 16.7803C22.28 20.6903 20 22.7503 15.9 22.7503ZM7.12 10.0703C6.55 10.0703 6.01 10.1803 5.49 10.4003C3.97 11.0603 2.99 12.5503 2.99 14.2003V16.1503C2.99 16.3603 3.01 16.5703 3.03 16.7703C3.22 19.6203 4.62 21.0203 7.43 21.2103C7.68 21.2403 7.88 21.2603 8.09 21.2603H15.89C19.19 21.2603 20.81 19.8103 20.97 16.7103C20.98 16.5303 20.99 16.3503 20.99 16.1503V14.2003C20.99 12.3903 19.78 10.7703 18.06 10.2403C17.69 10.1303 17.28 10.0703 16.86 10.0703H7.12V10.0703Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M2.23999 14.9518C1.82999 14.9518 1.48999 14.6118 1.48999 14.2018V11.2718C1.48999 8.12175 3.71999 5.40175 6.79999 4.80175C7.06999 4.75175 7.34999 4.85175 7.52999 5.06175C7.69999 5.27175 7.74999 5.57175 7.63999 5.82175L5.88999 10.0018C5.80999 10.1818 5.66999 10.3218 5.49999 10.4018C3.97999 11.0618 2.99999 12.5518 2.99999 14.2018C2.98999 14.6118 2.65999 14.9518 2.23999 14.9518ZM5.59999 6.82175C4.31999 7.54175 3.38999 8.80175 3.09999 10.2718C3.53999 9.82175 4.04999 9.44175 4.62999 9.16175L5.59999 6.82175Z"
        fill={props.color || "#8937CE"}
      />
      <path
        d="M21.76 14.9498C21.35 14.9498 21.01 14.6098 21.01 14.1998C21.01 12.3898 19.8 10.7698 18.08 10.2398C17.88 10.1798 17.71 10.0398 17.62 9.84978C17.53 9.65978 17.52 9.43978 17.6 9.24978C18.07 8.07978 18.19 7.22978 18 6.56978C17.98 6.47978 17.96 6.43978 17.95 6.39978C17.82 6.10978 17.89 5.76978 18.12 5.54978C18.35 5.32978 18.7 5.27978 18.98 5.42978C21.16 6.56978 22.51 8.80978 22.51 11.2698V14.1998C22.51 14.6098 22.17 14.9498 21.76 14.9498ZM19.25 9.08978C19.88 9.37978 20.44 9.78978 20.91 10.2798C20.72 9.29978 20.25 8.40978 19.56 7.70978C19.51 8.12978 19.41 8.58978 19.25 9.08978Z"
        fill={props.color || "#8937CE"}
      />
    </svg>
  );
}

export default WalletAddIcon;
