import { Button, CircularProgress } from "@mui/material";
import MessageModal from "components/Modal/MessageModal";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { useSearchParams } from "react-router-dom";
import { useDeleteCityMutation } from "services";
import { LoadingButton } from "@mui/lab";

const DeleteCityModal = ({ open, close, city }) => {
 const [searchParams] = useSearchParams();
 const stateId = searchParams.get("state");
 const [deleteCity, { isLoading }] = useDeleteCityMutation();

 const handleDelete = async () => {
  try {
   const res = await deleteCity({ stateId, cityId: city?.id }).unwrap();
   showToast(res?.message);
   close();
  } catch (err) {
   handleError(err);
  }
 };
 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   title={`Are you sure you want to Delete ${city?.name}`}
   description="Deleting doesn’t mean lorem ipsum"
   cta={
    <div className="grid grid-cols-2 gap-4 w-full">
     <Button variant="outlined" color="error" onClick={close}>
      Cancel
     </Button>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading}
      onClick={handleDelete}
      variant="contained"
      color="error"
     >
      Delete
     </LoadingButton>
    </div>
   }
  />
 );
};

export default DeleteCityModal;
