import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import Modal from "components/Modal";
import ToggleSwitch from "components/ToggleSwitch";
import UploadImage from "components/forms/UploadImage";
import ValidatedInput from "components/forms/ValidatedInput";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAddCountryMutation, useEditCountryMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const ManageCountryModal = ({ open, close, isEditing, country }) => {
 const methods = useForm();
 const status = methods.watch("status");
 const [addCountry, { isLoading: isAddingCountry }] = useAddCountryMutation();
 const [editCountry, { isLoading: isEditingCountry }] = useEditCountryMutation();
 useEffect(() => {
  if (isEditing) {
   methods.reset({
    ...country,
    country_flag: country?.country_flag || "",
   });
  }
 }, [country, isEditing, methods]);
 const handleSubmit = async (data) => {
  const addingData = new FormData();
  addingData.append("name", data.name);
  if (typeof data.country_flag === "object") {
   addingData.append("country_flag", data.country_flag[0]);
  }
  addingData.append("status", isEditing ? status : "active");
  try {
   isEditing
    ? await editCountry({
       id: country?.id,
       body: addingData,
      })
       .unwrap()
       .then((res) => {
        showToast(res?.message);
       })
    : await addCountry(addingData)
       .unwrap()
       .then((res) => {
        showToast(res?.message);
       });
   methods.reset();
   close();
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <Modal
   openModal={open}
   closeModal={() => {
    close();
    methods.reset();
   }}
   title={isEditing ? "Edit Country" : "Add Country"}
  >
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
     <ValidatedInput name="name" label="Country Name" placeholder="Country Name" rules={{ required: true }} />
     <UploadImage label="Country Flag" name="country_flag" required={isEditing ? false : true} />
     {isEditing && (
      <div className="my-6">
       <p className="text-grey30 text-sm mb-2">Status</p>
       <ToggleSwitch
        name="status"
        toggleState={status === "active"}
        setToggleState={() => {
         methods.setValue("status", status === "active" ? "inactive" : "active");
        }}
       />
      </div>
     )}
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isAddingCountry || isEditingCountry}
      fullWidth
      variant="contained"
      type="submit"
     >
      {isEditing ? "Edit Country" : "Add Country"}
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
};

export default ManageCountryModal;
