const SquareTickIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4993 18.9587H7.49935C2.97435 18.9587 1.04102 17.0253 1.04102 12.5003V7.50033C1.04102 2.97533 2.97435 1.04199 7.49935 1.04199H12.4993C17.0243 1.04199 18.9577 2.97533 18.9577 7.50033V12.5003C18.9577 17.0253 17.0243 18.9587 12.4993 18.9587ZM7.49935 2.29199C3.65768 2.29199 2.29102 3.65866 2.29102 7.50033V12.5003C2.29102 16.342 3.65768 17.7087 7.49935 17.7087H12.4993C16.341 17.7087 17.7077 16.342 17.7077 12.5003V7.50033C17.7077 3.65866 16.341 2.29199 12.4993 2.29199H7.49935Z"
        fill={props.color ? props.color : "#00A04A"}
      />
      <path
        d="M8.81719 12.9837C8.65052 12.9837 8.49219 12.917 8.37552 12.8003L6.01719 10.442C5.77552 10.2003 5.77552 9.80033 6.01719 9.55866C6.25885 9.31699 6.65885 9.31699 6.90052 9.55866L8.81719 11.4753L13.1005 7.19199C13.3422 6.95033 13.7422 6.95033 13.9839 7.19199C14.2255 7.43366 14.2255 7.83366 13.9839 8.07533L9.25885 12.8003C9.14219 12.917 8.98385 12.9837 8.81719 12.9837Z"
        fill={props.color ? props.color : "#00A04A"}
      />
    </svg>
  );
};
export default SquareTickIcon;
