import Modal from "components/Modal";
import { useAddBannedWordMutation } from "services";
import { FormProvider, useForm } from "react-hook-form";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import { Button, CircularProgress } from "@mui/material";
import TagsInput from "components/forms/TagsInput";
import { LoadingButton } from "@mui/lab";

const AddBannedWordModal = ({ open, close }) => {
 const [addWord, { isLoading }] = useAddBannedWordMutation();
 const methods = useForm({
  mode: "all",
 });

 const onSubmit = async (data) => {
  try {
   const res = await addWord({
    banned_words: data.banned_words,
   }).unwrap();
   close();
   showToast(res?.message);
  } catch (err) {
   handleError(err);
  }
 };

 return (
  <Modal openModal={open} closeModal={close} title={"Add banned word(s)"}>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} className="h-full">
     <div className="flex flex-col flex-grow">
      <p className="text-prop font-medium text-[14px] mt-[2rem] text-grey">Separate words by clicking ","</p>

      <div className="mt-4">
       <p className="font-[400] text-prop text-[12px] mb-2 text-grey">Ban word</p>
       <TagsInput name="banned_words" required={true} />
      </div>

      <div className="flex justify-between pt-3 items-center gap-4 mt-[50px]">
       <div className="flex-1 w-full">
        <Button variant="outlined" fullWidth onClick={close}>
         Cancel
        </Button>
       </div>

       <div className="flex-1 w-full">
        <LoadingButton
         loading={isLoading}
         type="submit"
         loadingIndicator={
          <CircularProgress
           sx={{
            color: "#ffffff",
           }}
           size="1.2rem"
          />
         }
         variant="contained"
         fullWidth
        >
         Submit
        </LoadingButton>
       </div>
      </div>
     </div>
    </form>
   </FormProvider>
  </Modal>
 );
};
export default AddBannedWordModal;
