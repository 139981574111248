import { useState } from "react";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import UserDisplay from "components/TableComponent/UserDisplay";
import MessageModal from "components/Modal/MessageModal";
import UserIconFilled from "components/Vectors/UserIconFilled";
import {
 useSuspendClientAccountMutation,
 useSuspendCreatorAccountMutation,
 useSuspendInfluencerAccountMutation,
 useSuspendUserMutation,
} from "services";
import { LoadingButton } from "@mui/lab";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { CircularProgress } from "@mui/material";

const SuspendUserModal = ({ user, openModal, setOpenModal }) => {
 const { pathname } = useLocation();
 const [{ isLoading: isSuspendLoading }] = useSuspendUserMutation();
 const [suspendCreator] = useSuspendCreatorAccountMutation();
 const [suspendClient] = useSuspendClientAccountMutation();
 const [suspendInfluencer] = useSuspendInfluencerAccountMutation();
 const [confirmRestore, setConfirmRestore] = useState(false);
 const [showCantSuspendModal, setShowCantSuspendModal] = useState(false);
 const isSuspended = user?.status?.toLowerCase() === "suspended";
 // console.log(user);
 const type = pathname.split("/")[1].slice(0, -1);

 const methods = useForm({
  mode: "all",
 });

 const onSubmit = async (body) => {
  setOpenModal(false);
  // setShowCantSuspendModal(true);
  methods.reset();

  const creatorRequestBody = {
   ...body, // Form values
   creator_id: user?.id,
  };

  const clientRequestBody = {
   ...body, // Form values
   client_id: user?.id,
  };

  const influencerRequestBody = {
   ...body, // Form values
   influencer_id: user?.id,
  };

  try {
   if (user?.user_type === "creator") {
    let res = await suspendCreator(creatorRequestBody).unwrap();
    console.log(res);
    setConfirmRestore(true);
    showToast(res?.message);
   }

   if (user?.user_type === "client") {
    let res = await suspendClient(clientRequestBody).unwrap();
    // console.log(res)
    setConfirmRestore(true);
    showToast(res?.message);
   }

   if (user?.user_type === "influencer") {
    let res = await suspendInfluencer(influencerRequestBody).unwrap();
    console.log(res);
    setConfirmRestore(true);
    showToast(res?.message);
   }
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <>
   <Modal
    openModal={openModal}
    closeModal={() => setOpenModal(!openModal)}
    title={`${isSuspended ? "Unsuspend" : "Suspend"} ${type}`}
   >
    <div className="w-full mb-[18px]">
     <UserDisplay
      size="large"
      max_width="200px"
      name={user?.full_name}
      handle={user?.username}
      img={user?.profile_image}
     />
    </div>

    <FormProvider {...methods}>
     <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col justify-between flex-grow">
      <ValidatedTextArea
       name="reason_for_suspension"
       type="text"
       label="Message"
       // required={false}
       placeholder={`Send a message stating the reason for the ${isSuspended ? "unsuspension" : "suspension"}`}
      />
      <div className="mt-[auto]">
       <LoadingButton
        loadingIndicator={
         <CircularProgress
          sx={{
           color: "#fff",
          }}
          size="1.2rem"
         />
        }
        loading={isSuspendLoading}
        type="submit"
        color="error"
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
       >
        {`${isSuspended ? "Unsuspend" : "Suspend"} ${type}`}
       </LoadingButton>
      </div>
     </form>
    </FormProvider>
   </Modal>

   <MessageModal
    openModal={confirmRestore}
    closeModal={() => setConfirmRestore(false)}
    title={<span className="capitalize">{`${type} suspended`}</span>}
    // title={<span className="capitalize">{`${type}  ${!isSuspended ? 'restored' : 'suspended'}`}</span>}
    iconBg={isSuspended ? "bg-[#EAEAF0]" : "bg-[#EAEAF0]"}
    description={`An email has been sent to ${user?.first_name + " " + user?.last_name}`}
    icon={<UserIconFilled color={isSuspended ? null : "#8585A3"} />}
   />

   <MessageModal
    openModal={showCantSuspendModal}
    closeModal={() => {
     setShowCantSuspendModal(false);
     setConfirmRestore(true);
    }}
    title="Can’t suspend user with ongoing engagement"
    iconBg={"bg-[#FAEBED]"}
    icon={<ErrorOutlineOutlinedIcon color="error" />}
   />
  </>
 );
};

export default SuspendUserModal;
