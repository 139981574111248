import { Button } from "@mui/material";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

export const URL = ({ url, color = "#8585A3" }) => {
 return (
  <>
   <h4 className="text-sm font-medium pt-6" style={{ color }}>
    Task URL
   </h4>
   <div className="border border-[#BFBFCF] rounded-lg bg-white flex items-center justify-between gap-4 p-4 mt-2">
    <div className="flex items-center gap-4">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M14.99 17.5H16.5C19.52 17.5 22 15.03 22 12C22 8.98 19.53 6.5 16.5 6.5H14.99"
       stroke="#292D32"
       strokeWidth="1.5"
       strokeLinecap="round"
       strokeLinejoin="round"
      />
      <path
       d="M9 6.5H7.5C4.47 6.5 2 8.97 2 12C2 15.02 4.47 17.5 7.5 17.5H9"
       stroke="#292D32"
       strokeWidth="1.5"
       strokeLinecap="round"
       strokeLinejoin="round"
      />
      <path d="M8 12H16" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
     </svg>{" "}
     <h4 className="text-[#2C2C3A] text-sm font-semibold">{url}</h4>
    </div>
    <svg
     width="20"
     height="20"
     viewBox="0 0 20 20"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
     role="button"
     onClick={() => navigator.clipboard.writeText(url)}
    >
     <path
      d="M17.5 7.50001L17.5 2.50001M17.5 2.50001H12.5M17.5 2.50001L10 10M8.33333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V11.6667"
      stroke="#8937CE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
     />
    </svg>
   </div>
  </>
 );
};

export const Screenshot = ({ img_url, color = "#8585A3", padding = "p-6", rounded = false }) => {
 return (
  <>
   <h4 className="text-sm font-medium pt-6" style={{ color }}>
    {!rounded ? "Screenshots" : "Image"}
   </h4>
   <div className={`bg-white ${padding} mt-2 w-full flex items-center justify-center rounded-lg`}>
    <img src={img_url} alt="" className={` object-cover ${rounded ? "rounded-xl h-[200px] w-full" : ""}`} />
   </div>
   <Link to={img_url} target="_blank">
    <Button
     className="flex items-center justify-center text-sm !rounded-md !text-[#181820] whitespace-nowrap !mt-4"
     sx={{
      height: "36px",
      width: "175px",
      border: "1px solid #BFBFCF",
      background: "#FFF",
     }}
     startIcon={
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
        d="M7.5 14.7917C7.41667 14.7917 7.34167 14.775 7.25833 14.7417C7.025 14.65 6.875 14.4167 6.875 14.1667V9.16669C6.875 8.82502 7.15833 8.54169 7.5 8.54169C7.84167 8.54169 8.125 8.82502 8.125 9.16669V12.6584L8.725 12.0584C8.96667 11.8167 9.36667 11.8167 9.60833 12.0584C9.85 12.3 9.85 12.7 9.60833 12.9417L7.94167 14.6084C7.825 14.725 7.65833 14.7917 7.5 14.7917Z"
        fill="#181820"
       />
       <path
        d="M7.49997 14.7917C7.34164 14.7917 7.18331 14.7333 7.05831 14.6083L5.39164 12.9417C5.14997 12.7 5.14997 12.3 5.39164 12.0583C5.6333 11.8167 6.03331 11.8167 6.27497 12.0583L7.94164 13.725C8.1833 13.9667 8.1833 14.3667 7.94164 14.6083C7.81664 14.7333 7.6583 14.7917 7.49997 14.7917Z"
        fill="#181820"
       />
       <path
        d="M12.5 18.9584H7.50002C2.97502 18.9584 1.04169 17.025 1.04169 12.5V7.50002C1.04169 2.97502 2.97502 1.04169 7.50002 1.04169H11.6667C12.0084 1.04169 12.2917 1.32502 12.2917 1.66669C12.2917 2.00835 12.0084 2.29169 11.6667 2.29169H7.50002C3.65835 2.29169 2.29169 3.65835 2.29169 7.50002V12.5C2.29169 16.3417 3.65835 17.7084 7.50002 17.7084H12.5C16.3417 17.7084 17.7084 16.3417 17.7084 12.5V8.33335C17.7084 7.99169 17.9917 7.70835 18.3334 7.70835C18.675 7.70835 18.9584 7.99169 18.9584 8.33335V12.5C18.9584 17.025 17.025 18.9584 12.5 18.9584Z"
        fill="#181820"
       />
       <path
        d="M18.3334 8.95834H15C12.15 8.95834 11.0417 7.85001 11.0417 5.00001V1.66668C11.0417 1.41668 11.1917 1.18334 11.425 1.09168C11.6584 0.991678 11.925 1.05001 12.1084 1.22501L18.775 7.89168C18.95 8.06668 19.0084 8.34168 18.9084 8.57501C18.8084 8.80834 18.5834 8.95834 18.3334 8.95834ZM12.2917 3.17501V5.00001C12.2917 7.15001 12.85 7.70834 15 7.70834H16.825L12.2917 3.17501Z"
        fill="#181820"
       />
      </svg>
     }
    >
     Download Image
    </Button>
   </Link>
  </>
 );
};

export const Summary = ({ summary }) => {
 return (
  <>
   <h4 className="text-[#8585A3] text-sm font-medium pt-6">Summary</h4>
   <p className="text-[#2C2C3A] text-sm font-medium pt-2 max-w-[400px]">{summary}</p>
  </>
 );
};

export const Video = ({ link, color = "#8585A3" }) => {
 const [isPaused, setIsPaused] = useState(false);
 const videoRef = useRef();

 const togglePause = () => setIsPaused(!isPaused);

 const handleVideo = () => {
  togglePause();
  if (isPaused) {
   videoRef.current.pause();
  } else {
   videoRef.current.play();
  }
 };

 return (
  <>
   <h4 className={"text-sm font-medium pt-6"} style={{ color }}>
    Video
   </h4>
   <div className="pt-2 relative">
    <video width={446} height={278} className="w-full" ref={videoRef}>
     <source src={link} type="video/mp4" />
    </video>

    <svg
     width="64"
     height="64"
     viewBox="0 0 64 64"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
     role="button"
     className={`absolute top-[50%] left-[50%] translate-x-[-50%]`}
     onClick={handleVideo}
    >
     <rect width="64" height="64" rx="32" fill="black" fill-opacity="0.5" />
     <path
      d="M31.9201 5.33331C17.2001 5.33331 5.25342 17.28 5.25342 32C5.25342 46.72 17.2001 58.6666 31.9201 58.6666C46.6401 58.6666 58.5867 46.72 58.5867 32C58.5867 17.28 46.6667 5.33331 31.9201 5.33331ZM39.9201 37.9466L32.1867 42.4C31.2267 42.96 30.1601 43.2266 29.1201 43.2266C28.0534 43.2266 27.0134 42.96 26.0534 42.4C24.1334 41.28 22.9867 39.3066 22.9867 37.0666V28.1333C22.9867 25.92 24.1334 23.92 26.0534 22.8C27.9734 21.68 30.2667 21.68 32.2134 22.8L39.9467 27.2533C41.8667 28.3733 43.0134 30.3466 43.0134 32.5866C43.0134 34.8266 41.8667 36.8266 39.9201 37.9466Z"
      fill="white"
     />
    </svg>
   </div>
   <Link to={link} target="_blank">
    <Button
     className="flex items-center justify-center text-sm !rounded-md !text-[#181820] whitespace-nowrap !mt-4"
     sx={{
      height: "36px",
      width: "175px",
      border: "1px solid #BFBFCF",
      background: "#FFF",
     }}
     startIcon={
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path
        d="M7.5 14.7917C7.41667 14.7917 7.34167 14.775 7.25833 14.7417C7.025 14.65 6.875 14.4167 6.875 14.1667V9.16669C6.875 8.82502 7.15833 8.54169 7.5 8.54169C7.84167 8.54169 8.125 8.82502 8.125 9.16669V12.6584L8.725 12.0584C8.96667 11.8167 9.36667 11.8167 9.60833 12.0584C9.85 12.3 9.85 12.7 9.60833 12.9417L7.94167 14.6084C7.825 14.725 7.65833 14.7917 7.5 14.7917Z"
        fill="#181820"
       />
       <path
        d="M7.49997 14.7917C7.34164 14.7917 7.18331 14.7333 7.05831 14.6083L5.39164 12.9417C5.14997 12.7 5.14997 12.3 5.39164 12.0583C5.6333 11.8167 6.03331 11.8167 6.27497 12.0583L7.94164 13.725C8.1833 13.9667 8.1833 14.3667 7.94164 14.6083C7.81664 14.7333 7.6583 14.7917 7.49997 14.7917Z"
        fill="#181820"
       />
       <path
        d="M12.5 18.9584H7.50002C2.97502 18.9584 1.04169 17.025 1.04169 12.5V7.50002C1.04169 2.97502 2.97502 1.04169 7.50002 1.04169H11.6667C12.0084 1.04169 12.2917 1.32502 12.2917 1.66669C12.2917 2.00835 12.0084 2.29169 11.6667 2.29169H7.50002C3.65835 2.29169 2.29169 3.65835 2.29169 7.50002V12.5C2.29169 16.3417 3.65835 17.7084 7.50002 17.7084H12.5C16.3417 17.7084 17.7084 16.3417 17.7084 12.5V8.33335C17.7084 7.99169 17.9917 7.70835 18.3334 7.70835C18.675 7.70835 18.9584 7.99169 18.9584 8.33335V12.5C18.9584 17.025 17.025 18.9584 12.5 18.9584Z"
        fill="#181820"
       />
       <path
        d="M18.3334 8.95834H15C12.15 8.95834 11.0417 7.85001 11.0417 5.00001V1.66668C11.0417 1.41668 11.1917 1.18334 11.425 1.09168C11.6584 0.991678 11.925 1.05001 12.1084 1.22501L18.775 7.89168C18.95 8.06668 19.0084 8.34168 18.9084 8.57501C18.8084 8.80834 18.5834 8.95834 18.3334 8.95834ZM12.2917 3.17501V5.00001C12.2917 7.15001 12.85 7.70834 15 7.70834H16.825L12.2917 3.17501Z"
        fill="#181820"
       />
      </svg>
     }
    >
     Download Video
    </Button>
   </Link>
  </>
 );
};

export const Text = ({ text }) => {
 return (
  <>
   <h4 className="text-[#2C2C3A] text-sm font-medium pt-6">Text</h4>
   <p className="text-[#69698C] text-sm font-medium pt-2 ">{text}</p>
   <Button
    className="flex items-center justify-center text-sm !rounded-md !text-[#181820] whitespace-nowrap !mt-4"
    sx={{
     height: "36px",
     width: "123px",
     border: "1px solid #BFBFCF",
     background: "#FFF",
    }}
    startIcon={
     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M9.25008 18.9584H5.75008C2.49175 18.9584 1.04175 17.5084 1.04175 14.25V10.75C1.04175 7.49169 2.49175 6.04169 5.75008 6.04169H9.25008C12.5084 6.04169 13.9584 7.49169 13.9584 10.75V14.25C13.9584 17.5084 12.5084 18.9584 9.25008 18.9584ZM5.75008 7.29169C3.16675 7.29169 2.29175 8.16669 2.29175 10.75V14.25C2.29175 16.8334 3.16675 17.7084 5.75008 17.7084H9.25008C11.8334 17.7084 12.7084 16.8334 12.7084 14.25V10.75C12.7084 8.16669 11.8334 7.29169 9.25008 7.29169H5.75008Z"
       fill="#181820"
      />
      <path
       d="M14.2501 13.9584H13.3334C12.9917 13.9584 12.7084 13.675 12.7084 13.3334V10.75C12.7084 8.16669 11.8334 7.29169 9.25008 7.29169H6.66675C6.32508 7.29169 6.04175 7.00835 6.04175 6.66669V5.75002C6.04175 2.49169 7.49175 1.04169 10.7501 1.04169H14.2501C17.5084 1.04169 18.9584 2.49169 18.9584 5.75002V9.25002C18.9584 12.5084 17.5084 13.9584 14.2501 13.9584ZM13.9584 12.7084H14.2501C16.8334 12.7084 17.7084 11.8334 17.7084 9.25002V5.75002C17.7084 3.16669 16.8334 2.29169 14.2501 2.29169H10.7501C8.16675 2.29169 7.29175 3.16669 7.29175 5.75002V6.04169H9.25008C12.5084 6.04169 13.9584 7.49169 13.9584 10.75V12.7084Z"
       fill="#181820"
      />
     </svg>
    }
    onClick={() => navigator.clipboard.writeText(text)}
   >
    Copy Text
   </Button>
  </>
 );
};
