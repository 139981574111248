import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControlLabel, Skeleton, Switch } from "@mui/material";
import ValidatedInput from "components/forms/ValidatedInput";
import { FormProvider, useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { handleError } from "utils";
import {
  useEnableOrDisableReferralSettingMutation,
  useEnableOrDisableInfluencerReferralSettingMutation,
  useGetAllReferralSettingQuery,
  useUpdateReferralSettingMutation,
} from "services";
import { showToast } from "store/store.hooks";
import { useEffect, useState } from "react";

const Referral = ({ referralType }) => {
  const [clickedId, setClickedId] = useState(null);
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
    ({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor: "#8937CE",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    }),
  );

  const methods = useForm();
  const errors = methods.formState.errors;
  const { data: allOptions, isLoading } = useGetAllReferralSettingQuery();
  const [update, { isLoading: isUpdating }] = useUpdateReferralSettingMutation();
  const [enableOrDisable, { isLoading: isEnablingOrDisabling }] = useEnableOrDisableReferralSettingMutation();
  useEnableOrDisableInfluencerReferralSettingMutation();

  const options = allOptions;
  console.log(options);

  const majorUpdate = update
  const majorEnableOrDisable = enableOrDisable

  useEffect(() => {
    const btns = document.querySelectorAll(".MuiLoadingButton-root");
    if (options) {
      options?.data.forEach(({ option, value }) => {
        methods.setValue(option, value);
      });
    }
    const referralIsTurnedOn = options?.data?.find((item) => item.id === 5)?.status === "active";
    if (referralIsTurnedOn) {
      btns.forEach((btn) => {
        btn.classList.remove("Mui-disabled");
        if (btn.getAttribute("disabled") === "true") btn.removeAttribute("disabled");
      });
    } else {
      btns.forEach((btn) => {
        btn.classList.add("Mui-disabled");
        btn.setAttribute("disabled", true);
      });
    }
  }, [options, methods]);

  //  console.log(options);
  return (
    <div>
      <p className="font-semibold text-grey text-xl">
        <span className="mr-6 inline-block text-grey30">{referralType}</span>
        {/* <FormControlLabel
          value="top"
          control={
            isEnablingOrDisabling || isInfluencerUpdating ? (
              <CircularProgress size={23} />
            ) : (
              <IOSSwitch
                defaultChecked={options?.data?.find((item) => item.id === 4)?.status === "active"}
                onClick={() => {
                  majorEnableOrDisable(5)
                    .unwrap()
                    .then(() => showToast("Referral setting updated successfully"))
                    .catch((error) => {
                      handleError(error);
                    });
                }}
              />
            )
          }
        /> */}
      </p>

      <p className="font-semibold text-grey text-xl mt-12">
        <span className="mr-6 inline-block text-grey30">Referral On/Off</span>
        <FormControlLabel
          value="top"
          control={
            isEnablingOrDisabling ? (
              <CircularProgress size={23} />
            ) : (
              <IOSSwitch
                defaultChecked={options?.data?.find((item) => item.id === 5)?.status === "active"}
                onClick={() => {
                  majorEnableOrDisable(5)
                    .unwrap()
                    .then(() => showToast("Referral setting updated successfully"))
                    .catch((error) => {
                      handleError(error);
                    });
                }}
              />
            )
          }
        />
      </p>
      {options?.data?.find((item) => item.id === 5)?.status === "active"
        ? <div className="flex-grow max-w-[100%] md:max-w-[95%] lg:max-w-[55%]">
          <FormProvider {...methods}>
            <form className="flex flex-col gap-[60px] mt-8">
              {isLoading
                ? [1, 2, 3, 4, 5, 6].map((val) => <Skeleton key={val} variant="rounded" height={70} />)
                : options?.data?.slice(1)?.map(({ description, option, title, label, id }) => {
                  // let camelLabel = "";
                  // if (description === "expected transaction volume") {
                  //   camelLabel = "Expected Transaction Volume";
                  // } else if (description === "amount to earn by the referred") {
                  //   camelLabel = "Amount to earn by the Referred";
                  // } else if (description === "amount to earn by the referrer") {
                  //   camelLabel = "Amount to earn by the Referrer";
                  // } else {
                  //   camelLabel = "Number of Days to Claim Bonus";
                  // }
                  return (
                    <fieldset key={id} className="flex flex-col gap-8">
                      <p className="text-sm text-grey10 -mb-5 font-semibold">{description}</p>
                      <div className="relative -mt-6 w-full">
                        <div className={`flex w-full items-center gap-4 ${errors[option] ? "items-start" : "items-center"}`}>
                          <ValidatedInput
                            className="mt-0"
                            labelFontSize="text-[12px] text-[#8585A3]"
                            showErrMsg={false}
                            name={option}
                            label={label}
                            placeholder="10"
                            prefixIcon={id === 3 && <p>%</p>}
                            type="number"
                          />
                          <LoadingButton
                            sx={{ mt: 5.5 }}
                            loading={isUpdating && clickedId === id}
                            loadingIndicator={
                              <CircularProgress
                                sx={{
                                  color: "#ffffff",
                                }}
                                size="1.2rem"
                              />
                            }
                            variant="contained"
                            onClick={async () => {
                              setClickedId(id);
                              await methods.trigger(option).then(async (res) => {
                                if (res) {
                                  try {
                                    let res = await majorUpdate({
                                      id,
                                      body: {
                                        option,
                                        value: methods.getValues(option),
                                        description,
                                        title,
                                        label,
                                      },
                                    }).unwrap();
                                    showToast(res?.message, "success");
                                  } catch (error) {
                                    handleError(error);
                                  }
                                }
                              });
                            }}
                          >
                            Update
                          </LoadingButton>
                        </div>
                      </div>
                    </fieldset>
                  );
                })}
            </form>
          </FormProvider>
        </div>
        : null}
    </div>
  );
};

export default Referral;
