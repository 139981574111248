const EditIcon2 = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99998 13.3341H14M2 13.3341H3.11636C3.44248 13.3341 3.60554 13.3341 3.75899 13.2973C3.89504 13.2646 4.0251 13.2108 4.1444 13.1377C4.27895 13.0552 4.39425 12.9399 4.62486 12.7093L13 4.33414C13.5523 3.78185 13.5523 2.88642 13 2.33413C12.4477 1.78185 11.5523 1.78185 11 2.33414L2.62484 10.7093C2.39424 10.9399 2.27894 11.0552 2.19648 11.1898C2.12338 11.3091 2.0695 11.4391 2.03684 11.5752C2 11.7286 2 11.8917 2 12.2178V13.3341Z"
        stroke={props.stroke || "#6E726E"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EditIcon2;
