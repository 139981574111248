import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducer";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "services/auth.api";
import { generalApi } from "services";
import { ticketsApi } from "services/tickets.api";
import { jobsApi } from "services/jobs.api";
import { engagementsApi } from "services/engagements.api";
import { campaignsApi } from "services/campaigns.api";
import { payoutsApi } from "services/payouts.api";
import { searchApi } from "services/search.api";
import { transactionsApi } from "services/transactions.api";

const persistConfig = {
 key: "root",
 version: 1,
 storage,
 whitelist: ["auth", "toasts"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
 reducer: persistedReducer,
 middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
   serializableCheck: false,
  })
   .concat(authApi.middleware)
   .concat(generalApi.middleware)
   .concat(ticketsApi.middleware)
   .concat(jobsApi.middleware)
   .concat(engagementsApi.middleware)
   .concat(payoutsApi.middleware)
   .concat(searchApi.middleware)
   .concat(campaignsApi.middleware)
   .concat(transactionsApi.middleware),
});

export const persistor = persistStore(store);

export default store;
