function FlagIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0167 10.2743L14 9.25768C13.7584 9.04935 13.6167 8.74102 13.6084 8.39935C13.5917 8.02435 13.7417 7.64935 14.0167 7.37435L15.0167 6.37435C15.8834 5.50768 16.2084 4.67435 15.9334 4.01602C15.6667 3.36602 14.8417 3.00768 13.625 3.00768H4.91669V2.29102C4.91669 1.94935 4.63335 1.66602 4.29169 1.66602C3.95002 1.66602 3.66669 1.94935 3.66669 2.29102V17.7077C3.66669 18.0493 3.95002 18.3327 4.29169 18.3327C4.63335 18.3327 4.91669 18.0493 4.91669 17.7077V13.641H13.625C14.825 13.641 15.6334 13.2744 15.9084 12.616C16.1834 11.9577 15.8667 11.1327 15.0167 10.2743Z"
        fill={props.color || "#8585A3"}
      />
    </svg>
  );
}

export default FlagIcon;
