import { Chip } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UserDisplay from "components/TableComponent/UserDisplay";
import { Link } from "react-router-dom";
import MyDataTable, { filterOption } from "components/TableComponent/MyDataTable";
import { STATUS2 } from "utils/constants";
import { getUserDetails, removeLastLetter, toCurrency } from "utils";
import { useGetJobsByStatusQuery } from "services/jobs.api";
import { useState, useEffect } from "react";
import BagIcon from "components/Vectors/BagIcon";

const ChipBg = {
 suspended: "bg-grey30",
 closed: "bg-error",
 open: "bg-primary",
};

function JobsTab({ status }) {
 const JOBS_PER_PAGE = 16;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);
 const { data, isLoading, isError } = useGetJobsByStatusQuery({
  status,
  params: { is_paginated: "yes", page, per_page: perPage },
 });

 const firstJobs = data?.data?.filter((job) => job);
 const [jobs, setJobs] = useState([]);

 useEffect(() => {
  if (data?.data) {
   const initialInfluencers = data?.data?.filter((job) => job);
   setJobs(initialInfluencers);
  }
 }, [data]);

 const searchFunc = (val) => {
  const matchedJobs = firstJobs?.filter((job) => job?.job_title?.toLowerCase().includes(val.toLowerCase()));

  setJobs(matchedJobs);
 };

 const options = {
  searchOpen: true,
  viewColumns: false,
  serverSide: true,
  selectableRows: "none",
  count: data?.total,
  rowsPerPage: perPage,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };
 const headCells = [
  {
   name: "sn",
   label: "S/N",
  },
  {
   name: "job",
   label: "Job",
   options: {
    customBodyRender: (value) => (
     <Link to={"/jobs/" + value.id}>
      <UserDisplay imgRounded={false} name={value.name} img={value.img} />
     </Link>
    ),
   },
  },
  {
   name: "client",
   label: "Client",
   options: {
    customBodyRender: (value) => <UserDisplay name={value.name} email={value.username} img={value.img} />,
   },
  },
  {
   name: "category",
   label: "Category",
  },

  {
   name: "price",
   label: "Price",
  },
  {
   name: "duration",
   label: "duration",
   ...filterOption,
  },
  {
   name: "location",
   label: "Location",
  },
  {
   name: "views",
   label: "No of Views",
  },
  {
   name: "status",
   label: "status",
   options: {
    filter: true,
    customBodyRender: (value) => {
     //  console.log(value.name);
     //  console.log(STATUS2[value.name.toLowerCase()]);
     return (
      <Chip
       color={value.name === "Suspended" ? "default" : `${STATUS2[value?.name?.toLowerCase()]}`}
       sx={{ minWidth: "100%", borderRadius: "20px" }}
       label={
        <span className="flex items-center font-semibold gap-x-1">
         <span className="text-[#3B3D3B]">{value?.name}</span>
         {value.name?.toLowerCase() !== "drafted" && (
          <>
           <span
            className={`flex text-xs rounded-full justify-center items-center p-1 h-[1.1rem] w-[1.1rem] text-white ${
             ChipBg[value?.name?.toLowerCase()]
            }`}
           >
            {value?.count}
           </span>
           of {value?.total}
          </>
         )}
        </span>
       }
      />
     );
    },
   },
  },
  {
   name: "actions",
   label: "",
   options: {
    customBodyRender: (id) => (
     <Link to={"/jobs/" + id} className="min-w-max flex items-center gap-x-2">
      <RemoveRedEyeOutlinedIcon /> View Job
     </Link>
    ),
   },
  },
 ];

 const bodyCells = jobs?.map((job, i) => ({
  sn: (data?.current_page - 1) * data?.per_page + (i + 1),
  job: {
   name: job?.job_title,
   img: job?.cover_image,
   id: job?.id,
  },
  client: {
   name: getUserDetails(job?.creator).name,
   username: job?.creator?.username,
   img: getUserDetails(job?.creator).img,
  },
  category: job?.category?.name,
  price: toCurrency(job?.price),
  duration: `${job?.duration_count} ${
   job?.duration_count <= 1 ? removeLastLetter(job?.duration_type) : job?.duration_type
  }`,
  location: `${job?.location_type === "any_Location" ? "Any Location" : `${job?.city?.name}, ${job?.state?.name}`} `,
  views: job?.views_count,
  status: {
   name: job?.status,
   count: job?.offers_count,
   total: job?.number_of_expected_offers_to_receive,
  },
  actions: job?.id,
 }));

 if (data) {
  console.log(data);
 }

 return (
  <div className="p-4 rounded-lg bg-white">
   <MyDataTable
    searchPlaceholder="Search Jobs"
    hideScrollbar={false}
    scrollX={true}
    options={options}
    headCells={headCells}
    searchFunc={searchFunc}
    data={bodyCells}
    isError={isError}
    isLoading={isLoading}
    emptyData={{
     message: `No ${status} jobs yet`,
     icon: BagIcon,
    }}
   />
  </div>
 );
}

export default JobsTab;
