import BellIcon from "components/Vectors/BellIcon";
import CampaignIcon from "components/Vectors/CampaignIcon";
import InfluencerIcon from "components/Vectors/InfluencerIcon";
import Layout from "components/Vectors/Layout";
import SettingsIcon from "components/Vectors/SettingsIcon";
// import StarIcon from "components/Vectors/StarIcon";
import TicketsIcon from "components/Vectors/TicketIcon";
import UsersIcon from "components/Vectors/UsersIcon";
// import VideoIcon from "components/Vectors/VideoIcon";
import WalletIcon from "components/Vectors/WalletIcon";

const AdminMenuLinks = [
 {
  icon: Layout,
  name: "Dashboard",
  link: "/",
  border: true,
 },
 {
  icon: InfluencerIcon,
  name: "Influencers",
  link: "influencers",
  permission_name: "Influencers",
 },
 {
  icon: UsersIcon,
  name: "Clients",
  link: "clients",
  permission_name: "Clients",
 },
 // {
 //   icon: VideoCameraIcon,
 //   name: "Creators",
 //   link: "creators",
 //   border: true,
 //   permission_name: "Creators",
 // },
 {
  icon: CampaignIcon,
  name: "Campaigns",
  link: "campaigns",
  // badgeContent: 2,
  permission_name: "Campaigns",
 },
 // {
 //   icon: ServicesIcon,
 //   name: "Jobs",
 //   link: "jobs",
 //   permission_name: "Jobs",
 // },
 // {
 //   icon: TwoUserIcon,
 //   name: "Engagements",
 //   link: "engagements",
 //   border: true,
 //   permission_name: "Engagements",
 // },
 {
  icon: WalletIcon,
  name: "Transactions",
  link: "transactions",
  badgeContent: 2,
  permission_name: "Transactions",
 },
 {
  icon: TicketsIcon,
  name: "Tickets",
  link: "tickets",
  border: true,
  permission_name: "Tickets",
 },
 // {
 //   icon: BellIcon,
 //   name: "Mobile Push Notifications",
 //   link: "push_notifications",
 //   permission_name: "Push Notifications",
 // },
 {
  icon: SettingsIcon,
  name: "Settings",
  link: "settings",
  permission_name: "Settings",
 },
];

export default AdminMenuLinks;
