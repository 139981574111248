import BackBtn from "components/BackBtn";
// import BlockIcon from "@mui/icons-material/Block";
// import { ReactComponent as TimeIcon } from "assets/icons/time.svg";
import { Avatar, Button, Chip } from "@mui/material";
import UserDisplay from "components/TableComponent/UserDisplay";
// import EachReview from "components/EachReview";
// import ReviewSummary from "components/ReviewSummary";
// import SeeMoreBtn from "components/SeeMoreBtn";
import { useState } from "react";
import DropDownWrapper from "components/DropDownWrapper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useParams } from "react-router-dom";
import Modal from "components/Modal";
// import ProfileWidget from "components/ProfileWidget";
import DisableModal from "./Widgets/DisableModal";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Star } from "@mui/icons-material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import SubCategoryIcon from "components/Vectors/sub_categoryIcon";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
// import CustomRating from "components/Ratings";
import DeleteIcon from "components/Vectors/DeleteIcon";
import FacebookIcon from "components/Vectors/facebookIcon";
import MultipleImageVideo from "components/MultipleImageVideo";
import SquareTickIcon from "components/Vectors/SquareTickIcon";
import EnableModal from "./Widgets/EnableModal";
import { useGetSingleServicesQuery, useUpdateServiceStatusMutation } from "services";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import ChipsWrap from "components/ChipsWrap";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";
import LoadingBtn from "components/forms/LoadingBtn";
// import StarIcon from "components/Vectors/StarIcon";

export const rating = {
  name: "Robert Fox",
  text: "Tristique sit morbi suspendisse in et iaculis. In nulla pellentesque ac tellus suspendisse consectetur. Arcu, vel, sit nullam sagittis, nunc. Sem vitae tristique velit porttitor mi elementum lectus ornare. Quis in quis aliquet velit et.",
  username: "lazymeercat616",
  avatar: "/images/avatar.png",
  value: 4,
  date: "June 23, 2021",
};

const ViewUser = ({
  name,
  username,
  btnText = "View User",
  avatar = "/images/avatar.png",
}) => (
  <div className="flex justify-between rounded-lg items-center p-5 pr-2 border mb-3">
    <UserDisplay img={avatar} name={name} email={username} />
    <Button sx={{ fontWeight: 600 }} component={Link} to="">
      {btnText}
    </Button>
  </div>
);

export const PackageDetails = ({ socials, price }) => {
  return (
    <div className="border rounded-lg px-6 py-4 mb-6">
      <p>Description</p>
      <p className="text-grey mb-3 mt-1">
        Fames sit ut amet, rhoncus odio egestas vitae aliquam. Pharetra felis
        arcu, vel tempor, dictum arcu. Morbi auctor tortor, facilisis a lobortis
        tortor mi, vitae. Vulputate ligula pharetra et erat amet sollicitudin.
        Purus hac ut lorem feugiat id scelerisque. Nec volutpat malesuada id
        tincidunt feugiat. Morbi est rutrum viverra eget proin.
      </p>
      <p>Features</p>
      <ul className="text-grey mt-1 mb-3 list-disc ml-6">
        {[
          Array(4)
            .fill()
            .map((el, i) => (
              <li key={`feature_${i}`}>
                Fames sit ut amet, rhoncus odio egestas vitae aliquam.
              </li>
            )),
        ]}
      </ul>
      <p>Socials</p>
      <div className="flex space-x-2 mt-1">
        {socials.map((social) => (
          <Avatar
            sx={{ borderRadius: 0, width: 24, height: 24 }}
            src={`/images/social_icons/${social.toLowerCase()}.png`}
            alt={social}
          />
        ))}
      </div>
    </div>
  );
};

function AdminEachService({ showDelete = true }) {
  const [reviews, setreviews] = useState(Array(3).fill(4));
  const [openImpressionsModal, setopenImpressionsModal] = useState(false);
  const [openOrderModal, setopenOrderModal] = useState(false);
  const [openDisableModal, setopenDisableModal] = useState(false);
  const [openLikesModal, setopenLikesModal] = useState(false);

  const [storeId, setStoreId] = useState(null);
  const [userSlug, setUserSlug] = useState(null);
  const [storeName, setStoreName] = useState(null);

  const [disableBtn, setDisableBtn] = useState(false);
  const [openEnableModal, setOpenEnableModal] = useState(false);

  const { id } = useParams();

  const [updateStatus, { isLoading: isUpdateLoading }] =
    useUpdateServiceStatusMutation();
  const { data, isLoading, error } = useGetSingleServicesQuery(id);

  const content = data?.data;

  const handleDisable = (id, slug, name) => {
    setStoreId(id);
    setUserSlug(slug);
    setopenDisableModal(true);
    setStoreName(name);
  };

  console.log(content);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <div className="h-full p-6 lg:px-10 px-4 bg-white">
      <BackBtn />
      <div className="rounded-lg p-5 font-medium flex flex-col gap-y-8 md:grid md:grid-cols-[1.25fr_1fr] md:gap-5 lg:gap-[100px] ">
        <div className="w-full ">
          <h3 className="text-2xl font-semibold text-black mb-4">
            {content?.title}
          </h3>
          <MultipleImageVideo
            source={content?.videos[0]?.video_url}
            images={content?.images}
          />
          <div className="buttons flex justify-between items-center w-full my-6">
            <span
              className={`w-fit px-4 h-[30px] font-medium ${
                content?.status?.toLowerCase() === "active"
                  ? "text-[#00A04A]"
                  : content?.status?.toLowerCase() === "suspended"
                  ? "text-[#D61E34]"
                  : "text-[#69698C]"
              } ${
                content?.status?.toLowerCase() === "active"
                  ? "bg-[#E5F7ED]"
                  : content?.status?.toLowerCase() === "suspended"
                  ? "bg-[#FAEBED]"
                  : "bg-[#EAEAF0] "
              } flex items-center justify-center rounded-[20px]  `}
            >
              {content?.status}
            </span>
            {content?.status?.toLowerCase() === "active" ? (
              <div>
                <Button
                  variant="contained"
                  color="errorInverse"
                  startIcon={<DoNotDisturbIcon />}
                  onClick={() =>
                    handleDisable(
                      content?.id,
                      content?.user?.role?.slug,
                      content?.user?.full_name
                    )
                  }
                >
                  Disable Service
                </Button>
              </div>
            ) : (
              <>
                <LoadingBtn
                  loading={isUpdateLoading}
                  variant="contained"
                  color="successInverse"
                  startIcon={<SquareTickIcon />}
                  // onClick={() => setOpenEnableModal(true)}
                  onClick={async () => {
                    try {
                      let res = await updateStatus({
                        id: content?.id,
                        body: {
                          status: "Active",
                        },
                      }).unwrap();
                      
                      showToast(res?.message);
                    } catch (error) {
                      handleError(error);
                    }
                  }}
                >
                  Enable Campaign
                </LoadingBtn>
              </>
            )}
          </div>

          <div className="grid gap-4 grid-cols-2 mb-4">
            <div className="flex gap-2 border rounded-lg p-4">
              <BusinessCenterIcon color="primary" />
              <div>
                <p className="text-sm font-medium mb-3 text-grey50">Category</p>
                <p className="text-base font-semibold text-grey10">
                  {content?.category?.name}
                </p>
              </div>
            </div>
            <div className="flex gap-2 border rounded-lg p-4">
              <SubCategoryIcon />
              <div>
                <p className="text-sm font-medium mb-3 text-grey50">
                  Sub Category
                </p>
                <p className="text-base font-semibold text-grey10">
                  {content?.sub_category?.name}
                </p>
              </div>
            </div>
            <div className="flex gap-2 border rounded-lg p-4">
              <CalendarMonthRoundedIcon color="primary" />
              <div>
                <p className="text-sm font-medium mb-3 text-grey50">
                  Expected Deliver
                </p>
                <p className="text-base font-semibold text-grey10">
                  {content?.duration_count} {content?.duration_type}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-between border rounded-lg items-center p-4">
            <div className="flex gap-4 items-center">
              <Avatar
                sx={{ width: 48, height: 48 }}
                src={content?.user?.profile_image}
              />
              <div>
                <h2 className="text-base text-black font-semibold">
                  {content?.user?.full_name}
                </h2>
                <p className="text-[12px]">@{content?.user?.username}</p>
              </div>
            </div>
            <Button
              component={Link}
              to={`/${content?.user?.role?.slug}s/${content?.user?.id}`}
              variant="contained"
              color="primaryInverse"
            >
              View {content?.user?.role?.name}
            </Button>
          </div>

          <ChipsWrap chipsArr={content?.tags} title="Tags" />

          <div className="mt-8 border rounded-lg p-4 flex justify-between ">
            <div className="flex flex-col gap-3 border-r text-center w-4/12">
              <p className="text-grey10 text-xl font-semibold">5</p>
              <p className="text-grey50 text-[12px] font-medium">
                Request Received
              </p>
            </div>
            <div className="flex flex-col gap-3 border-r text-center w-4/12">
              <p className="text-grey10 text-xl font-semibold">
                {content?.views_count}
              </p>
              <p className="text-grey50 text-[12px] font-medium">Views</p>
            </div>
            <div className="flex flex-col gap-3 text-center w-4/12">
              <p className="text-grey10 text-xl font-semibold">234</p>
              <p className="text-grey50 text-[12px] font-medium">Favourites</p>
            </div>
          </div>

          <div className="mt-8 border rounded-lg p-4">
            <div className="md:flex space-y-3 justify-between">
              <div className="flex gap-4 font-semibold justify-between md:justify-start items-center">
                <p className="md:text-lg">234 Reviews</p>
              </div>
              <DropDownWrapper
                action={
                  <button className="flex md:text-base text-sm w-full md:w-auto justify-between gap-x-1 px-4 py-2 rounded-lg bg-gray-100">
                    <span>Most Relevant</span>
                    <KeyboardArrowDownIcon />
                  </button>
                }
              >
                <button>Most Relevant</button>
                <button>Most Recent</button>
                <button>Positive comments</button>
                <button>Last Week</button>
                <button>Negative Comments</button>
              </DropDownWrapper>
            </div>
            <div className="mt-6">
              {Array(3)
                .fill("")
                .map((el, i) => (
                  <div
                    key={`rating_received_${i}`}
                    className="flex flex-col gap-4 py-4 border-t"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          src="/images/avatar.png"
                        />
                        <div>
                          <h2 className="text-base text-black font-semibold">
                            Damilare Usman
                          </h2>
                          <p className="text-[10px]">lazymeercat616</p>
                        </div>
                      </div>
                      <p className="text-base text-[#FFB33E] flex gap-2 items-center font-medium">
                        5 <Star color="#FFB33E" />
                      </p>
                    </div>

                    <p className="font-medium text-sm text-grey30">
                      Tristique sit morbi suspendisse in et iaculis. In nulla
                      pellentesque ac tellus suspendisse consectetur. Arcu, vel,
                      sit nullam sagittis, nunc. Sem vitae tristique velit
                      porttitor mi elementum lectus ornare. Quis in quis aliquet
                      velit et.
                    </p>

                    <div className="flex justify-between items-center">
                      <p className="font-medium text-grey50 text-sm">
                        June 23, 2021
                      </p>
                      <Button
                        variant="contained"
                        color="errorInverse"
                        startIcon={<DeleteIcon color="#D61E34" />}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
            <Button
              sx={{ p: 0, fontSize: "1rem", fontWeight: 600 }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              See More
            </Button>
          </div>
        </div>
        <div className="w-full">
          <p className="text-grey50 font-medium text-sm">Compensation</p>
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-3xl txt-grey10">
              ₦{content?.price.toLocaleString()}
            </h3>

            {content?.is_promoted > 0 && (
              <Chip
                color="primaryInverse"
                sx={{ borderRadius: "5px" }}
                label={
                  <div>
                    Promoted &nbsp; <Star color="primary" />
                  </div>
                }
              />
            )}
          </div>

          {content?.is_promoted > 0 && (
            <div className="mt-4 p-4 bg-grey100 text-grey30 rounded">
              <p className="font-semibold text-3xl">5 Days Left</p>
              <p className="text-sm font-medium">
                You have 5 days of promotion left
              </p>
            </div>
          )}

          <div className="border mt-4 rounded-lg px-4 py-6 text-sm font-medium">
            <p className="text-grey10 mb-2">Description</p>
            <p className="text-grey50 mb-4">{content?.description}</p>
            <p className="text-grey10 mb-2">Features</p>
            <ul className="text-grey50 list-disc mb-4 pl-4">
              {content?.features.map((feature) => (
                <li key={feature?.id}>{feature?.feature}</li>
              ))}
            </ul>

            <p className="text-grey10 mb-3">Socials</p>

            <div className="flex gap-2">
              <FacebookIcon />
              {/* <PinterestIcon /> */}
            </div>
          </div>
        </div>
      </div>
      {/* impressions */}
      <Modal
        openModal={openImpressionsModal}
        closeModal={() => setopenImpressionsModal(false)}
        title={"232 Impressions"}
      >
        <span className="mt-3"></span>
        {Array(40)
          .fill("")
          .map((el, i) => (
            <ViewUser name="Eze Chinedu" username={"@brownfish258"} />
          ))}
      </Modal>
      {/* orders */}
      <Modal
        openModal={openOrderModal}
        closeModal={() => setopenOrderModal(false)}
        title={"3 orders"}
      >
        <span className="mt-3"></span>
        {Array(3)
          .fill("")
          .map((el, i) => (
            <ViewUser
              name="Eze Chinedu"
              username={"@brownfish258"}
              btnText="View Buyer"
            />
          ))}
      </Modal>
      {/* Likes */}
      <Modal
        openModal={openLikesModal}
        closeModal={() => setopenLikesModal(false)}
        title={"10 Likes"}
      >
        <span className="mt-3"></span>
        {Array(10)
          .fill("")
          .map((el, i) => (
            <ViewUser name="Eze Chinedu" username={"@brownfish258"} />
          ))}
      </Modal>
      <DisableModal
        id={storeId}
        userSlug={userSlug}
        openModal={openDisableModal}
        setopenModal={setopenDisableModal}
        fullName={storeName}
      />
      {/* <DisableModal
        openModal={openDisableModal}
        otherState={() => setDisableBtn(true)}
        setopenModal={setopenDisableModal}
      /> */}
      <EnableModal
        otherState={() => setDisableBtn(false)}
        openModal={openEnableModal}
        setopenModal={setOpenEnableModal}
      />
    </div>
  );
}

export default AdminEachService;
