function WalletIcon(props) {
 return (
  <svg
   width={props.height || "24"}
   height={props.height || "24"}
   viewBox="0 0 24 24"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
   {...props}
  >
   <path
    d="M14.5 16.75H9.5C9.09 16.75 8.75 16.41 8.75 16C8.75 15.59 9.09 15.25 9.5 15.25H14.5C14.91 15.25 15.25 15.59 15.25 16C15.25 16.41 14.91 16.75 14.5 16.75Z"
    fill={props.color || "#D0D0EF"}
   />
   <path
    d="M5.2 10.4599C5.01 10.4599 4.82 10.3799 4.67 10.2399C4.46 10.0299 4.39 9.69988 4.51 9.41988L6.37 4.97988C6.41 4.88988 6.43 4.82988 6.46 4.77988C7.94 1.36988 9.83 0.539877 13.17 1.81988C13.36 1.88988 13.51 2.03988 13.59 2.22988C13.67 2.41988 13.67 2.62988 13.59 2.81988L10.66 9.61988C10.54 9.88988 10.27 10.0699 9.97 10.0699H7.12C6.55 10.0699 6.01 10.1799 5.49 10.3999C5.4 10.4399 5.3 10.4599 5.2 10.4599ZM10.61 2.74988C9.37 2.74988 8.61 3.55988 7.82 5.39988C7.81 5.42988 7.79 5.45988 7.78 5.48988L6.47 8.59988C6.69 8.57988 6.9 8.56988 7.12 8.56988H9.47L11.88 2.96988C11.41 2.81988 10.99 2.74988 10.61 2.74988Z"
    fill={props.color || "#D0D0EF"}
   />
   <path
    d="M18.29 10.27C18.22 10.27 18.14 10.26 18.07 10.24C17.7 10.13 17.29 10.07 16.87 10.07H9.96998C9.71998 10.07 9.47998 9.94002 9.33998 9.73002C9.20998 9.52002 9.17998 9.25002 9.27998 9.02002L12.18 2.29002C12.33 1.93002 12.76 1.69002 13.14 1.81002C13.26 1.85002 13.37 1.90002 13.49 1.95002L15.85 2.94002C17.23 3.51002 18.15 4.11002 18.75 4.83002C18.87 4.97002 18.97 5.12002 19.06 5.27002C19.17 5.44002 19.27 5.65002 19.34 5.86002C19.37 5.93002 19.42 6.06002 19.45 6.20002C19.73 7.14002 19.59 8.31002 18.99 9.81002C18.87 10.09 18.59 10.27 18.29 10.27ZM11.11 8.57002H16.88C17.2 8.57002 17.51 8.60002 17.82 8.65002C18.1 7.78002 18.16 7.11002 18 6.57002C17.98 6.48002 17.96 6.44002 17.95 6.40002C17.89 6.24002 17.85 6.15002 17.8 6.07002C17.73 5.96002 17.68 5.87002 17.6 5.78002C17.17 5.26002 16.41 4.78002 15.28 4.32002L13.3 3.49002L11.11 8.57002Z"
    fill={props.color || "#D0D0EF"}
   />
   <path
    d="M15.9 22.7498H8.1C7.82 22.7498 7.56 22.7298 7.3 22.6998C3.79 22.4598 1.79 20.4598 1.55 16.9098C1.52 16.6898 1.5 16.4198 1.5 16.1498V14.1998C1.5 11.9498 2.84 9.91982 4.91 9.01982C5.61 8.71982 6.36 8.56982 7.13 8.56982H16.89C17.46 8.56982 18.01 8.64982 18.52 8.80982C20.87 9.51982 22.52 11.7398 22.52 14.1998V16.1498C22.52 16.3698 22.51 16.5798 22.5 16.7798C22.28 20.6898 20 22.7498 15.9 22.7498ZM7.12 10.0698C6.55 10.0698 6.01 10.1798 5.49 10.3998C3.97 11.0598 2.99 12.5498 2.99 14.1998V16.1498C2.99 16.3598 3.01 16.5698 3.03 16.7698C3.22 19.6198 4.62 21.0198 7.43 21.2098C7.68 21.2398 7.88 21.2598 8.09 21.2598H15.89C19.19 21.2598 20.81 19.8098 20.97 16.7098C20.98 16.5298 20.99 16.3498 20.99 16.1498V14.1998C20.99 12.3898 19.78 10.7698 18.06 10.2398C17.69 10.1298 17.28 10.0698 16.86 10.0698H7.12Z"
    fill={props.color || "#D0D0EF"}
   />
   <path
    d="M2.23999 14.9498C1.82999 14.9498 1.48999 14.6098 1.48999 14.1998V11.2698C1.48999 8.1198 3.71999 5.3998 6.79999 4.7998C7.06999 4.7498 7.34999 4.8498 7.52999 5.0598C7.69999 5.2698 7.74999 5.5698 7.63999 5.8198L5.88999 9.9998C5.80999 10.1798 5.66999 10.3198 5.49999 10.3998C3.97999 11.0598 2.99999 12.5498 2.99999 14.1998C2.98999 14.6098 2.65999 14.9498 2.23999 14.9498ZM5.59999 6.8198C4.31999 7.5398 3.38999 8.7998 3.09999 10.2698C3.53999 9.8198 4.04999 9.4398 4.62999 9.1598L5.59999 6.8198Z"
    fill={props.color || "#D0D0EF"}
   />
   <path
    d="M21.76 14.9498C21.35 14.9498 21.01 14.6098 21.01 14.1998C21.01 12.3898 19.8 10.7698 18.08 10.2398C17.88 10.1798 17.71 10.0398 17.62 9.84978C17.53 9.65978 17.52 9.43978 17.6 9.24978C18.07 8.07978 18.19 7.22978 18 6.56978C17.98 6.47978 17.96 6.43978 17.95 6.39978C17.82 6.10978 17.89 5.76978 18.12 5.54978C18.35 5.32978 18.7 5.27978 18.98 5.42978C21.16 6.56978 22.51 8.80978 22.51 11.2698V14.1998C22.51 14.6098 22.17 14.9498 21.76 14.9498ZM19.25 9.08978C19.88 9.37978 20.44 9.78978 20.91 10.2798C20.72 9.29978 20.25 8.40978 19.56 7.70978C19.51 8.12978 19.41 8.58978 19.25 9.08978Z"
    fill={props.color || "#D0D0EF"}
   />
  </svg>
 );
}

export default WalletIcon;
