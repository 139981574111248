import { forwardRef, useState } from 'react';
import './style.scss';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const InputField = forwardRef(
    (
        {
            label,
            labelFontSize,
            className = '',
            spaceY = true,
            variant = 'free',
            bg = 'white',
            prefixIcon,
            suffixIcon,
            isInvalid,
            ...props
        },
        ref,
    ) => {
        const [showPassword, setShowPassword] = useState(false);
        return (
            <div
                style={{ background: bg }}
                className={`form-group ${isInvalid ? 'invalid' : ''} ${
                    !spaceY ? '' : variant === 'free' ? 'mt-12' : 'mt-5'
                }  ${className}`}
            >
                {label && (
                    <label
                        className={`font-medium text-gray-500 ${
                            variant === 'condensed' ? '-top-3' : '-top-7 -ml-4'
                        }  px-2 ${labelFontSize ? labelFontSize : 'text-sm'}`}
                    >
                        {label} {props.required ? <span className="text-error">*</span> : ''}
                    </label>
                )}
                {prefixIcon && <span className="select-none -ml-1 mr-2 .5icon-btn">{prefixIcon}</span>}
                <input
                    {...props}
                    ref={ref}
                    type={showPassword ? 'text' : props.type}
                    placeholder={props.placeholder}
                    className={`focus:outline-none placeholder-neutral bg-transparent w-full z-10 `}
                />
                {suffixIcon && <span className="select-none ml-1 icon-btn">{suffixIcon}</span>}
                {props.type === 'password' && (
                    <button
                        type="button"
                        className="select-none icon-btn -mr-1"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <VisibilityOffOutlinedIcon sx={{ color: '#8585A3' }} />
                        ) : (
                            <RemoveRedEyeOutlinedIcon sx={{ color: '#8585A3' }} />
                        )}
                    </button>
                )}
            </div>
        );
    },
);

export default InputField;
