const StarFilledIcon = ({width, height, color, ...props}) => {
  return (
    <svg
      width={width || "32"}
      height={height || "29"}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 0L20.9844 9.13954L31.2169 11.0557L24.065 18.6205L25.4046 28.9443L16 24.48L6.59544 28.9443L7.93504 18.6205L0.783095 11.0557L11.0156 9.13954L16 0Z"
        fill={color || "#FFB33E"}
      />
    </svg>
  );
};
export default StarFilledIcon;
