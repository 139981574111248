import {Button, Fade, Slide} from "@mui/material";
import "./style.scss";
const MessageModal = ({
  otherChildren,
  closeModal,
  openModal = true,
  title,
  icon,
  description,
  cta,
  otherClasses,
  iconBg,
  btnColor,
}) => {
  return (
    <Fade in={openModal}>
      <div
        onClick={(e) => e.target === e.currentTarget && closeModal()}
        className={`modal-wrap ${otherClasses || ""}`}
      >
        <Slide direction="up" in={openModal} mountOnEnter unmountOnExit>
          <div className="modal-content msg-modal">
            {icon && (
              <div className={`icon-wrap ${iconBg || "bg-primary-100"}`}>
                {icon}
              </div>
            )}
            <div className="my-auto">
              <p className="title">{title}</p>
              <div className="my-2">{description}</div>
              {otherChildren}
            </div>
            {cta || (
              <Button
                fullWidth
                color={btnColor}
                variant="contained"
                onClick={closeModal}
              >
                Thanks
              </Button>
            )}
          </div>
        </Slide>
      </div>
    </Fade>
  );
};

export default MessageModal;
