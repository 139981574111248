import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress } from "@mui/material";
import MessageModal from "components/Modal/MessageModal";
import { useDeleteSingleSubCategoryMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

const DeleteSubCategoryModal = ({ open, close, detail }) => {
 const [deleteSubCategory, { isLoading }] = useDeleteSingleSubCategoryMutation();

 const handleDelete = async () => {
  try {
   const res = await deleteSubCategory(detail.id).unwrap();
   showToast(res?.message);
   close();
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <MessageModal
   openModal={open}
   closeModal={close}
   title={`Are you sure you want to Delete ${detail?.name}?`}
   description="Suspending doesn’t mean lorem ipsum"
   icon={null}
   cta={
    <div className="grid grid-cols-2 gap-4 w-full">
     <Button variant="outlined" color="error" onClick={close}>
      Cancel
     </Button>
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      loading={isLoading}
      onClick={handleDelete}
      variant="contained"
      color="error"
     >
      Delete
     </LoadingButton>
    </div>
   }
  />
 );
};

export default DeleteSubCategoryModal;
