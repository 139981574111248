import Modal from "components/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { useParams } from "react-router-dom";
import { useCloseCampaignMutation } from "services/campaigns.api";
import { CircularProgress } from "@mui/material";
import ValidatedTextArea from "components/forms/ValidatedTextArea";

export default function CloseCampaignModal({ campaign, openModal, setOpenModal }) {
 const methods = useForm();
 const { id } = useParams();
 const [closeCampaign, { isLoading: isClosing }] = useCloseCampaignMutation();

 //  console.log(campaign, id);

 const onSubmit = async (data) => {
  const body = new FormData();
  body.append("campaign_id", id);
  body.append("reason_for_closing", data.reason_for_closing);

  try {
   let res = await closeCampaign({
    ...body,
    campaign_id: id,
    reason_for_closing: data.reason_for_closing,
   }).unwrap();
   setOpenModal(false);
   showToast(res?.message);
  } catch (error) {
   handleError(error);
  }
 };
 return (
  <Modal openModal={openModal} closeModal={() => setOpenModal(false)} title="Close Campaign">
   <div className="">
    <img src={campaign?.campaign_channel?.icon} alt="" />
    <h4 className="text-[#8585A3] font-medium text-sm pt-2">{campaign?.campaign_source?.name}</h4>
    <p className="text-[#181820] text-[18px] font-semibold">{campaign?.campaign_channel?.name}</p>
   </div>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)}>
     <ValidatedTextArea
      name="reason_for_closing"
      type="text"
      label="Message"
      required
      placeholder={`Send a message stating the reason for closing the campaign`}
     />
     <LoadingButton
      loadingIndicator={
       <CircularProgress
        sx={{
         color: "#ffffff",
        }}
        size="1.2rem"
       />
      }
      fullWidth
      variant="contained"
      type="submit"
      loading={isClosing}
      color="error"
      className="bg-red-500"
      sx={{ mt: "72px", bgColor: "red" }}
     >
      Close
     </LoadingButton>
    </form>
   </FormProvider>
  </Modal>
 );
}
