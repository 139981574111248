import React from 'react'

const FileDownloadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 14.791C7.41667 14.791 7.34167 14.7743 7.25833 14.741C7.025 14.6493 6.875 14.416 6.875 14.166V9.16602C6.875 8.82435 7.15833 8.54102 7.5 8.54102C7.84167 8.54102 8.125 8.82435 8.125 9.16602V12.6577L8.725 12.0577C8.96667 11.816 9.36667 11.816 9.60833 12.0577C9.85 12.2993 9.85 12.6993 9.60833 12.941L7.94167 14.6077C7.825 14.7243 7.65833 14.791 7.5 14.791Z" fill="#181820" />
            <path d="M7.50052 14.7915C7.34219 14.7915 7.18385 14.7332 7.05885 14.6082L5.39219 12.9415C5.15052 12.6999 5.15052 12.2999 5.39219 12.0582C5.63385 11.8165 6.03385 11.8165 6.27552 12.0582L7.94219 13.7249C8.18385 13.9665 8.18385 14.3665 7.94219 14.6082C7.81719 14.7332 7.65885 14.7915 7.50052 14.7915Z" fill="#181820" />
            <path d="M12.5013 18.9577H7.5013C2.9763 18.9577 1.04297 17.0243 1.04297 12.4993V7.49935C1.04297 2.97435 2.9763 1.04102 7.5013 1.04102H11.668C12.0096 1.04102 12.293 1.32435 12.293 1.66602C12.293 2.00768 12.0096 2.29102 11.668 2.29102H7.5013C3.65964 2.29102 2.29297 3.65768 2.29297 7.49935V12.4993C2.29297 16.341 3.65964 17.7077 7.5013 17.7077H12.5013C16.343 17.7077 17.7096 16.341 17.7096 12.4993V8.33268C17.7096 7.99102 17.993 7.70768 18.3346 7.70768C18.6763 7.70768 18.9596 7.99102 18.9596 8.33268V12.4993C18.9596 17.0243 17.0263 18.9577 12.5013 18.9577Z" fill="#181820" />
            <path d="M18.3346 8.95841H15.0013C12.1513 8.95841 11.043 7.85007 11.043 5.00007V1.66674C11.043 1.41674 11.193 1.18341 11.4263 1.09174C11.6596 0.991739 11.9263 1.05007 12.1096 1.22507L18.7763 7.89174C18.9513 8.06674 19.0096 8.34174 18.9096 8.57507C18.8096 8.8084 18.5846 8.95841 18.3346 8.95841ZM12.293 3.17507V5.00007C12.293 7.15007 12.8513 7.70841 15.0013 7.70841H16.8263L12.293 3.17507Z" fill="#181820" />
        </svg>
    )
}

export default FileDownloadIcon