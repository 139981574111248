import StatCard from "components/StatCard";
import { useState, useEffect } from "react";
// import AddBuyerModal from "../Buyers/Widgets/AddBuyerModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllEngagementsStatisticsQuery } from "services/engagements.api";

import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TwoUserIcon from "components/Vectors/TwoUserIcon";
import EngagementTab from "./Widgets/EngagementTab";

const AdminEngagements = () => {
 const [searchParams] = useSearchParams();
 const tab = searchParams.get("tab");
 const [activeTab, setactiveTab] = useState("ongoing");
 const navigate = useNavigate();

 const { data, isLoading, isError } = useGetAllEngagementsStatisticsQuery();
 const stats = data?.data;

//  if (data) {
//   console.log(stats);
//  }
 //  console.log(stats);

 useEffect(() => {
  if (tab) {
   setactiveTab(tab);
  }
 }, [tab]);

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-grey100">
   <div className="flex justify-between items-center mb-8">
    <h1 className="title">Engagement</h1>
   </div>
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    <StatCard
     name="Ongoing Engagements"
     value={stats?.ongoing}
     iconBg="bg-[#FAF5FF]"
     isLoading={isLoading}
     isError={isError}
     icon={<TwoUserIcon width={32} height={32} color="#8937CE" />}
    />
    <StatCard
     name="Completed Engagements"
     value={stats?.completed}
     iconBg="bg-success-100"
     isLoading={isLoading}
     isError={isError}
     icon={<TwoUserIcon width={32} height={32} color="#00A04A" />}
    />
    <StatCard
     name="Cancelled Engagements"
     value={stats?.cancelled}
     iconBg="bg-grey100"
     isLoading={isLoading}
     isError={isError}
     icon={<TwoUserIcon width={32} height={32} color="#8585A3" />}
    />
    <StatCard
     name="Terminated Engagements"
     value={stats?.terminated}
     iconBg="bg-[#FAEBED]"
     isLoading={isLoading}
     isError={isError}
     icon={<TwoUserIcon width={32} height={32} color="#D61E34" />}
    />
   </div>

   <div className="mt-5">
    <TabContext value={activeTab}>
     <TabList
      variant="scrollable"
      scrollButtons="auto"
      className="mt-6 font-semibold"
      value={activeTab}
      onChange={(e, newValue) => {
       navigate(`?tab=${newValue}`);
       setactiveTab(newValue);
      }}
      aria-label="basic tabs example"
     >
      <Tab label={"Ongoing"} value="ongoing" />
      <Tab label={"Completed"} value="completed" />
      <Tab label={"Cancelled"} value="cancelled" />
      <Tab label={"Terminated"} value="terminated" />
     </TabList>
     <TabPanel value="ongoing">
      <EngagementTab status="Ongoing" />
     </TabPanel>
     <TabPanel value="completed">
      <EngagementTab status="Completed" />
     </TabPanel>
     <TabPanel value="cancelled">
      <EngagementTab status="Cancelled" />
     </TabPanel>
     <TabPanel value="terminated">
      <EngagementTab status="Terminated" />
     </TabPanel>
    </TabContext>
   </div>
  </div>
 );
};
export default AdminEngagements;
