import { useState } from "react";
import { Button, Chip } from "@mui/material";
import MyDataTable, { TableDropDown } from "components/TableComponent/MyDataTable";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import EditIcon2 from "components/Vectors/EditIcon2";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import moment from "moment";
import ManageCountryModal from "../Modals/ManageCountryModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import CountryDetail from "../Widgets/CountryDetail";
import SuspendCountryModal from "../Modals/SuspendCountryModal";
import StateDetail from "../Widgets/StateDetail";
import ManageStateModal from "../Modals/ManageStateModal";
import { useGetAllCountriesQuery } from "services";

const LocationSettingTab = () => {
 const [searchParams] = useSearchParams();
 const isShowingCountryDetail = searchParams.get("id") !== null;
 const isShowingStateDetail = searchParams.get("state") !== null;

 if (isShowingStateDetail) return <StateDetail />;
 else if (isShowingCountryDetail) return <CountryDetail />;
 else return <CountryList />;
};
const CountryList = () => {
 const navigate = useNavigate();
 const [showSuspendModal, setShowSuspendModal] = useState(false);
 const [showAddStateModal, setShowAddStateModal] = useState(false);
 const [showAddCountryModal, setShowAddCountryModal] = useState(false);
 const [showEditCountryModal, setShowEditCountryModal] = useState(false);
 const [selectedEl, setSelectedEl] = useState(null);
 const [country, setCountry] = useState("");
 const { data: allCountries, isLoading, error } = useGetAllCountriesQuery();
  // console.log(allCountries);
 const headCells = [
  {
   label: "S/N",
   name: "sn",
  },
  {
   label: "Countries",
   name: "countries",
   options: {
    customBodyRender: (value) => (
     <div className="flex items-center gap-x-2">
      <img src={value?.flag} alt={value?.name} className="w-12 h-8" />
      <span>{value?.name}</span>
     </div>
    ),
   },
  },
  {
   label: "States",
   name: "states",
  },
  {
   label: "Cities",
   name: "cities",
  },
  {
   label: "Last Modified",
   name: "lastModified",
  },
  {
   label: "Status",
   name: "status",
   options: {
    customBodyRender: (value) => (
     <Chip
      color={
       value?.toLowerCase() === "active"
        ? "successInverse"
        : value?.toLowerCase() === "suspended"
        ? "errorInverse"
        : "greyBorder"
      }
      label={value}
     />
    ),
   },
  },
  {
   label: "",
   name: "action",
   options: {
    customBodyRender: (country) => (
     <TableDropDown tableId={"table"}>
      <Button
       onClick={() => {
        setSelectedEl(country);
        setShowAddStateModal(true);
        setCountry(country?.name);
       }}
       startIcon={<CorporateFareRoundedIcon />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       Add state
      </Button>
      <Button
       onClick={() => navigate(`?tab=location&id=${country?.id}`)}
       startIcon={<CorporateFareRoundedIcon />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       View states
      </Button>
      {/*<Button
       onClick={() => {
        setSelectedEl(country);
        setShowEditCountryModal(true);
       }}
       startIcon={<EditIcon2 />}
       color="inherit"
       sx={{ py: "0.5rem !important" }}
      >
       Edit country
      </Button>
      <Button
       onClick={() => {
        setSelectedEl(country);
        setShowSuspendModal(true);
       }}
       startIcon={<DoDisturbAltRoundedIcon color="#D61E34" />}
       color="error"
       sx={{ py: "0.5rem !important" }}
      >
       Suspend country
      </Button>*/}
     </TableDropDown>
    ),
   },
  },
 ];
 const data = allCountries?.data?.map((country, index) => ({
  sn: index + 1,
  countries: {
   name: country?.name,
   flag: country?.country_flag,
  },
  states: country?.states?.length,
  cities: country?.city_count,
  lastModified: moment(country?.updated_at).format("DD MMMM YYYY"),
  status: country?.status,
  action: country,
 }));

 return (
  <div>
   <div className="flex justify-between mb-4 items-center">
    <h1 className="font-semibold text-prop text-[20px] text-grey30 my-3">Location settings</h1>
    {/*<Button onClick={() => setShowAddCountryModal(true)} variant="contained">
     Add Country
 </Button>*/}
   </div>
   <MyDataTable
    options={{
     searchOpen: true,
     viewColumns: false,
     filter: false,
    }}
    isLoading={isLoading}
    error={error}
    headCells={headCells}
    scrollX={false}
    data={data}
    emptyData={{
     icon: LocationCityRoundedIcon,
     message: "No Locations yet",
    }}
   />
   <SuspendCountryModal open={showSuspendModal} close={() => setShowSuspendModal(false)} country={selectedEl} />
   <ManageStateModal
    open={showAddStateModal}
    close={() => setShowAddStateModal(false)}
    state={selectedEl}
    location={country}
   />
   <ManageCountryModal open={showAddCountryModal} close={() => setShowAddCountryModal(false)} />
   <ManageCountryModal
    open={showEditCountryModal}
    close={() => setShowEditCountryModal(false)}
    isEditing
    country={selectedEl}
   />
  </div>
 );
};

export default LocationSettingTab;
