import React from "react";

function NewFileIcon({...props}) {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M25.6667 11.6668V17.5002C25.6667 23.3335 23.3334 25.6668 17.5 25.6668H10.5C4.66671 25.6668 2.33337 23.3335 2.33337 17.5002V10.5002C2.33337 4.66683 4.66671 2.3335 10.5 2.3335H16.3334"
          stroke={props.color || "#8937CE"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.6667 11.6668H21C17.5 11.6668 16.3334 10.5002 16.3334 7.00016V2.3335L25.6667 11.6668Z"
          stroke={props.color || "#8937CE"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.16663 15.1665H15.1666"
          stroke={props.color || "#8937CE"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.16663 19.8335H12.8333"
          stroke={props.color || "#8937CE"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default NewFileIcon;
