import BackBtn from "components/BackBtn";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import EachCampaignComp from "./EachCampaignComp";
import MessageBox from "../Campaigns/Widgets/MessageBox";
import { Button, Chip, Tab } from "@mui/material";
import FinalFile from "./Widgets/FinalFile";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TerminateEngagementModal from "./Widgets/TerminateEngagementModal";
import ActivityCards from "./Widgets/ActivityCards";
import StatusEngagementPanel from "./Widgets/StatusEngagementPanel";
// import OngoingEngagement from "./Widgets/OngoingEngagement";
import EachClientReview from "components/EachClientReview";
import StarOutlineIcon from "components/Vectors/StarOutlineIcon";
import EmptyResponse from "components/EmptyResponse";
import {
 useGetSingleEngagementRelatedDataQuery,
 useGetSingleUserEngagementReviewsQuery,
 useGetSingleEngagementQuery,
} from "services/engagements.api";
import { useGetUserReviewsGivenQuery } from "services";

const ChipColor = {
 terminated: "errorInverse",
 expired: "errorInverse",
 cancelled: "greyBorder",
 ongoing: "primaryInverse",
 completed: "successInverse",
};

function EachEngagement() {
 const { id } = useParams();

 const { data, isLoading, isError } = useGetSingleEngagementQuery({
  id,
 });
 const { data: relatedData } = useGetSingleEngagementRelatedDataQuery({ id });

 //  const { data: reviews } = useGetSingleUserEngagementReviewsQuery({
 const clientId = data?.data?.client_id;
 const creatorId = data?.data?.service_provider_id;
 const { data: clientReviewsGiven } = useGetUserReviewsGivenQuery({ id: clientId });
 const { data: creatorReviewsGiven } = useGetUserReviewsGivenQuery({ id: creatorId });

 const engagementData = data?.data;
 const status = engagementData?.status;
 const clientReviews = clientReviewsGiven?.data;
 const creatorReviews = creatorReviewsGiven?.data;
 const givenReviews = [...(clientReviews || []), ...(creatorReviews || [])];
 const job = engagementData?.job;
 const isPendingPayment = engagementData?.payment_status === "pending";
 const isPendingStart = engagementData?.start_date === null;
 //console.log(reviews);
 // if (data) {
 //  console.log(engagementData);
 // }
 //  if (isLoading) {
 //   console.log("loading...");
 //  }
 //  if (!isLoading) {
 //   console.log(engagementData);
 //  }

 const statusQry = engagementData?.status;
//   console.log(statusQry)

 const [activeTab, setActiveTab] = useState("details");
 const [showTerminateModal, setshowTerminateModal] = useState(false);

 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-white">
   <BackBtn />
   <div className="pt-[50px]">
    <div className="grid grid-cols-2 gap-6 mb-3">
     <div>
      <Link
       to={`/jobs/${engagementData?.job?.id}`}
       className="text-prop font-semibold text-[24px] text-black transition hover:text-primary"
      >
       {engagementData?.job?.job_title}
      </Link>
      {/* <h3 className="text-prop font-semibold text-[24px] text-black">{engagementData?.job?.job_title}</h3> */}
      <div className="flex justify-between mt-2 items-center mb-[24px]">
       {statusQry && (
        <Chip
         color={`${ChipColor[statusQry?.toLowerCase()] ||'greyBorder'}`}
         sx={{ minWidth: 90, borderRadius: "20px" }}
         label={statusQry}
        />
       )}
       {statusQry && statusQry?.toLowerCase() === "ongoing" && (
        <Button onClick={() => setshowTerminateModal(true)} variant="contained" color="errorInverse">
         Terminate Engagement
        </Button>
       )}
      </div>
     </div>
    </div>
    <div className="grid grid-cols-2 gap-6">
     <div>
      <TabContext value={activeTab}>
       <TabList
        variant="scrollable"
        scrollButtons="auto"
        className="font-semibold"
        value={activeTab}
        sx={{
         fontWeight: 600,
         textTransform: "uppercase",
         mb: 3,
        }}
        onChange={(_, newValue) => {
         setActiveTab(newValue);
        }}
        aria-label="review tab"
       >
        <Tab label={"Details"} value="details" />
        <Tab label="Activities" value="activities" />
        {status?.toLowerCase() === "completed" && <Tab label={"Reviews"} value={"reviews"} />}
       </TabList>
       {/* if startdate then */}
       {/* {engagementData?.start_date ? (
        <OngoingEngagement
         start_date={new Date()}
         end_date={new Date().setDate(new Date().getDate() + 7)}
         days_left={5}
         isLoading={isLoading}
         isError={isError}
        />
       ) : (
        <StatusEngagementPanel
         paymentStatus={engagementData?.payment_status}
         duration={`${engagementData?.job?.duration_count} ${engagementData?.job?.duration_type}`}
         status={statusQry}
         isLoading={isLoading}
         isError={isError}

         //  status={statusQry}
         //  date={new Date()}
        />
       )} */}

       {/* {engagementData?.status === "Terminated" ? (
        <StatusEngagementPanel
         paymentStatus={engagementData?.payment_status}
         duration={`${engagementData?.terminated_at} `}
         status={statusQry}
         isLoading={isLoading}
         isError={isError}
        />
       ) : engagementData?.start_date ? (
        <OngoingEngagement
         start_date={new Date()}
         end_date={new Date().setDate(new Date().getDate() + 7)}
         days_left={5}
         isLoading={isLoading}
         isError={isError}
        />
       ) : (
        <StatusEngagementPanel
         paymentStatus={engagementData?.payment_status}
         duration={`${engagementData?.job?.duration_count} ${engagementData?.job?.duration_type}`}
         status={statusQry}
         isLoading={isLoading}
         isError={isError}
        />
       )} */}

       {/* {statusQry === "ongoing" ? (
        <OngoingEngagement
         start_date={engagementData?.start_date}
         end_date={engagementData?.end_date}
         days_left={engagementData?.days_left}
        />
       ) : (
        <StatusEngagementPanel
         paymentStatus={engagementData?.payment_status}
         duration={`${engagementData?.job?.duration_count} ${engagementData?.job?.duration_type}`}
         status={statusQry}
         //  date={new Date()}
        />
       )} */}
       <TabPanel value="details">
        <StatusEngagementPanel
         engagementData={engagementData}
         paymentStatus={engagementData?.payment_status}
         duration={`${engagementData?.job?.duration_count} ${engagementData?.job?.duration_type}`}
         status={statusQry}
         isLoading={isLoading}
         isError={isError}
        />
        <EachCampaignComp
         data={engagementData}
         terminationReason={engagementData?.reason_for_termination}
         cancellationReason={engagementData?.reason_for_cancellation}
         status={statusQry || "ongoing"}
        />
       </TabPanel>
       <TabPanel value="activities">
        <ActivityCards activities={relatedData?.activities} />
       </TabPanel>
       {status?.toLowerCase() === "completed" && (
        <TabPanel value={"reviews"}>
         <div className="flex p-4 flex-col gap-y-4">
          <Link
           to={`/client/jobs/${job?.id}`}
           className="text-3xl font-bold text-grey10 w-fit transition hover:text-primary"
          >
           {job?.job_title}
          </Link>
          <div className="flex items-center gap-4 ">
           <Chip
            sx={{ borderRadius: "8px", width: "fit-content" }}
            color={ChipColor[status?.toLowerCase()]}
            label={isPendingPayment ? "Pending Payment" : isPendingStart ? "Pending Start" : status}
           />
          </div>
          {givenReviews?.length === 0 || !givenReviews ? (
           <EmptyResponse
            message={"No Reviews yet"}
            icon={<StarOutlineIcon />}
            className="bg-[#F9F9FB] w-[108%] -left-[4%] relative min-h-[20rem] my-0 -mb-4 rounded-b-2xl"
           />
          ) : (
           givenReviews?.map((review) => <EachClientReview review={review} user={data} isClient showDelete={false} />)
          )}
         </div>
        </TabPanel>
       )}
      </TabContext>
     </div>

     <div>
      <div className="border-[1px] mt-[75px] h-fit border-borderColor rounded-[16px]">
       <p className="text-center py-[24px] font-semibold text-[18px] text-prop text-grey10  ">Conversation</p>

       <div className="">
        <MessageBox isAdmin loading={isLoading} messages={relatedData?.conversations?.data} />
       </div>
      </div>
      <FinalFile
       isProvider={false}
       // file={data?.data?.images}
       submissions={relatedData?.submission_logs?.data}
       size={data?.data?.engagement_completion_file_size}
      />
     </div>
    </div>
   </div>
   <TerminateEngagementModal
    openModal={showTerminateModal}
    setOpenModal={setshowTerminateModal}
    engagement_id={engagementData?.id}
    client_id={engagementData?.client_id}
    // setForceChange={setForceChange}
    // forceChange={forceChange}
   />
  </div>
 );
}

export default EachEngagement;
