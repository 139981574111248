import BackBtn from "components/BackBtn";
// import { useState } from "react";
import { useParams } from "react-router-dom";
import {
//  useApproveCampaignMutation,
 useGetCampaignTaskStatisticsQuery,
 useGetSingleCampaignQuery,
 useGetCampaignInfluencersQuery,
} from "services/campaigns.api";
// import { showToast } from "store/store.hooks";
import Left from "./Left";
import Right from "./Right";
// import { handleError } from "utils";
import Loader from "components/Loader";

function EachAdminCampaign() {
 const { id } = useParams();

 const { data, isLoading } = useGetSingleCampaignQuery(id);
    // const [approveCampaign,
    //     // { isLoading: isApproving }
    // ] = useApproveCampaignMutation();
 const { data: taskStats } = useGetCampaignTaskStatisticsQuery(id);
 const { data: influencers } = useGetCampaignInfluencersQuery(id);

//  const [selectedData, setselectedData] = useState({ status: "", type: "" });

 const singleCampaign = data?.data;

//  const handleApproveCampaign = async () => {
//   try {
//    const res = await approveCampaign(id).unwrap();
//    // console.log(res)
//    showToast(res?.message);
//   } catch (error) {
//    handleError(error);
//   }
//  };

 return (
  <>
   {!isLoading ? (
    <div className="h-full p-6 lg:px-10 px-4 bg-white">
     <BackBtn />
     <div className="flex gap-8 max-[1080px]:flex-col">
      <Left campaign={singleCampaign} />
      <Right campaign={singleCampaign} taskStats={taskStats} influencers={influencers} />
     </div>
    </div>
   ) : (
    <Loader />
   )}
  </>
 );
}

export default EachAdminCampaign;
