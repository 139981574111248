import EmptyResponse from "components/EmptyResponse";
import EachOfferCard from "../Widgets/EachOfferCard";
import {useParams} from "react-router-dom";
import RoundBag from "components/Vectors/RoundBag";
import { useGetSingleJobQuery } from "services/jobs.api";

const FilteredOfferTab = ({status}) => {
  const {id} = useParams();
  const {data} = useGetSingleJobQuery(id);
  const allOffers = data?.data?.offers;
  const filteredOffers = allOffers?.filter((offer) => offer?.status === status);
  // console.log(filteredOffers)
  if (filteredOffers?.length === 0)
    return (
      <EmptyResponse icon={<RoundBag />} message={`No ${status} Offers`} />
    );
  
  return (
    <div>
      {filteredOffers?.map((offer) => (
        <EachOfferCard key={offer?.id} offer={offer && offer} />
      ))}
    </div>
  );
};

export default FilteredOfferTab;
