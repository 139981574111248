import {Button, Chip, Skeleton} from "@mui/material";
import DoubleMessageIcon from "components/Vectors/DoubleMessageIcon";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useGetAllCampaignSourcesQuery} from "services";
import MusicNote from "components/Vectors/MusicNote";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import GamingPad from "components/Vectors/GamingPad";
import MessageQuestionCircle from "components/Vectors/MessageQuestionCircle";
import SubTaskDetail from "../Widgets/SubTaskDetail";
import CampaignDetail from "../Widgets/CampaignDetail";
export const icons = [
  {
    name: "social",
    icon: <DoubleMessageIcon color="black" />,
  },
  {
    name: "music",
    icon: <MusicNote />,
  },
  {
    name: "blog",
    icon: <ArticleOutlinedIcon />,
  },
  {
    name: "podcast",
    icon: <MicNoneOutlinedIcon />,
  },
  {
    name: "gaming",
    icon: <GamingPad />,
  },
  {
    name: "survey",
    icon: <MessageQuestionCircle />,
  },
];

const CampaignTab = () => {
  const [searchParams] = useSearchParams();
  const detailTab = searchParams.get("campaign");
  const subtaskDetail = searchParams.get("channel");

  return (
    <div>
      {subtaskDetail ? (
        <SubTaskDetail />
      ) : detailTab ? (
        <CampaignDetail />
      ) : (
        <CampaignList />
      )}
    </div>
  );
};

export default CampaignTab;

const CampaignList = () => {
  const {data, isLoading} = useGetAllCampaignSourcesQuery();
  const campaign = data?.data;
  const navigate = useNavigate();

  return (
    <>
      <h1 className="font-semibold text-prop text-[20px] text-grey30 my-3">
        Campaign settings
      </h1>
      <div className="flex flex-col gap-y-2">
        {isLoading
          ? [1, 2, 3, 4, 5].map(() => (
              <div className="my-6">
                <Skeleton variant="rounded" height={50} width={450} />
              </div>
            ))
          : campaign.map((item) => (
              <div
                key={item?.id}
                className="general-border flex justify-between items-center"
              >
                <div className="flex gap-x-2 items-center">
                  {
                    icons.find((icon) =>
                      item?.name?.toLowerCase()?.includes(icon.name)
                    )?.icon
                  }
                  <span className="text-grey10 font-medium">{item?.name}</span>
                  <Chip
                    label={item?.channels.length + " Channels"}
                    color="greyBorder"
                  />
                </div>
                <Button
                  onClick={() => navigate(`?tab=campaign&campaign=${item?.id}`)}
                  variant="contained"
                  color="primaryInverse"
                >
                  Manage Channels
                </Button>
              </div>
            ))}
      </div>
    </>
  );
};
