import { Skeleton } from "@mui/material";
import { createElement } from "react";

const MiniStatCard = (props) => {
 const { type, typeValue, typeCount } = props;
 return (
  <div className="bg-grey100 rounded p-1.5 w-[180px] flex flex-col gap-2">
   <p className="font-medium text-[12px] text-[#69698C]">{type}</p>
   <div className="flex justify-between">
    <p className="text-base font-semibold text-grey30">{typeValue}</p>
    <p className="text-sm font-medium text-grey50">({typeCount})</p>
   </div>
  </div>
 );
};
function StatCard({
 name,
 color = "#181820",
 type,
 typeValue,
 typeCount,
 icon,
 iconBg = "",
 value,
 value2,
 shortText,
 containerClass = "",
 reverse,
 isLoading,
 isError,
 iconDown = false,
 titleStyle = "#69698C",
}) {
 return (
  <div
   className={`rounded-xl border min-h[100px] lg:minh-[160px] font-medium bg-white border-grey90 px-5 py-4 flex items-center ${containerClass}`}
  >
   <div className="w-full">
    <div
     className={`flex mb-3 min-h-[56px] ${
      reverse ? "flex-row-reverse justify-end gap-x-3 items-center" : "justify-between items-start"
     }`}
    >
     <p className={`text-sm ${titleStyle}`}>{name}</p>
     {iconDown === false && icon && (
      <div className={`w-[56px] flex items-center justify-center h-[56px] rounded-[8px] ${iconBg}`}>{icon}</div>
     )}
    </div>
    <div className={`flex gap-4 ${iconDown ? "justify-between items-end" : ""}`}>
     {isLoading ? (
      <Skeleton variant="text" width={80} height={52} />
     ) : isError ? (
      <span className="font-bold text-lg text-grey50">...</span>
     ) : (
      <div className="flex flex-col">
       <h1 className={`text-2xl text-[${color}]`}>{value}</h1>
       {value2 && <p className="font-medium text-[#53536F] text-[18px]">{value2}</p>}
      </div>
     )}
     {iconDown && icon && (
      <div className={`w-[56px] flex items-center justify-center h-[56px] rounded-[8px] ${iconBg}`}>{icon}</div>
     )}
     {type && (
      <div className="flex gap-2">
       <MiniStatCard type={type} typeValue={typeValue} typeCount={typeCount} />
       <MiniStatCard type={type} typeValue={typeValue} typeCount={typeCount} />
      </div>
     )}
    </div>
    {/* {percentage && (
          <p className="text-xs mt-2">
            <span className="text-success mr-1">{percentage}</span> more than
            last month
          </p>
        )} */}
    {shortText && <p className="text-xs text-grey mt-2">{shortText}</p>}
   </div>
  </div>
 );
}

export function BuyerStatCard({ name, icon, iconBg = "bg-primary-100", value, iconColor = "#8937CE" }) {
 return (
  <div className="border min-w-[250px] rounded-lg bg-white h-[200px] flex flex-col items-center justify-center gap-3">
   <div className={`rounded-full w-[56px] h-[56px] flex items-center justify-center ${iconBg}`}>
    {createElement(icon, { eidth: 32, height: 32, color: iconColor })}
   </div>
   <p className="text-sm text-grey font-semibold">{name} </p>
   <p className="text-2xl text-grey30 font-medium">{value}</p>
  </div>
 );
}

export default StatCard;
