import BackBtn from "components/BackBtn";
import { Chip, CircularProgress, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import { STATUS2 } from "utils/constants";
import CalendarIcon from "components/Vectors/CalendarIcon";
// import UserDisplay from "components/TableComponent/UserDisplay";
import ImageSlideShow from "components/ImageSlideshow";
import UserProfileCard from "components/UserProfileCard";
// import MyDataTable from "components/TableComponent/MyDataTable";
// import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import InfoCard from "components/InfoCard";
import { MoreHoriz } from "@mui/icons-material";
import DropDownWrapper from "components/DropDownWrapper";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import Video from "components/Video";
import { useState } from "react";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import UsersIcon from "components/Vectors/UsersIcon";
import { useParams } from "react-router-dom";
import { useGetSingleJobQuery, useSuspendUnSuspendJobMutation } from "services/jobs.api";
import moment from "moment";
import { handleError, toCurrency } from "utils";
import { showToast } from "store/store.hooks";
import { LoadingButton } from "@mui/lab";
import SuspendJobModal from "./Widgets/SuspendJobModal";
import Tabs from "./Tabs";

const EachAdminJob = () => {
 const { id } = useParams();
 const { data } = useGetSingleJobQuery(id);
 const job = data?.data;
 if (data) console.log(data);
 const [showSuspendModal, setShowSuspendModal] = useState(false);
 const [suspendUnSuspend, { isLoading: isUnSuspending }] = useSuspendUnSuspendJobMutation();
 const handleUnSuspend = async () => {
  const body = {
   status: "Open",
   redirect_url: window.location.href,
  };
  try {
   const res = await suspendUnSuspend({ id: job?.id, body }).unwrap();
   showToast(res?.message);
  } catch (err) {
   handleError(err);
  }
 };
 const jobStatusChip = {
  Open: "successInverse",
  Suspended: "errorInverse",
  Inactive: "default",
 };
 // const approvedOffersCount = job?.offers?.filter((offer) => offer.status === "Approved").length;
 // const withdrawnOffersCount = job?.offers?.filter((offer) => offer.status === "Withdrawn").length;
 // const declinedOffersCount = job?.offers?.filter((offer) => offer.status === "Declined").length;
 // const headCells = [
 //     {
 //         name: "sn",
 //         label: "S/N",
 //     },

 //     {
 //         name: "creator",
 //         label: "Creators",
 //         options: {
 //             customBodyRender: (value) => <UserDisplay name={value.name} img={value.img} />,
 //         },
 //     },
 //     {
 //         name: "date",
 //         label: "Date",
 //     },

 //     {
 //         name: "status",
 //         label: "status",
 //         options: {
 //             filter: true,
 //             customBodyRender: (value) => (
 //                 <Chip color={`${STATUS2[value.toLowerCase()]}`} sx={{ minWidth: "100%" }} label={`${value}`} />
 //             ),
 //         },
 //     },
 //     {
 //         name: "actions",
 //         label: "",
 //         options: {
 //             customBodyRender: (id) => (
 //                 <IconButton component={Link} to={"/engagements/" + id} color="inherit">
 //                     <RemoveRedEyeOutlinedIcon />
 //                 </IconButton>
 //             ),
 //         },
 //     },
 // ];
 // const bodyData = job?.offers?.map((offer, i) => ({
 //     sn: i + 1,
 //     creator: {
 //         name: getUserDetails(offer?.creator).name,
 //         img: getUserDetails(offer?.creator).img,
 //     },
 //     date: moment(offer?.created_at).format("DD MMM, YYYY"),
 //     status: offer?.status,
 //     actions: offer?.id,
 // }));
 return (
  <div className="h-full p-6 lg:px-10 px-4 bg-white">
   <BackBtn />
   <div className="pt-6">
    <div className="gap-16 lg:gap-[114px] flex flex-col lg:flex-row justify-between">
     <div className="flex-1">
      <div className="flex justify-between items-center gap-4">
       <div>
        <p className="font-semibold text-2xl mt-3 mb-2">{job?.job_title}</p>
        <Chip label={job?.status} color={jobStatusChip[job?.status]} />
       </div>
       {job?.status?.toLocaleLowerCase() === "closed" ? null : (
        <DropDownWrapper
         position="right"
         contentPadding="px-0"
         closeOnBtnClick={false}
         action={
          <IconButton
           size="large"
           sx={{
            height: "54.2px",
            width: "54.2px",
            bgcolor: "#F2F4F7",
            borderRadius: "16px",
            "&:hover": {
             bgcolor: "#F2F4F7",
            },
           }}
          >
           <MoreHoriz width={24} height={24} />
          </IconButton>
         }
        >
         <div className="space-y-3">
          <LoadingButton
           loadingIndicator={
            <CircularProgress
             sx={{
              color: "#ffffff",
             }}
             size="1.2rem"
            />
           }
           loading={isUnSuspending}
           onClick={job?.status === "Suspended" ? handleUnSuspend : () => setShowSuspendModal(true)}
           color={job?.status === "Suspended" ? "inherit" : "error"}
           sx={{ textTransform: "none" }}
           startIcon={<DoDisturbAltOutlinedIcon />}
          >
           {job?.status === "Suspended" ? "Unsuspend" : "Suspend"} Job
          </LoadingButton>
         </div>
        </DropDownWrapper>
       )}

       {/* <DropDownWrapper
        position="right"
        contentPadding="px-0"
        closeOnBtnClick={false}
        action={
         <IconButton
          size="large"
          sx={{
           height: "54.2px",
           width: "54.2px",
           bgcolor: "#F2F4F7",
           borderRadius: "16px",
           "&:hover": {
            bgcolor: "#F2F4F7",
           },
          }}
         >
          <MoreHoriz width={24} height={24} />
         </IconButton>
        }
       > */}
       {/* <div className="space-y-3"> */}
       {/* </div> */}
       {/* </DropDownWrapper> */}
      </div>
      {job?.status.toLowerCase() === "suspended" && (
       <div className="flex gap-2 rounded-[24px] bg-grey100 my-4 px-4 py-[25px] md:px-[35px]">
        <ErrorOutlineIcon /> {job?.reason_for_suspension}
       </div>
      )}

      <div className="grid gap-4 grid-cols-2 mt-5">
       <InfoCard title="Category" description={job?.category?.name} />
       <InfoCard
        title="Timeline"
        icon={<CalendarIcon color="#8937CE" />}
        description={`${job?.duration_count} ${job?.duration_type}`}
       />
      </div>
      <p className="font-semibold text-grey10 my-5">
       <span className="text-grey60">Created:</span> {moment(job?.created_at).format("DD MMM, YYYY")}
      </p>
      <div className="mb-5">
       <UserProfileCard user={job?.creator} btnTitle="Client" />
      </div>
      <div className="flex my-7 flex-wrap ">
       {job?.tags?.map((tag) => (
        <Chip
         key={tag?.id}
         sx={{ height: 43, px: 2, borderRadius: "60px", mt: 1, mr: 1 }}
         label={tag?.tag}
         color="greyBorder"
        />
       ))}
      </div>
      {job?.images?.length > 0 && (
       <div className="my-7">
        <p className="font-semibold mt-4 mb-2">Images</p>
        <ImageSlideShow images={job?.images} />
       </div>
      )}
      {job?.videos?.length > 0 && (
       <div className="my-7">
        <p className="mb-3.5 font-semibold text-lg text-grey10">Video</p>
        <Video videoUrl={job?.videos[0]?.image_url} />
       </div>
      )}
      <div className="grid grid-cols-2 gap-4 mb-6">
       <div className="border text-center rounded-lg p-4 min-h-[90px]">
        <p className="font-medium text-sm">Offers</p>
        <p className="text-xl font-semibold">{`${job?.offers_count} of ${job?.number_of_expected_offers_to_receive}`}</p>
       </div>
       <div className="border text-center rounded-lg p-4 min-h-[90px]">
        <p className="font-medium text-sm">Views</p>
        <p className="text-xl font-semibold">{job?.views_count}</p>
       </div>
      </div>
      {/* <div className="bg-white border rounded-xl w-full mt-5">
       <div className="p-4 rounded-t-xl bg-grey100 flex gap-2 items-center">
        <p className="text-lg font-semibold">Creators List</p>
        <span className="flex rounded-full justify-center font-normal items-center h-6 w-6 text-white bg-primary">
         {job?.offers_count}
        </span>
       </div>
       <div className="p-4 py-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 mb-6">
         <div className="border rounded-lg p-4 min-h-[90px]">
          <p className="text-success font-medium">Approved</p>
          <p className="text-xl font-semibold">{approvedOffersCount}</p>
         </div>
         <div className="border rounded-lg p-4 min-h-[90px]">
          <p className="text-error font-medium">Declined</p>
          <p className="text-xl font-semibold">{declinedOffersCount}</p>
         </div>
         <div className="border rounded-lg p-4 min-h-[90px]">
          <p className="text-primary font-medium">Pending</p>
          <p className="text-xl font-semibold">5</p>
         </div>
         <div className="border rounded-lg p-4 min-h-[90px]">
          <p className="text-grey50 font-medium">Withdrawn</p>
          <p className="text-xl font-semibold">{withdrawnOffersCount}</p>
         </div>
        </div>
        {job?.offers_count > 0 && (
         <MyDataTable
          hideScrollbar={false}
          scrollX={false}
          options={{
           searchOpen: false,
           viewColumns: false,
           filter: false,
          }}
          emptyData={{ message: "No Creators yet", icon: UsersIcon }}
          headCells={headCells}
          data={bodyData}
         />
        )}
       </div>
      </div> */}
      {/* <div className="flex mt-5 gap-2 bg-[#EAEAF0] p-4 rounded-lg justify-center">
       <InfoOutlinedIcon />
       <span>
        The Overall Status of the Campaign will be marked as completed once all the submissions are marked as completed
       </span>
      </div> */}
     </div>
     <div className="flex-1">
      <div className="flex mb-6 gap-2 justify-between">
       <div className="">
        <span className="text-prop font-medium text-grey50 text-[14px]">Price</span>
        <p className="font-semibold leading-[140%] text-[32px] tracking-[-0.06em] text-grey10">
         {toCurrency(job?.price)}
        </p>
       </div>
       <div>
        <p className="font-medium text-sm text-grey50 mb-3 text-end">Location</p>
        <p className="font-medium text-grey10 text-sm flex items-center gap-1">
         {job?.city_id ? `${job?.city?.name}, ${job?.state?.name}` : "Any Location"}
        </p>
       </div>
      </div>

      <div className={`border border-borderColor p-6 rounded-lg`}>
       <p className="font-semibold text-[16px] mb-[4px] text-grey10">Description</p>
       <p className="text-prop font-medium text-[1rem] text-grey50">{job?.description}</p>
      </div>

      <div className="mt-16">
       <div className="flex gap-2.5 items-center mb-8 ">
        <p className="font-semibold text-xl text-black">All Offers received</p>
        <span className="p3 rounded-[6px] h-12 w-12 justify-center flex items-center bg-[#424257] font-semibold text-white text-xl">
         {job?.offers_count}
        </span>
       </div>
       {job?.offers_count > 0 && <Tabs />}
      </div>
     </div>
    </div>
   </div>
   <SuspendJobModal job={job} openModal={showSuspendModal} closeModal={() => setShowSuspendModal(false)} />
  </div>
 );
};

export default EachAdminJob;
