import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FilteredOfferTab from "./FilteredOfferTab";
import { useGetSingleJobQuery } from "services/jobs.api";

const Tabs = () => {
 const [presentStep, setPresentStep] = useState(1);
 const [searchParams] = useSearchParams();
 const navigate = useNavigate();

 const { id } = useParams();
 const { data } = useGetSingleJobQuery(id);
 const allOffers = data?.data?.offers;

 const filterOffers = (status) => {
  return allOffers?.filter((offer) => offer?.status === status);
 };
 console.log(allOffers);
 const stepComponents = {
  1: <FilteredOfferTab status="Pending" />,
  2: <FilteredOfferTab status="Accepted" />,
  3: <FilteredOfferTab status="Declined" />,
  4: <FilteredOfferTab status="Withdrawn" />,
 };

 useEffect(() => {
  const step = searchParams.get("step");
  if (step) setPresentStep(Number(step));
 }, [presentStep, searchParams]);

 return (
  <>
   <div className="flex border-b justify-between items-center noScrollBar overflow-x-auto flex-nowrap my-7 md:my-0 ">
    {steps.map((value) => (
     <button
      key={value.id}
      type="button"
      onClick={() => {
       navigate(`?step=${value.id}`);
       setPresentStep(value.id);
      }}
      className="flex"
     >
      <span
       className={`px-5 pb-2 flex gap-1 items-center font-semibold ${
        presentStep === value.id ? "border-b-[3px] border-primary text-grey10" : "text-grey50"
       }`}
      >
       {value.title}
       <span
        className={`p3 rounded-[6px] h-6 w-6 justify-center flex items-center hfit ${
         presentStep === value.id ? "bg-primary" : "bg-[#424257]"
        } font-semibold text-white text-xs`}
       >
        {filterOffers(value.title)?.length}
       </span>
      </span>
     </button>
    ))}
   </div>
   <div className="mt-4">{stepComponents[presentStep]}</div>
  </>
 );
};

export default Tabs;
const steps = [
 {
  id: 1,
  title: "Pending",
 },
 {
  id: 2,
  title: "Accepted",
 },
 {
  id: 3,
  title: "Declined",
 },
 {
  id: 4,
  title: "Withdrawn",
 },
];
