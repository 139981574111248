function ArchiveIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 33 32"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 13.7067C14.89 13.7067 13.53 13.4667 12.2367 13.0001C11.7167 12.8134 11.45 12.2401 11.6367 11.7201C11.8367 11.2001 12.41 10.9334 12.93 11.1201C15.0767 11.8934 17.4367 11.8934 19.5833 11.1201C20.1033 10.9334 20.6767 11.2001 20.8633 11.7201C21.05 12.2401 20.7833 12.8134 20.2633 13.0001C18.97 13.4667 17.61 13.7067 16.25 13.7067Z"
        fill={props.color || "#2C2C3A"}
      />
      <path
        d="M25.6767 30.3332C24.9967 30.3332 24.25 30.1332 23.53 29.7198L17.0233 26.1065C16.6367 25.8932 15.8767 25.8932 15.49 26.1065L8.98333 29.7198C7.66333 30.4532 6.31667 30.5332 5.29 29.9198C4.26333 29.3198 3.67667 28.1065 3.67667 26.5998V7.81317C3.67667 4.4265 6.43667 1.6665 9.82333 1.6665H22.69C26.0767 1.6665 28.8367 4.4265 28.8367 7.81317V26.5998C28.8367 28.1065 28.25 29.3198 27.2233 29.9198C26.7567 30.1998 26.2233 30.3332 25.6767 30.3332ZM16.25 23.9465C16.8767 23.9465 17.49 24.0798 17.9833 24.3598L24.49 27.9732C25.17 28.3598 25.7967 28.4398 26.1967 28.1998C26.5967 27.9598 26.8233 27.3865 26.8233 26.5998V7.81317C26.8233 5.53317 24.9567 3.6665 22.6767 3.6665H9.82333C7.54333 3.6665 5.67667 5.53317 5.67667 7.81317V26.5998C5.67667 27.3865 5.90333 27.9732 6.30333 28.1998C6.70333 28.4265 7.31667 28.3598 8.01 27.9732L14.5167 24.3598C15.01 24.0798 15.6233 23.9465 16.25 23.9465Z"
        fill={props.color || "#2C2C3A"}
      />
      <path
        d="M25.6767 30.3332C24.9967 30.3332 24.25 30.1332 23.53 29.7198L17.0233 26.1065C16.6367 25.8932 15.8767 25.8932 15.49 26.1065L8.98333 29.7198C7.66333 30.4532 6.31667 30.5332 5.29 29.9198C4.26333 29.3198 3.67667 28.1065 3.67667 26.5998V7.81317C3.67667 4.4265 6.43667 1.6665 9.82333 1.6665H22.69C26.0767 1.6665 28.8367 4.4265 28.8367 7.81317V26.5998C28.8367 28.1065 28.25 29.3198 27.2233 29.9198C26.7567 30.1998 26.2233 30.3332 25.6767 30.3332ZM16.25 23.9465C16.8767 23.9465 17.49 24.0798 17.9833 24.3598L24.49 27.9732C25.17 28.3598 25.7967 28.4398 26.1967 28.1998C26.5967 27.9598 26.8233 27.3865 26.8233 26.5998V7.81317C26.8233 5.53317 24.9567 3.6665 22.6767 3.6665H9.82333C7.54333 3.6665 5.67667 5.53317 5.67667 7.81317V26.5998C5.67667 27.3865 5.90333 27.9732 6.30333 28.1998C6.70333 28.4265 7.31667 28.3598 8.01 27.9732L14.5167 24.3598C15.01 24.0798 15.6233 23.9465 16.25 23.9465Z"
        fill={props.color || "#2C2C3A"}
      />
    </svg>
  );
}

export default ArchiveIcon;
