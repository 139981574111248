import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const searchApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "searchApi",
 tagTypes: ["search"],
 endpoints: (builder) => ({
  searchUser: builder.query({
    query: ( body ) => ({
        url: `search-users`,
        method: "POST",
        body,
       }),
          providesTags: ["search"],
  }),
 
 }),
});

export const {
useSearchUserQuery,
useLazySearchUserQuery,
} = searchApi;
