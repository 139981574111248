import { Fade, Slide } from "@mui/material";

import "./style.scss";

const SharedModal = ({ closeModal, openModal = false, direction = "up", children }) => {
 return (
  <Fade in={openModal}>
   <div onClick={(e) => e.target === e.currentTarget && closeModal()} className="modal-wrap modal-dark">
    <Slide direction={direction} in={openModal} mountOnEnter unmountOnExit>
     {children}
    </Slide>
   </div>
  </Fade>
 );
};

export default SharedModal;
