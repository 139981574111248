import React from "react";

const Bag2Icon = ({fill, width, height}) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 8.62981C16.09 8.62981 15.75 8.28981 15.75 7.87981V6.49981C15.75 5.44981 15.3 4.42981 14.52 3.71981C13.73 2.99981 12.71 2.66981 11.63 2.76981C9.83 2.93981 8.25 4.77981 8.25 6.69981V7.66981C8.25 8.07981 7.91 8.41981 7.5 8.41981C7.09 8.41981 6.75 8.07981 6.75 7.66981V6.68981C6.75 3.99981 8.92 1.51981 11.49 1.26981C12.99 1.12981 14.43 1.59981 15.53 2.60981C16.62 3.59981 17.25 5.01981 17.25 6.49981V7.87981C17.25 8.28981 16.91 8.62981 16.5 8.62981Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M15.0001 22.75H9.00006C4.38006 22.75 3.52006 20.6 3.30006 18.51L2.55006 12.52C2.44006 11.44 2.40006 9.89 3.45006 8.73C4.35006 7.73 5.84006 7.25 8.00006 7.25H16.0001C18.1701 7.25 19.6601 7.74 20.5501 8.73C21.5901 9.89 21.5601 11.44 21.4501 12.5L20.7001 18.51C20.4801 20.6 19.6201 22.75 15.0001 22.75ZM8.00006 8.75C6.31006 8.75 5.15006 9.08 4.56006 9.74C4.07006 10.28 3.91006 11.11 4.04006 12.35L4.79006 18.34C4.96006 19.94 5.40006 21.26 9.00006 21.26H15.0001C18.6001 21.26 19.0401 19.95 19.2101 18.36L19.9601 12.35C20.0901 11.13 19.9301 10.3 19.4401 9.75C18.8501 9.08 17.6901 8.75 16.0001 8.75H8.00006Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M15.4199 13.1499C14.8599 13.1499 14.4099 12.6999 14.4099 12.1499C14.4099 11.5999 14.8599 11.1499 15.4099 11.1499C15.9599 11.1499 16.4099 11.5999 16.4099 12.1499C16.4099 12.6999 15.9699 13.1499 15.4199 13.1499Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M8.41991 13.1499C7.85991 13.1499 7.40991 12.6999 7.40991 12.1499C7.40991 11.5999 7.85991 11.1499 8.40991 11.1499C8.95991 11.1499 9.40991 11.5999 9.40991 12.1499C9.40991 12.6999 8.96991 13.1499 8.41991 13.1499Z"
        fill={fill || "#FAF5FF"}
      />
    </svg>
  );
};

export default Bag2Icon;
