import { Button, Checkbox, FormControlLabel } from '@mui/material';
import ValidatedInput from 'components/forms/ValidatedInput';
import MailIcon from 'components/Vectors/MailIcon';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import BasicAuthLayout from 'templates/AuthLayout/BasicAuthLayout';
import { useLoginMutation } from 'services/auth.api';
import { useDispatch } from 'react-redux';
import { setUserDetails, setUserToken } from 'store/slice/AuthSlice';
import { handleError } from 'utils';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';
import MessageModal from 'components/Modal/MessageModal';
import MessageIcon from 'components/Vectors/MessageIcon';
import { useState } from 'react';
import ProfileDeleteIcon from 'components/Vectors/ProfileDeleteIcon';

function AdminLogin() {
    const [showUnverifiedModal, setShowUnverifiedModal] = useState(false);
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const methods = useForm({
        mode: 'all',
    });
    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // let { search } = useLocation();
    // const params = new URLSearchParams(search);
    // const invitation = params.get("invitation");
    const handleContactSuperAdmin = () => {};

    const onSubmit = async (body) => {
        try {
            let res = await login(body).unwrap();
            dispatch(setUserToken(res?.data?.access_token));
            dispatch(setUserDetails(res?.data));
            navigate('/');
        } catch (error) {
            if (error?.data?.message === 'Email not verified') {
                setShowUnverifiedModal(true);
            } else if (error?.data?.message === 'Account suspended/restricted') {
                setShowSuspendedModal(true);
            }
            handleError(error);
        }
    };
    return (
        <BasicAuthLayout>
            <div className="max-w-[450px] text-center">
                <h1 className="text-3xl font-bold text-[#2C2C3A]">Log in to Admin Dashboard</h1>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="pt-8">
                        <ValidatedInput
                            label="Email Address"
                            name="email"
                            type="email"
                            placeholder="Enter Email Address"
                            suffixIcon={<MailIcon fontSize="12px" color="#8585A3" />}
                        />
                        <ValidatedInput
                            label="Password"
                            placeholder="Enter Your Password"
                            type="password"
                            name="password"
                        />
                        <div className="flex justify-between items-center text-sm">
                            <FormControlLabel
                                control={
                                    <Controller
                                        control={methods.control}
                                        defaultValue={1}
                                        render={({ field: props }) => (
                                            <Checkbox
                                                {...props}
                                                checked={props.value === 1 ? true : false}
                                                onChange={(e) => props.onChange(e.target.checked ? 1 : 0)}
                                                size={'small'}
                                                defaultChecked
                                            />
                                        )}
                                        name={'remember_me'}
                                    />
                                }
                                sx={{ 'MuiFormControlLabel-label': { fontSize: 14 } }}
                                label="Remember me"
                            />
                            <Link className="text-primary" to="/reset-password">
                                Forgot Password
                            </Link>
                        </div>

                        <LoadingButton
                            loadingIndicator={
                                <CircularProgress
                                    sx={{
                                        color: '#fff',
                                    }}
                                    size="1.2rem"
                                />
                            }
                            type="submit"
                            loading={isLoading}
                            sx={{ width: '100%', mt: 6 }}
                            variant="contained"
                        >
                            Log In
                        </LoadingButton>
                    </form>
                </FormProvider>
            </div>

            <MessageModal
                openModal={showUnverifiedModal}
                closeModal={() => setShowUnverifiedModal(false)}
                title="Email not verified"
                description={
                    <p className="text-[#8585A3]">
                        <span className="text-[#181820]">{methods.getValues('email')}</span> has not been verified as an
                        admin
                    </p>
                }
                icon={<MessageIcon />}
                iconBg="bg-[#FAF5FF]"
                cta={
                    <Button
                        onClick={() => {
                            methods.reset();
                            setShowUnverifiedModal(false);
                        }}
                        variant="contained"
                        fullWidth
                    >
                        Try another mail
                    </Button>
                }
            />

            <MessageModal
                openModal={showSuspendedModal}
                closeModal={() => setShowSuspendedModal(false)}
                title="Account suspended"
                description={
                    <p className="text-[#8585A3]">
                        <span className="text-[#181820]">{methods.getValues('email')}</span> has been suspended from
                        being an admin
                    </p>
                }
                icon={<ProfileDeleteIcon />}
                iconBg="bg-[#FAF5FF]"
                cta={
                    <div className="flex gap-x-3.5">
                        <Button
                            onClick={handleContactSuperAdmin}
                            variant="contained"
                            color="primaryInverse"
                            sx={{ textTransform: 'none', minWidth: 'max-content' }}
                        >
                            Contact super admin
                        </Button>
                        <Button
                            onClick={() => {
                                methods.reset();
                                setShowSuspendedModal(false);
                                setShowUnverifiedModal(false);
                            }}
                            variant="contained"
                            fullWidth
                        >
                            Try another mail
                        </Button>
                    </div>
                }
            />
        </BasicAuthLayout>
    );
}

export default AdminLogin;
