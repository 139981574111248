import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "services";

export const campaignsApi = createApi({
 baseQuery: baseQueryWithReauth,
 reducerPath: "campaignsApi",
 tagTypes: ["campaigns"],
 endpoints: (builder) => ({
  getAllCampaigns: builder.query({
   query: ({ params }) => ({
    url: `campaign/list`,
    params,
   }),
  }),
  getAllCampaignsByStatus: builder.query({
   query: ({ status, ...params }) => {
    // console.log(status, params)
    return {
     url: `campaign/status/${status}`,
     params,
    };
   },

   providesTags: ["campaigns"],
  }),

  getAllCampaignsStatistics: builder.query({
   query: () => ({
    url: `campaign/statistics`,
   }),
   providesTags: ["campaigns"],
  }),
  getSingleCampaign: builder.query({
   query: (id) => ({
    url: `/campaign/show/${id}`,
   }),
   providesTags: ["campaigns"],
  }),

  suspendCampaign: builder.mutation({
   query: ({ id, body }) => {
    // console.log(id, body)
    return {
     url: `campaign/suspend-campaign/${id}`,
     method: "POST",
     body,
    };
   },
   invalidatesTags: ["campaigns"],
  }),

  approveCampaign: builder.mutation({
   query: (id) => ({
    url: `campaign/approve-campaign/${id}`,
    method: "POST",
   }),
   invalidatesTags: ["campaigns"],
  }),
  getCampaignTasks: builder.query({
   query: (id) => ({
    url: `campaign/tasks/${id}`,
   }),
   providesTags: ["campaigns"],
  }),
  getCampaignInfluencers: builder.query({
   query: (id) => ({
    url: `campaign/influencers/${id}`,
   }),
   providesTags: ["campaigns"],
  }),

  getInfluencerSubmissions: builder.query({
   query: (id) => ({
    url: `submissions/${id}`,
   }),
   providesTags: ["campaigns"],
  }),

  getCampaignTaskStatistics: builder.query({
   query: (id) => ({
    url: `campaign/task/statistics/${id}`,
   }),
   providesTags: ["campaigns"],
  }),
  acceptRejectTaskCompletion: builder.mutation({
   query: (body) => ({
    url: "campaign/task/accept-decline-completion",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaigns"],
  }),
  removeInfluencerFromCampaign: builder.mutation({
   query: (body) => ({
    url: "campaign/remove-influencer-from-task",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaigns"],
  }),
  closeCampaign: builder.mutation({
   query: (body) => ({
    url: "campaign/close-campaign",
    method: "POST",
    body,
   }),
   invalidatesTags: ["campaigns"],
  }),
  deleteCampaign: builder.mutation({
   query: (id) => ({
    url: `campaign/delete-campaign/${id}`,
    method: "DELETE",
   }),
   invalidatesTags: ["campaigns"],
  }),
 }),
});

export const {
 useGetAllCampaignsQuery,
 useGetAllCampaignsByStatusQuery,
 useGetAllCampaignsStatisticsQuery,
 useGetSingleCampaignQuery,
 useGetCampaignTasksQuery,
 useGetCampaignInfluencersQuery,
 useGetInfluencerSubmissionsQuery,
 useGetCampaignTaskStatisticsQuery,
 useAcceptRejectTaskCompletionMutation,

 useSuspendCampaignMutation,
 useApproveCampaignMutation,
 useCloseCampaignMutation,
 useDeleteCampaignMutation,
 useRemoveInfluencerFromCampaignMutation,
} = campaignsApi;
