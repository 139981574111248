import { Button } from "@mui/material";
import { useState } from "react";

import SharedModal from "components/Modal/SharedModal";
import SubmissionModal from "./SubmissionModal";
import moment from "moment";
import { getStatusColor } from "utils";

const CampaignCard = ({ title, value, color = "" }) => {
 return (
  <div className="border border-[#EAEAF0] rounded-lg p-4 w-full">
   <div className="flex items-center gap-2">
    <span className={`h-[8px] w-[8px] rounded-full`} style={{ background: color }}></span>
    <h4 className="text-[#2C2C3A] text-sm font-medium">{title}</h4>
   </div>

   <h3 className="text-[#2C2C3A] text-[20px] font-semibold pt-4">{value}</h3>
  </div>
 );
};

const Right = ({ campaign, taskStats, influencers }) => {
 const [modal, setModal] = useState(false);

 //  console.log(influencers);

 const stats = [
  {
   title: "Ongoing",
   val: taskStats?.data?.ongoing,
   color: "#1989D4",
  },
  {
   title: "Exited",
   val: taskStats?.data?.exited,
   color: "#D61E34",
  },
  {
   title: "Failed Timeline",
   val: taskStats?.data?.deadline_failure,
   color: "#D61E34",
  },
  {
   title: "Removed",
   val: taskStats?.data?.removed,
   color: "#4B5E5F",
  },
 ];

 return (
  <div className="border border-[#EAEAF0] rounded-2xl w-full">
   <header className="py-4 border-b border-b-[#EAEAF0]">
    <h3 className="text-[#2C2C3A] text-2xl font-bold pl-4">Influencers list</h3>
   </header>

   <div className="p-4">
    <h4 className="text-[#2C2C3A] font-semibold pb-6">{campaign?.influencers_application_count} Influencers</h4>
    <CampaignCard title="Completed" color="#00A04A" value={taskStats?.data?.completed} />

    <div className="grid grid-cols-2 gap-2 mt-2 max-[1180px]:grid-cols-2">
     {stats.map((stat) => (
      <CampaignCard key={stat.title} title={stat.title} color={stat.color} value={stat.val} />
     ))}
    </div>

    {influencers?.data?.length > 0 ? (
     <div className="mt-6 w-full overflow-auto">
      <table className="w-full">
       <thead>
        <tr>
         <th className="text-[#181820] text-xs font-medium text-left p-4 whitespace-nowrap bg-[#F6F6F7]">Influencer</th>
         <th className="text-[#181820] text-xs font-medium text-left p-4 whitespace-nowrap bg-[#F6F6F7]">
          Date Joined
         </th>
         <th className="text-[#181820] text-xs font-medium text-left p-4 whitespace-nowrap bg-[#F6F6F7]">
          Task Status
         </th>
         <th className="text-[#181820] text-xs font-medium text-left p-4 whitespace-nowrap bg-[#F6F6F7]">
          Submission Status
         </th>
         <th className="p-4 bg-[#F6F6F7]"></th>
        </tr>
       </thead>

       <tbody>
        {influencers?.data?.map((item, i) => (
         <tr key={item?.influencer?.id}>
          <td className="p-4 border-b border-b-[#DCDCE5]">
           <div className="flex items-center gap-2  max-[1080px]:mr-[4rem]">
            <img
             src={!item?.influencer?.profile_image ? "/default_avatar.png" : item?.influencer?.profile_image}
             alt="influencer display pic"
             className="h-[2.5rem] w-[2.5rem] rounded-full"
            />
            <div>
             <h4 className="text-[#2C2C3A] text-sm font-semibold">
              {item?.influencer?.first_name} {item?.influencer?.last_name}
             </h4>
             <p className="text-[#8585A3] text-xs font-medium">@{item?.influencer?.username}</p>
            </div>
           </div>
          </td>
          <td className="text-[#2C2C3A] text-sm font-medium p-4 border-b border-b-[#DCDCE5] text-left">
           {moment(item?.created_at).format("DD MMM, YYYY")}
          </td>
          <td className="text-[#2C2C3A] text-sm font-medium p-4 border-b border-b-[#DCDCE5] text-left">
           <span
            style={{
             color: getStatusColor(item?.status?.toLowerCase()),
            }}
            className="flex items-center justify-center h-[24px] px-2 font-semibold text-xs bg-white uppercase
           border border-[#DCDCE5] rounded-[4px] w-fit whitespace-nowrap
           "
           >
            {item?.status}
           </span>
          </td>
          <td className="text-[#2C2C3A] text-sm font-medium p-4 border-b border-b-[#DCDCE5] text-left">
           <span
            style={{
             color: getStatusColor(
              !item?.last_submission_status ? item?.last_submission_status : item?.last_submission_status.toLowerCase(),
             ),
            }}
            className="flex items-center justify-center h-[24px] px-2 font-semibold text-xs bg-white uppercase
           border border-[#DCDCE5] rounded-[4px] w-fit whitespace-nowrap
           "
           >
            {!item?.last_submission_status ? "no submission yet" : item?.last_submission_status}
           </span>
          </td>
          <td className="p-4 border-b border-b-[#DCDCE5]">
           <button onClick={() => setModal(item)}>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
             <rect width="34" height="34" rx="8" fill="#EAEAF0" />
             <path
              d="M16.9999 20.2475C15.2074 20.2475 13.7524 18.7925 13.7524 17C13.7524 15.2075 15.2074 13.7525 16.9999 13.7525C18.7924 13.7525 20.2474 15.2075 20.2474 17C20.2474 18.7925 18.7924 20.2475 16.9999 20.2475ZM16.9999 14.8775C15.8299 14.8775 14.8774 15.83 14.8774 17C14.8774 18.17 15.8299 19.1225 16.9999 19.1225C18.1699 19.1225 19.1224 18.17 19.1224 17C19.1224 15.83 18.1699 14.8775 16.9999 14.8775Z"
              fill="#292D32"
             />
             <path
              d="M17.0001 23.765C14.1801 23.765 11.5176 22.115 9.68756 19.25C8.89256 18.0125 8.89256 15.995 9.68756 14.75C11.5251 11.885 14.1876 10.235 17.0001 10.235C19.8126 10.235 22.4751 11.885 24.3051 14.75C25.1001 15.9875 25.1001 18.005 24.3051 19.25C22.4751 22.115 19.8126 23.765 17.0001 23.765ZM17.0001 11.36C14.5776 11.36 12.2601 12.815 10.6401 15.3575C10.0776 16.235 10.0776 17.765 10.6401 18.6425C12.2601 21.185 14.5776 22.64 17.0001 22.64C19.4226 22.64 21.7401 21.185 23.3601 18.6425C23.9226 17.765 23.9226 16.235 23.3601 15.3575C21.7401 12.815 19.4226 11.36 17.0001 11.36Z"
              fill="#292D32"
             />
            </svg>
           </button>
          </td>
         </tr>
        ))}
       </tbody>
      </table>
     </div>
    ) : (
     <div className="flex items-center flex-col mt-12 gap-6">
      <img src="/empty-influncers.png" alt="" />
      <h4 className="font-semibold text-center">
       No influencers <br />
       has joined
      </h4>
     </div>
    )}

    {influencers?.data?.length > 0 && (
     <div className="flex items-center justify-center mt-6">
      <Button
       className="flex items-center justify-center text-sm !rounded-md !text-[#181820] whitespace-nowrap"
       sx={{
        height: "36px",
        width: "123px",
        border: "1px solid #BFBFCF",
       }}
      >
       View more
      </Button>
     </div>
    )}

    <div className="mt-6 bg-[#EAEAF066] px-8 py-6 rounded-lg flex items-center gap-2">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
       d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
       fill="#8585A3"
      />
      <path
       d="M12 13.75C11.59 13.75 11.25 13.41 11.25 13V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75Z"
       fill="#8585A3"
      />
      <path
       d="M12 17C11.87 17 11.74 16.97 11.62 16.92C11.5 16.87 11.39 16.8 11.29 16.71C11.2 16.61 11.13 16.51 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.87 11.03 15.74 11.08 15.62C11.13 15.5 11.2 15.39 11.29 15.29C11.39 15.2 11.5 15.13 11.62 15.08C11.86 14.98 12.14 14.98 12.38 15.08C12.5 15.13 12.61 15.2 12.71 15.29C12.8 15.39 12.87 15.5 12.92 15.62C12.97 15.74 13 15.87 13 16C13 16.13 12.97 16.26 12.92 16.38C12.87 16.51 12.8 16.61 12.71 16.71C12.61 16.8 12.5 16.87 12.38 16.92C12.26 16.97 12.13 17 12 17Z"
       fill="#8585A3"
      />
     </svg>
     <p className="text-[#8585A3] text-xs font-medium">
      The Overall Status of the Campaign will be marked as completed once all the submissions are marked as completed
     </p>
    </div>
   </div>
   <SharedModal openModal={modal} closeModal={() => setModal(false)} direction="up">
    <div>
     <SubmissionModal item={modal} setModal={setModal} clientId={campaign?.client?.id} />
    </div>
   </SharedModal>
  </div>
 );
};

export default Right;
