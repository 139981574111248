const UserTick = ({width, height, fill}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 17.0003C11.7733 17.0003 8.33331 13.5603 8.33331 9.33366C8.33331 5.10699 11.7733 1.66699 16 1.66699C20.2266 1.66699 23.6666 5.10699 23.6666 9.33366C23.6666 13.5603 20.2266 17.0003 16 17.0003ZM16 3.66699C12.88 3.66699 10.3333 6.21366 10.3333 9.33366C10.3333 12.4537 12.88 15.0003 16 15.0003C19.12 15.0003 21.6666 12.4537 21.6666 9.33366C21.6666 6.21366 19.12 3.66699 16 3.66699Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M4.54688 30.3333C4.00021 30.3333 3.54688 29.88 3.54688 29.3333C3.54688 23.64 9.13354 19 16.0002 19C17.3469 19 18.6669 19.1734 19.9469 19.5334C20.4802 19.68 20.7869 20.2267 20.6402 20.76C20.4936 21.2933 19.9469 21.6 19.4135 21.4534C18.3202 21.1467 17.1735 21 16.0002 21C10.2402 21 5.54688 24.7333 5.54688 29.3333C5.54688 29.88 5.09354 30.3333 4.54688 30.3333Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M24 30.3337C21.7867 30.3337 19.7067 29.1603 18.5867 27.2537C17.9867 26.2937 17.6667 25.1603 17.6667 24.0003C17.6667 22.0537 18.5334 20.2536 20.04 19.0536C21.16 18.1603 22.5734 17.667 24 17.667C27.4934 17.667 30.3334 20.507 30.3334 24.0003C30.3334 25.1603 30.0134 26.2937 29.4134 27.267C29.08 27.827 28.6533 28.3337 28.1467 28.7603C27.04 29.7737 25.56 30.3337 24 30.3337ZM24 19.667C23.0134 19.667 22.08 20.0003 21.2934 20.627C20.2667 21.4403 19.6667 22.6803 19.6667 24.0003C19.6667 24.787 19.88 25.5603 20.2934 26.227C21.0667 27.5336 22.4934 28.3337 24 28.3337C25.0534 28.3337 26.0667 27.947 26.84 27.2537C27.1867 26.9603 27.48 26.6137 27.6933 26.2403C28.12 25.5603 28.3334 24.787 28.3334 24.0003C28.3334 21.6137 26.3867 19.667 24 19.667Z"
        fill={fill || "#FAF5FF"}
      />
      <path
        d="M23.2398 26.3204C22.9865 26.3204 22.7331 26.2271 22.5331 26.0271L21.2132 24.7071C20.8265 24.3205 20.8265 23.6804 21.2132 23.2937C21.5998 22.907 22.2398 22.907 22.6265 23.2937L23.2665 23.9338L25.3998 21.9604C25.7998 21.587 26.4398 21.6138 26.8131 22.0138C27.1865 22.4138 27.1598 23.0538 26.7598 23.4271L23.9198 26.0537C23.7198 26.2271 23.4798 26.3204 23.2398 26.3204Z"
        fill={fill || "#FAF5FF"}
      />
    </svg>
  );
};

export default UserTick;
