import SendIconPlane from "components/Vectors/SendIconPlane";
import paperClip from "../../../../assets/icons/paperclip.svg";
import emojiIcon from "../../../../assets/icons/emoji-icon.svg";
import FileDisplayUi from "components/FileDisplayUI";
import { getFileSize, truncateString } from "utils";
import { useState } from "react";
import DropDownWrapper from "components/DropDownWrapper";
import SmallTickIcon from "components/Vectors/SmallTickIcon";
import MessageIcon from "components/Vectors/MessageIcon";
import EmptyResponse from "components/EmptyResponse";

import EmojiPicker from "emoji-picker-react";
import { Avatar } from "@mui/material";
import "./style.scss";
import { formatDateTime } from "utils";

const MessageWidget = ({ isSender, message }) => {
 // console.log(message)
 return (
  // <div
  //   className={`flex gap-2 max-w-[400px] mt-[32px] ${
  //     isSender ? "flex-row-reverse float-left" : "flex-row-reverse float-left"
  //   }`}
  // >
  //   <div className="">
  //     <p
  //       className={`${
  //         isSender ? "bg-[#531F7F] text-[#fff]" : "bg-white text-grey50"
  //       } p-4 w-full max-w-[250px] md:max-w-[260px] lg:max-w-[350px] rounded-tr-[10px] rounded-br-[10px]
  //     rounded-bl-[10px] font-medium text-prop `}
  //     >
  // Lorem ipsum dolor sit amet consectetur adipisicing elit. At sapiente
  // illum provident. Alias laboriosam suscipit natus minus quo
  // consequuntur ipsa repellendus assumenda quos, laborum aut!
  //     </p>

  //     {/* time  */}
  //     <span
  //       className={`text-[14px] font-medium text-prop text-grey ${
  //         isSender ? "float-left" : ""
  //       } mt-2 `}
  //     >
  //       11:30am
  //     </span>
  //   </div>
  //   <img
  //     src="/images/jide-kosoko.png"
  //     alt="avatar"
  //     className="h-[40px] w-[40px] rounded-full "
  //   />
  // </div>
  <div key={1}>
   <div className={`message ${isSender ? "receiver" : "sender"} `}>
    {!isSender && (
     //           <div className="relative">

     // <Avatar
     //             className="avatar"
     //             // src={senderInfo?.avatar?.url || ""}
     //             src={message?.sender?.profile_image}
     //             alt={"Sender"}
     //           />

     //           </div>
     <div className="relative h-[40px] w-[40px] ">
      <Avatar
       src={message?.sender?.profile_image}
       alt="avatar"
       className="w-full"
       // sx={{
       //     borderRadius: imgRounded ? '50%' : '4px',
       //     width: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
       //     height: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
       // }}
      />
      {/* <div className="absolute -top-1 -right-1">
    <SmallTickIcon TooltipTitle="verified"  color="#8937CE" />
</div> */}
     </div>
    )}
    <div className="right">
     <div className={`text  ${!isSender ? "bg-white" : "text-white bg-[#531F7F]"}`}>
      <p className="font-bold mb-3">
       {`${message?.sender?.first_name} ${message?.sender?.last_name} (${
        message?.sender?.user_type === "client" ? "Client" : "Creator"
       })`}
      </p>
      <p>{message?.message}</p>
     </div>

     <p className={`time w-fit mb-1  ${isSender ? "mr-auto" : "ml-auto"}`}>{formatDateTime(message?.created_at)}</p>
    </div>
    {isSender && (
     // <Avatar
     //   className="avatar ml-3"
     //   // src={senderInfo?.avatar?.url || ""}
     //   src={message?.sender?.profile_image}
     //   alt={"Sender"}
     // />
     <div className="relative h-[40px] w-[40px]">
      <Avatar
       src={message?.sender?.profile_image}
       alt="avatar"
       className="w-full"
       // sx={{
       //     borderRadius: imgRounded ? '50%' : '4px',
       //     width: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
       //     height: size === 'medium' ? 40 : size === 'small' ? 30 : 50,
       // }}
      />
      {message?.sender?.kyc_verified && (
       <div className="absolute -top-1 -right-1">
        <SmallTickIcon TooltipTitle="verified" color="#8937CE" />
       </div>
      )}
     </div>
    )}
   </div>
  </div>
 );
};

const MessageBox = ({ isAdmin = false, messages, loading }) => {
 const [attachment, setAttachment] = useState(null);
 const [value, setValue] = useState("");

 console.log(messages);

 return (
  <div className="message__box ">
   <div className="bg-[#EAEAF0] max-h-[70vh] overflow-y-auto flex flex-col p-[24px] scrollbar-style">
    <div className="flex justify-center mt-[20px] ">
     {messages?.length !== 0 ||
      (loading && (
       <p
        className=" px-[12px] py-[8px] bg-[#F9F9FB]
        rounded-[40px] max-w-[150px]  text-center  "
       >
        June 20, 2022
       </p>
      ))}
    </div>
    <div className="mt-[3rem]">
     {messages?.length === 0 || loading ? (
      <EmptyResponse
       message={"No conversations yet"}
       icon=<MessageIcon />
       className="bg-[#F9F9FB] w-[108%] -left-[4%] relative min-h-[20rem] my-0 -mb-4 rounded-b-2xl"
      />
     ) : (
      <>
       {messages?.map((message) => (
        <MessageWidget key={message?.id} message={message} isSender={message?.sender?.user_type === "creator"} />
       ))}
      </>
     )}
    </div>
   </div>

   {!isAdmin && (
    <form
     onSubmit={(e) => {
      e.preventDefault();
     }}
     className="bg-white p-4 flex items-end gap-4 "
    >
     <div className="bg-[#EAEAF0] px-2 w-full rounded-[8px]">
      {attachment && (
       <div className="p-3 bg-white mt-2">
        <FileDisplayUi
         name={truncateString(attachment.name, 30)}
         isFileLarge={attachment?.size > 1000000}
         downloadType={false}
         onCancel={() => setAttachment(null)}
         size={getFileSize(attachment?.size)}
        />
       </div>
      )}
      <div className="flex items-center gap-3 min-h-[48px] h-[50px]">
       <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="w-full focus:outline-none bg-transparent min-h-[48px] h-[48px]"
       />
       <DropDownWrapper
        contentPadding="p-0"
        orientation="top"
        mobilePosition={"-right-10"}
        position="right"
        action={
         <button type="button" className="my-auto">
          <img src={emojiIcon} alt="icon" className="h-[22px] w-[22px] " />
         </button>
        }
       >
        <div>
         <EmojiPicker
          onEmojiClick={(e, val) => setValue((prev) => `${prev}${val.emoji}`)}
          disableAutoFocus={true}
          native
         />
        </div>
       </DropDownWrapper>
       <label className="cursor-pointer">
        <img src={paperClip} alt="icon" className="h-[22px] w-[22px] " />
        <input type="file" accept="image/*" onChange={(e) => setAttachment(e.target.files[0])} hidden />
       </label>
      </div>
     </div>
     <button className="h-[50px] w-[55px] rounded-[8px] flex items-center justify-center bg-[#8937CE] ">
      <SendIconPlane color="#fff" className="h-[25px] w-[25px] " />
     </button>
    </form>
   )}
  </div>
 );
};
export default MessageBox;
