import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
// import { FOCUSABLE_SELECTOR } from "@testing-library/user-event/dist/utils";
// import ExportBtn from "components/ExportBtn";
import MyDataTable, { TableDropDown } from "components/TableComponent/MyDataTable";
import UserDisplay from "components/TableComponent/UserDisplay";
import { useState } from "react";
import TransactionDetailsModal from "../Widgets/CampaignTransactionDetailsModal";
import { useGetInfluencerEarningsQuery } from "services/transactions.api";
import { formatDate, getUserDetails, toCurrency } from "utils";
import { STATUS2 } from "utils/constants";
import WalletIcon from "components/Vectors/WalletIcon";

const InfluencerEarningTabs = () => {
 const [openTxnDetModal, setOpenTxnDetModal] = useState(false);

 const JOBS_PER_PAGE = 16;
 const [page, setPage] = useState(1);
 const [perPage, setPerPage] = useState(JOBS_PER_PAGE);

 const { data, isLoading } = useGetInfluencerEarningsQuery({
  params: { is_paginated: "yes", page, per_page: perPage },
 });

 if (data) console.log(data);

 const options = {
  searchOpen: true,
  viewColumns: false,
  filter: true,
  serverSide: true,
  selectableRows: "none",
  count: data?.total,
  rowsPerPage: perPage,
  onTableChange: (action, tableState) => {
   switch (action) {
    case "changePage":
     setPage(tableState.page + 1);
     break;
    case "changeRowsPerPage":
     setPerPage(tableState.rowsPerPage);
     break;
    default:
     break;
   }
  },
 };

 return (
  <div className="p-4 bg-white py-6 border rounded-xl mt-5">
   {isLoading || data?.data?.length > 0 ? (
    <MyDataTable
     searchPlaceholder="Select Service, influencer, buyer,..."
     options={options}
     isLoading={isLoading}
     headCells={[
      {
       name: "sn",
       label: "S/N",
      },
      {
       name: "channel",
       label: "channel",
       options: {
        customBodyRender: (value) => <UserDisplay name={value.name} img={value.img} />,
       },
      },
      { name: "category", label: "category" },
      {
       name: "client",
       label: "client",
       options: {
        customBodyRender: (value) => <UserDisplay name={value.name} email={value.searchBy} img={value.img} />,
       },
      },
      {
       name: "influencer",
       label: "influencer",
       options: {
        customBodyRender: (value) => <UserDisplay name={value.name} email={value.searchBy} img={value.img} />,
       },
      },
      { name: "price", label: "Unit Price" },
      { name: "date", label: "Date Completed" },
      {
       name: "location",
       label: "location",
       options: {
        customBodyRender: (value) => <UserDisplay name={value.name} img={value.img} />,
       },
      },
      {
       name: "status",
       label: "Status",
       options: {
        filter: true,
        customBodyRender: (value) => (
         <Chip
          color={`${STATUS2[value.toLowerCase()] || "greyBorder"}`}
          // sx={{minWidth: 90}}
          label={`${value}`}
         />
        ),
       },
      },
      {
       name: "actions",
       label: null,
       options: {
        customBodyRender: (children) => <TableDropDown tableId={"table"}>{children}</TableDropDown>,
       },
      },
     ]}
     data={data?.data?.map((el, i) => ({
      sn: (data?.current_page - 1) * data?.per_page + (i + 1),
      client: {
       name: getUserDetails(el?.client).name,
       searchBy: `@${el?.client?.username}`,
       img: el?.client?.profile_image,
      },
      influencer: {
       name: getUserDetails(el?.influencer).name,
       type: `@${el?.influencer?.username}`,
       img: el?.influencer?.profile_image,
      },
      category: el?.task?.campaign?.sub_task?.title,
      channel: {
       name: el?.task?.campaign?.campaign_channel?.name,
       img: el?.task?.campaign?.campaign_channel?.icon,
      },
      location: {
       name: el?.influencer?.state?.country_details?.name,
       img: el?.influencer?.state?.country_details?.country_flag,
      },
      price: toCurrency(el?.task?.campaign?.amount_per_influencer),
      date: el?.task?.task_accepted_at ? formatDate(el?.task?.task_accepted_at) : "N/A",
      status: el?.status,
      actions: (
       <>
        <button onClick={() => setOpenTxnDetModal(!openTxnDetModal)}>
         <RemoveRedEyeOutlined /> View
        </button>
       </>
      ),
     }))}
    />
   ) : (
    <div className="flex items-center justify-center flex-col gap-4 h-[500px]">
     <WalletIcon height={80} width={80} color="#A2A2B9" />
     <h4 className="text-[#53536F] text-2xl font-semibold">No Records yet</h4>
    </div>
   )}
  </div>
 );
};

export default InfluencerEarningTabs;
