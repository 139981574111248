import React from 'react'

const PenIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || "32"}
      height={height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.64 17.5999C22.3867 17.5999 22.12 17.4933 21.9333 17.3066L14.7333 10.0933C14.4 9.75992 14.3467 9.25326 14.6 8.85326L16.8533 5.29326C17.48 4.30659 18.4267 3.69326 19.5333 3.57326C20.7733 3.45326 22.0533 3.93326 23.0533 4.93326L27.0933 8.97326C28.0533 9.93326 28.5333 11.2266 28.3867 12.5066C28.2667 13.6266 27.6667 14.5999 26.7333 15.1866L23.1733 17.4399C23.0133 17.5466 22.8267 17.5999 22.64 17.5999ZM16.7067 9.25326L22.7867 15.3333L25.68 13.5066C26.0933 13.2399 26.36 12.8133 26.4133 12.2933C26.4933 11.6266 26.2133 10.9199 25.6933 10.3999L21.6533 6.35992C21.08 5.78659 20.3867 5.47992 19.76 5.57326C19.2533 5.62659 18.84 5.90659 18.5467 6.37326L16.7067 9.25326Z"
        fill={fill || "#8937CE"}
      />
      <path
        d="M7.85334 28.4532C6.66667 28.4532 5.6 28.0265 4.8 27.2399C3.86667 26.3065 3.45334 25.0132 3.62667 23.5732L4.93334 12.4665C5.33334 9.10654 6.81334 7.87987 10.2667 8.07987L15.4933 8.38654C16.04 8.42654 16.4667 8.89321 16.4267 9.43987C16.3867 9.98654 15.92 10.4132 15.3733 10.3732L10.1467 10.0799C7.77334 9.93321 7.18667 10.3999 6.92 12.7065L5.61334 23.7999C5.52 24.6132 5.73334 25.3332 6.21334 25.8132C6.70667 26.2932 7.42667 26.5199 8.24 26.4132L19.3333 25.1065C21.6667 24.8265 22.1867 24.2132 21.96 21.9199L21.64 16.6532C21.6133 16.1065 22.0267 15.6265 22.5733 15.5999C23.12 15.5599 23.6 15.9865 23.6267 16.5332L23.9333 21.7599C24.2533 25.0799 22.9467 26.6799 19.5467 27.0932L8.45334 28.3999C8.26667 28.4399 8.05334 28.4532 7.85334 28.4532Z"
        fill={fill || "#8937CE"}
      />
      <path
        d="M6.14666 26.8933C5.89333 26.8933 5.63999 26.7999 5.43999 26.5999C5.05333 26.2133 5.05333 25.5733 5.43999 25.1866L9.49333 21.1333C9.87999 20.7466 10.52 20.7466 10.9067 21.1333C11.2933 21.5199 11.2933 22.1599 10.9067 22.5466L6.85333 26.5999C6.65333 26.7999 6.39999 26.8933 6.14666 26.8933Z"
        fill={fill || "#8937CE"}
      />
    </svg>
  );
}

export default PenIcon