import {useCallback, useState} from "react";

export default function useClientRect() {
  const [rect, setRect] = useState();

  const ref = useCallback(node => {
    if (node !== null) {
      let clientRect = node.getBoundingClientRect();
      setRect(clientRect);
      window.addEventListener("scroll", () => setRect(clientRect));
    }
  }, []);
  return [rect, ref];
}

export const getRect = node => {
  let clientRect;
  if (node !== null) {
    clientRect = node.getBoundingClientRect();
    window.addEventListener(
      "scroll",
      () => (clientRect = node.getBoundingClientRect())
    );
  }
  return clientRect;
};
