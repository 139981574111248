import { Button } from "@mui/material";
import InputField from "components/forms/InputField";
import LoadingBtn from "components/forms/LoadingBtn";
import TextAreaField from "components/forms/TextAreaField";
import ValidatedInput from "components/forms/ValidatedInput";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import Modal from "components/Modal";
import MessageModal from "components/Modal/MessageModal";
import ServicesIcon from "components/Vectors/ServicesIcon";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useUpdateServiceStatusMutation } from "services";
import { showToast } from "store/store.hooks";
import { handleError } from "utils";

function DisableModal({
  openModal,
  setopenModal,
  otherState,
  disabledTitle,
  title,
  id,
  userSlug,
  fullName,
}) {
  const methods = useForm();
  const [openSuccessModal, setopenSuccessModal] = useState(false);

  const [updateStatus, { isLoading }] = useUpdateServiceStatusMutation();

  const handleClick = () => {
    if (otherState) {
      otherState();
    }

    setopenModal(false);
    setopenSuccessModal(true);
  };

  // console.log(userSlug, id);

  const handleSubmit = async (data) => {
    // console.log(data);
    try {
      let res = await updateStatus({
        id: id,
        body: {
          comment: data?.comment,
          status: "Suspended",
          redirect_url: `https://hookik-staging.vercel.app/${userSlug}/services/${id}`,
        },
      }).unwrap();
      console.log(res);
      showToast(res?.message);
      methods.reset();
      handleClick();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Modal
        title={title ? title : `Disable Service`}
        openModal={openModal}
        closeModal={() => setopenModal(false)}
      >
        <FormProvider {...methods}>
          <form
            className="flex flex-col h-full flex-grow justify-between"
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <div>
              {/* <ValidatedInput
                name="email"
                label="Email Address "
                placeholder="user@gmail.com"
              /> */}
              <ValidatedTextArea
                name="comment"
                label="Send a message"
                placeholder="Enter text..."
              />
            </div>
            <LoadingBtn
              type="submit"
              loading={isLoading}
              sx={{ mt: 5 }}
              variant="contained"
              fullWidth
              color="error"
            >
              Disable
            </LoadingBtn>
          </form>
        </FormProvider>
      </Modal>

      <MessageModal
        openModal={openSuccessModal}
        icon={<ServicesIcon color="#D61E34" />}
        closeModal={() => setopenSuccessModal(false)}
        iconBg={"bg-error-100"}
        title={`${disabledTitle ? disabledTitle : "Service Disabled"}`}
        description={`A message has been sent to ${fullName || "Nneka Chukwu"}`}
      />
    </>
  );
}

export default DisableModal;
