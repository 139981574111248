import { Button, CircularProgress } from "@mui/material";
import Modal from "components/Modal";
import "./style.scss";
import { useForm, FormProvider } from "react-hook-form";
import ValidatedTextArea from "components/forms/ValidatedTextArea";
import { useDeclineUserKycMutation } from "services";
import { handleError } from "utils";
import { showToast } from "store/store.hooks";
import { LoadingButton } from "@mui/lab";

const DeclineModal = ({
 openModal,
 setOpenModal,
 btnColor,
 btn2Color,
 title,
 desc,
 buttonTitle,
 button2Title,
 btn2,
 userId,
}) => {
 const methods = useForm();
 const [declineKyc, { isLoading }] = useDeclineUserKycMutation();
 const onSubmit = async (body) => {
  try {
   let res = await declineKyc({
    id: userId,
    body,
   }).unwrap();
   showToast(res?.message);
   setOpenModal(false);
  } catch (error) {
   handleError(error);
  }
 };

 return (
  <Modal openModal={openModal} closeModal={() => setOpenModal(!openModal)} title={title}>
   <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col justify-between flex-grow">
     <ValidatedTextArea name="comment" label="Send a message" />

     <div className="mt-[auto] ">
      <div className="flex items-center gap-4 justify-between ">
       {btn2 && (
        <Button
         onClick={() => setOpenModal(!openModal)}
         color={btn2Color}
         variant="contained"
         sx={{ width: "100%", mt: 2 }}
        >
         {button2Title}
        </Button>
       )}
       <LoadingButton
        loadingIndicator={
         <CircularProgress
          sx={{
           color: "#ffffff",
          }}
          size="1.2rem"
         />
        }
        type="submit"
        loading={isLoading}
        color={btnColor}
        variant="contained"
        sx={{ width: "100%", mt: 2 }}
       >
        {buttonTitle}
       </LoadingButton>
      </div>
     </div>
    </form>
   </FormProvider>
  </Modal>
 );
};
export default DeclineModal;
